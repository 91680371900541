import { ArchitectureType, SourceLanguage } from '../../common'
import ApiService from '../../services/api.service'
import { toLowerCaseKeys } from '../../utils'
import { 
  FunctionBlockCategoryModel, 
  FunctionBlockDefinitionV2,
  FunctionBlockModel,
  FunctionBlockPackageInfoModel,
  FunctionDefinition,
  GlobalVariableModel,
  SolutionHardwareDefinitionCategoryModel,
  SolutionHardwareDefinitionModel, 
} from '../models'

export default {
  async functionBlocks(language: string): Promise<FunctionBlockModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().functionBlockQueryApi}/FunctionBlock?resourceLanguage=${language}`)
    // api returns uppercase keys    
    const data: FunctionBlockModel[] = toLowerCaseKeys(response.data).value
    data.forEach(fb => {
      fb.architectureType = ArchitectureType.Classic
      fb.sourceLanguage = SourceLanguage.StructuredText
    })
    return data
  },
  async functionBlockCategories(language: string): Promise<FunctionBlockCategoryModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().functionBlockQueryApi}/FunctionBlockCategory?resourceLanguage=${language}`)
    // api returns uppercase keys    
    return toLowerCaseKeys(response.data).value
  },
  async solutionHardwareDefinitions(language: string): Promise<SolutionHardwareDefinitionModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().systemConfigurationQueryApi}/SolutionHardwareDefinition?resourceLanguage=${language}`)
    return response.data.value
  },
  async solutionHardwareDefinitionCategories(language: string): Promise<SolutionHardwareDefinitionCategoryModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().systemConfigurationQueryApi}/SolutionHardwareDefinitionCategory?resourceLanguage=${language}`)
    return response.data.value
  },
  async globalVariables(): Promise<GlobalVariableModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().functionBlockQueryApi}/GlobalVariables`)
    // api returns uppercase keys    
    return toLowerCaseKeys(response.data).value
  },
  async functionDefinition(id: string): Promise<FunctionBlockDefinitionV2> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().functionBlockQueryApi}/FunctionBlock/${id}`)
    // api returns uppercase keys    
    const data: FunctionDefinition = toLowerCaseKeys(response.data)
    // convert to v2
    return {
      id: data.id,
      internalName: data.internalName,
      xml: data.functionBlockXml,
      version: data.version,
      nameResourceId: data.nameResourceId,
      descriptionResourceId: data.descriptionResourceId,
      iconResourceId: data.iconResourceId,
      functionBlockDefinitionCategoryId: data.categoryId,
      twinCatLibraryDependency: data.twinCatLibraryDependency,
      cycleTimeInMilliseconds: 0,
      messagesEnumInternalName: '',
      controlDefinitionMappings: data.controlDefinitionIds.map(cdid => {
        const defaultValue = data.defaultValues.find(_ => _.controlDefinitionId === cdid)
        return {
          controlDefinitionId: cdid,
          defaultValues: {
            defaultValue: defaultValue?.defaultValue,
          },
        }
      }),
      endpoints: [],
      autoMappingSlots: data.autoMappingSlots,
      endpointResources: data.endpointResources,
      instanceResources: [],
      measuringPoints: [],
      energyStatusItems: [],
      defaultAlarmDefinitions:[],
      architectureType: ArchitectureType.Classic,
      sourceLanguage: SourceLanguage.StructuredText,
      licenseTags: [],
    }
  },
  // query.systemconfiguration
  async functionBlockDefinitions(language: string): Promise<FunctionBlockModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().systemConfigurationQueryApi}/FunctionBlockDefinition?resourceLanguage=${language}`)
    const data: FunctionBlockModel[] = response.data.value
    data.forEach(fb => {
      fb.architectureType = ArchitectureType.ObjectOriented
      fb.sourceLanguage = SourceLanguage.StructuredText
    })    
    return data
  },
  async functionBlockDefinitionCategories(language: string): Promise<FunctionBlockCategoryModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().systemConfigurationQueryApi}/FunctionBlockDefinitionCategory?resourceLanguage=${language}`)
    return response.data.value
  },
  async functionBlockDefinition(id: string): Promise<FunctionBlockDefinitionV2> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().systemConfigurationQueryApi}/FunctionBlockDefinition/${id}`)
    const data: FunctionBlockDefinitionV2 = response.data
    data.architectureType = ArchitectureType.ObjectOriented
    data.sourceLanguage = SourceLanguage.StructuredText
    return data
  },
  async cSharpFunctionBlockDefinitions(language: string): Promise<FunctionBlockModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().systemConfigurationQueryApi}/CSharpFunctionBlockDefinition?resourceLanguage=${language}`)
    const data: FunctionBlockModel[] = response.data.value
    data.forEach(fb => {
      fb.architectureType = ArchitectureType.ObjectOriented
      fb.sourceLanguage = SourceLanguage.CSharp
    })
    return data
  },
  async cSharpFunctionBlockDefinition(id: string): Promise<FunctionBlockDefinitionV2> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().systemConfigurationQueryApi}/CSharpFunctionBlockDefinition/${id}`)
    const data: FunctionBlockDefinitionV2 = response.data
    data.architectureType = ArchitectureType.ObjectOriented
    data.sourceLanguage = SourceLanguage.CSharp
    return data
  },
  async cSharpFunctionBlockPackageInfo(): Promise<FunctionBlockPackageInfoModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().systemConfigurationQueryApi}/CSharpFunctionBlockDefinition/packageInfo`)
    return response.data.value
  },
}