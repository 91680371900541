
import { OneClickTargetSystem } from './models'
import { ConsumptionState } from './types'

export const state: ConsumptionState = {
  projects: [],
  projectId: '',
  targetSystem: OneClickTargetSystem.Ecocoach,
  accounts: [],
  meters: [],
  users: [],
  minergieInfo: null,
  minergieChartData: [],
  egonlineConfiguration: null,
}
