<template>
    <div class="mobile-app">
      <wizard-step-header 
        :title="'navigation.mobileapp' | translate" 
        :sub-title="'mobileapp.desktop.access' | translate"
      />
        <div class="usageNote" >
            <div v-html="dictionary('information.usagenote')" />
        </div>
        <div>
        <base-text-button
          @buttonClicked="redirect"
        >
          {{'productionmobileapp.link' | translate}}
        </base-text-button>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const Resource = namespace('resource')

@Component
export default class MobileApp extends Vue {
    @Resource.Getter public dictionary

    public redirect() {
      window.open('https://productionmobileapp.ecocoa.ch/', '_blank')
    }

}
</script>

<style lang="scss" scoped>
    header {
        padding: 30px 0 0 25px;
        height: 100px;
    }
    .mobile-app {
        padding-top: 10px;
        .usageNote {
            padding-top: 15px;
            padding-left: 30px;
            p {color: white;}
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
        }
        .v-btn {
            margin-left: 30px;
            border: 1px solid white;
        }
    }
</style>

