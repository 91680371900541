<template>
  <div class="manageProjects">
		<header>
			<div class="dashboard__title">
				<h1>{{ 'partner.manage.projects' | translate }}</h1>
			</div>
		</header>
		<project-component
			v-for="item in projectsWithPlcs"
			:key="item.project.id"
			:project="item.project"
      :plcs="item.plcs"
    />
		<string-add-component 
			:pending="pending"
			@stringAddConfirmed="handleAddProject"
			:placeholder="dictionary('manage.project.add')"/>
  </div>
</template>

<script lang="ts">
import ProjectComponent from '@/components/views/ecoPartnerTool/ProjectComponent.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { PartnerModel } from '../../../../../eco-domain-store-modules/src/authorization/models'

import { ProjectWithPlcs } from '../../../store/modules/projectManagementUi/models'

const Authorization = namespace('authorization')
const App = namespace('app')
const ProjectManagementUi = namespace('projectManagementUi')
const Resource = namespace('resource')

@Component({
  components: {
    ProjectComponent,
  },
})
export default class PartnerTool extends Vue {
  @ProjectManagementUi.Getter public projectsWithPlcs: ProjectWithPlcs[]
  @ProjectManagementUi.Getter public isInteracted: boolean
  @ProjectManagementUi.Action public createProject: (payload: { name: string }) => Promise<void>
  @Authorization.Getter public partners: PartnerModel[]
  @Resource.Getter public dictionary
  @App.Getter public userIdentifier: string

  public pending = false

  public async handleAddProject(text: string) {
    if (this.partners.length === 0) {
      this.$store.commit('wizard/setToastContent', this.dictionary('error.no.partner.loaded'), {root: true})
      this.$store.commit('wizard/showToast', {root: true})
    }
    try {
      this.pending = true
      await this.createProject({
        name: text.trim(),
      })
    } finally {
      this.pending = false
    }
  }
}
</script>
<style lang="scss" scoped>
.string-add-component{
  border-style: solid;
  border-color: #000;
  border-width: 1px;
  border-spacing: 0;
	background: linear-gradient(to bottom, #0f3a64, #0f3a64); 
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #0f3a64), color-stop(0.6, #0f3a64));	
  padding-right: 20px;
}
.projectTable {
	width: 100%;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-spacing: 0px;
  text-align: right;

	th {
		&.nameCol {
			font-weight: bold;
			justify-content: center;
			text-align: left;
			width: 45%;
		}

		&.actionsCol {
			font-weight: bold;
			justify-content: center;
			text-align: left;
			width: 20%; 
			.material-icons {
        max-width: 24px;
        color: white;
      }
		}
	}
  .plcs{
    background: linear-gradient(to bottom, #0f3a64, #0f3a64); 
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #0f3a64), color-stop(0.6, #0f3a64));
	}
}

.headerRow {
	background: linear-gradient(to bottom, #0f3a64, #0f3a64); 
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #0f3a64), color-stop(0.6, #0f3a64));
}

.manageProjects{
	width: 80%;
	margin-left: 25px;
	color: #fff;
}
    header {
        height: 100px;
        margin-right: 35px;
        display: flex;
        align-items: center;
        justify-content: left;
       .dashboard__title {
            margin-left: 0;
            h1 {
                text-transform: uppercase;
                text-align: left;
                color: #fff;
                font-weight: 200;
                font-family: Titillium Web,sans-serif;
            }
        }
    }
</style>

