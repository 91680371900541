import { PlcModel } from './models'

export interface PlcState {
  plcs: PlcModel[]
}

export enum PlcAction {
  loadPlcs = 'loadPlcs',
  connectToPlcStateHub = 'connectToPlcStateHub',
  disconnectFromPlcStateHub = 'disconnectFromPlcStateHub',
  startForPlcs = 'startForPlcs',
  stopForPlcs = 'stopForPlcs',
}

export enum PlcMutation {
  setPlcs = 'setPlcs',
  upsertPlc = 'upsertPlc',
}
