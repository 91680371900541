import { GetterTree } from 'vuex'
import { UserSettingsModel } from './models'
import { UserSettingsGetter, UserSettingsState } from './types'

export const getters: GetterTree<UserSettingsState, {}> = {
  [UserSettingsGetter.currentUserSettings]({ currentUserSettings }): UserSettingsModel {
    return currentUserSettings
  },
  [UserSettingsGetter.isFeatureOn]({ currentUserSettings }) {
    return (featureId: string): boolean => {
      return currentUserSettings.featureSwitches.includes(featureId)
    }
  },
  [UserSettingsGetter.isEmailMfaEnabled]({ currentUserSettings }): boolean | null {
    return currentUserSettings.emailMfaEnabled
  },
}
