import ApiService from '../../services/api.service'
import { AlarmDefinitionAuthorizationsModel, DeviceAuthorizationsModel, PartnerModel, PlcAuthorizationsModel, ProjectAuthorizationsModel, ProjectModel, RoomModel, UserPermissions } from '../models'
import { permissionConverter } from '../permissionConverter'

export default {
  async partners(): Promise<PartnerModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().projectApi}/Partners`)
    return response.data.value
  },
  async projects(): Promise<ProjectModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().projectApi}/Authorizations/projects`)
    return response.data.value
  },
  async consumptionProjects(): Promise<ProjectModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Authorizations/projects`)
    return response.data.value
  },
  async projectAuthorizations(projectId: string): Promise<ProjectAuthorizationsModel> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().projectApi}/Authorizations/projects/${projectId}`)
    return {
      id: response.data.projectId,
      permissions: response.data.permissions.map(p => {
        return {
          userIdentifier: p.userIdentifier,
          permissions: permissionConverter.stringToPermissions(p.role),
        } as UserPermissions
      }),
    } as ProjectAuthorizationsModel
  },
  async consumptionAuthorizations(projectId: string): Promise<ProjectAuthorizationsModel> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().consumptionQueryApi}/Authorizations/projects/${projectId}`)
    return {
      id: response.data.projectId,
      permissions: response.data.permissions.map(p => {
        return {
          userIdentifier: p.userIdentifier,
          permissions: permissionConverter.stringToPermissions(p.role),
        } as UserPermissions
      }),
    } as ProjectAuthorizationsModel
  },
  async plcAuthorizations(projectId: string): Promise<PlcAuthorizationsModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationQueryApi}/Authorizations/projects/${projectId}/plcs`)
    return response.data.value.map(plc => {
      return {
        id: plc.plcId,
        projectId: plc.projectId,
        name: plc.name,
        permissions: plc.permissions.map(p => {
          return {
            userIdentifier: p.userIdentifier,
            permissions: permissionConverter.stringToPermissions(p.role),
          } as UserPermissions
        }),
      } as PlcAuthorizationsModel
    })
  },
  async deviceAuthorizations(projectId: string): Promise<{ devices: DeviceAuthorizationsModel[], rooms: RoomModel[] }> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationQueryApi}/Authorizations/projects/${projectId}/devices`)
    return {
      devices: response.data.value.map(device => {
        return {
          id: device.deviceId,
          plcId: device.plcId,
          projectId: device.projectId,
          roomId: device.roomId,
          name: device.name,
          icon: device.icon,
          sortOrder: device.sortOrder,
          permissions: device.permissions.map(p => {
            return {
              userIdentifier: p.userIdentifier,
              permissions: permissionConverter.stringToPermissions(p.role),
            } as UserPermissions
          }),
        } as DeviceAuthorizationsModel
      }),
      rooms: response.data.rooms,
    }
  },
  async alarmDefinitionAuthorizations(projectId: string): Promise<AlarmDefinitionAuthorizationsModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationQueryApi}/Authorizations/projects/${projectId}/alarmDefinitions`)
    return response.data.value.map(alarmDefinition => {
      return {
        id: alarmDefinition.alarmDefinitionId,
        plcId: alarmDefinition.plcId,
        projectId: alarmDefinition.projectId,
        name: alarmDefinition.name,
        nameResourceId: alarmDefinition.nameResourceId,
        permissions: alarmDefinition.permissions.map(p => {
          return {
            userIdentifier: p.userIdentifier,
            permissions: permissionConverter.stringToPermissions(p.role),
          } as UserPermissions
        }),
      } as AlarmDefinitionAuthorizationsModel
    })
  },
  async resolveAppRegistrationNames(appIds: string[]): Promise<Map<string, string>> {
    if (!appIds.length) {
      return new Map<string, string>()
    }
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().projectApi}/AppRegistrations/resolvenames`, { externalIdentifiers: appIds })
    return new Map(Object.entries(response.data.value))
  },
}