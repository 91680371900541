<template>
  <modal-dialog :show="show"
    :header="'common.button.download' | translate"
    :button1-text="'common.button.download' | translate"
    :button2-text="'common.button.cancel' | translate"
    :button1-disabled="isInteracted"
    :button2-disabled="isInteracted"
    @button1Pressed="onDownload"
    @button2Pressed="onClose"
  >
    <v-layout column>
      <v-flex>
        <checkbox-with-label
          :value="useDateTimeFilter"
          :text="'logtool.datetimefilter' | translate"
          :disabled="isInteracted"
          @checked="useDateTimeFilter = !$event"
        />
        <form-field :text="'logtool.starttime' | translate">
          <div class="date-time-select">
            <date-select
              :value="startDate"
              :language="selectedLanguage"
              :disabled="isInteracted || !useDateTimeFilter"
              @changed="onStartDateChanged"
            />
            <time-select
              :value="startTime"
              :disabled="isInteracted || !useDateTimeFilter"
              @changed="onStartTimeChanged"
            />
          </div>
        </form-field>
        <form-field :text="'logtool.endtime' | translate">
          <div class="date-time-select">
            <date-select 
              :value="endDate" 
              :language="selectedLanguage"
              :disabled="isInteracted || !useDateTimeFilter"
              @changed="onEndDateChanged"
            />
            <time-select 
              :value="endTime" 
              :disabled="isInteracted || !useDateTimeFilter"
              @changed="onEndTimeChanged"
            />
          </div>
        </form-field>
      </v-flex>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { getLocalDateFromUtcDateTime, getLocalTimeFromUtcDateTime, updateUtcDateTimeWithLocalDate, updateUtcDateTimeWithLocalTime } from '../../../../../store/modules/logToolUi/helpers'
import { EndpointRecordingConfigurationModel, EndpointRecordingDataInput } from '../../../../../store/modules/logToolUi/models'

import moment from 'moment'

const LogToolUi = namespace('logToolUi')
const Resource = namespace('resource')
const App = namespace('app')

@Component({
  components: {
  },
})
export default class DownloadEndpointRecordingDataDialog extends Vue {
  @Prop() public show: boolean
  @Prop() public configuration: EndpointRecordingConfigurationModel
  @LogToolUi.Getter public isInteracted: boolean
  @LogToolUi.Action public downloadEndpointRecordingData: (payload: EndpointRecordingDataInput) => Promise<void>
  @LogToolUi.Action public downloadAllEndpointRecordingData: (payload: EndpointRecordingDataInput) => Promise<void>
  @App.Getter public selectedLanguage: string
  @Resource.Getter public dictionary

  public useDateTimeFilter = false
  public startDateTime = ''
  public endDateTime = ''

  @Watch('show')
  public async onConfigurationChanged() {
    if (this.configuration) { // download only one
      this.startDateTime = this.configuration.startDateTimeUtc
      this.endDateTime = this.configuration.endDateTimeUtc
    } else { // download all
      this.startDateTime = moment.utc().startOf('minute').subtract(1, 'day').toISOString()
      this.endDateTime = moment.utc().startOf('minute').toISOString()
    }
  }

  public get startDate() {
    return getLocalDateFromUtcDateTime(this.startDateTime)
  }

  public get startTime() {
    return getLocalTimeFromUtcDateTime(this.startDateTime)
  }

  public onStartDateChanged(newLocalDate: string) {
    this.startDateTime = updateUtcDateTimeWithLocalDate(this.startDateTime, newLocalDate)
  }

  public onStartTimeChanged(newLocalTime: string) {
    this.startDateTime = updateUtcDateTimeWithLocalTime(this.startDateTime, newLocalTime)
  }

  public get endDate() {
    return getLocalDateFromUtcDateTime(this.endDateTime)
  }

  public get endTime() {
    return getLocalTimeFromUtcDateTime(this.endDateTime)
  }

  public onEndDateChanged(newLocalDate: string) {
    this.endDateTime = updateUtcDateTimeWithLocalDate(this.endDateTime, newLocalDate)
  }

  public onEndTimeChanged(newLocalTime: string) {
    this.endDateTime = updateUtcDateTimeWithLocalTime(this.endDateTime, newLocalTime)
  }

  public async onDownload() {
    const dateFilter = {
      ...(this.useDateTimeFilter && { startDateTimeUtc: this.startDateTime }),
      ...(this.useDateTimeFilter && { endDateTimeUtc: this.endDateTime }),
    }
    if (this.configuration) {
      this.downloadEndpointRecordingData({
        id: this.configuration.id,
        ...dateFilter,
      } as EndpointRecordingDataInput)
    } else {
      this.downloadAllEndpointRecordingData({
        ...dateFilter,
      } as EndpointRecordingDataInput)
    }
    this.onClose()
  }

  @Emit('closed')
  public onClose() {
    return
  }
}
</script>
<style lang="scss" scoped>
.field {
  height: 70px;
  .text-input {
    padding-left: 10px;
    padding-right: 10px;
  }
  .label {
    padding-left: 10px;
  }
}
.date-time-select {
  display: flex;
  margin: -10px 10px 10px 10px;
  .date-select, .time-select {
    flex: 0;
    min-width: 150px;
    max-width: 150px;
  }
  .date-select {
    margin-right: 20px;
  }
}
</style>