import { MappingUiAction, MappingUiMutation, MappingUiState } from '@/store/modules/mappingUi/types'
import { RootState } from '@/store/types'
import { AutoMappingModel, DeviceMappingModel } from '@ecocoach/domain-store-modules/src/plcConfiguration/models'
import { ActionContext, ActionTree } from 'vuex'

export const actions: ActionTree<MappingUiState, RootState> = {
  async [MappingUiAction.addSolutionHardwareToCurrentManualMapping] ({ commit, dispatch, getters, state }
    : ActionContext<MappingUiState, RootState>, payload: { type: 'input' | 'output', id: string, endpointId: string }): Promise<void> {
    try {
      commit(MappingUiMutation.setInteracted, true)
      commit(MappingUiMutation.addSolutionHardwareToCurrentManualMapping, payload)
      if (getters.isCurrentManualMappingComplete) {
        const newDeviceMapping: DeviceMappingModel = {
          id: '', // created by api
          solutionHardwareInstanceId: state.currentManualMapping.solutionHardwareInstanceId,
          solutionHardwareEndpointId: state.currentManualMapping.solutionHardwareEndpointId,
          deviceId: state.currentManualMapping.deviceId,
          deviceEndpointId: state.currentManualMapping.deviceEndpointId,
        }
        dispatch(`plcConfiguration/createDeviceMapping`, newDeviceMapping, { root:true })
        commit(MappingUiMutation.resetCurrentManualMapping)
      }
    } finally {
      commit(MappingUiMutation.setInteracted, false)
    }
  },
  async [MappingUiAction.addDeviceToCurrentManualMapping] ({ commit, dispatch, getters, state }
    : ActionContext<MappingUiState, RootState>, payload: { type: 'input' | 'output', id: string, endpointId: string }): Promise<void> {
    try {
      commit(MappingUiMutation.setInteracted, true)
      commit(MappingUiMutation.addDeviceToCurrentManualMapping, payload)
      if (getters.isCurrentManualMappingComplete) {
        const newDeviceMapping: DeviceMappingModel = {
          id: '', // created by api
          solutionHardwareInstanceId: state.currentManualMapping.solutionHardwareInstanceId,
          solutionHardwareEndpointId: state.currentManualMapping.solutionHardwareEndpointId,
          deviceId: state.currentManualMapping.deviceId,
          deviceEndpointId: state.currentManualMapping.deviceEndpointId,
        }
        dispatch(`plcConfiguration/createDeviceMapping`, newDeviceMapping, { root:true })
        commit(MappingUiMutation.resetCurrentManualMapping)
      }
    } finally {
      commit(MappingUiMutation.setInteracted, false)
    }
  },
  async [MappingUiAction.removeManualMapping] ({ commit, dispatch }
    : ActionContext<MappingUiState, RootState>, payload: DeviceMappingModel): Promise<void> {
    try {
      commit(MappingUiMutation.setInteracted, true)
      commit(MappingUiMutation.resetCurrentManualMapping)
      dispatch(`plcConfiguration/deleteDeviceMapping`, payload.id, { root:true })
    } finally {
      commit(MappingUiMutation.setInteracted, false)
    }
  },
  async [MappingUiAction.addSolutionHardwareToCurrentAutoMapping] ({ commit, dispatch, getters, state }
    : ActionContext<MappingUiState, RootState>, payload: { interface: string, id: string, slot: string }): Promise<void> {
    try {
      commit(MappingUiMutation.setInteracted, true)
      commit(MappingUiMutation.addSolutionHardwareToCurrentAutoMapping, payload)
      if (getters.isCurrentAutoMappingComplete) {
        const newAutoMapping: AutoMappingModel = {
          id: '', // created by api
          solutionHardwareInstanceId: state.currentAutoMapping.solutionHardwareInstanceId,
          solutionHardwareSlotInternalName: state.currentAutoMapping.solutionHardwareSlotInternalName,
          deviceId: state.currentAutoMapping.deviceId,
          deviceSlotInternalName: state.currentAutoMapping.deviceSlotInternalName,
          instanceNumber: state.currentAutoMapping.instanceNumber,
        }
        dispatch(`plcConfiguration/createAutoMapping`, newAutoMapping, { root:true })
        commit(MappingUiMutation.resetCurrentAutoMapping)
      }
    } finally {
      commit(MappingUiMutation.setInteracted, false)
    }
  },
  async [MappingUiAction.addDeviceToCurrentAutoMapping] ({ commit, dispatch, getters, state }
    : ActionContext<MappingUiState, RootState>, payload: { interface: string, id: string, slot: string }): Promise<void> {
    try {
      commit(MappingUiMutation.setInteracted, true)
      commit(MappingUiMutation.addDeviceToCurrentAutoMapping, payload)
      if (getters.isCurrentAutoMappingComplete) {
        const newAutoMapping: AutoMappingModel = {
          id: '', // created by api
          solutionHardwareInstanceId: state.currentAutoMapping.solutionHardwareInstanceId,
          solutionHardwareSlotInternalName: state.currentAutoMapping.solutionHardwareSlotInternalName,
          deviceId: state.currentAutoMapping.deviceId,
          deviceSlotInternalName: state.currentAutoMapping.deviceSlotInternalName,
          instanceNumber: state.currentAutoMapping.instanceNumber,
        }
        dispatch(`plcConfiguration/createAutoMapping`, newAutoMapping, { root:true })
        commit(MappingUiMutation.resetCurrentAutoMapping)
      }
    } finally {
      commit(MappingUiMutation.setInteracted, false)
    }
  },
  async [MappingUiAction.addInstanceNumberToCurrentAutoMapping] ({ commit, dispatch, getters, state }
    : ActionContext<MappingUiState, RootState>, payload: { instanceNumber: number }): Promise<void> {
    try {
      commit(MappingUiMutation.setInteracted, true)
      commit(MappingUiMutation.addInstanceNumberToCurrentAutoMapping, payload)
      if (getters.isCurrentAutoMappingComplete) {
        const newAutoMapping: AutoMappingModel = {
          id: '', // created by api
          solutionHardwareInstanceId: state.currentAutoMapping.solutionHardwareInstanceId,
          solutionHardwareSlotInternalName: state.currentAutoMapping.solutionHardwareSlotInternalName,
          deviceId: state.currentAutoMapping.deviceId,
          deviceSlotInternalName: state.currentAutoMapping.deviceSlotInternalName,
          instanceNumber: state.currentAutoMapping.instanceNumber,
        }
        dispatch(`plcConfiguration/createAutoMapping`, newAutoMapping, { root:true })
        commit(MappingUiMutation.resetCurrentAutoMapping)
      }
    } finally {
      commit(MappingUiMutation.setInteracted, false)
    }
  },
  async [MappingUiAction.removeAutoMapping] ({ commit, dispatch }
    : ActionContext<MappingUiState, RootState>, payload: AutoMappingModel): Promise<void> {
    try {
      commit(MappingUiMutation.setInteracted, true)
      commit(MappingUiMutation.resetCurrentAutoMapping)
      dispatch(`plcConfiguration/deleteAutoMapping`, payload.id, { root:true })
    } finally {
      commit(MappingUiMutation.setInteracted, false)
    }
  },
}
