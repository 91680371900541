import { MutationTree } from 'vuex'
import { UserSettingsMutation, UserSettingsState } from './types'

export const mutations: MutationTree<UserSettingsState> = {
  [UserSettingsMutation.setCurrentUserFeatureSwitches](state, payload: string[]) {
    state.currentUserSettings.featureSwitches = payload
  },
  [UserSettingsMutation.setEmailMfaEnabled](state, payload: boolean) {
    state.currentUserSettings.emailMfaEnabled = payload
  },
}
