import { ActionTree } from 'vuex'
import { ArchitectureType } from '../common'
import CacheService from '../services/cache.service'
import commandApi from './api/command'
import queryApi from './api/query'
import { SetFunctionBlockObsoleteInput } from './models'
import { SystemConfigurationAction, SystemConfigurationMutation, SystemConfigurationState } from './types'

export const actions: ActionTree<SystemConfigurationState, {}> = {
  async [SystemConfigurationAction.loadSystemConfiguration]({ commit }, payload: { language: string, isEcocoachEmployee: boolean }) {
    await CacheService.load({
      action: SystemConfigurationAction.loadSystemConfiguration,
      parameters: [payload.language],
      load: () => Promise.all([
        queryApi.functionBlockDefinitions(payload.language),
        queryApi.cSharpFunctionBlockDefinitions(payload.language),
        queryApi.functionBlockDefinitionCategories(payload.language),
        queryApi.solutionHardwareDefinitions(payload.language),
        queryApi.solutionHardwareDefinitionCategories(payload.language),
        queryApi.functionBlocks(payload.language),
      ]),
      commit: ([functionBlockDefinitions, cSharpFunctionBlockDefinitions, fuctionBlockDefinitionCategories, solutionHardwareDefinitions, solutionHardwareDefinitionCategories, functionBlocks]) => {
        commit(SystemConfigurationMutation.setFunctionBlocks, [...functionBlockDefinitions, ...cSharpFunctionBlockDefinitions, ...functionBlocks])
        commit(SystemConfigurationMutation.setFunctionBlockCategories, fuctionBlockDefinitionCategories)
        commit(SystemConfigurationMutation.setSolutionHardwareDefinitions, solutionHardwareDefinitions)
        commit(SystemConfigurationMutation.setSolutionHardwareDefinitionCategories, solutionHardwareDefinitionCategories)
      },
    })
  },
  async [SystemConfigurationAction.loadGlobalVariables]({ commit }) {
    const data = await queryApi.globalVariables()
    commit(SystemConfigurationMutation.setGlobalVariables, data)
  },
  async [SystemConfigurationAction.loadPackageInfo]({ commit }) {
    const data = await queryApi.cSharpFunctionBlockPackageInfo()
    commit(SystemConfigurationMutation.setPackageInfo, data)
  },
  async [SystemConfigurationAction.setFunctionBlockActive]({ commit, state }, payload: string) {
    const functionBlockDefinition = state.functionBlocks.find(_ => _.id === payload)!
    let obsoleteIds: string[] = []
    if (functionBlockDefinition.architectureType === ArchitectureType.ObjectOriented) {
      obsoleteIds = await commandApi.setFunctionBlockDefinitionActive(payload)
    } else { // ArchitectureType.Classic
      obsoleteIds = await commandApi.setFunctionBlockActive(payload)
    }
    commit(SystemConfigurationMutation.setFunctionBlockActive, payload)
    obsoleteIds.forEach(id => commit(SystemConfigurationMutation.setFunctionBlockObsolete, { id } as SetFunctionBlockObsoleteInput))
  },
  async [SystemConfigurationAction.setFunctionBlockObsolete]({ commit, state }, payload: SetFunctionBlockObsoleteInput) {
    const functionBlockDefinition = state.functionBlocks.find(_ => _.id === payload.id)!
    if (functionBlockDefinition.architectureType === ArchitectureType.ObjectOriented) {
      await commandApi.setFunctionBlockDefinitionObsolete(payload.id, payload.successorFunctionBlockId)
    } else { // ArchitectureType.Classic
      await commandApi.setFunctionBlockObsolete(payload.id, payload.successorFunctionBlockId)
    }
    commit(SystemConfigurationMutation.setFunctionBlockObsolete, payload)
  },
}
