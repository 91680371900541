<template>
  <table class="projectTable">
    <tr class="headerRow">
      <th class="iconCol">
        <img src="/img/icons/projectAuthorisation.project.icon.png" width="24" />
      </th>
      <th valign="middle" class="descriptionCol">{{ 'consumption.project' | translate }}
      </th>
      <th valign="middle" class="authCol bigger">
      </th>
      <th valign="middle" class="authCol">
        <div class="authDiv">
          {{ 'ecoPartnerTool' | translate }}
          <div @click.stop="toggleProjectWritePermission">
            <svg-icon :icon="checkboxIcon(permissionsOfProject.hasWrite)" width="25" height="25"/>
          </div>
        </div>
      </th>
      <th valign="middle" class="authCol">
        <div class="authDiv">
          {{ 'ecoAuthTool' | translate }}
          <div @click.stop="toggleProjectAuthorizePermission">
            <svg-icon :icon="checkboxIcon(permissionsOfProject.hasAuthorize)" width="25" height="25"/>
          </div>
        </div>
      </th>
      <th valign="middle" class="collapse">
      </th>
    </tr>
  </table>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { PermissionsViewModel } from '../../../store/modules/authorizationUi/models'

import { Permission } from '../../../../../eco-domain-store-modules/src/authorization/models'

import SvgIcon from '../../ui/SvgIcon.vue'

const AuthorizationUi = namespace('authorizationUi')

@Component({
  components: {
    SvgIcon,
  },
})
export default class ProjectBasedAuthorisation extends Vue {
  @Prop() public permissionsOfProject: PermissionsViewModel
  @AuthorizationUi.Getter public isInteracted: boolean
  @AuthorizationUi.Action public grantProjectPermission: (payload: { projectId: string, permission: Permission }) => Promise<void>
  @AuthorizationUi.Action public revokeProjectPermission: (payload: { projectId: string, permission: Permission }) => Promise<void>

  public checkboxIcon(value?: boolean | null) {
    if (value === null) {
      return 'eco.checkbox-partial.white'
    }
    return value ? 'eco.checkbox-all.white' : 'eco.checkbox-none.white'
  }

  public toggleProjectWritePermission() {
    if (this.permissionsOfProject.hasWrite) {
      // revoke also read so the user has either ReadWrite or no
      this.revokeProjectPermission({ projectId: this.permissionsOfProject.id, permission: Permission.READ })
    } else {
      this.grantProjectPermission({ projectId: this.permissionsOfProject.id, permission: Permission.EDIT })
    }
  }

  public toggleProjectAuthorizePermission() {
    if (this.permissionsOfProject.hasAuthorize) {
      this.revokeProjectPermission({ projectId: this.permissionsOfProject.id, permission: Permission.AUTHORIZE })
    } else {
      this.grantProjectPermission({ projectId: this.permissionsOfProject.id, permission: Permission.AUTHORIZE })
    }
  }
}
</script>
<style lang="scss" scoped>
.projectTable {
  width: 100%;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-spacing: 0px;
  text-align: right;
  table-layout: fixed;
  th {
    padding: 10px 10px;
    font-weight: normal;
    &.iconCol {
      width: 36px;
      justify-content: center;
      text-align: left;
    }
    &.descriptionCol {
      font-weight: bold;
      justify-items: center;
      text-align: left;
      width: 25%;
    }
    &.authCol {
      font-weight: bold;
      width: 20%;
      &.bigger {
        width: 30%;
      }
    }
    &.collapse {
      width: 36px;
    }
  }
  .headerRow {
    background: linear-gradient(to bottom, #0f3a64, #0f3a64); 
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #0f3a64), color-stop(0.6, #0f3a64));
  }
  .authDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: -5px;
    > div {
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
</style> 