import { CustomPlcAlarmModel } from '@ecocoach/domain-store-modules/src/alarm/models'
import { AlarmOperator } from '@ecocoach/domain-store-modules/src/common'
import moment from 'moment'
import { AlarmViewModel } from './models'
import { AlarmDefinitionModel } from '@ecocoach/domain-store-modules/src/alarmDefinition/models'

export function toViewModel(alarm: CustomPlcAlarmModel, alarmdefinition: AlarmDefinitionModel | null, resolveStringResource: (resourceId: string) => string) {
  return {
    ...alarm,
    opened: formatDateTime(alarm.openedTimestamp),
    closed: alarm.isOpen ? '' : formatDateTime(alarm.closedTimestamp),
    deviceId: alarmdefinition?.option?.deviceId ?? '',
    deviceName: alarmdefinition?.option?.deviceName ?? 'n/a',
    peakValueText: peakValueText(alarm, resolveStringResource),
    alarmConditionText: alarmConditionText(alarm, resolveStringResource),
    displayName: alarm.alarmDefinitionName || resolveStringResource(alarm.nameResourceId),
    displayMessage: alarm.alarmDefinitionMessage || resolveStringResource(alarm.messageResourceId),
  } as AlarmViewModel
}

function formatDateTime(dateTime: string) {
  return dateTime ? moment.utc(dateTime).local().format('DD.MM.YYYY - HH:mm:ss') : ''
}

function peakValueText(alarm: CustomPlcAlarmModel, resolveStringResource: (resourceId: string) => string) {
  return typeof alarm.thresholdValue === 'number'
    ? numericValueText(alarm.peakValue as number, alarm.unit)
    : booleanValueText(alarm.peakValue as boolean, resolveStringResource)
}

function alarmConditionText(alarm: CustomPlcAlarmModel, resolveStringResource: (resourceId: string) => string) {
  return typeof alarm.thresholdValue === 'number'
    ? `${operatorOptionText(alarm.operator, resolveStringResource)} ${alarm.thresholdValue} ${alarm.unit}`
    : booleanValueText(alarm.thresholdValue as boolean, resolveStringResource)
}

function numericValueText(value: number, unit: string) {
  const isNumber = typeof value === 'number'
  const roundedValue = (isNumber && !Number.isInteger(value)) ? value.toFixed(1) : value
  return `${roundedValue} ${unit}`
}

function booleanValueText(value: boolean, resolveStringResource: (resourceId: string) => string) {
  return value
    ? resolveStringResource('alarmdefinition.on')
    : resolveStringResource('alarmdefinition.off')
}

function operatorOptionText(operator: AlarmOperator, resolveStringResource: (resourceId: string) => string) {
  switch (operator) {
  case AlarmOperator.Equal: return resolveStringResource('operator.equals')
  case AlarmOperator.LessThan: return resolveStringResource('operator.lessthan')
  case AlarmOperator.GreaterThan: return resolveStringResource('operator.greaterthan')
  default: return ''
  }
}
