import { addToMap } from '@ecocoach/domain-store-modules/src/utils'
import { MutationTree } from 'vuex'
import { DeviceUiMutation, DeviceUiState } from './types'

export const mutations: MutationTree<DeviceUiState> = {
  [DeviceUiMutation.setInteracted](state, payload: boolean) {
    state.interacted = payload
  },
  [DeviceUiMutation.setDeviceName](state, payload: { id: string, name: string }) {
    state.modifiedDeviceNames = addToMap(state.modifiedDeviceNames, payload.id, payload.name)
  },
  [DeviceUiMutation.setDeviceIcon](state, payload: { id: string, icon: string }) {
    state.modifiedDeviceIcons = addToMap(state.modifiedDeviceIcons, payload.id, payload.icon)
  },
  [DeviceUiMutation.clearChanges](state) {
    state.modifiedDeviceNames = new Map<string, string>()
    state.modifiedDeviceIcons = new Map<string, string>()
  },
}
