<template>
  <eco-labeled-control
    :context="context"
    :attributes="attributes"
  >
    <base-value-circle v-if="isPercentageCircle"
      :value="displayValue"
      :unit="unit"
      :gradientColors="gradient"
    />
    <div class="value" v-else>
      <span>{{ displayValue }}</span>
      <span class="unit">{{ unit }}</span>
    </div>
  </eco-labeled-control>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import BaseValueCircle from '../../base/BaseValueCircle.vue'
import { ControlContextV2, InvalidDisplayValue, InvalidValue, NumericDisplayControlAppearance, NumericDisplayControlAttributes } from './models'
import EcoLabeledControl from './shared/EcoLabeledControl.vue'

import Gradients from './../v1/shared/gradients'

@Component({
  components: {
    BaseValueCircle,
    EcoLabeledControl,
  },
})
export default class EcoNumericDisplayControl extends Vue {
  @Prop() public id: string
  @Prop() public context: ControlContextV2
  @Prop() public attributes: NumericDisplayControlAttributes
  @Prop() public state: object

  public get isPercentageCircle() {
    return this.attributes?.appearance === NumericDisplayControlAppearance.PercentageCircle
  }

  public get gradient() {
    return Gradients.stops('gradient-8')
  }

  public get displayValue() {
    // special handling of realtime-side magic constant for invalid values
    const invalidValue = this.attributes.invalidValue ?? InvalidValue
    if (this.currentValue === invalidValue) {
      return this.attributes.invalidDisplayValue ?? InvalidDisplayValue
    }
    
    const isNumber = typeof (this as any).currentValue === 'number'
    const decimals = this.attributes.decimals
    if (!isNumber || Number.isInteger(this.currentValue)) {
      return this.currentValue
    }

    const fixed = this.currentValue.toFixed(decimals)

    // special handling of shortcomings of string.toFixed() like -0.0
    if (parseFloat(fixed) === 0) {
      return '0'
    }

    return fixed
  }

  public get unit() {
    return this.attributes.unit
  }

  public get currentValue() {
    return this.state[this.attributes.state]
  }
}
</script>

<style lang="scss" scoped>
  .value {
    display: flex;
    flex-direction: row;
    padding: 5px 5px;
    line-height: 1.2;
    border-radius: 5px;
    background: rgba(123, 139, 154, 0.1);
    align-self: center;
    .unit {
      align-self: flex-end;
      margin-left: 5px;
      color: #7b8b9a;
      align-self: center;
    }
  }
</style>

<style lang="css">
</style>
