import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'
import { ChartAction, ChartMutation, ChartState } from './types'

import { Module } from 'vuex'

const namespaced: boolean = true
export const chart: Module<ChartState,any> = {
  namespaced,
  state,
  actions,
  mutations,
}

export const types = {
  action:  ChartAction,
  mutation: ChartMutation,
}

export const domain = 'chart'

export const vuexModule = {
  [domain]: chart,
}