<template>
  <div class="releases-list">
    <div class="header">
      <div class="name head">{{ 'releases.name' | translate}}</div>
      <div class="description head">{{ 'releases.description' | translate}}</div>
      <div class="template head">{{ 'releases.template' | translate}}</div>
      <div class="architecture head">{{ 'releases.architecture' | translate}}</div>
      <div class="created head">{{ 'releases.created.by' | translate}}</div>
      <div class="loaded head">{{ 'releases.loaded' | translate}}</div>
      <div class="state head">{{ 'releases.state' | translate}}</div>
    </div>
    <div class="releases-list-scroll">
      <div
        v-for="release in completedReleasesToShow"
        :key="release.id"
        class="release-item"
        :class="release.id === selectedReleaseId ? 'selected' : ''"
        @click.stop="selectRelease(release.id)"
      >
        <div class="name item">{{ release && release.name }}</div>
        <div class="description item">{{ release && release.description }}</div>
        <div class="template item">
          <div class="checkbox-container">
            <input type="checkbox" :checked="release && release.isTemplate" disabled />
          </div>
        </div>
        <div class="architecture item">
          <v-chip v-if="chipText(release)" small :color="chipColor(release)">{{ chipText(release) }}</v-chip>
        </div>
        <div class="created item">
          {{ release && timeStampToDateWithTime(release.builtAt) }}
          <div class="user-overflow">{{ release && release.builtBy }}</div>
        </div>
        <div class="loaded item">
          {{ release && timeStampToDateWithTime(release.deployedAt) }}
          <div class="user-overflow">{{ release && release.deployedBy }}</div>
        </div>
        <div class="state item">
          <div class="icon-container">
            <v-icon 
              v-if="release && isCurrentlyDeployed(release.id)" 
              color="green" 
              :title="'releases.active.configuration' | translate">
                done
              </v-icon>
            <v-icon v-else color="grey" :title="'releases.not.active.configuration' | translate">error</v-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ArchitectureType, PlcType } from '../../../../../../../eco-domain-store-modules/src/common'
import { ReleaseModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

const ReleaseUi = namespace('releaseUi')

@Component
export default class ReleasesList extends Vue {
  @Prop({ type: String, default: '' }) public selectedReleaseId
  @Prop({ type: Boolean, default: false }) public inactive
  @ReleaseUi.Getter public completedReleasesToShow: ReleaseModel[]
  @ReleaseUi.Getter public timeStampToDateWithTime: string
  @ReleaseUi.Getter public isCurrentlyDeployed: (releaseId: string) => boolean

  public mounted() {
    this.selectedReleaseId = ''
    this.$emit('selectedRelease', '')  
  }

  public chipText(release: ReleaseModel) {
    return release.architectureType === ArchitectureType.ObjectOriented
      ? (release.plcType === PlcType.ECO_BASIC_CONTROL_LIGHT ? 'C#' : 'OO')
      : 'Classic'
  }

  public chipColor(release: ReleaseModel) {
    return release.architectureType === ArchitectureType.ObjectOriented ? 'green' : 'blue'
  }

  public selectRelease(id) {
    if (!this.inactive) {
      this.selectedReleaseId = id
      this.$emit('selectedRelease', id)
    }
  }
}
</script>
<style lang="scss" scoped>
.releases-list {
  flex: 1
}
.releases-list-scroll {
  min-height: 200px;
  max-height: 200px;
  overflow-y: auto;
}
.header {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
  border-bottom: 2px solid white;
  align-items: center;
  text-transform: uppercase;
  .head {
    font-weight: bold;
    padding: 10px 10px 2px 10px;
  }
}
.release-item {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden; 
  cursor: pointer;
  border-bottom: 1px solid white;    
  align-items: center;
  max-height: 65px;
  .item {  
    min-height: 45px;
    padding: 10px;
  }
}
.name {
  flex: 0 0 20%;
  min-width: 120px;
}
.description {
  flex: 0 0 20%;
  min-width: 120px;
}
.template {
  flex: 0 0 7%;
  min-width: 40px;
  text-align: center;
}
.architecture {
  flex: 0 0 9%;
  min-width: 40px;
  text-align: center;
}
.created {
  flex: 0 0 18%;
  min-width: 120px;
  overflow: hidden;
}
.loaded {
  flex: 0 0 18%;
  min-width: 120px;
  overflow: hidden;
}
.state {
  flex: 0 0 8%;
  min-width: 40px;
  text-align: center;
}
.selected {
  background: #c2043e;
}
.checkbox-container {
  text-align: center;
}
.icon-container {
  text-align: center;
}
.user-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>