<template>
  <table class="table">
    <tr class="headerRow" @click="toggleRoomList">
      <th valign="middle" class="iconCol">
        <svg-icon :icon="permissions.icon" width="25" height="25"/>
      </th>
      <th valign="middle" class="descriptionCol"> {{ plc.name }} </th>
      <th valign="middle" class="authCol bigger">
        <div class="authDiv">
          {{ 'authorizationLevel.systemNotification' | translate }}
          <div @click.stop="togglePlcSystemNotificationPermission">
            <svg-icon :icon="checkboxIcon(permissions.hasSystemNotification)" width="25" height="25"/>
          </div>
        </div>
        <div style="height: 5px;"></div>
        <div class="authDiv">
          {{ 'authorizationLevel.systemNotificationEmail' | translate }}
          <div @click.stop="togglePlcSystemNotificationEmailPermission">
            <svg-icon :icon="checkboxIcon(permissions.hasSystemNotificationEmail)" width="25" height="25"/>
          </div>
        </div>
      </th>
      <th valign="middle" class="authCol">
        <div class="authDiv">
          {{ 'ecoSetupTool' | translate }}
          <div @click.stop="togglePlcWritePermission">
            <svg-icon :icon="checkboxIcon(permissions.hasWrite)" width="25" height="25"/>
          </div>
        </div>
      </th>
      <th valign="middle" class="authCol">
        <div class="authDiv">
          {{ 'ecoAuthTool' | translate }}
          <div @click.stop="togglePlcAuthorizePermission">
            <svg-icon :icon="checkboxIcon(permissions.hasAuthorize)" width="25" height="25"/>
          </div>
        </div>
      </th>
      <th valign="middle" class="collapse">
        <v-icon @click.stop="toggleRoomList" :disabled="!expandable">
            {{ collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}
        </v-icon>
      </th>
    </tr>
    <tr v-if="!collapsed">
      <td colspan="8" style="padding: 10px;" class="deviceContainer">
        <div class="group-title bold">{{ 'ecoMobileApp' | translate }}</div>
        <all-rooms-permissions v-if="rooms.length" :plc-id="plc.id"/>
        <inherited-device-permissions :plc-id="plc.id"/>
        <div class="group-title spacious" v-if="rooms.length">{{ 'navigation.rooms' | translate }}</div>
        <div v-for="room in rooms" :key="room.roomId">
          <room-permissions :room="room"/>
        </div>
      </td>
    </tr>
    <tr v-if="!collapsed && alarmDefinitions.length">
      <td colspan="8" style="padding: 0px 10px 10px 10px;" class="deviceContainer">
        <div class="group-title">{{ 'wizard.alarms' | translate}}</div>
        <alarm-definition-permissions :plc="plc"/>
      </td>
    </tr>
  </table>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import AlarmDefinitionPermissions from './AlarmDefinitionPermissions.vue'
import AllRoomsPermissions from './AllRoomsPermissions.vue'
import InheritedDevicePermissions from './InheritedDevicePermissions.vue'
import RoomPermissions from './RoomPermissions.vue'

import { PermissionsViewModel } from '../../../store/modules/authorizationUi/models'

import { AlarmDefinitionAuthorizationsModel, Permission, PlcAuthorizationsModel, RoomModel } from '../../../../../eco-domain-store-modules/src/authorization/models'

import SvgIcon from '../../ui/SvgIcon.vue'

const AuthorizationUi = namespace('authorizationUi')

@Component({
  components: {
    AlarmDefinitionPermissions,
    AllRoomsPermissions,
    InheritedDevicePermissions,
    RoomPermissions,
    SvgIcon,
  },
})
export default class PlcPermission extends Vue {
  @Prop({type: Object, required: true }) public plc: PlcAuthorizationsModel
  @AuthorizationUi.Getter public isInteracted: boolean
  @AuthorizationUi.Getter public roomsOfPlc: (plcId: string) => RoomModel[]
  @AuthorizationUi.Getter public alarmDefinitionsOfPlc: (plcId: string) => AlarmDefinitionAuthorizationsModel[]
  @AuthorizationUi.Getter public plcPermissions: (plcId: string) => PermissionsViewModel
  @AuthorizationUi.Action public grantPlcPermission: (payload: { plcId: string, permission: Permission }) => Promise<void>
  @AuthorizationUi.Action public revokePlcPermission: (payload: { plcId: string, permission: Permission }) => Promise<void>

  public collapsed = true

  public get expandable() {
    return this.rooms.length || this.alarmDefinitionsOfPlc.length
  }

  public get rooms(): RoomModel[] {
    return this.roomsOfPlc(this.plc.id)
  }

  public get alarmDefinitions(): AlarmDefinitionAuthorizationsModel[] {
    return this.alarmDefinitionsOfPlc(this.plc.id)
  }

  public get permissions(): PermissionsViewModel {
    return this.plcPermissions(this.plc.id)
  }

  public checkboxIcon(value?: boolean | null) {
    if (value === null) {
      return 'eco.checkbox-partial.white'
    }
    return value ? 'eco.checkbox-all.white' : 'eco.checkbox-none.white'
  }

  public togglePlcReadPermission() {
    if (this.permissions.hasRead) {
      this.revokePlcPermission({ plcId: this.permissions.id, permission: Permission.READ })
    } else {
      this.grantPlcPermission({ plcId: this.permissions.id, permission: Permission.READ })
    }
  }

  public togglePlcExecutePermission() {
    if (this.permissions.hasExecute) {
      this.revokePlcPermission({ plcId: this.permissions.id, permission: Permission.EXECUTE })
    } else {
      this.grantPlcPermission({ plcId: this.permissions.id, permission: Permission.EXECUTE })
    }
  }

  public togglePlcWritePermission() {
    if (this.permissions.hasWrite) {
      this.revokePlcPermission({ plcId: this.permissions.id, permission: Permission.EDIT })
    } else {
      this.grantPlcPermission({ plcId: this.permissions.id, permission: Permission.EDIT })
    }
  }

  public togglePlcAuthorizePermission() {
    if (this.permissions.hasAuthorize) {
      this.revokePlcPermission({ plcId: this.permissions.id, permission: Permission.AUTHORIZE })
    } else {
      this.grantPlcPermission({ plcId: this.permissions.id, permission: Permission.AUTHORIZE })
    }
  }

  public togglePlcSystemNotificationPermission() {
    if (this.permissions.hasSystemNotification) {
      this.revokePlcPermission({ plcId: this.permissions.id, permission: Permission.SYSTEM_NOTIFICATION })
    } else {
      this.grantPlcPermission({ plcId: this.permissions.id, permission: Permission.SYSTEM_NOTIFICATION })
    }
  }

  public togglePlcSystemNotificationEmailPermission() {
    if (this.permissions.hasSystemNotificationEmail) {
      this.revokePlcPermission({ plcId: this.permissions.id, permission: Permission.SYSTEM_NOTIFICATION_EMAIL })
    } else {
      this.grantPlcPermission({ plcId: this.permissions.id, permission: Permission.SYSTEM_NOTIFICATION_EMAIL })
    }
  }

  public toggleRoomList() {
    if(this.roomsOfPlc.length > 0) {
      this.collapsed = !this.collapsed
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  width: 100%;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-spacing: 0px;
  text-align: right;
  table-layout: fixed;
  th {
    padding: 10px 10px;
    font-weight: normal;
    &.iconCol {
      width: 36px;
      justify-content: center;
      text-align: left;
    }
    &.descriptionCol {
      width: 25%;
      font-weight: bold;
      justify-items: center;
      text-align: left;
    }
    &.authCol {
      font-weight: bold;
      width: 20%;
      &.bigger {
        width: 30%;
      }
    }
    &.collapse {
      width: 36px;
      padding: 10px 0px;
      text-align: center;
      font-weight: bold;
      .material-icons {
        color: white;
        margin: 0;
      }
    }
  }
  tr {
    .deviceContainer {
      background: linear-gradient(to bottom, #0f3a64, #0f3a64); 
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #0f3a64), color-stop(0.6, #0f3a64));
    }
  }
  .headerRow {
    background: linear-gradient(to bottom, #0f3a64, #0f3a64); 
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #0f3a64), color-stop(0.6, #0f3a64));
  }
  .authDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: -5px;
    > div {
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
.group-title {
  text-align: left;
  font-size: 14px;
  padding: 5px;
  &.bold {
    font-weight: bold;
  }
  &.spacious {
    margin-top: 10px;
  }
}
</style>