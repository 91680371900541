import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { state } from './state'
import { PlcOperationAction, PlcOperationGetter, PlcOperationMutation, PlcOperationState } from './types'

import { Module } from 'vuex'

const namespaced: boolean = true
export const plcOperation: Module<PlcOperationState,any> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action:  PlcOperationAction,
  mutation: PlcOperationMutation,
  getter: PlcOperationGetter,
}

export const domain = 'plcOperation'

export const vuexModule = {
  [domain]: plcOperation,
}