export interface ParametrizationUiState {
  selectedRoomId: string
  selectedDeviceId: string
  interacted: boolean
}

export enum ParametrizationUiAction {
  startControlUpdatesForDevice = 'startControlUpdatesForDevice',
  stopControlUpdates = 'stopControlUpdates',
  confirmDevice = 'confirmDevice',
  setControlColorGradient = 'setControlColorGradient',
  setDeviceIgnoreInCharts = 'setDeviceIgnoreInCharts',
  setDeviceConsumptionValidationEnabled = 'setDeviceConsumptionValidationEnabled',
  setConsumptionProcessOwnerLockEnabled = 'setConsumptionProcessOwnerLockEnabled',
  setMeasuringPointColor = 'setMeasuringPointColor',
  setEnergyStatusItemColor = 'setEnergyStatusItemColor',
}

export enum ParametrizationUiMutation {
  setSelectedRoomId = 'setSelectedRoomId',
  setSelectedDeviceId = 'setSelectedDeviceId',
  setInteracted = 'setInteracted',
}

export enum ParametrizationUiGetter {
  rooms = 'rooms',
  selectedRoomId = 'selectedRoomId',
  devicesOfRoom = 'devicesOfRoom',
  selectedDeviceId = 'selectedDeviceId',
  isConfirmedDevice = 'isConfirmedDevice',
  hasMessage = 'hasMessage',
  hasOpenAlarm = 'hasOpenAlarm',
  statusControlsOfSelectedDevice = 'statusControlsOfSelectedDevice',
  parameterControlsOfSelectedDevice = 'parameterControlsOfSelectedDevice',
  functionTestControlsOfSelectedDevice = 'functionTestControlsOfSelectedDevice',
  chartConfigurationControlsOfSelectedDevice = 'chartConfigurationControlsOfSelectedDevice',
  isInteracted = 'isInteracted',
  descriptionOfActiveRelease = 'descriptionOfActiveRelease',
  nameOfActiveRelease = 'nameOfActiveRelease',
  appDisplayLevelOptions = 'appDisplayLevelOptions',
}