export interface AppState {
  interacted: boolean
  navigationDrawerMinimized: boolean
  hydrated: boolean
  loadingApiFailed: boolean
  selectedLanguage: string
  selectedProjectId: string
  selectedPlcId: string
}

export enum AppMutation {
  setInteracted = 'setInteracted',
  setNavigationDrawerMinimized = 'setNavigationDrawerMinimized',
  loading = 'loading',
  loaded = 'loaded',
  setLoadingFailure = 'setLoadingFailure',
  selectLanguage = 'selectLanguage',
  setProjectSelection = 'setProjectSelection',
  setSelectedPlc = 'setSelectedPlc',
}

export enum AppAction {
  load = 'load',
  loadAppSettings = 'loadAppSettings',
  setNavigationDrawerMinimized = 'setNavigationDrawerMinimized',
  acceptUserAgreement = 'acceptUserAgreement',
  handleApiError = 'handleApiError',
  handleHubReconnecting = 'handleHubReconnecting',
  handleHubReconnected = 'handleHubReconnected',
  selectLanguage = 'selectLanguage',
  selectPlc = 'selectPlc',
  loadActiveRelease = 'loadActiveRelease',
  setEmailMfaEnabled = 'setEmailMfaEnabled',
  initiateAccountDeletion = 'initiateAccountDeletion',
}

export enum AppGetter {
  isInteracted = 'isInteracted',
  isNavigationDrawerMinimized = 'isNavigationDrawerMinimized',
  succeededHydrating = 'succeededHydrating',
  isHydrating = 'isHydrating',
  loadingApiSuccess = 'loadingApiSuccess',
  userAgreements = 'userAgreements',
  isEmailMfaEnabled = 'isEmailMfaEnabled',
  languages = 'languages',
  selectedLanguage = 'selectedLanguage',
  getPlcVersion = 'getPlcVersion',
  writablePlcs = 'writablePlcs',
  hasSelectedPlc = 'hasSelectedPlc',
  selectedPlcId = 'selectedPlcId',
  selectedPlc = 'selectedPlc',
  hasPlcReleaseRooms = 'hasPlcReleaseRooms',
  hasPlcReleaseDevices = 'hasPlcReleaseDevices',
  hasPlcReleaseSolutionHardware = 'hasPlcReleaseSolutionHardware',
  isEcocoachEmployee = 'isEcocoachEmployee',
  isWeidmuellerEmployee = 'isWeidmuellerEmployee',
  userIdentifier = 'userIdentifier',
  webAppReleaseNotes = 'webAppReleaseNotes',
  eccReleaseNotes = 'eccReleaseNotes',
  version = 'version',
  showPartnerTool = 'showPartnerTool',
  showSetupTool = 'showSetupTool',
  showAuthTool = 'showAuthTool',
  environmentBadge = 'environmentBadge',
}
