<template>
  <modal-dialog 
      :show="show"
      :header="`Create new resource`"
      button1-text='Create'
      button2-text="Cancel"
      @button1Pressed="onConfimed"
      @button2Pressed="onCancelled"
  >
    <v-layout column>
      <v-flex>
        <v-layout row>
          <form-field v-if="prefix" text="Prefix">
            <div class="field-label">
              {{prefix}}
            </div>
          </form-field>
          <form-field text="Main part">
            <text-input
              :value="value"
              @input="onChanged"
            />
          </form-field>
          <form-field v-if="postfix" text="Postfix">
            <div class="field-label">
              {{postfix}}
            </div>
          </form-field>
        </v-layout>
      </v-flex>
      <v-flex>
        <form-field text="Default value (de)">
          <text-input
            :value="resource"
            @input="resource = $event"
          />
        </form-field>
      </v-flex>
      <v-flex>
        <div class="label">
          Resource ID: {{prefix}}{{value}}{{postfix}}
        </div>
      </v-flex>
      <v-flex>
        <div class="label">
          Category: {{category}}
        </div>
      </v-flex>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { CreateResourceInput } from '../../../../../eco-domain-store-modules/src/resource/models'

@Component
export default class AddStringResourceDialog extends Vue {
  @Prop({type: Boolean}) public show: boolean
  @Prop({type: String}) public category: string
  @Prop({type: String}) public prefix: string
  @Prop({type: String}) public postfix: string
  @Prop({type: String}) public defaultValue: string

  public value: string = ''
  public resource: string = ''

  @Watch('show')
  public setDefaults() {
    this.value = this.defaultValue
  }

  public onChanged(value: string) {
    this.value = value
  }

  @Emit('created') 
  public onConfimed() {
    return {
      id: `${this.prefix || ''}${this.value || ''}${this.postfix || ''}`,
      category: this.category,
      defaultValue: this.resource || '',
    } as CreateResourceInput
  }

  @Emit('closed') 
  public onCancelled () {
    return true
  }
}
</script>
<style lang="scss" scoped>
  .field .field-label {
    padding-left: 10px;
    padding-top: 12px;
  }
  .label {
    padding-left: 6px;
  }
</style>