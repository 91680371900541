import { actions } from '@/store/modules/loadProgramUi/actions'
import { getters } from '@/store/modules/loadProgramUi/getters'
import { mutations } from '@/store/modules/loadProgramUi/mutations'
import { state } from '@/store/modules/loadProgramUi/state'
import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { LoadProgramUiAction, LoadProgramUiGetter, LoadProgramUiMutation, LoadProgramUiState } from './types'

const namespaced: boolean = true
export const loadProgramUi: Module<LoadProgramUiState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action: LoadProgramUiAction, 
  mutation: LoadProgramUiMutation,
  getter: LoadProgramUiGetter,
}

export const domain = 'loadProgramUi'

export const vuexModule = {
  [domain]: loadProgramUi,
}