<template>
  <table class="table">
    <tr class="headerRow">
      <th valign="middle" class="iconCol">
        <svg-icon :icon="permissions.icon" width="25" height="25"/>
      </th>
      <th valign="middle" class="descriptionCol"> {{ 'authorizationLevel.allDevices' | translate }} </th>
      <th valign="middle" class="authCol">
        <div class="authDiv">
          {{ 'authorizationLevel.read' | translate }}
          <div @click.stop="toggleDeviceReadPermissionForPlc">
            <svg-icon :icon="checkboxIcon(permissions.hasRead)" width="25" height="25"/>
          </div>
        </div>
      </th>
      <th valign="middle" class="authCol">
        <div class="authDiv">
          {{ 'authorizationLevel.control' | translate }}
          <div @click.stop="toggleDeviceExecutePermissionForPlc">
            <svg-icon :icon="checkboxIcon(permissions.hasExecute)" width="25" height="25"/>
          </div>
        </div>
      </th>
      <th valign="middle" class="collapse"></th>
    </tr>
  </table>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { PermissionsViewModel } from '../../../store/modules/authorizationUi/models'

import { Permission } from '../../../../../eco-domain-store-modules/src/authorization/models'

import SvgIcon from '../../ui/SvgIcon.vue'

const AuthorizationUi = namespace('authorizationUi')

@Component({
  components: {
    SvgIcon,
  },
})
export default class AllRoomsPermissions extends Vue {
  @Prop() public plcId: string
  @AuthorizationUi.Getter public isInteracted: boolean
  @AuthorizationUi.Getter public devicePermissionsForPlc: (plcId: string) => PermissionsViewModel
  @AuthorizationUi.Action public grantDevicePermissionForPlc: (payload: { plcId: string, permission: Permission }) => Promise<void>
  @AuthorizationUi.Action public revokeDevicePermissionForPlc: (payload: { plcId: string, permission: Permission }) => Promise<void>

  public get permissions(): PermissionsViewModel {
    return this.devicePermissionsForPlc(this.plcId)
  }
  
  public checkboxIcon(value?: boolean | null) {
    if (value === null) {
      return 'eco.checkbox-partial.white'
    }
    return value ? 'eco.checkbox-all.white' : 'eco.checkbox-none.white'
  }

  public toggleDeviceReadPermissionForPlc() {
    if (this.permissions.hasRead) {
      this.revokeDevicePermissionForPlc({ plcId: this.permissions.id, permission: Permission.READ })
    } else {
      this.grantDevicePermissionForPlc({ plcId: this.permissions.id, permission: Permission.READ })
    }
  }

  public toggleDeviceExecutePermissionForPlc() {
    if (this.permissions.hasExecute) {
      this.revokeDevicePermissionForPlc({ plcId: this.permissions.id, permission: Permission.EXECUTE })
    } else {
      this.grantDevicePermissionForPlc({ plcId: this.permissions.id, permission: Permission.EXECUTE })
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  table-layout: fixed;
  width: 100%;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-spacing: 0px;
  text-align: right;
  th {
    padding: 10px 10px;
    font-weight: normal;
    &.iconCol {
      width: 36px;
      justify-content: center;
      text-align: left;
    }
    &.descriptionCol {
      justify-items: center;
      text-align: left;
      width: 33%;
    }
    &.authCol {
      width: 33%;
    }
    &.collapse {
      width: 7%;
    }
  }
  .headerRow {
    background: linear-gradient(to bottom, #20476e, #20476e); 
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #20476e), color-stop(0.6, #20476e));
  }
  .authDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: -5px;
    > div {
      cursor: pointer;
      margin-left: 5px;
    }
  }
}
</style>