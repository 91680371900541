<template>
  <modal-dialog 
    :show="show"
    :header="isCreate ? 'Create control definition' : 'Edit control definition'"
    :button1-text="isCreate ? 'Create' : 'Save'"
    button2-text="Cancel"
    @button1Pressed="onConfirmed"
    @button2Pressed="onCancelled"
    width=900
  >
    <v-layout row wrap>
      <!-- left part: common properties  -->
      <v-flex xs4>
        <form-field text="Control definition ID">
          <div class="label">
            {{controlDefinitionInput.id}}
          </div>
        </form-field>
        <form-field text="Control kind">
          <icon-select 
            :options="controlDefinitionKindOptions"
            option-value="id"
            option-label="label"
            option-icon='icon'
            :selected-value="controlDefinitionInput.kind"
            :disabled="controlDefinitionInput.isExisting"
            @selected="onKindSelected"
          />
        </form-field>
        <form-field text="Control type">
          <autocomplete-select 
            :options="controlDefinitionTypeOptions(controlDefinitionInput.kind)"
            option-value="id"
            option-label="label"
            :selected-value="controlDefinitionInput.type"
            :disabled="controlDefinitionInput.isExisting"
            @selected="setProperty('type', $event)"
          />
        </form-field>
        <form-field text="Preview (readonly)">
          <div class="control previewbox">
            <preview-control :control-definition="controlDefinitionInput" :readonly="true" />
          </div>
        </form-field>
        <form-field text="Name">
          <string-resource-select
            :options="controlDefinitionNameResources"
            :selected-value="controlDefinitionInput.nameResourceId"
            category="FunctionBlockDescriptions"
            prefix="ControlDefinitions_"
            postfix="_Name"
            default-value=""
            @selected="onNameResourceIdSelected"
            @created="onNameResourceCreated"
          />
        </form-field>
        <form-field text="Description">
          <string-resource-select
            :options="controlDefinitionDescriptionResources"
            :selected-value="controlDefinitionInput.descriptionResourceId"
            category="FunctionBlockDescriptions"
            prefix="ControlDefinitions_"
            postfix="_Description"
            default-value=""
            @selected="setProperty('descriptionResourceId', $event)"
            @created="onDescriptionResourceCreated"
          />
        </form-field>
        <form-field text="App display level">
          <autocomplete-select 
            :options="controlDefinitionAppDisplayLevelOptions"
            :selected-value="controlDefinitionInput.appDisplayLevel"
            @selected="setProperty('appDisplayLevel', $event)"
          />
        </form-field>
        <form-field text="Configuration tool category">
          <autocomplete-select 
            :options="controlDefinitionConfigurationToolOptions"
            :selected-value="controlDefinitionInput.configurationToolCategory"
            @selected="onConfigurationToolCategorySelected"
          />
        </form-field>
      </v-flex>
      <!-- center part: kind-specific properties  -->
      <v-flex xs4>
        <form-field text="Obsolete">
          <checkbox-with-label
            :value="controlDefinitionInput.obsolete"
            text="Control definition is obsolete"
            :disabled="!controlDefinitionInput.isExisting"
            @checked="setProperty('obsolete', !$event)"
          />
        </form-field>
        <form-field v-if="controlDefinitionProperties(controlDefinitionInput.kind, controlDefinitionInput.type).includes('energyDisplayLevel')"
          text="Energy display level">
          <autocomplete-select 
            :options="controlDefinitionEnergyDisplayLevelOptions"
            :selected-value="controlDefinitionInput.energyDisplayLevel"
            @selected="setProperty('energyDisplayLevel', $event)"
          />
        </form-field>
        <form-field v-if="controlDefinitionProperties(controlDefinitionInput.kind, controlDefinitionInput.type).includes('energyDisplaySummary')"
          text="Energy display summary">
          <autocomplete-select 
            :options="['Yes', 'No']"
            :selected-value="controlDefinitionInput.energyDisplaySummary ? 'Yes' : 'No'"
            @selected="setProperty('energyDisplaySummary', $event === 'Yes' ? true : false)"
          />
        </form-field>
        <form-field v-if="controlDefinitionProperties(controlDefinitionInput.kind, controlDefinitionInput.type).includes('minValue')"
          text="Minimum value">
          <text-input
            :value="controlDefinitionInput.minValue"
            @changed="setProperty('minValue', $event)"
          />
        </form-field>
        <form-field v-if="controlDefinitionProperties(controlDefinitionInput.kind, controlDefinitionInput.type).includes('maxValue')"
          text="Maximum value">
          <text-input
            :value="controlDefinitionInput.maxValue"
            @changed="setProperty('maxValue', $event)"
          />
        </form-field>
        <form-field v-if="controlDefinitionProperties(controlDefinitionInput.kind, controlDefinitionInput.type).includes('interval')"
          text="Interval">
          <text-input
            :value="controlDefinitionInput.interval"
            @changed="setProperty('interval', $event)"
          />
        </form-field>
        <form-field v-if="controlDefinitionProperties(controlDefinitionInput.kind, controlDefinitionInput.type).includes('colorGradient')"
          text="Color gradient">
            <color-select 
              :options="gradients"
              option-value="id"
              option-label="id"
              option-color="primaryColor"
              :selected-value="controlDefinitionInput.colorGradient"
              @selected="setProperty('colorGradient', $event)"
            />
        </form-field>
        <form-field v-if="controlDefinitionProperties(controlDefinitionInput.kind, controlDefinitionInput.type).includes('unit')"
          text="Unit">
          <autocomplete-select 
            :options="controlDefinitionUnitOptions"
            :selected-value="controlDefinitionInput.unit"
            @selected="setProperty('unit', $event)"
          />
        </form-field>
        <form-field v-if="controlDefinitionProperties(controlDefinitionInput.kind, controlDefinitionInput.type).includes('softwareMappingGlobalVariableId')"
          text="Software mapping category">
          <autocomplete-select
            :options="controlDefinitionSoftwareMappingCategories"
            option-value="id"
            option-label="name"
            :selected-value="controlDefinitionInput.softwareMappingGlobalVariableId"
            @selected="setProperty('softwareMappingGlobalVariableId', $event)"
          />
        </form-field>
        <form-field v-if="controlDefinitionProperties(controlDefinitionInput.kind, controlDefinitionInput.type).includes('isMeterDataAggregationEnabled')"
          text="Meter data aggregation enabled">
          <autocomplete-select
            :options="['Yes', 'No']"
            :selected-value="controlDefinitionInput.isMeterDataAggregationEnabled ? 'Yes' : 'No'"
            @selected="setProperty('isMeterDataAggregationEnabled', $event === 'Yes')"
          />
        </form-field>
        <form-field v-if="controlDefinitionProperties(controlDefinitionInput.kind, controlDefinitionInput.type).includes('isDynamicMeterControl')"
          text="Dynamic meter control">
          <autocomplete-select
            :options="['Yes', 'No']"
            :selected-value="controlDefinitionInput.isDynamicMeterControl ? 'Yes' : 'No'"
            @selected="setProperty('isDynamicMeterControl', $event === 'Yes')"
          />
        </form-field>
        <form-field v-if="controlDefinitionProperties(controlDefinitionInput.kind, controlDefinitionInput.type).includes('isMeterActivationControl')"
          text="Meter activation control">
          <autocomplete-select
            :options="['Yes', 'No']"
            :selected-value="controlDefinitionInput.isMeterActivationControl ? 'Yes' : 'No'"
            @selected="setProperty('isMeterActivationControl', $event === 'Yes')"
          />
        </form-field>
      </v-flex>
      <!-- right part: endpoints, dropdown values  -->
      <v-flex xs4>
        <form-field v-for="(endpoint, index) in controlDefinitionEndpointProperties(controlDefinitionInput.kind, controlDefinitionInput.type)"
          :key="`${endpoint}-${index}`"
          :text="endpoint">
          <autocomplete-select
            :options="endpointsForDropdown(endpoint)"
            option-value="id"
            option-label="name"
            :selected-value="controlDefinitionInput[endpoint]"
            :disabled="controlDefinitionInput.isExisting"
            @selected="setProperty(endpoint, $event)"
          />
        </form-field>
        <form-field v-if="showEndpointSubscriptionTypeOptions"
          text="Endpoint subscription handling">
          <autocomplete-select
            :options="['Subscribe', 'Poll']"
            :selected-value="controlDefinitionInput.toSubscribe ? 'Subscribe' : 'Poll'"
            @selected="setProperty('toSubscribe', $event === 'Subscribe')"
          />
        </form-field>
        <form-field v-if="showEndpointSubscriptionPollIntervalOptions"
          text="Polling interval (ms)">
          <text-input
            :value="controlDefinitionInput.pollInterval"
            @changed="setProperty('pollInterval', $event)"
          />
        </form-field>
        <!-- inline, when used as separate component getting weird vuex error because of several vue instances -->
        <div v-if="controlDefinitionProperties(controlDefinitionInput.kind, controlDefinitionInput.type).includes('dropdownValueList')">
        <form-field text="Dropdown list value input">
          <text-input
            :value="dropdownValueListInputValue"
            :disabled="controlDefinitionInput.isExisting"
            @changed="setDropdownValueListInputValue"
          />
        </form-field>
        <form-field text="Dropdown list resource ID input">
          <string-resource-select
            :options="controlDefinitionDropDownValueNameResources"
            :selected-value="dropdownValueListInputResourceId"
            category="FunctionBlockDescriptions"
            prefix="DropdownValueDefinitions_"
            postfix="_Name"
            default-value=""
            :disabled="controlDefinitionInput.isExisting"
            @selected="setDropdownValueListInputResourceId"
            @created="onDropdownValueListResourceCreated"
          />
        </form-field>
        <base-text-button
          :enabled="!controlDefinitionInput.isExisting"
          @buttonClicked="addDropdownValueListItem"
        >
          Add dropdown list item
        </base-text-button>
        <p v-if="controlDefinitionInput.dropdownValueList.length">Dropdown list items:</p>
        <ul>
          <li v-for="item in controlDefinitionInput.dropdownValueList"
            :key="item.value"
          >
            <div class="itemslist">
              <div class="truncate">{{`${item.value}-${item.resourceId}`}}</div>
              <icon-button
                v-if="!controlDefinitionInput.isExisting"
                @buttonClicked="deleteDropdownValueListItem(item.value)"
                type="materialIcon" 
                materialIconName="delete" 
                width=20 
                height=20
                class="deletebutton"
              />
            </div>
          </li>
        </ul>
      </div>
     </v-flex>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import PreviewControl from './PreviewControl.vue'
import StringResourceSelect from './StringResourceSelect.vue'

import { Gradients } from '@ecocoach/shared-components/src'

import { AppDisplayLevels, ConfigurationToolCategories, ControlKinds, ControlTypes, EnergyDisplayLevels } from '../../../../../eco-domain-store-modules/src/plcOperation/models'

import { ControlDefinitionViewModel, EndpointPropertyName, IconDropdownOption } from '../../../store/modules/functionDefinitionEditorUi/models'

import { GlobalVariableModel } from '../../../../../eco-domain-store-modules/src/systemConfiguration/models'

import { CreateResourceInput } from '../../../../../eco-domain-store-modules/src/resource/models'
import { DropdownOption } from '../../../../../shared-components/src'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')

@Component({
  components: {
    PreviewControl,
    StringResourceSelect,
  },
})
export default class ControlDefinitionDetailsDialog extends Vue {
  @Prop({type: Boolean}) public show: boolean
  @Prop({type: Boolean, default: false}) public isCreate: boolean
  @FunctionDefinitionEditorUi.Action public showError: (error: string) => Promise<void>
  @FunctionDefinitionEditorUi.Getter public controlDefinitionInput: ControlDefinitionViewModel
  @FunctionDefinitionEditorUi.Getter public controlDefinitionProperties: (kind: ControlKinds, type: ControlTypes) => string[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionEndpointProperties: (kind: ControlKinds, type: ControlTypes) => EndpointPropertyName[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionKindOptions: IconDropdownOption[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionTypeOptions: (kind: ControlKinds) => ControlTypes[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionUnitOptions: string[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionAppDisplayLevelOptions: AppDisplayLevels[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionConfigurationToolOptions: ConfigurationToolCategories[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionEnergyDisplayLevelOptions: EnergyDisplayLevels[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionNameResources: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionDescriptionResources: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionSoftwareMappingCategories: GlobalVariableModel[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionInputsAndOutputsForEndpoint: (endpoint: EndpointPropertyName) => string[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionDropDownValueNameResources: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public controlDefinitionUsedInputsAndOutputs: string[]
  @FunctionDefinitionEditorUi.Mutation public setControlDefinitionInputProperty: (payload: { property: string, value: any }) => void
  @FunctionDefinitionEditorUi.Action public createResource: (payload: CreateResourceInput) => Promise<void>

  public dropdownValueListInputValue: string = '0'
  public dropdownValueListInputResourceId: string = ''

  public get gradients() {
    return [ { id: '', primaryColor: '' }, ...Gradients.list()]
  }

  public get showEndpointSubscriptionTypeOptions(): boolean {
    return this.controlDefinitionProperties(this.controlDefinitionInput.kind, this.controlDefinitionInput.type).includes('toSubscribe')
      && this.controlDefinitionInput.configurationToolCategory !== ConfigurationToolCategories.PARAMETER
  }

  public get showEndpointSubscriptionPollIntervalOptions(): boolean {
    return this.showEndpointSubscriptionTypeOptions && !this.controlDefinitionInput.toSubscribe
  }

  public setDropdownValueListInputValue(value: string) {
    this.dropdownValueListInputValue = value
  }

  public setDropdownValueListInputResourceId(value: string) {
    this.dropdownValueListInputResourceId = value
  }

  public onDropdownValueListResourceCreated(input: CreateResourceInput) {
    this.createResource(input)
    this.setDropdownValueListInputResourceId(input.id)
  }

  public addDropdownValueListItem() {
    const newEntry = { 
      value: Number(this.dropdownValueListInputValue),
      resourceId: this.dropdownValueListInputResourceId,
    }
    if (this.controlDefinitionInput.dropdownValueList.some(_ => _.value === newEntry.value)) {
      return
    }
    const dropdownValues = [...this.controlDefinitionInput.dropdownValueList, newEntry ]
    this.setControlDefinitionInputProperty({ property: 'dropdownValueList', value: dropdownValues})
  }

  public deleteDropdownValueListItem(value: number) {
    const dropdownValues = this.controlDefinitionInput.dropdownValueList.filter(item => item.value !== value)
    this.setControlDefinitionInputProperty({ property: 'dropdownValueList', value: dropdownValues})
  }

  @Emit('closed') 
  public onCancelled () {
    return true
  }

  @Emit('confirmed') 
  public onConfirmed() {
    return this.controlDefinitionInput.id
  }

  public onNameResourceIdSelected(value: string) {
    this.setProperty('nameResourceId', value)
    // try to select the corresponding description by convention
    const descriptionResourceId = value.replace('_Name', '_Description')
    if (this.controlDefinitionDescriptionResources.find(_ => _.id === descriptionResourceId)) {
      this.setProperty('descriptionResourceId', descriptionResourceId)
    }
  }

  public onNameResourceCreated(input: CreateResourceInput) {
    this.createResource(input)
    this.onNameResourceIdSelected(input.id)
  }

  public onDescriptionResourceCreated(input: CreateResourceInput) {
    this.createResource(input)
    this.setProperty('descriptionResourceId', input.id)
  }

  public onKindSelected(kind: ControlKinds) {
    this.setProperty('kind', kind)
    this.setDefaultEndpointSubscriptionProperties()
  }

  public onConfigurationToolCategorySelected(category: ConfigurationToolCategories) {
    this.setProperty('configurationToolCategory', category)
    this.setDefaultEndpointSubscriptionProperties()
  }

  public setDefaultEndpointSubscriptionProperties() {
    const toSubscribe = this.controlDefinitionInput.kind !== ControlKinds.MONITORING_CONTROL
      && this.controlDefinitionInput.configurationToolCategory !== ConfigurationToolCategories.PARAMETER
    this.setProperty('toSubscribe', toSubscribe)
  }

  public endpointsForDropdown(endpoint: EndpointPropertyName) {
    const allUsedInputsAndOutputs = this.controlDefinitionUsedInputsAndOutputs
    return [{ id: '', name: '' }].concat(this.controlDefinitionInputsAndOutputsForEndpoint(endpoint).map(id => {
      return {
        id,
        name: (this.isCreate && allUsedInputsAndOutputs.includes(id)) ? `${id} (used)` : id,
      }
    }))
  }

  public setProperty(property: string, event: any) {
    const numericPropertyNames = ['minValue', 'maxValue', 'interval', 'pollInterval']
    try {
      if (numericPropertyNames.includes(property)) {
        const value: number = Number(event)
        this.setControlDefinitionInputProperty({
          property,
          value,
        })
      } else {
        const value: string = event
        this.setControlDefinitionInputProperty({
          property,
          value,
        })
      }
    } catch (exception) {
      this.showError('wrong format')
    }
  }
}
</script>
<style lang="scss" scoped>
  .field {
    height: 70px;
  }
  .field .text-input {
    padding-left: 10px;
  }
  .field .label {
    padding-left: 10px;
    overflow: hidden;
    max-height: 40px;
  }
  .field {
    .iconSelect {
      margin-top: -18px;
      margin-left: 5px;
    }
  }
  .itemslist {
    display: flex;
  }
  .truncate {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .deletebutton {
    margin: 0;
    height: 20px;
  }
  .control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 10px;
    &.selected {
      border-color: $fore-color-primary;
    }
  }
  .previewbox {

  }
</style>