import { ArchitectureType, PlcType } from '../../common'
import ApiService from '../../services/api.service'
import {
  CreateAutoMappingInput,
  CreateDeviceInput,
  CreateDeviceMappingInput,
  ReleaseDetails,
  ReleaseUpgradeDetails,
  UpdateDeviceInput,
  UpdateReleaseInput,
  UpsertRoomInput,
} from '../models'

export default {
  async setPlcType(plcId: string, plcType: PlcType): Promise<{ updatedReleaseIds: string[] }> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/setPlcType`, {
      plcType,
    })
    return {
      updatedReleaseIds: response.data.updatedPlcConfigurationReleaseIds,
    }
  },
  async createRelease(plcId: string, architectureType: ArchitectureType): Promise<{id: string, plcType: PlcType, details: ReleaseDetails, architectureType: ArchitectureType }> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases`, { architectureType})
    const details: ReleaseDetails = {
      devices: response.data.devices,
      solutionHardwareInstances: response.data.solutionHardwareInstances,
      deviceMappings: response.data.deviceMappings,
      autoDeviceMappings: response.data.autoDeviceMappings,
      rooms: response.data.rooms,
    }
    return { 
      id: response.data.id,
      plcType: response.data.plcType,
      details,
      architectureType: response.data.architectureType,
    }
  },
  async importRelease(plcId: string, data: any): Promise<{id: string, plcType: PlcType, details: ReleaseDetails, architectureType: ArchitectureType }> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/import`, { ...data})
    const details: ReleaseDetails = {
      devices: response.data.devices,
      solutionHardwareInstances: response.data.solutionHardwareInstances,
      deviceMappings: response.data.deviceMappings,
      autoDeviceMappings: response.data.autoDeviceMappings,
      rooms: response.data.rooms,
    }
    return { 
      id: response.data.id,
      plcType: response.data.plcType,
      details,
      architectureType: response.data.architectureType,
    }
  },
  async copyRelease(plcId: string, releaseId: string, applyTemplateOperationData: boolean, architectureType: ArchitectureType): Promise<{ id: string, plcType: PlcType, details: ReleaseDetails, architectureType: ArchitectureType}> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases`, {
      copyPlcConfigurationReleaseId: releaseId,
      applyTemplateOperationData,
      architectureType,
    })
    const details: ReleaseDetails = {
      devices: response.data.devices,
      solutionHardwareInstances: response.data.solutionHardwareInstances,
      deviceMappings: response.data.deviceMappings,
      autoDeviceMappings: response.data.autoDeviceMappings,
      rooms: response.data.rooms,
    }
    return { 
      id: response.data.id,
      plcType: response.data.plcType,
      details,
      architectureType: response.data.architectureType,
    }
  },
  async updateRelease(plcId: string, releaseId: string, input: UpdateReleaseInput): Promise<void> {
    await ApiService.put(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}`, input)
  },
  async completeRelease(plcId: string, releaseId: string, archive: boolean): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/complete`, {
      preserveProjectFilesInUranus: archive,
    })
  },
  async deployRelease(plcId: string, releaseId: string, forceDeployment: boolean): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/${plcId}/configurationReleases/${releaseId}/deploy`, {
      forceDeployment,
    })
  },
  async deleteRelease(plcId: string, releaseId: string): Promise<void> {
    await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}`, {})
  },
  async upgradeRelease(plcId: string, releaseId: string, useInTestDefinitions: boolean, language: string): Promise<ReleaseUpgradeDetails> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/upgradeConfiguration/?resourceLanguage=${language}`, {
      useInTestDefinitions,
    })
    return {
      addedDevices: response.data.addedDevices,
      removedDevices: response.data.removedDevices,
      addedDeviceMappings: response.data.addedDeviceMappings,
      removedDeviceMappings: response.data.removedDeviceMappings,
      addedDeviceAutoMappings: response.data.addedDeviceAutoMappings,
      removedDeviceAutoMappings: response.data.removedDeviceAutoMappings,
      addedSolutionHardwareInstances: response.data.addedSolutionHardwareInstances,
      removedSolutionHardwareInstances: response.data.removedSolutionHardwareInstances,
      deviceSortOrder: response.data.deviceSortOrder,
      solutionHardwareInstanceSortOrder: response.data.solutionHardwareInstanceSortOrder,
      deviceUpgradeInfos: response.data.deviceUpgradeInfos,
    }
  },
  async createRoom(plcId: string, releaseId: string, input: UpsertRoomInput): Promise<{ id: string }> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/rooms`, input)
    return {
      id: response.data.id,
    }
  },
  async updateRoom(plcId: string, releaseId: string, roomId: string, input: UpsertRoomInput): Promise<void> {
    await ApiService.put(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/rooms/${roomId}`, input)
  },
  async deleteRoom(plcId: string, releaseId: string, roomId: string): Promise<void> {
    await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/rooms/${roomId}`, {})
  },
  async sortRooms(plcId: string, releaseId: string, sortedRoomIds: string[]): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/rooms/sort`, {
      sortedRoomIds,
    })
  },
  async createDevice(plcId: string, releaseId: string, input: CreateDeviceInput, language: string): Promise<{ id: string }> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/devices/?resourceLanguage=${language}`, input)
    return {
      id: response.data.id,
    }
  },
  async updateDevice(plcId: string, releaseId: string, deviceId: string, input: UpdateDeviceInput): Promise<void> {
    await ApiService.put(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/devices/${deviceId}`, input)
  },
  async deleteDevice(plcId: string, releaseId: string, deviceId: string): Promise<{ removedDeviceMappings: string[], removedAutoMappings: string[] }> {
    const response = await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/devices/${deviceId}`, {})
    return {
      removedDeviceMappings: response.data.removedDeviceMappings,
      removedAutoMappings: response.data.removedAutoDeviceMappings,
    }
  },
  async sortDevices(plcId: string, releaseId: string, sortedDeviceIds: string[]): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/devices/sort`, {
      sortedDeviceIds,
    })
  },
  async createSolutionHardwareInstance(plcId: string, releaseId: string, solutionHardwareDefinitionId: string): Promise<{ id: string }> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/solutionHardwareInstances`, {
      solutionHardwareDefinitionId,
    })
    return {
      id: response.data.id,
    }
  },
  async updateSolutionHardwareInstance(plcId: string, releaseId: string, solutionHardwareInstanceId: string, input: { lastOctet: number}): Promise<void> {
    await ApiService.put(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/solutionHardwareInstances/${solutionHardwareInstanceId}`, {
      lastOctet: input.lastOctet,
    })
  },
  async deleteSolutionHardwareInstance(plcId: string, releaseId: string, solutionHardwareInstanceId: string): Promise<{ removedDeviceMappings: string[], removedAutoMappings: string[]}> {
    const response = await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/solutionHardwareInstances/${solutionHardwareInstanceId}`, {})
    return {
      removedDeviceMappings: response.data.removedDeviceMappings,
      removedAutoMappings: response.data.removedAutoDeviceMappings,
    }
  },
  async sortSolutionHardwareInstances(plcId: string, releaseId: string, sortedSolutionHardwareInstanceIds: string[]): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/solutionHardwareInstances/sort`, {
      sortedSolutionHardwareInstanceIds,
    })
  },
  async createDeviceMapping(plcId: string, releaseId: string, input: CreateDeviceMappingInput): Promise<{ id: string }> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/mappings`, input)
    return {
      id: response.data.id,
    }
  },
  async deleteDeviceMapping(plcId: string, releaseId: string, deviceMappingId: string): Promise<void> {
    await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/mappings/${deviceMappingId}`, {})
  },
  async createAutoMapping(plcId: string, releaseId: string, input: CreateAutoMappingInput): Promise<{ id: string }> {
    const response = await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/automappings`, input)
    return {
      id: response.data.id,
    }
  },
  async deleteAutoMapping(plcId: string, releaseId: string, autoMappingId: string): Promise<void> {
    await ApiService.delete(`${ApiService.backendEnvironmentConfiguration().plcConfigurationCommandApi}/${plcId}/configurationReleases/${releaseId}/automappings/${autoMappingId}`, {})
  },
}