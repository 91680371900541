<template>
  <modal-dialog v-if="selectedPlcId" :fullscreen="true"
    :show="true"
    :header="'navigation.ecoLogTool' | translate"
    closing-x=true
    @xPressed="closeLogTool"
    width=1000
    height=750
  >
    <v-layout column class="log-tool">
      <div>{{ plcInfo }}</div>
      <endpoint-recorder v-if="isEcocoachEmployee"  :expanded="expandedCard === 'endpointRecorder'" @expandedChanged="expandedChanged('endpointRecorder', $event)"/>
      <cloud-logging :expanded="expandedCard === 'cloudLogging'" @expandedChanged="expandedChanged('cloudLogging', $event)"/>
      <db-logging :expanded="expandedCard === 'dbLogging'" @expandedChanged="expandedChanged('dbLogging', $event)"/>
      <console-logging :expanded="expandedCard === 'consoleLogging'" @expandedChanged="expandedChanged('consoleLogging', $event)"/>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import CloudLogging from './CloudLogging.vue'
import ConsoleLogging from './ConsoleLogging.vue'
import DbLogging from './DbLogging.vue'
import EndpointRecorder from './EndpointRecorder.vue'

import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'

const App = namespace('app')
const LogToolUi = namespace('logToolUi')

@Component({
  components: {
    CloudLogging,
    ConsoleLogging,
    DbLogging,
    EndpointRecorder,
  },
})
export default class LogTool extends Vue { 
  @App.Getter public isEcocoachEmployee: boolean
  @App.Getter public writablePlcs: PlcModel[]
  @LogToolUi.Getter public selectedPlcId: string
  @LogToolUi.Action public closeLogTool: () => Promise<void>

  public expandedCard = 'cloudLogging'

  public expandedChanged(card: string, expanded: boolean) {
    this.expandedCard = expanded ? card : ''
  }

  public get plcInfo() {
    const plc = this.writablePlcs.find(p => p.id === this.selectedPlcId)
    return plc && `${plc.name}, ecoCloudConnector ${plc.ecoCloudConnectorVersion}`
  }
}
</script>
<style lang="scss" scoped>
.log-tool {
  .card {
    margin-left: 0px;
  }
}

</style>