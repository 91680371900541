import { ReleaseModel } from '@ecocoach/domain-store-modules/src/plcConfiguration/models'

export interface ReleaseStepInfo {
  step: ReleaseProgressStep
  release: ReleaseModel
}

export enum ReleaseProgressStep {
  CreateRelease,
  PreparingBuild,
  Building,
  BuildError,
  DeployRelease,
  RedeployRelease,
  PreparingDeployment,
  Deploying,
  Verifying,
  DeployError,
  ReleaseActive,
}

export interface ReleaseProperties {
  name: string
  description: string
  isTemplate: boolean
  archive: boolean
  updateTemplateOperationData: boolean
}

export interface ValidationIssue {
  validationEnum: ValidationEnum
  deviceId: string
  controlId: string
  measuringPointId: string
  deviceName: string
  roomName: string
}

export enum ValidationEnum {
  ControlCurrentlyAssignedToConsumptionAccount = 'ControlCurrentlyAssignedToConsumptionAccount',
  ControlCurrentlyAssignedToMinergieConsumptionAccount = 'ControlCurrentlyAssignedToMinergieConsumptionAccount',
  ControlCurrentlyAssignedToEgonlineConsumptionAccount = 'ControlCurrentlyAssignedToEgonlineConsumptionAccount',
  ControlCurrentlyUsedInAggregation = 'ControlCurrentlyUsedInAggregation',
  MeasuringPointCurrentlyUsedInAggregation = 'MeasuringPointCurrentlyUsedInAggregation',
  MeasuringPointCurrentlyAssignedToConsumptionAccount = 'MeasuringPointCurrentlyAssignedToConsumptionAccount',
  DefaultAlarmNotSupported = 'DefaultAlarmNotSupported',
  InstantaneousReadingsNotSupported = 'InstantaneousReadingsNotSupported',
  InformationLevelAlarmNotSupported = 'InformationLevelAlarmNotSupported',
  DeviceMessagesControlNotSupported = 'DeviceMessagesControlNotSupported',
}

export interface ValidationIssueViewModel extends ValidationIssue {
  issueText: string
}

export enum UpgradeRequiredReason {
  None = 'None',
  Obsolete = 'Obsolete',
  Conflict = 'Conflict',
}
