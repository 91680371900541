<template>
  <eco-labeled-control :context="contextV2" :attributes="attributes" class="chart-configuration-control">
    <color-gradient-picker 
      :color-gradient="gradient"
      :disabled="isInteracted"
      @selected="onGradientSelected"
    />
  </eco-labeled-control>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ColorGradientPicker from '@/components/ui/ColorGradientPicker.vue'

import { ControlModelV2, SetControlColorGradientInput } from '../../../../../../../eco-domain-store-modules/src/plcOperation/models'

import { EcoLabeledControl } from '@ecocoach/shared-components/src'
import { ControlContextV2 } from '@ecocoach/shared-components/src/components/deviceControls/v2/models'

const Resource = namespace('resource')
const ParametrizationUi = namespace('parametrizationUi')

@Component({
  components: {
    ColorGradientPicker,
    EcoLabeledControl,
  },
})
export default class ChartConfigurationControl extends Vue {
  @Prop() public control: ControlModelV2
  @Resource.Getter public dictionary
  @ParametrizationUi.Getter public isInteracted: boolean
  @ParametrizationUi.Action public setControlColorGradient: (input: SetControlColorGradientInput) => Promise<void>

  public get contextV2(): ControlContextV2 {
    return {
      resolveStringResource: (resourceKey: string): string => {
        return this.dictionary(resourceKey)
      },
    } as ControlContextV2
  }

  public get attributes() {
    return this.control.attributes
  }

  public get gradient() {
    return this.attributes?.gradient ?? ''
  }

  public onGradientSelected(colorGradient: string) {
    this.setControlColorGradient({
      controlId: this.control.id,
      payload: {
        colorGradient,
      },
    } as SetControlColorGradientInput)
  }
}
</script>
<style lang="scss" scoped>
.chart-configuration-control {
  padding-bottom: 15px;
}
</style>
