import { MutationTree } from 'vuex'
import { PlcConnectionHistoryUiMutation, PlcConnectionHistoryUiState } from './types'

export const mutations: MutationTree<PlcConnectionHistoryUiState> = {
  [PlcConnectionHistoryUiMutation.setInteracted](state, payload: boolean) {
    state.interacted = payload
  },
  [PlcConnectionHistoryUiMutation.setSelectedPlcId](state, payload: string) {
    state.selectedPlcId = payload
  },
}