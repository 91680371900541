import { ControlModelV2, DeviceModel, RoomModel } from './models'
import { PlcOperationState } from './types'

export const state: PlcOperationState = {
  plcId: '',
  projectId: '',
  projects: [],
  plcs: [],
  rooms: [],
  roomsLookup: new Map<string, RoomModel>(),
  roomsForPlcLookup: new Map<string, RoomModel[]>(),
  devices: [],
  devicesLookup: new Map<string, DeviceModel>(),
  devicesForRoomLookup: new Map<string, DeviceModel[]>(),
  controls: [],
  controlsLookup: new Map<string, ControlModelV2>(),
  controlsForDeviceLookup: new Map<string, ControlModelV2[]>(),
  controlsFilter: () => false,
  scenes: [],
  favorites: [],
  pendingControlCommands: [],
  confirmedDeviceIds: [],
  controlUpdateTimeout: false,
  controlUpdateFailed: false,
  activePlcConfigurationReleaseId: '',
  componentFirmwareReleases: [],
}
