import { RootState } from '@/store/types'
import { DeviceModel, DeviceUpgradeInfoItem, ReleaseModel, ReleaseState, SolutionHardwareInstanceModel } from '@ecocoach/domain-store-modules/src/plcConfiguration/models'
import { FunctionBlockState, SolutionHardwareDefinitionState } from '@ecocoach/domain-store-modules/src/systemConfiguration/models'
import { SystemConfigurationGetter } from '@ecocoach/domain-store-modules/src/systemConfiguration/types'
import moment from 'moment'
import { GetterTree } from 'vuex'
import { ReleaseUiGetter, ReleaseUiState } from './types'

(moment as any).suppressDeprecationWarnings = true

export const getters: GetterTree<ReleaseUiState, RootState> = {
  [ReleaseUiGetter.isInteracted] ({ interacted }): boolean {
    return interacted
  },
  [ReleaseUiGetter.releaseById] (_, __, rootState) {
    return (id: string): ReleaseModel => rootState.plcConfiguration.releases.find(r => r.id === id)!
  },
  [ReleaseUiGetter.needsNewRelease](state, __, rootState: RootState) {
    return state.releasesLoaded && rootState.plcConfiguration.releases.length === 0
  },
  [ReleaseUiGetter.hasNewWithoutCompleted](_, __, rootState: RootState) {
    return rootState.plcConfiguration.releases.filter(release => release.state === ReleaseState.COMPLETED).length === 0
    &&  rootState.plcConfiguration.releases.filter(release => release.state === ReleaseState.NEW).length > 0
  },
  [ReleaseUiGetter.hasNewWithCompleted](_, __, rootState: RootState) {
    return rootState.plcConfiguration.releases.filter(release => release.state === ReleaseState.COMPLETED).length > 0
    &&  rootState.plcConfiguration.releases.filter(release => release.state === ReleaseState.NEW).length > 0
  },
  [ReleaseUiGetter.hasOnlyCompleted](_, __, rootState: RootState) {
    return rootState.plcConfiguration.releases.filter(release => release.state === ReleaseState.COMPLETED).length > 0
    &&  rootState.plcConfiguration.releases.filter(release => release.state === ReleaseState.NEW).length === 0
  },
  [ReleaseUiGetter.currentNewRelease] (_, __, rootState: RootState) {
    return rootState.plcConfiguration.releases.find(release => release.state === ReleaseState.NEW)
  },
  [ReleaseUiGetter.completedReleasesToShow] (_, __, rootState: RootState) {
    return rootState.plcConfiguration.releases
      .filter(release => release.state === ReleaseState.COMPLETED)
      .sort((first, second) => moment(first.builtAt).isAfter(second.builtAt) ? -1 : 1)
  },
  [ReleaseUiGetter.timeStampToDate] () {
    return (timeStamp: string) => {
      const date = timeStamp && moment.utc(timeStamp).local()
      return (date && date.format('DD.MM.YYYY') !== 'Invalid date') ?  date.format('DD.MM.YYYY') : ''        
    }
  }, 
  [ReleaseUiGetter.timeStampToDateWithTime] () {
    return (timeStamp: string) => {
      const date = timeStamp && moment.utc(timeStamp).local()
      return (date && date.format('DD.MM.YYYY - k:mm') !== 'Invalid date') ?  date.format('DD.MM.YYYY - k:mm') : ''
    }
  }, 
  [ReleaseUiGetter.isCurrentlyDeployed] (_, __, rootState) {
    return(releaseId: string) => {
      return rootState.plcOperation.activePlcConfigurationReleaseId !== '' && releaseId === rootState.plcOperation.activePlcConfigurationReleaseId
    }
  },
  [ReleaseUiGetter.currentRelease] (_, __, rootState: RootState) {
    return rootState.plcConfiguration.releases.find(r => r.id === rootState.plcConfiguration.releaseId)
  },
  [ReleaseUiGetter.currentReleaseShouldBeUpgraded] (_, getter, rootState, rootGetters): boolean {
    const currentRelease: ReleaseModel = getter.currentRelease
    if (!currentRelease || currentRelease.id !== rootState.plcConfiguration.releaseId) {
      return false
    }
    const isObsoleteDevice = (device: DeviceModel): boolean => {
      const functionBlock = rootGetters['systemConfiguration/functionBlockById'](device.functionBlockDefinitionId)
      return functionBlock && functionBlock.state === FunctionBlockState.OBSOLETE
    }
    const isObsoleteSolutionHardware = (solutionHardware: SolutionHardwareInstanceModel): boolean => {
      const definition = rootGetters['systemConfiguration/solutionHardwareDefinitionById'](solutionHardware.solutionHardwareDefinitionId)
      return definition && definition.state === SolutionHardwareDefinitionState.OBSOLETE
    }
    const hasObsoleteDevices = rootState.plcConfiguration.devices.some(d => isObsoleteDevice(d))
    const hasObsoleteSolutionHardware = rootState.plcConfiguration.solutionHardwareInstances.some(s => isObsoleteSolutionHardware(s))
    return hasObsoleteDevices || hasObsoleteSolutionHardware
  },
  [ReleaseUiGetter.currentReleaseHasInTestDevices] (_, getter, rootState, rootGetters): boolean {
    const currentRelease: ReleaseModel = getter[ReleaseUiGetter.currentRelease]
    if (!currentRelease || currentRelease.id !== rootState.plcConfiguration.releaseId) {
      return false
    }
    const isInTestDevice = (device: DeviceModel): boolean => {
      const functionBlock = rootGetters[`systemConfiguration/${SystemConfigurationGetter.functionBlockById}`](device.functionBlockDefinitionId)
      return functionBlock && functionBlock.state === FunctionBlockState.IN_TEST
    }
    const hasInTestDevices = rootState.plcConfiguration.devices.some(d => isInTestDevice(d))
    return hasInTestDevices
  },
  [ReleaseUiGetter.releaseUpgradeConfirmationDialogVisible] ({ releaseUpgradeConfirmationDialogVisible }) {
    return releaseUpgradeConfirmationDialogVisible
  },
  [ReleaseUiGetter.releaseUpgradeLogEntries] (_, __, rootState): DeviceUpgradeInfoItem[] {
    return rootState.plcConfiguration.deviceUpgradeInfos
  },
}