import { AppState } from '@/store/modules/app/types'
  
export const state: AppState = {
  interacted: false,
  navigationDrawerMinimized: false,
  hydrated: false,
  loadingApiFailed: false,
  selectedLanguage: '',
  selectedProjectId: '',
  selectedPlcId: '',
}
