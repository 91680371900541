import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { state } from './state'
import { SystemConfigurationAction, SystemConfigurationGetter, SystemConfigurationMutation, SystemConfigurationState } from './types'

import { Module } from 'vuex'

const namespaced: boolean = true
export const systemConfiguration: Module<SystemConfigurationState, any> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export const types = {
  action: SystemConfigurationAction, 
  mutation: SystemConfigurationMutation,
  getter: SystemConfigurationGetter,
}

export const domain = 'systemConfiguration'

export const vuexModule = {
  [domain]: systemConfiguration,
}