<template>
  <eco-labeled-control class="eco-enum-input-control"
    :context="context"
    :attributes="attributes"
  >
    <base-single-select
      :options="options"
      :option-value="'id'"
      :option-label="'label'"
      :selected-value="pendingValue !== undefined ? pendingValue : currentValue"
      :pending="pendingValue !== undefined"
      :disabled="readonly"
      @selected="onSelected"
    />
  </eco-labeled-control>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DropdownOption } from '../../..'
import BaseSingleSelect from '../../base/BaseSingleSelect.vue'
import { ControlContextV2, EnumInputControlAttributes } from './models'
import EcoLabeledControl from './shared/EcoLabeledControl.vue'

@Component({
  components: {
    BaseSingleSelect,
    EcoLabeledControl,
  },
})
export default class EcoSelectControl extends Vue {
  @Prop() public id: string
  @Prop() public context: ControlContextV2
  @Prop() public attributes: EnumInputControlAttributes
  @Prop() public state: object
  @Prop() public pendingState: object
  @Prop() public readonly: boolean

  public onSelected(value: string | number | boolean) {
    this.context.executeCommand(this.id, {
      command: this.attributes.command,
      params: value,
      pendingState: {
        [this.attributes.state]: value,
      },
    })
  }

  public get options() {
    return this.attributes.options.map(o => ({
      id: o.id,
      label: this.context.resolveStringResource(o.label),
    } as DropdownOption))
  }

  public get currentValue() {
    return this.state[this.attributes.state]
  }

  public get pendingValue() {
    return this.pendingState[this.attributes.state]
  }
}
</script>

<style lang="scss" scoped>
</style>

<style lang="css">
</style>
