import { RootState } from '@/store/types'
import { CompleteReleasePayload, DeployReleasePayload, ReleaseModel } from '@ecocoach/domain-store-modules/src/plcConfiguration/models'
import { PlcConfigurationAction } from '@ecocoach/domain-store-modules/src/plcConfiguration/types'
import moment from 'moment'
import { ActionTree } from 'vuex'
import { WizardMutation } from '../wizard/types'
import { ReleaseProperties, UpgradeRequiredReason } from './models'
import { LoadProgramUiAction, LoadProgramUiMutation, LoadProgramUiState } from './types'

export const actions: ActionTree<LoadProgramUiState, RootState> = {
  async [LoadProgramUiAction.setProperties] ({ commit, dispatch, rootState }, payload: ReleaseProperties): Promise<void> {
    try {
      commit(LoadProgramUiMutation.setInteracted, true)
      const release = rootState.plcConfiguration.releases.find(r => r.id === rootState.plcConfiguration.releaseId)!
      const input: ReleaseModel = {
        ...release,
        ...payload,
      }
      await dispatch(`plcConfiguration/${PlcConfigurationAction.updateRelease}`, input, { root: true })
    } finally {
      commit(LoadProgramUiMutation.setInteracted, false)
    }
  },
  async [LoadProgramUiAction.completeRelease] ({ commit, dispatch, rootState }, payload: { archive: boolean }): Promise<void> {
    try {
      commit(LoadProgramUiMutation.setInteracted, true)
      const input = {
        releaseId: rootState.plcConfiguration.releaseId,
        userIdentifier: rootState.authentication.loggedInUser.userIdentifier,
        builtAt: moment().toISOString(),
        archive: payload.archive,
      } as CompleteReleasePayload
      await dispatch(`plcConfiguration/${PlcConfigurationAction.completeRelease}`, input, { root: true })
      commit(LoadProgramUiMutation.setUpgradeRequiredReason, UpgradeRequiredReason.None)
    } catch(error) {
      if ((error as any).response?.data?.exceptionType === 'ConfigurationReleaseUpdateRequiredException') {
        commit(LoadProgramUiMutation.setUpgradeRequiredReason, UpgradeRequiredReason.Obsolete)
        commit(`wizard/${WizardMutation.hideToast}`, undefined, { root:true })
      } else if ((error as any).response?.data?.exceptionType === 'ConfigurationReleaseVersionConflictException') {
        commit(LoadProgramUiMutation.setUpgradeRequiredReason, UpgradeRequiredReason.Conflict)
        commit(`wizard/${WizardMutation.hideToast}`, undefined, { root:true })
      }
    } finally {
      commit(LoadProgramUiMutation.setInteracted, false)
    }
  },
  async [LoadProgramUiAction.deployRelease] ({ commit, dispatch, rootState }, payload: { forceDeployment: boolean }): Promise<void> {
    try {
      commit(LoadProgramUiMutation.setInteracted, true)
      const input = {
        releaseId: rootState.plcConfiguration.releaseId,
        forceDeployment: payload.forceDeployment,
        userIdentifier: rootState.authentication.loggedInUser.userIdentifier,
        deployedAt: moment().toISOString(),
      } as DeployReleasePayload
      await dispatch(`plcConfiguration/${PlcConfigurationAction.deployRelease}`, input, { root: true })
    } catch(error) {
      if ((error as any).response?.data?.exceptionType === 'PlcConfigurationActivationValidationException') {
        commit(LoadProgramUiMutation.setValidationIssues, (error as any).response.data.validationIssues ?? [])
        commit(`wizard/${WizardMutation.hideToast}`, undefined, { root:true })
      }
    } finally {
      commit(LoadProgramUiMutation.setInteracted, false)
    }
  },
}
