import { RootState } from '@/store/types'
import { SolutionHardwareInstanceModel } from '@ecocoach/domain-store-modules/src/plcConfiguration/models'
import { SolutionHardwareDefinitionState, SolutionHardwareInterfaceType } from '@ecocoach/domain-store-modules/src/systemConfiguration/models'
import { nextAvailableSortOrder } from '@ecocoach/domain-store-modules/src/utils'
import { ActionTree } from 'vuex'
import { SolutionHardwareClampLines, SolutionHardwareInstanceViewModel } from './models'
import { SolutionHardwareUiAction, SolutionHardwareUiMutation, SolutionHardwareUiState } from './types'

export const actions: ActionTree<SolutionHardwareUiState, RootState> = {
  async [SolutionHardwareUiAction.createSolutionHardwareInstance] ({ commit, dispatch, rootState }, payload: string): Promise<void> {
    try {
      commit(SolutionHardwareUiMutation.setInteracted, true)
      const input = { 
        solutionHardwareDefinitionId: payload,
        lastOctet: 0,
        sortOrder: nextAvailableSortOrder(rootState.plcConfiguration.solutionHardwareInstances),
      } as SolutionHardwareInstanceModel
      await dispatch('plcConfiguration/createSolutionHardwareInstance', input, { root: true })
      await dispatch(SolutionHardwareUiAction.computeClampLinesAndModules)
    } finally {
      commit(SolutionHardwareUiMutation.setInteracted, false)
    }
  },
  async [SolutionHardwareUiAction.updateSolutionHardwareInstance] ({ commit, dispatch }, payload: {id: string, lastOctet: number}): Promise<void> {
    try {
      commit(SolutionHardwareUiMutation.setInteracted, true)
      const input = { 
        ...payload,
      } as SolutionHardwareInstanceModel
      await dispatch('plcConfiguration/updateSolutionHardwareInstance', input, { root: true })
    } finally {
      commit(SolutionHardwareUiMutation.setInteracted, false)
    }
  },
  async [SolutionHardwareUiAction.deleteSolutionHardwareInstance] ({ commit, dispatch }, payload: string): Promise<void> {
    try {
      commit(SolutionHardwareUiMutation.setInteracted, true)
      await dispatch('plcConfiguration/deleteSolutionHardwareInstance', payload, { root: true })
      await dispatch(SolutionHardwareUiAction.computeClampLinesAndModules)
    } finally {
      commit(SolutionHardwareUiMutation.setInteracted, false)
    }
  },
  async [SolutionHardwareUiAction.sortSolutionHardwareInstances] ({ state, commit, dispatch }): Promise<void> {
    try {
      commit(SolutionHardwareUiMutation.setInteracted, true)
      const sortedIds: string[] = []
      sortedIds.push(...state.clampLines.masterClampLine.map(clamp => clamp.id))
      state.clampLines.slaveClampLines.forEach(line => {
        sortedIds.push(line.couplerClamp.id, ...line.clamps.map(clamp => clamp.id))
      })
      sortedIds.push(...state.modules.map(module => module.id))

      await dispatch('plcConfiguration/sortSolutionHardwareInstances', sortedIds, { root: true })
    } finally {
      commit(SolutionHardwareUiMutation.setInteracted, false)
    }
  },
  async [SolutionHardwareUiAction.computeClampLinesAndModules] ({ commit, rootState }): Promise<void> {
    const toViewModel = (shi: SolutionHardwareInstanceModel): SolutionHardwareInstanceViewModel => {
      const definition = rootState.systemConfiguration.solutionHardwareDefinitions.find(shd => shi.solutionHardwareDefinitionId === shd.id)
      return {
        id: shi.id,
        name: definition && definition.name || '',
        description: definition && definition.description || '',
        version: definition && definition.version || '',
        type: definition && definition.interfaceType || SolutionHardwareInterfaceType.UNKNOWN,
        obsolete: definition ? definition.state === SolutionHardwareDefinitionState.OBSOLETE : true,
        imageResourceId: definition && definition.imageResourceId || '',
        lastOctet: shi.lastOctet,
      }
    }
    const delimitingType = SolutionHardwareInterfaceType.EBUS_EXTENSION
    const clampTypes = [SolutionHardwareInterfaceType.EBUS, SolutionHardwareInterfaceType.KBUS, delimitingType]
    const clampLines: SolutionHardwareClampLines = {
      masterClampLine: [],
      slaveClampLines: [],
    }
    const allSolutionHardware = rootState.plcConfiguration.solutionHardwareInstances.map(shi => toViewModel(shi))
    const allClamps = allSolutionHardware.filter(vm => clampTypes.includes(vm.type))
    allClamps.forEach(clamp => { // iterating by current sortorder
      if (clamp.type === delimitingType) { // coupler clamp starting new slave line
        clampLines.slaveClampLines.push({
          couplerClamp: clamp,
          clamps: [],
        })
      } else { // regular clamp to be added to last line
        const lineToPush = clampLines.slaveClampLines.length === 0
          ? clampLines.masterClampLine
          : clampLines.slaveClampLines[clampLines.slaveClampLines.length - 1].clamps
        lineToPush.push(clamp)
      }
    })

    const modules = allSolutionHardware.filter(vm => !clampTypes.includes(vm.type))
    commit(SolutionHardwareUiMutation.setClampLines, clampLines)
    commit(SolutionHardwareUiMutation.setModules, modules)
  },
}
