import { PlcOperationStateHistoryItemModel } from './models'

export interface PlcConnectionState {
  plcOperationStateHistory: PlcOperationStateHistoryItemModel[]
}

export enum PlcConnectionAction {
  loadPlcOperationStateHistory = 'loadPlcOperationStateHistory',
}

export enum PlcConnectionMutation {
  setPlcOperationStateHistory = 'setPlcOperationStateHistory',
}
