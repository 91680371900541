import { ActionTree } from 'vuex'
import api from './api'
import { AlarmDefinitionModel, CreateAlarmDefinitionInput } from './models'
import { AlarmDefinitionAction, AlarmDefinitionMutation, AlarmDefinitionState} from './types'

export const actions: ActionTree<AlarmDefinitionState, {}> = {
  async [AlarmDefinitionAction.loadAlarmDefinitions]({ commit }, payload: { plcId: string, language: string }): Promise<void> {
    const data = await api.loadAlarmDefinitions(payload.plcId, payload.language)
    commit(AlarmDefinitionMutation.setAlarmDefinitions, data)
  },
  async [AlarmDefinitionAction.loadAlarmDefinitionOptions]({ commit }, payload: { plcId: string, language: string }): Promise<void> {
    const data = await api.loadAlarmDefinitionsOptions(payload.plcId, payload.language)
    commit(AlarmDefinitionMutation.setAlarmDefinitionsOptions, data)
  },
  async [AlarmDefinitionAction.createAlarmDefinition]({ commit }, payload: CreateAlarmDefinitionInput): Promise<void> {
    const { id } = await api.createAlarmDefinition(payload)
    commit(AlarmDefinitionMutation.upsertAlarmDefinition, {
      ...payload,
      id,
    })
  },
  async [AlarmDefinitionAction.updateAlarmDefinition]({ commit }, payload: AlarmDefinitionModel): Promise<void> {
    await api.updateAlarmDefinition(payload)
    commit(AlarmDefinitionMutation.upsertAlarmDefinition, {
      ...payload,
    })
  },
  async [AlarmDefinitionAction.deleteAlarmDefinition]({ commit }, payload: { id: string, plcId: string }): Promise<void> {
    await api.deleteAlarmDefinition(payload.plcId, payload.id)
    commit(AlarmDefinitionMutation.removeAlarmDefinition, payload.id)
  },
}
