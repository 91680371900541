import MobileApp from'@/components/views/MobileApp.vue'
import AuthTool from'@/components/views/ecoAuthTool/index.vue'
import PartnerTool from'@/components/views/ecoPartnerTool/index.vue'
import SetupTool from'@/components/views/ecoSetupTool/index.vue'
import FunctionDefinitionEditor from'@/components/views/ecoFunctionDefinitionEditor/index.vue'
import Account from'@/components/views/Account.vue'
import ReleaseNotes from'@/components/views/ReleaseNotes.vue'

import configureDevices from '@/components/views/ecoSetupTool/steps/configureDevices/index.vue'
import defineDevices from '@/components/views/ecoSetupTool/steps/defineDevices/index.vue'
import defineRooms from '@/components/views/ecoSetupTool/steps/defineRooms/index.vue'
import loadProgram from '@/components/views/ecoSetupTool/steps/loadProgram/index.vue'
import manageAlarms from '@/components/views/ecoSetupTool/steps/manageAlarms/index.vue'
import mapHardware from '@/components/views/ecoSetupTool/steps/mapHardware/index.vue'
import selectPlc from '@/components/views/ecoSetupTool/steps/selectPlc/index.vue'
import solutionHardware from '@/components/views/ecoSetupTool/steps/solutionHardware/index.vue'

import consumptionAccounts from '@/components/views/ecoOneClick/administration/index.vue'
import consumptionExports from '@/components/views/ecoOneClick/exports/index.vue'
import consumptionVisualization from '@/components/views/ecoOneClick/visualization/index.vue'

import store from '@/store'
import { AppGetter, AppMutation } from '@/store/modules/app/types'
import { ConsumptionUiGetter } from '@/store/modules/consumptionUi/types'
import { WizardGetter } from '@/store/modules/wizard/types'

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes: [
    {
      path: '/mobileApp',
      name: 'mobileApp',
      component: MobileApp,
      meta: {
        title: 'navigation.mobileapp',
        icon: 'smartphone',
        sortOrder: 1,
      },
    },
    {
      path: '/management',
      name: 'management',
      component: PartnerTool,
      meta: {
        requirements: [
          `app/${AppGetter.showPartnerTool}`,
        ],
        title: 'navigation.ecopartnertool',
        icon: 'explore',
        sortOrder: 2,
      },
    }, 
    {
      path: '/ecoSetupTool',
      name: 'ecoSetupTool',
      component: SetupTool,
      meta: {
        requirements: [
          `app/${AppGetter.showSetupTool}`,
        ],
        title: 'navigation.ecosetuptool',
        icon: 'settings',
        sortOrder: 3,
      },
      redirect: {
        name: 'selectPlc',
      },
      children: [
        {
          path: 'select-plc',
          name: 'selectPlc',
          component: selectPlc,
        },
        // manage release is no component for router => dialog
        {
          path: 'define-rooms',
          name: 'defineRooms',
          component: defineRooms,
        },
        {
          path: 'define-devices',
          name: 'defineDevices',
          component: defineDevices,
        },
        {
          path: 'define-solution-hardware',
          name: 'defineSolutionHardware',
          component: solutionHardware,
        },
        {
          path: 'map-hardware',
          name: 'mapHardware',
          component: mapHardware,
        },
        {
          path: 'load-program',
          name: 'loadProgram',
          component: loadProgram,
        },
        {
          path: 'configure-devices',
          name: 'configureDevices',
          component: configureDevices,
        },
        {
          path: 'manage-alarms',
          name: 'manageAlarms',
          component: manageAlarms,
        },
      ], 
    },
    {
      path: '/authorizations',
      name: 'authorizations',
      component: AuthTool,
      meta: {
        title: 'navigation.ecoauthtool',
        icon: 'people',
        sortOrder: 4,
        requirements: [
          `app/${AppGetter.showAuthTool}`,
        ],
      },
    },
    {
      path: '/ecoOneClick',
      name: 'ecoOneClick',
      meta: {
        requirements: [
          `consumptionUi/${ConsumptionUiGetter.showOneClickTopNavigation}`,
        ],
        title: 'ecoOneClick',
        icon: '/img/icons/oneClick.icon.png', // absolut to dist (root)  
        sortOrder: 5,
      },
    },
    {
      path: '/ecoOneClick-administration',
      name: 'ecoOneClick-administration',
      component: consumptionAccounts,
      meta: {
        requirements: [
          `consumptionUi/${ConsumptionUiGetter.showOneClickSubNavigation}`,
          `consumptionUi/${ConsumptionUiGetter.hasOneClickEditProjects}`,
        ],
        title: 'navigation.ecoOneClick.administration',
        // no icon => indicates sub-item
        sortOrder: 6,
      },
    },
    {
      path: '/ecoOneClick-export',
      name: 'ecoOneClick-export',
      component: consumptionExports,
      meta: {
        requirements: [
          `consumptionUi/${ConsumptionUiGetter.showOneClickSubNavigation}`,
          `consumptionUi/${ConsumptionUiGetter.hasOneClickExportProjects}`,
        ],
        title: 'navigation.ecoOneClick.export',
        // no icon => indicates sub-item
        sortOrder: 7,
      },
    },
    {
      path: '/ecoOneClick-visualization',
      name: 'ecoOneClick-visualization',
      component: consumptionVisualization,
      meta: {
        requirements: [
          `consumptionUi/${ConsumptionUiGetter.showOneClickSubNavigation}`,
          `consumptionUi/${ConsumptionUiGetter.hasOneClickVisualizationProjects}`,
        ],
        title: 'navigation.ecoOneClick.visualization',
        // no icon => indicates sub-item
        sortOrder: 8,
      },
    },
    {
      path: '/ecoFunctionDefinitionEditor',
      name: 'ecoFunctionDefinitionEditor',
      component: FunctionDefinitionEditor,
      meta: {
        requirements: [
          `app/${AppGetter.isEcocoachEmployee}`,
        ],
        title: 'ecoFunctionDefinitionEditor',
        icon: 'build',
        sortOrder: 9,
      },
    },   
    {
      path: '/account',
      name: 'account',
      component: Account,
      meta: {
        title: 'navigation.account',
        icon: 'person',
        sortOrder: 10,
      },
    },
    {
      path: '/help',
      name: 'help',
      component: undefined,
      meta: {
        title: 'navigation.help',
        icon: 'help',
        sortOrder: 11,
      },
    },
    {
      path: '/releaseNotes',
      name: 'releaseNotes',
      component: ReleaseNotes,
      meta: {
        title: 'navigation.releaseNotes',
        icon: 'list',
        sortOrder: 12,
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  if(to.name === 'help') {
    window.open('http://help.ecocoach.com/help-content/index.html')
    next(false)
    return
  }
  if(to.name === 'ecoOneClick') {
    store.dispatch('consumptionUi/topNavigationClicked', router)
    next(false)
    return
  }
  next(vm => {
    if(!vm.$store.getters[WizardGetter.entryConditionMet] as boolean) {
      vm.$router.go(-1)
    }
  })
})

store.subscribe(({ type }) => {
  if (type === `app/${AppMutation.loaded}`) {
    // navigate to default route
    const currentRouteName = router.currentRoute.name
    const defaultRouteName = store.getters[`app/${AppGetter.showSetupTool}`] ? 'selectPlc' : 'mobileApp'
    if (currentRouteName !== defaultRouteName) {
      router.push({ name: defaultRouteName })
    }
  }
})

export default router