import { WizardGetter, WizardState, WizardStep } from '@/store/modules/wizard/types'
import { RootState } from '@/store/types'
import { ReleaseState } from '@ecocoach/domain-store-modules/src/plcConfiguration/models'
import { GetterTree } from 'vuex'

export const getters: GetterTree<WizardState, RootState> = {
  [WizardGetter.wizardSteps] ({ steps }: WizardState) {
    return steps
  },
  [WizardGetter.getActiveStep]({ currentStep }: WizardState) {
    return currentStep
  },
  [WizardGetter.entryConditionMet] (_, __, ___, rootGetters) {
    return (nextStep: WizardStep) => {
      // this gets an array with the results of all getters
      // simply check if none is false => AND condition for all getters
      const falses = nextStep.entryCondition.map((conditionialGetter) => {
        return !rootGetters[conditionialGetter] ? false : rootGetters[conditionialGetter] === true
      }).filter(val => val === false)
      return falses.length === 0
    }
  },
  [WizardGetter.isWaiting]({ waiting }: WizardState) {
    return waiting
  },
  [WizardGetter.isReleaseDialogOpen] ({releaseDialogOpened}: WizardState)  {
    return releaseDialogOpened
  },
  [WizardGetter.conditionStillInEdit] (_, __, rootState)  {
    return rootState.plcConfiguration.releases.filter(release => release.state === ReleaseState.NEW).length > 0
  },
  [WizardGetter.conditionNotInEdit] (_, __, rootState)  {
    return rootState.plcConfiguration.releases.filter(release => release.state === ReleaseState.NEW).length === 0
  },
}
