<template>
  <auto-mapping-item
    :auto-mapping-slot="autoMappingSlot"
    :is-mapped="false"
    :is-currently-mapping="isCurrentlyMapping"
    :disabled="disabled"
    @click="onClick"
  />
</template>
<script lang="ts">
import AutoMappingItem from '@/components/views/ecoSetupTool/steps/mapHardware/AutoMappingItem.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { AutoMappingSlotModel } from '../../../../../../../eco-domain-store-modules/src/systemConfiguration/models'

const MappingUi = namespace('mappingUi')

@Component ({
  components: {
    AutoMappingItem,
  },
})
export default class AutoMappingUnmappedSlot extends Vue {
  @Prop() public autoMappingSlot: AutoMappingSlotModel
  @Prop({ default: false }) public isCurrentlyMapping: boolean
  @Prop({ default: false }) public disabled: boolean
  @MappingUi.Mutation public resetCurrentAutoMapping

  public onClick() {
    if (this.isCurrentlyMapping) {
      this.resetCurrentAutoMapping()
    } else {
      this.$emit('click')
    }
  }
}
</script>
