import { ManageAlarmsUiState } from '@/store/modules/manageAlarmsUi/types'

export const state: ManageAlarmsUiState = {
  interacted: false,
  selectedAlarmDefinitionId: '',
  filterSettings: {
    levels: [],
    name: '',
    controlName: '',
    deviceName: '',
    roomName: '',
    message: '',
  },
  editingAlarmDefinition: null,
}
