<template>
  <div class="consumption-users">
    <div class="user-head">
      <div class="user-title">
        {{'oneclick.user.name' | translate}}
      </div>
    </div>
    <div class="name-filter">
      <text-input
        :value="adminUserFilterString"
        :placeholder="'oneclick.filter.name' | translate"
        @input="setUserFilterString"
      />
    </div>
    <div :class="adminUsers.length > 0 ? 'user-content' : ''">
      <div 
        v-for="user in adminUsers"
        :key="user.userIdentifier"
        class="users"
      >
        <div class="user-description" :class="user.disabled ? 'other-assigned' : ''">
          <div class="user-name">
            {{ user.userIdentifier }}
          </div>
          <div class="assigned-account" v-if="user.disabled">
            {{ user.accountName }} 
          </div>
        </div>
        <div class="user-selection">
          <icon-button v-if="!user.selected"
            type="materialIcon"
            materialIconName="check_box_outline_blank"
            :width="25"
            :height="25"
            :enabled="!user.disabled && !isInteracted"
            @buttonClicked="assignUser(user.userIdentifier)"
          />
          <icon-button v-else
            type="materialIcon"
            materialIconName="check_box"
            :width="25"
            :height="25"
            :enabled="!user.disabled && !isInteracted"
            @buttonClicked="unassignUser(user.userIdentifier)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { UserViewModel } from '../../../../store/modules/consumptionUi/models'

const ConsumptionUi = namespace ('consumptionUi')

@Component({
  components: {
  },
})
export default class ConsumptionUserList extends Vue {
  @ConsumptionUi.Getter public isInteracted: boolean
  @ConsumptionUi.Getter public adminUsers: UserViewModel[]
  @ConsumptionUi.Getter public adminUserFilterString: string
  @ConsumptionUi.Mutation public setUserFilterString: (filter: string) => void
  @ConsumptionUi.Action public assignUser: (dynamicMeterUserIdentifier: string) => Promise<void>
  @ConsumptionUi.Action public unassignUser: (dynamicMeterUserIdentifier: string) => Promise<void>
}
</script>
<style lang="scss" scoped>
.consumption-users {
  width: 100%;
  text-align: left;
  .users {
    display: flex;
    flex-direction: row;
    flex: 1;
    border-bottom: 1px solid white;
    .user-description {
      justify-content: flex-start;
      padding-right: 8px;
      flex: 1 1 92%;
      min-height: 45px;
      padding: 10px;
      .user-name {
        align-items: center;
      }
    }
    .user-selection {
      justify-content: flex-end;
      flex: 0 0 8%;
    }
  }
}
.other-assigned {
  color: grey;
}
.user-head {
  .user-title{
    font-size: 24px;
    line-height: 48px;
    height: 48px;
  }
}

</style>