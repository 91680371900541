<template>
  <card header="Function definitions" :chipText="chipText" :chipColor="chipColor">
    <template v-slot:actions>
      <card-action
        icon="add"
        tooltip="Create new function definition"
        @click="onAdd"
      />
      <card-action
        icon="keyboard_arrow_down"
        tooltip="Expand all"
        @click="onExpandAll"
      />
      <card-action
        icon="keyboard_arrow_up"
        tooltip="Collapse all"
        @click="onCollapseAll"
      />
    </template>
    <input 
      :id="'file-upload-create'"
      type="file" 
      ref="input"
      :accept="fileType"
      @change="onXmlFileSelected($event.target.files[0])"
    >
    <div class="toggle">
      <label-component
          text="Include obsolete"
          descriptionContent="When active, function blocks with state 'Obsolete' are included"
      />
      <base-toggle-button
        :value="includeObsolete"
        @buttonToggled="includeObsolete = !includeObsolete"
      />
    </div>
    <div class="filter">
      <form-field text="Filter">
        <string-filter-component
          :value="searchString"
          :placeholder="'functionblocks.filter.placeholder' | translate"
          @changed="searchString = $event"
        />
      </form-field>
      <label-component class="filterinfo"
        v-if="this.searchResults.length === 0"
        :text="'functionblocks.filter.noresults' | translate"
      />
    </div>
    <tree-view class="fbtree">
      <tree-view-group
        v-for="({ category, functionBlocks }) in searchResults"
        :key="category.id"
        :expanded="treeExpanded"
        :title="category.name"
        :icon="category.iconResourceId"
      >
        <function-definition-tree-item v-for="functionBlock in functionBlocks"
          :key="functionBlock.id"
          :functionBlock="functionBlock"
          @createNewVersion="onCreateNewVersion(functionBlock)"
          @setActive="functionDefinitionToBeSetActive = functionBlock"
          @setObsolete="functionDefinitionToBeSetObsolete = functionBlock"
          @editReleaseNotes="functionDefinitionToEditReleaseNotes = functionBlock"
          @downloadXmlFile="onDownloadXmlFile(functionBlock)"
          @copyIdToClipboard="onCopyIdToClipboard(functionBlock)"
        />
      </tree-view-group>
    </tree-view>
    <set-function-definition-active-dialog v-if="functionDefinitionToBeSetActive"
      :functionDefinition="functionDefinitionToBeSetActive"
      @closed="functionDefinitionToBeSetActive = null"
    />
    <set-function-definition-obsolete-dialog v-if="functionDefinitionToBeSetObsolete"
      :functionDefinition="functionDefinitionToBeSetObsolete"
      @closed="functionDefinitionToBeSetObsolete = null"
    />
    <edit-function-definition-release-notes-dialog v-if="functionDefinitionToEditReleaseNotes"
      :functionDefinition="functionDefinitionToEditReleaseNotes"
      @closed="functionDefinitionToEditReleaseNotes = null"
    />
    <select-package-dialog v-if="showSelectPackageDialog"
      @selected="onPackageSelected"
      @cancelled="showSelectPackageDialog = false"
    />
  </card>
</template>

<script lang="ts">
import Card from '../../ui/Card.vue'
import CardAction from '../../ui/CardAction.vue'
import TreeView from '../../ui/TreeView.vue'
import TreeViewGroup from '../../ui/TreeViewGroup.vue'

import EditFunctionDefinitionReleaseNotesDialog from './EditFunctionDefinitionReleaseNotesDialog.vue'
import FunctionDefinitionTreeItem from './FunctionDefinitionTreeItem.vue'
import SelectPackageDialog from './SelectPackageDialog.vue'
import SetFunctionDefinitionActiveDialog from './SetFunctionDefinitionActiveDialog.vue'
import SetFunctionDefinitionObsoleteDialog from './SetFunctionDefinitionObsoleteDialog.vue'

import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { SetFunctionBlockObsoleteInput } from '../../../../../eco-domain-store-modules/src/systemConfiguration/models'

import { ResourceCategory, ResourceModel } from '../../../../../eco-domain-store-modules/src/resource/models'
import { FunctionBlockDefinitionSearchParams, FunctionBlocksOfCategory, FunctionBlockTreeItemViewModel } from '../../../store/modules/functionBlockUi/models'

import { ArchitectureType, SourceLanguage } from '../../../../../eco-domain-store-modules/src/common'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')
const FunctionBlockUi = namespace('functionBlockUi')
const SystemConfiguration = namespace('systemConfiguration')
const Resource = namespace('resource')

@Component({
  components: {
    Card,
    CardAction,
    EditFunctionDefinitionReleaseNotesDialog,
    SelectPackageDialog,
    SetFunctionDefinitionActiveDialog,
    SetFunctionDefinitionObsoleteDialog,
    TreeView,
    TreeViewGroup, 
    FunctionDefinitionTreeItem,
  },
})
export default class FunctionDefinitionTree extends Vue {
  @FunctionBlockUi.Getter public functionBlocksSearchResult: (filter: FunctionBlockDefinitionSearchParams) => FunctionBlocksOfCategory[]
  @Resource.Getter public resourcesOfCategory: (category: ResourceCategory) => ResourceModel[]
  @SystemConfiguration.Action public setFunctionBlockActive: (id: string) => Promise<void>
  @SystemConfiguration.Action public setFunctionBlockObsolete: (payload: SetFunctionBlockObsoleteInput) => Promise<void>
  @FunctionDefinitionEditorUi.Getter public architectureType: ArchitectureType
  @FunctionDefinitionEditorUi.Getter public sourceLanguage: SourceLanguage
  @FunctionDefinitionEditorUi.Action public createFunctionDefinitionFromXmlFile
  @FunctionDefinitionEditorUi.Action public createFunctionDefinitionFromPackage: (functionBlockPackageInfoId: string) => Promise<void>
  @FunctionDefinitionEditorUi.Action public createFunctionDefinitionFromExisting
  @FunctionDefinitionEditorUi.Action public downloadFunctionBlockXml: (id: string) => Promise<void>

  public treeExpanded: boolean = false
  public searchString = ''
  public includeObsolete = false

  public functionDefinitionToBeSetActive: FunctionBlockTreeItemViewModel | null = null
  public functionDefinitionToBeSetObsolete: FunctionBlockTreeItemViewModel | null = null
  public functionDefinitionToEditReleaseNotes: FunctionBlockTreeItemViewModel | null = null

  public showSelectPackageDialog = false

  public mounted() {
    this.functionDefinitionToBeSetActive = null
    this.functionDefinitionToBeSetObsolete = null
    this.functionDefinitionToEditReleaseNotes = null
  }

  @Watch('searchString')
  public searchStringChanged(value: string) {
    this.treeExpanded = !!value
  }

  public get searchResults() {
    return this.functionBlocksSearchResult({
      search: this.searchString, 
      includeInTest: true,
      includeObsolete: this.includeObsolete,
      architectureType: this.architectureType,
      sourceLanguage: this.sourceLanguage,
    })
  }

  public onAdd() {
    if (this.sourceLanguage === SourceLanguage.CSharp) {
      this.showSelectPackageDialog = true
    } else { 
      this.openFileDialog()
    }
  }

  public onPackageSelected(id: string) {
    this.createFunctionDefinitionFromPackage(id)
    this.showSelectPackageDialog = false
  }

  public openFileDialog() {
    const input:any = this.$refs.input
    input.value = null
    input.click()
  }

  public onCollapseAll() {
    this.treeExpanded = false
  }

  public onExpandAll() {
    this.treeExpanded = true
  }

  public onXmlFileSelected(file: File) {
    this.createFunctionDefinitionFromXmlFile(file)
  }

  public get isCategoryExpanded(): boolean {
    return !!this.searchString
  }

  public onCreateNewVersion(functionBlock: FunctionBlockTreeItemViewModel) {
    this.createFunctionDefinitionFromExisting(functionBlock.id)
  }

  public onDownloadXmlFile(functionBlock: FunctionBlockTreeItemViewModel) {
    this.downloadFunctionBlockXml(functionBlock.id)
  }

  public onCopyIdToClipboard(functionBlock: FunctionBlockTreeItemViewModel) {
    this.$copyText(functionBlock.id)
  }

  public get chipText() {
    return this.architectureType === ArchitectureType.ObjectOriented
      ? (this.sourceLanguage === SourceLanguage.CSharp ? 'C#' : 'OO')
      : 'Classic'
  }

  public get chipColor() {
    return this.architectureType === ArchitectureType.ObjectOriented ? 'green' : 'blue'
  }
}
</script>

<style lang="scss" type="text/scss" scoped>
  .toggle {
		display:flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
    flex: 1;
    padding: 0px 12px 0px 4px;
	}
  .filter {
    margin-left: -7px;
    margin-right: 10px;
	}
  .filterinfo {
    padding: 0px 36px 0px 12px;
	}
  .fbtree {
    margin-right: -24px;
  }

</style>