import { NoteUiAction, NoteUiMutation, NoteUiState } from '@/store/modules/noteUi/types'
import { RootState } from '@/store/types'
import { NoteModel } from '@ecocoach/domain-store-modules/src/note/models'
import { NoteAction } from '@ecocoach/domain-store-modules/src/note/types'
import { ActionTree } from 'vuex'

export const actions: ActionTree<NoteUiState, RootState> = {
  async [NoteUiAction.openNotesForPlc] ({ commit, dispatch }, plcId: string): Promise<void> {
    try {
      commit(NoteUiMutation.setLoading, true)
      await dispatch(`note/${NoteAction.initializeNotesForPlc}`, plcId, { root:true })
    } finally {
      commit(NoteUiMutation.setLoading, false)
    }
  },
  async [NoteUiAction.closeNotesForPlc] ({ commit, dispatch }): Promise<void> {
    try {
      commit(NoteUiMutation.setInteracted, true)
      await dispatch(`note/${NoteAction.uninitializeNotesForPlc}`, undefined, { root:true })
    } finally {
      commit(NoteUiMutation.setInteracted, false)
    }
  },
  async [NoteUiAction.createNoteForPlc] ({ commit, dispatch }, payload: NoteModel): Promise<void> {
    try {
      commit(NoteUiMutation.setInteracted, true)
      dispatch(`note/${NoteAction.createNoteForPlc}`, payload, { root:true })
    } finally {
      commit(NoteUiMutation.setInteracted, false)
    }
  },
  async [NoteUiAction.updateNoteForPlc] ({ commit, dispatch }, payload: NoteModel): Promise<void> {
    try {
      commit(NoteUiMutation.setInteracted, true)
      dispatch(`note/${NoteAction.updateNoteForPlc}`, payload, { root:true })
    } finally {
      commit(NoteUiMutation.setInteracted, false)
    }
  },
  async [NoteUiAction.deleteNoteForPlc] ({ commit, dispatch }, payload: string): Promise<void> {
    try {
      commit(NoteUiMutation.setInteracted, true)
      dispatch(`note/${NoteAction.deleteNoteForPlc}`, payload, { root:true })
    } finally {
      commit(NoteUiMutation.setInteracted, false)
    }
  },
}
