<template>
  <div class="table-filter-autocomplete">
    <v-autocomplete
      :items="options"
      item-value="id"
      item-text="label"
      :value="values"
      :multiple="true"
      :placeholder="placeholder"
      hide-no-data
      @change="onSelected($event)"
      solo
      :menu-props="{ contentClass: 'table-filter-autocomplete-menu' }"
    >
      <template v-slot:selection="{ item, index }">
        <v-chip class="selected-item" v-if="index === 0">
          <span>{{ truncate(item.label) }}</span>
        </v-chip>
        <div v-if="index === 1"
          class="more grey--text caption"
        >
          (+{{ values.length - 1 }})
        </div>
      </template>
    </v-autocomplete>
    <icon-button v-if="values.length > 0"
      @buttonClicked="onCleared"
      type="materialIcon"
      materialIconName="clear"
      :height="25"
      :width="25"
    />
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import { DropdownOption } from '@ecocoach/shared-components/src'

const maxLabelLength = 20

@Component({
  components: {
  },
})
export default class TableFilterAutoCompleteMultiSelect extends Vue {
  @Prop() public options: DropdownOption[]
  @Prop() public values: string[]
  @Prop() public placeholder: string

  public truncate(text: string) {
    if (text.length > maxLabelLength) {
      return text.substring(0, maxLabelLength) + '...'
    }
    return text
  }

  @Emit('selected')
  public onSelected(selectedValues: string[]) {
    return selectedValues
  }

  @Emit('cleared')
  public onCleared() {
    return undefined
  }
}
</script>
<style lang="scss" scoped>
.table-filter-autocomplete {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  height: 30px;
  min-height: 30px;
}
</style>
<style lang="scss">

.table-filter-autocomplete > .v-text-field--solo>.v-input__control>.v-input__slot {
  background-color: transparent !important;
  box-shadow: none !important;
}
.table-filter-autocomplete > .v-input > .v-input__control > .v-text-field__details {
  display: none;
}
.table-filter-autocomplete > .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
  color: $fore-color-primary;
  padding: 0px;
}
.table-filter-autocomplete .v-icon {
  color: $fore-color-primary !important;
  padding-top: 5px;
  opacity: 1 !important;
}
.table-filter-autocomplete .v-input {
  font-size: inherit;
}
.table-filter-autocomplete .v-select__selections {
  font-size: inherit;
  flex-wrap: nowrap;
}
.table-filter-autocomplete .selected-item {
  background: $bg-primary !important;
  margin: 5px 0px 0px 0px;
}
.table-filter-autocomplete .more {
  margin: 10px 5px 5px 5px;
}
.table-filter-autocomplete .selected-item .v-chip__content {
  padding: 5px;
  height: 25px;
}
.table-filter-autocomplete-menu .v-list {
  background: $bg-primary !important;
}
.table-filter-autocomplete-menu .v-list__tile__action {
  min-width: 36px;
}
.table-filter-autocomplete-menu .v-list__tile__title {
  font-size: 14px;
}
.table-filter-autocomplete-menu .primary--text {
  color: inherit !important;
}
</style>