<template>
  <modal-dialog v-if="selectedPlcId"
    :show="true"
    :header="'common.button.auditLog' | translate"
    closing-x=true
    @xPressed="closeAuditLog"
    width=1000
    height=750
  >
    <v-layout column>
      <audit-log-table/>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import AuditLogTable from './AuditLogTable.vue'

const AuditLogUi = namespace('auditLogUi')

@Component({
  components: {
    AuditLogTable,
  },
})
export default class AuditLog extends Vue { 
  @AuditLogUi.Getter public isInteracted: boolean
  @AuditLogUi.Getter public selectedPlcId: boolean
  @AuditLogUi.Action public closeAuditLog: () => Promise<void>
}
</script>
<style lang="scss" scoped>

</style>