<template>
  <div>
    <v-data-table :headers="headers" :items="endpointRecordingConfigurations" item-key="id"
      class="endpoint-recordings-table"
      :rows-per-page-items="[5]"
      :loading="isLoading"
    >
      <template v-slot:items="props">
        <tr :key="props.item.id">
          <td :title="`${props.item.deviceName}`">{{ props.item.deviceName }}</td>
          <td :title="`${props.item.qualifiedEndpointPath}`">{{ props.item.endpointDisplayName }}</td>
          <td :title="`${props.item.intervalInMilliseconds} (ms)`">{{ props.item.interval }}</td>
          <td :title="`${props.item.startDateTimeUtc} (UTC)`">{{ props.item.started }}</td>
          <td :title="`${props.item.endDateTimeUtc} (UTC)`">{{ endTimeDisplayText(props.item) }}</td>
          <td :title="`${props.item.numEntries} (recorded)`">{{ props.item.numEntries }}</td>
          <td>
            <div class="endpoint-recordings-table-actions">
              <v-tooltip bottom class="endpoint-recordings-table-action">
                <v-btn slot="activator" :disabled="isInteracted" @click="onDownload(props.item)" icon flat>
                  <v-icon small>download</v-icon>
                </v-btn>
                <span>{{ 'common.button.download' | translate }}</span>
              </v-tooltip>
              <v-tooltip bottom class="endpoint-recordings-table-action">
                <v-btn slot="activator" :disabled="isInteracted" @click="onEditConfiguration(props.item)" icon flat>
                  <v-icon small>edit</v-icon>
                </v-btn>
                <span>{{ 'common.button.edit' | translate }}</span>
              </v-tooltip>
              <v-tooltip bottom class="endpoint-recordings-table-action">
                <v-btn slot="activator" :disabled="isInteracted" @click="deleteEndpointRecordingConfiguration(props.item.id)" icon flat>
                  <v-icon small>delete</v-icon>
                </v-btn>
                <span>{{ 'common.button.delete' | translate }}</span>
              </v-tooltip>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <edit-endpoint-recording-dialog 
      :show="configurationToEdit"
      :configuration="configurationToEdit" 
      @closed="configurationToEdit = null"
    />
    <download-endpoint-recording-data-dialog
      :show="configurationToDownload"
      :configuration="configurationToDownload" 
      @closed="configurationToDownload = null"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import DownloadEndpointRecordingDataDialog from './DownloadEndpointRecordingDataDialog.vue'
import EditEndpointRecordingDialog from './EditEndpointRecordingDialog.vue'

import { EndpointRecordingViewModel } from '../../../../../store/modules/logToolUi/models'

const App = namespace('app')
const Resource = namespace('resource')
const LogToolUi = namespace('logToolUi')

@Component({
  components: {
    DownloadEndpointRecordingDataDialog,
    EditEndpointRecordingDialog,
  },
})
export default class EndpointRecordingsTable extends Vue {
  @LogToolUi.Getter public isInteracted: boolean
  @LogToolUi.Getter public isLoading: boolean
  @LogToolUi.Getter public endpointRecordingConfigurations: EndpointRecordingViewModel[]
  @LogToolUi.Action public deleteEndpointRecordingConfiguration: (id: string) => Promise<void>
  @App.Getter public selectedLanguage: string
  @Resource.Getter public dictionary

  public configurationToEdit: EndpointRecordingViewModel | null = null
  public configurationToDownload: EndpointRecordingViewModel | null = null

  public endTimeDisplayText(recording: EndpointRecordingViewModel) {
    return recording.stillRunning ? `${this.dictionary('logtool.runninguntil')} (${recording.ended})` : recording.ended
  }

  public get headers() {
    return [
      {
        text: this.dictionary('logtool.device'),
        value: 'deviceName',
        align: 'left',
        width: '20%',
      },
      {
        text: this.dictionary('logtool.endpointpath'),
        value: 'qualifiedEndpointPath',
        align: 'left',
        width: '20%',
      },
      {
        text: this.dictionary('logtool.interval'),
        value: 'interval',
        align: 'left',
        width: '10%',
      },
      {
        text: this.dictionary('logtool.starttime'),
        value: 'started',
        align: 'left',
        width: '15%',
      },
      {
        text: this.dictionary('logtool.endtime'),
        value: 'ended',
        align: 'left',
        width: '15%',
      },
      {
        text: this.dictionary('logtool.numentries'),
        value: 'numEntries',
        align: 'left',
        width: '10%',
      },
      {
        text: '',
        sortable: false,
        width: '10%',
      },
    ]
  }

  public onEditConfiguration(configuration: EndpointRecordingViewModel) {
    this.configurationToEdit = configuration
  }

  public onDownload(configuration: EndpointRecordingViewModel) {
    this.configurationToDownload = configuration
  }
}
</script>
<style lang="scss" scoped>
th, td {
  overflow: hidden;
  text-overflow: ellipsis;
}
thead tr {
  border-bottom: 1px solid white!important;
}
.endpoint-recordings-table {
  .endpoint-recordings-table-actions {
    display: flex;
    justify-content: flex-end;
    .endpoint-recordings-table-action {
      width: 36px;
    }
  }
}
</style>
<style lang="css">
.endpoint-recordings-table .theme--dark.v-table {
  background-color: transparent !important;
  table-layout: fixed;
  width: 100%;
}
.endpoint-recordings-table .v-datatable__actions {
  background-color: transparent !important;
}
</style>