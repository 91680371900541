<template>
  <v-data-table :headers="headers" :items="plcOperationStateHistoryItems" :pagination.sync="pagination" item-key="id"
    class="plc-connection-table"
    :custom-filter="filteredPlcOperationStateHistoryItems"
    :search="''"
    :rows-per-page-items="[10]"
  >
    <template v-slot:headers="props">
      <tr class="table-header-row">
        <th v-for="header in props.headers" :key="header.value" 
          :class="[
            'column', 
            header.align === 'center' ? 'text-xs-center' : 'text-xs-left',
            header.sortable !== false ? 'sortable' : '',
            pagination.descending ? 'desc' : 'asc', 
            header.value === pagination.sortBy ? 'active' : ''
          ]"
          :style="{ 
            display: header.hidden === true ? 'none' : 'table-cell',
            width: header.width,
          }"
        >
          <div class="table-header" @click="changeSort(header)">
            {{ header.text | translate }}
            <v-icon v-if="header.sortable !== false" small>arrow_upward</v-icon>
          </div>
          <table-filter-multi-select v-if="header.type === 'multiselect'"
            :options="header.options"
            :values="header.selectedValues"
            :placeholder="header.placeholder | translate"
            @selected="header.selected"
            @cleared="header.cleared"
          />
          <table-filter-date v-if="header.type === 'date'"
            :value="header.filter"
            :placeholder="header.placeholder | translate"
            :language="header.language"
            @changed="header.changed"
          />
        </th>
      </tr>
    </template>
    <template v-slot:items="props">
      <tr :key="props.item.id">
        <td :title="props.item.order"></td>
        <td>
          <plc-connection-history-table-status :model="props.item"/>
        </td>
        <td>{{ props.item.dateTimeFormatted }}</td>
      </tr>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import TableFilterDate from '../../../../ui/TableFilterDate.vue'
import TableFilterMultiSelect from '../../../../ui/TableFilterMultiSelect.vue'

import PlcConnectionHistoryTableStatus from './PlcConnectionHistoryTableStatus.vue'

import { PlcConnectionHistoryTableFilterSettings, PlcOperationStateHistoryItemViewModel } from '../../../../../store/modules/plcConnectionHistoryUi/models'

import { DropdownOption } from '../../../../../../../shared-components/src'

const App = namespace('app')
const PlcConnectionHistoryUi = namespace('plcConnectionHistoryUi')

@Component({
  components: {
    PlcConnectionHistoryTableStatus,
    TableFilterDate,
    TableFilterMultiSelect,
  },
})
export default class PlcConnectionHistoryTable extends Vue {
  @PlcConnectionHistoryUi.Getter public filterSettings: PlcConnectionHistoryTableFilterSettings
  @PlcConnectionHistoryUi.Getter public plcOperationStateHistoryItems: PlcOperationStateHistoryItemViewModel[]
  @PlcConnectionHistoryUi.Getter public filteredPlcOperationStateHistoryItems: 
    (alarms: PlcOperationStateHistoryItemViewModel[]) => PlcOperationStateHistoryItemViewModel[]
  @PlcConnectionHistoryUi.Getter public statuses: DropdownOption
  @PlcConnectionHistoryUi.Action public updateFilterSettings: (payload: { property: string, value: any }) => Promise<void>
  @App.Getter public selectedLanguage: string

  public pagination = {
    sortBy: 'order',
    descending: true,
    rowsPerPage: 10,
  }

  public changeSort(column) {
    if (column.sortable === false) {
      return
    }
    if (this.pagination.sortBy === column.value) {
      this.pagination.descending = !this.pagination.descending
    } else {
      this.pagination.sortBy = column.value
      this.pagination.descending = false
    }
  }

  public get headers() {
    return [
      { 
        text: '#',
        value: 'order',
        align: 'left',
        sortable: true,
        width: '5%',
      },
      { 
        text: 'select.plc.state',
        value: 'status',
        type: 'multiselect',
        options: this.statuses,
        selectedValues: this.filterSettings.statuses,
        placeholder: 'common.filter',
        selected: (values: boolean[]) => this.updateFilterSettings({ 
          property: 'statuses',
          value: values,
        }),
        cleared: () => this.updateFilterSettings({ 
          property: 'statuses',
          value: [],
        }),
        width: '20%',
      },
      { 
        text: 'alarm.timestamp',
        value: 'timeStamp',
        type: 'date',
        filter: this.filterSettings.date,
        placeholder: 'common.filter',
        changed: (filter: string) => this.updateFilterSettings({ 
          property: 'date',
          value: filter,
        }),
        language: this.selectedLanguage,
        align: 'left',
        sortable: true,
        width: '75%',
      },
    ]
  }
}
</script>
<style lang="scss" scoped>
th, td {
  padding: 0px 15px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
thead tr {
  border-bottom: 1px solid white!important;
}
th:first-child, td:first-child {
  padding-left: 0px !important;
}
.table-header-row {
  vertical-align: top;
}
.table-header {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
tr.selected {
  background: #c2043e !important;
}
</style>
<style lang="css">
.plc-connection-table .theme--dark.v-table {
  background-color: transparent !important;
  table-layout: fixed;
  width: 100%;
}
.plc-connection-table .v-datatable__actions {
  background-color: transparent !important;
}
.plc-connection-table .autocomplete-input input{
  cursor: pointer !important;
}
</style>