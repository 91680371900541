<template>
  <modal-dialog :show="show"
    :header="'Advanced log configurations for log sources'"
    :button1-text="'OK'"
    :button1-disabled="isInteracted"
    @button1Pressed="onClose"
  >
  <card :header="'Log configurations'">
    <template v-slot:actions>
      <card-action
        icon="add"
        tooltip="Create a new log config for a specific log source"
        :disabled="isInteracted || isLoading"
        @click="showCreateDialog = true"
      />
      <card-action
        icon="delete"
        tooltip="Clear all log configs"
        :disabled="isInteracted || isLoading || !loggingConfigurationsForDestination(destination).length"
        @click="onDeleteAll"
      />
    </template>
    <v-data-table :headers="headers" :items="loggingConfigurationsForDestination(destination)" item-key="logSource"
      class="log-sources-table"
      :rows-per-page-items="[5]"
      :loading="isLoading"
    >
      <template v-slot:items="props">
        <tr :key="props.item.logSource">
          <td :title="`${props.item.logSource}`">{{ logSourceDisplayName(props.item) }}</td>
          <td :title="`${props.item.logLevel}`">{{ props.item.logLevel }}</td>
          <td class="justify-center layout px-0">
            <v-tooltip bottom class="delete-icon">
              <v-btn slot="activator" :disabled="isInteracted" @click="onDelete(props.item.logSource)" icon flat>
                <v-icon small>delete</v-icon>
              </v-btn>
              <span>{{ 'common.button.delete' | translate }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <create-log-config-dialog :show="showCreateDialog" :destination="destination" @closed="showCreateDialog = false"/>
  </card>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Card from '../../../../ui/Card.vue'
import CardAction from '../../../../ui/CardAction.vue'

import CreateLogConfigDialog from './CreateLogConfigDialog.vue'

import { LogDestination, LoggerConfigurationModel } from '../../../../../store/modules/logToolUi/models'

const LogToolUi = namespace('logToolUi')

@Component({
  components: {
    Card,
    CardAction,
    CreateLogConfigDialog,
  },
})
export default class AdvancedLogConfigDialog extends Vue {
  @Prop() public show: boolean
  @Prop() public destination: LogDestination
  @LogToolUi.Getter public isInteracted: boolean
  @LogToolUi.Getter public isLoading: boolean
  @LogToolUi.Getter public loggingConfigurationsForDestination: (destination: LogDestination) => LoggerConfigurationModel[]
  @LogToolUi.Action public resetLogSource: (payload: LoggerConfigurationModel) => Promise<void>

  public showCreateDialog = false

  public get headers() {
    return [
      {
        text: 'Log source',
        value: 'endpoint',
        align: 'left',
        width: '65%',
      },
      {
        text: 'Log level',
        value: 'interval',
        align: 'left',
        width: '25%',
      },
      {
        text: '',
        sortable: false,
        width: '10%',
      },
    ]
  }

  @Emit('closed')
  public onClose() {
    return
  }

  public onDeleteAll() {
    this.loggingConfigurationsForDestination(this.destination).forEach(logConfig => {
      this.onDelete(logConfig.logSource)
    })
  }

  public onDelete(logSource: string) {
    this.resetLogSource({
      logDestination: this.destination,
      logSource,
    } as LoggerConfigurationModel)
  }

  public logSourceDisplayName(logConfig: LoggerConfigurationModel) {
    return logConfig.logSource.split('Ecocoach.EcoCloudConnector.').pop()
  }
}
</script>
<style lang="scss" scoped>
th, td {
  overflow: hidden;
  text-overflow: ellipsis;
}
thead tr {
  border-bottom: 1px solid white!important;
}
.log-sources-table {
  .delete-icon {
    display: flex;
    align-items: center;
  }
}
</style>
<style lang="css">
.log-sources-table .theme--dark.v-table {
  background-color: transparent !important;
  table-layout: fixed;
  width: 100%;
}
.log-sources-table .v-datatable__actions {
  background-color: transparent !important;
}
</style>