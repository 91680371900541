import { AppGetter, AppState } from '@/store/modules/app/types'
import { RootState } from '@/store/types'
import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'
import { ResourceCategory, ResourceModel } from '@ecocoach/domain-store-modules/src/resource/models'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { UserAgreementModel } from '@ecocoach/domain-store-modules/src/userAgreement/models'
import { UserSettingsGetter } from '@ecocoach/domain-store-modules/src/userSettings/types'
import { compare, validate } from 'compare-versions'
import { GetterTree } from 'vuex'
import { ReleaseNotesViewModel, UserAgreementViewModel } from './models'
import { backendEnvironment } from '@/environment'
import { BackendEnvironmentTypes } from '@ecocoach/domain-store-modules/src/services/api.service'

function getReleaseNotes(resources: ResourceModel[], idPrefix: string): ReleaseNotesViewModel[] {
  return resources
    .filter(appString => appString.id.startsWith(idPrefix) && validate(appString.id.substr(idPrefix.length)))
    .map(
      s =>
        ({
          version: s.id.substr(idPrefix.length),
          releaseNotes: s.value,
        } as ReleaseNotesViewModel),
    )
    .sort((a, b) => compare(a.version, b.version, '>') ? -1 : 1)
}

export const getters: GetterTree<AppState, RootState> = {
  [AppGetter.isInteracted]({ interacted }): boolean {
    return interacted
  },
  [AppGetter.isNavigationDrawerMinimized]({ navigationDrawerMinimized }): boolean {
    return navigationDrawerMinimized
  },
  [AppGetter.succeededHydrating]({ hydrated }) {
    return hydrated
  },
  [AppGetter.isHydrating]({ hydrated }) {
    return !hydrated
  },
  [AppGetter.loadingApiSuccess]({ loadingApiFailed }) {
    return !loadingApiFailed
  },
  [AppGetter.userAgreements](_, __, rootState, rootGetters): UserAgreementViewModel[] {
    const isAccepted = (document: UserAgreementModel): boolean => {
      return rootState.userAgreement.acceptedUserAgreements
        .some(d => d.documentType === document.documentType && d.documentVersion === document.documentVersion)
    }
    return rootState.userAgreement.currentUserAgreements.map(doc => ({
      type: doc.documentType,
      version: doc.documentVersion,
      name: rootGetters[`resource/${ResourceGetter.dictionary}`](doc.nameResourceId),
      content: rootGetters[`resource/${ResourceGetter.dictionary}`](doc.contentResourceId),
      accepted: isAccepted(doc),
    }))
  },
  [AppGetter.isEmailMfaEnabled](_, __, ___, rootGetters): boolean | null {
    return rootGetters[`userSettings/${UserSettingsGetter.isEmailMfaEnabled}`]
  },
  [AppGetter.languages](): Array<{ id: string, label: string }> {
    return [
      { id: 'de', label: 'Deutsch' },
      { id: 'en-UK', label: 'English' },
      { id: 'it', label: 'Italiano' },
    ]
  },
  [AppGetter.selectedLanguage](state: AppState): string {
    return state.selectedLanguage
  },
  [AppGetter.getPlcVersion](_, __, rootState: RootState) {
    return (plcId: string): string => {
      try {
        const plc = rootState.plc.plcs.find(p => p.id === plcId)
        return plc?.ecoCloudConnectorVersion ? plc.ecoCloudConnectorVersion + ' ecoCloudConnector' : 'Communicator'
      } catch (e) {
        return ''
      }
    }
  },
  [AppGetter.writablePlcs](_, __, rootState): PlcModel[] {
    return rootState.plc.plcs
  },
  [AppGetter.hasSelectedPlc]({ selectedPlcId }) {
    return !!selectedPlcId
  },
  [AppGetter.selectedPlcId]({ selectedPlcId }) {
    return selectedPlcId
  },
  [AppGetter.selectedPlc]({ selectedPlcId }, _, rootState) {
    return rootState.plc.plcs.find(p => p.id === selectedPlcId)
  },
  [AppGetter.hasPlcReleaseRooms](_, __, rootState): boolean {
    return !!rootState.plcConfiguration.releaseId &&
      !!rootState.plcConfiguration.rooms.length
  },
  [AppGetter.isEcocoachEmployee](_, localGetters): boolean {
    return localGetters[AppGetter.userIdentifier].endsWith('@ecocoach.com')
  },
  [AppGetter.isWeidmuellerEmployee](_, localGetters) {
    return localGetters[AppGetter.userIdentifier].endsWith('@weidmueller.com')
  },
  [AppGetter.userIdentifier](_, __, rootState): string {
    return rootState.authentication.loggedInUser.userIdentifier
  },
  [AppGetter.version](): string {
    return process.env.APP_VERSION || ''
  },
  [AppGetter.webAppReleaseNotes](_, getters, ___, rootGetters): ReleaseNotesViewModel[] {
    const resources = rootGetters[`resource/${ResourceGetter.resourcesOfCategory}`](ResourceCategory.APP_STRINGS)
    const currentAppVersion = getters[AppGetter.version]
    return getReleaseNotes(resources, 'release.notes.webapp.v').filter(r => compare(currentAppVersion, r.version, '>='))
  },
  [AppGetter.eccReleaseNotes](_, __, ___, rootGetters): ReleaseNotesViewModel[] {
    const resources = rootGetters[`resource/${ResourceGetter.resourcesOfCategory}`](ResourceCategory.APP_STRINGS)
    return getReleaseNotes(resources, 'release.notes.ecc.v')
  },
  [AppGetter.showPartnerTool](_, __, rootState): boolean {
    return !!rootState.project.projects.length || !!rootState.authorization.partners.length
  },
  [AppGetter.showSetupTool](_, __, rootState): boolean {
    return !!rootState.plc.plcs.length
  },
  [AppGetter.showAuthTool](_, __, rootState): boolean {
    return !!rootState.authorization.projects.length || !!rootState.authorization.consumptionProjects.length
  },
  [AppGetter.environmentBadge](): string {
    const environment = backendEnvironment()
    switch (environment) {
    case BackendEnvironmentTypes.DEVELOPMENT: return 'Development'
    case BackendEnvironmentTypes.STAGING: return 'Staging'
    default: return ''
    }
  },
}
