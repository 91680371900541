import { ActionTree } from 'vuex'
import CacheService, { CacheOptions } from '../services/cache.service'
import commandApi from './api/command'
import queryApi from './api/query'
import { AcceptedUserAgreementModel, UserAgreementModel } from './models'
import { UserAgreementAction, UserAgreementMutation, UserAgreementState } from './types'

export const actions: ActionTree<UserAgreementState, {}> = {
  async [UserAgreementAction.loadCurrentUserAgreements]({ commit }) {
    await CacheService.load({
      action: UserAgreementAction.loadCurrentUserAgreements,
      load: () => queryApi.currentUserAgreements(),
      commit: data => commit(UserAgreementMutation.setCurrentUserAgreements, data),
    } as CacheOptions<UserAgreementModel[]>)
  },
  async [UserAgreementAction.loadAcceptedUserAgreements]({ commit }) {
    await CacheService.load({
      action: UserAgreementAction.loadAcceptedUserAgreements,
      load: () => queryApi.acceptedUserAgreements(),
      commit: data => commit(UserAgreementMutation.setAcceptedUserAgreements, data),
    } as CacheOptions<AcceptedUserAgreementModel[]>)
  },
  async [UserAgreementAction.acceptUserAgreement]({ commit }, payload: AcceptedUserAgreementModel) {
    await commandApi.acceptUserAgreement(payload)
    commit(UserAgreementMutation.addAcceptedUserAgreement, payload)
  },
}
