import { Module } from 'vuex'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'
import { PlcAction, PlcMutation, PlcState } from './types'

const namespaced: boolean = true
export const plc: Module<PlcState,any> = {
  namespaced,
  state,
  actions,
  mutations,
}

export const types = {
  action:  PlcAction,
  mutation: PlcMutation,
}

export const domain = 'plc'

export const vuexModule = {
  [domain]: plc,
}