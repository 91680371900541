<template>
    <div class="base-spinner">
      <div class="bounce1"/>
      <div class="bounce2"/>
      <div class="bounce3"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
    name: 'BaseSpinner',
})
</script>

<style lang="scss" scoped>
  .base-spinner {
    text-align: center;
  }

  .base-spinner > div {
    width: 18px;
    height: 18px;
    background-color: white;
    margin-top: 5px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .base-spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .base-spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }
</style>

<docs>
A spinner

Defult spinner
```jsx
<base-spinner />
```

Custom size via style pro, you can also owverwrite the the css-class (.base-spinner):
```jsx
<base-spinner style="width: 100px;"/>
```
</docs>