import fileHelper from '@ecocoach/domain-store-modules/src/helpers/fileHelper'
import ApiService from '@ecocoach/domain-store-modules/src/services/api.service'
import { Endpoint } from '@ecocoach/domain-store-modules/src/systemConfiguration/models'
import qs from 'qs'
import { EndpointRecordingConfigurationModel, EndpointRecordingDataInput } from '../models'

export default {
  async loadEndpointRecordingConfigurations(plcId: string): Promise<EndpointRecordingConfigurationModel[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/EndpointRecording/${plcId}/configuration`)
    return response.data.value
  },
  async downloadEndpointRecordingData(plcId: string, fileName: string, input: EndpointRecordingDataInput): Promise<void> {
    const params = qs.stringify({
      ...(input.startDateTimeUtc && { fromDate: input.startDateTimeUtc }),
      ...(input.endDateTimeUtc && { toDate: input.endDateTimeUtc }),
    })
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/EndpointRecording/${plcId}/${input.id}/csv?${params}`, {
      responseType: 'blob',
    })
    fileHelper.saveBlobToFile(response.data, fileName)
  },
  async downloadAllEndpointRecordingData(plcId: string, fileName: string, input: EndpointRecordingDataInput): Promise<void> {
    const params = qs.stringify({
      ...(input.startDateTimeUtc && { fromDate: input.startDateTimeUtc }),
      ...(input.endDateTimeUtc && { toDate: input.endDateTimeUtc }),
    })
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/EndpointRecording/${plcId}/csv?${params}`, {
      responseType: 'blob',
    })
    fileHelper.saveBlobToFile(response.data, fileName)
  },
  // query.functionblock
  async endpoints(functionBlockId: string): Promise<Endpoint[]> {
    const params = qs.stringify({
      functionBlockId,
      showLocals: true,
    })
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().functionBlockQueryApi}/Endpoints?${params}`)
    return response.data.value
  },
  // query.systemconfiguration
  async endpointDefinitions(functionBlockDefinitionId: string, language: string): Promise<Endpoint[]> {
    const response = await ApiService.get(`${ApiService.backendEnvironmentConfiguration().systemConfigurationQueryApi}/FunctionBlockDefinition/${functionBlockDefinitionId}/endpoints?resourceLanguage=${language}`)
    return response.data.value
  },
}
