import { AlarmUiMutation, AlarmUiState } from '@/store/modules/alarmUi/types'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<AlarmUiState> = {
  [AlarmUiMutation.closeDialog](state: AlarmUiState) {
    state.dialogOpened = false
  },
  [AlarmUiMutation.openDialog](state: AlarmUiState) {
    state.dialogOpened = true
  },
}
