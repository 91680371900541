import { ConsumptionUiMutation, ConsumptionUiState } from '@/store/modules/consumptionUi/types'
import { ConsumptionReportAggregationInterval, MinergieVisualizationInterval } from '@ecocoach/domain-store-modules/src/consumption/models'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<ConsumptionUiState> = {
  [ConsumptionUiMutation.setInteracted](state, payload: boolean) {
    state.interacted = payload
  },
  [ConsumptionUiMutation.setSelectedAccountId](state: ConsumptionUiState, payload: string) {
    state.selectedAccountId = payload
  },
  [ConsumptionUiMutation.setAccountFilterString](state: ConsumptionUiState, payload: string) {
    state.accountFilterString = payload
  },
  [ConsumptionUiMutation.setMeterFilterString](state: ConsumptionUiState, payload: string) {
    state.meterFilterString = payload
  },
  [ConsumptionUiMutation.setUserFilterString](state: ConsumptionUiState, payload: string) {
    state.userFilterString = payload
  },
  [ConsumptionUiMutation.setSelectedReportAggregationInterval](state: ConsumptionUiState, interval: ConsumptionReportAggregationInterval) {
    state.selectedReportAggregationInterval = interval
  },
  [ConsumptionUiMutation.setSelectedReportFromDate](state: ConsumptionUiState, fromDate: string) {
    state.selectedReportFromDate = fromDate
  },
  [ConsumptionUiMutation.setSelectedReportToDate](state: ConsumptionUiState, toDate: string) {
    state.selectedReportToDate = toDate
  },
  [ConsumptionUiMutation.setSelectedReportConsumptionAccountId](state: ConsumptionUiState, payload: string) {
    state.selectedReportConsumptionAccountId = payload
  },
  [ConsumptionUiMutation.setSelectedVisualizationInterval](state: ConsumptionUiState, interval: MinergieVisualizationInterval) {
    state.selectedVisualizationInterval = interval
  },
  [ConsumptionUiMutation.setSelectedVisualizationConsumptionAccountId](state: ConsumptionUiState, payload: string) {
    state.selectedVisualizationConsumptionAccountId = payload
  },
  [ConsumptionUiMutation.setNavigationMinimized](state: ConsumptionUiState, minimized: boolean) {
    state.navigationMinimized = minimized
  },
}
