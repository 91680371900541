<template>
  <modal-dialog 
    :header="control.attributes.label | translate"
    :button1-text="'common.button.save' | translate"
    :button2-text="'common.button.cancel' | translate"
    :show="show"
    @button1Pressed="onSave"
    @button2Pressed="onClose"
  >
    <v-layout column>
      <v-flex>
        <form-field :text="'appDisplayLevel.label' | translate">
          <autocomplete-select 
            :options="appDisplayLevelOptions"
            option-value="id"
            option-label="label"
            :selected-value="appDisplayLevel"
            @selected="appDisplayLevel = $event"
          />
        </form-field>
      </v-flex>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { AppDisplayLevels, ControlModelV2, SetControlAppDisplayLevelInput } from '../../../../../../../eco-domain-store-modules/src/plcOperation/models'
import { DropdownOption } from '../../../../../../../shared-components/src'

const PlcOperation = namespace('plcOperation')
const ParametrizationUi = namespace('parametrizationUi')

@Component({
  components: {
  },
})
export default class CustomAppDisplayLevelDialog extends Vue {
  @Prop() public show: boolean
  @Prop() public control: ControlModelV2
  @ParametrizationUi.Getter public appDisplayLevelOptions: DropdownOption[]
  @PlcOperation.Action public setControlAppDisplayLevel: (input: SetControlAppDisplayLevelInput) => Promise<void>

  public appDisplayLevel = AppDisplayLevels.NOT_DISPLAYED

  public mounted() {
    this.appDisplayLevel = this.control.attributes.appDisplayLevel
  }

  public onSave() {
    this.setControlAppDisplayLevel({
      controlId: this.control.id,
      payload:  {
        appDisplayLevel: this.appDisplayLevel,
      },
    } as SetControlAppDisplayLevelInput)
    this.onClose()
  }

  @Emit('closed')
  public onClose() {
    return
  }
}
</script>
<style lang='scss' scoped>
</style>
