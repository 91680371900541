<template>
  <v-btn 
    class="panel-box-action"
    :loading="this.isLoading" 
    :disabled="this.isDisabled ? this.isDisabled : this.isLoading"
    @click="handler()">
    {{ this.title }}
  </v-btn>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PanelBoxAction extends Vue {
  @Prop({type: String}) public title: string
  @Prop({type: Function}) public handler: () => void
  @Prop({type: Boolean, default: false}) public isLoading: boolean
  @Prop({type: Boolean, default: false}) public isDisabled: boolean
}
</script>

<style lang="scss" scoped>
  .panel-box-action {
    width: 100%;
    color: #fff;
    border: none;
    line-height: 50px;
    height: 50px;
    border-radius: 0px;
    background-image: linear-gradient(to bottom, #216bdc, #0a3367);
    font-weight: 700;
    text-transform: uppercase;
    font-family: Titillium Web,sans-serif;
  }
</style>
<style lang="scss">
  .panel-box-action > span.v-btn__loading {
    .v-progress-circular.v-progress-circular--indeterminate {
      position: absolute;
      top: 13px !important;
      padding: 10px;
    }
  }
</style>
