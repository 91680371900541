import { actions } from '@/store/modules/wizard/actions'
import { getters } from '@/store/modules/wizard/getters'
import { mutations } from '@/store/modules/wizard/mutations'
import { state } from '@/store/modules/wizard/state'
import { WizardAction, WizardGetter, WizardMutation, WizardState } from '@/store/modules/wizard/types'
import { RootState } from '@/store/types'
import { Module } from 'vuex'

const namespaced: boolean = true
export const wizard: Module<WizardState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action: WizardAction,
  mutation: WizardMutation,
  getter: WizardGetter,
}

export const domain = 'wizard'

export const vuexModule = {
  [domain]: wizard,
}