import { actions } from '@/store/modules/mappingUi/actions'
import { getters } from '@/store/modules/mappingUi/getters'
import { mutations } from '@/store/modules/mappingUi/mutations'
import { state } from '@/store/modules/mappingUi/state'
import { MappingUiAction, MappingUiGetter, MappingUiMutation, MappingUiState } from '@/store/modules/mappingUi/types'
import { RootState } from '@/store/types'
import { Module } from 'vuex'

const namespaced: boolean = true
export const mappingUi: Module<MappingUiState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action: MappingUiAction, 
  mutation: MappingUiMutation,
  getter: MappingUiGetter,
}

export const domain = 'mappingUi'

export const vuexModule = {
  [domain]: mappingUi,
}