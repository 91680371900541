<template>
  <div class="templates-list">
    <div class="header">
      <div class="name head">{{ 'releases.name' | translate}}</div>
      <div class="description head">{{ 'releases.description' | translate}}</div>
      <div class="architecture head">{{ 'releases.architecture' | translate}}</div>
      <div class="created head">{{ 'releases.created.by' | translate}}</div>
    </div>
    <div class="template-list-scroll">
      <div
        v-for="template in releaseTemplates"
        :key="template.id"
        class="template-item"
        :class="selectedTemplateId === template.id ? 'selected' : ''"
        @click.stop="selectTemplate(template.id)"
      >
        <div class="name item">{{ template && template.name }}</div>
        <div class="description item">{{ template && template.description }}</div>
        <div class="architecture item">
          <v-chip v-if="chipText(template)" small :color="chipColor(template)">{{ chipText(template) }}</v-chip>
        </div>
        <div class="created item">
          <span>{{ template && timeStampToDate(template.createdAt) }}</span>
          <br />
          <span>{{ template && template.createdBy }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ArchitectureType, PlcType } from '@ecocoach/domain-store-modules/src/common'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ReleaseModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

const PlcConfiguration = namespace('plcConfiguration')
const ReleaseUi = namespace('releaseUi')

@Component
export default class TemplateList extends Vue {
  @Prop({ type: String, default: '' }) public selectedTemplateId
  @PlcConfiguration.Getter public releaseTemplates: ReleaseModel[]
  @ReleaseUi.Getter public timeStampToDate: string

  public mounted() {
    this.selectedTemplateId = ''
    this.$emit('selectedTemplate', '')  
  }

  public chipText(release: ReleaseModel) {
    return release.architectureType === ArchitectureType.ObjectOriented
      ? (release.plcType === PlcType.ECO_BASIC_CONTROL_LIGHT ? 'C#' : 'OO')
      : 'Classic'
  }

  public chipColor(release: ReleaseModel) {
    return release.architectureType === ArchitectureType.ObjectOriented ? 'green' : 'blue'
  }
  
  public selectTemplate(id) {
    this.selectedTemplateId = id
    this.$emit('selectedTemplate', id)
  }
}
</script>
<style lang="scss" scoped>
.template-list-scroll {
  min-height: 200px;
  max-height: 200px;
  overflow-y: auto;
}
.templates-list {
  flex: 1;
}
.header {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
  border-bottom: 2px solid white;
  align-items: center;
  text-transform: uppercase;
  .head {
    font-weight: bold;
    padding: 10px 10px 2px 10px;
  }
}
.template-item {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden; 
  border-bottom: 1px solid white;
  cursor: pointer;
  align-items: center;
  .item {      
    min-height: 45px;
    padding: 10px;
  }
}
.name {
  flex: 0 0 35%;
  min-width: 120px;
}
.description {
  flex: 0 0 35%;
  min-width: 120px;
}
.architecture {
  flex: 0 0 10%;
  min-width: 100px;
  text-align: center;
}
.created {
  flex: 0 0 20%;
  min-width: 100px;
}
.selected {
  background: #c2043e;
}
</style>