<template>
  <modal-dialog 
    :show="show"
    :header="'release.dialog.setproperties' | translate"
    button1-text="OK"
    :button2-text="'common.button.cancel' | translate"
    :button1-disabled="disabled"
    :button2-disabled="disabled"
    @button1Pressed="onOk"
    @button2Pressed="onCancelled"
  >
    <v-layout column>
      <v-flex>
        <form-field :text="'releases.name' | translate">
          <text-input
            :value="name"
            :disabled="disabled"
            @changed="name = $event"
          />
        </form-field>
        <form-field :text="'releases.description' | translate">
          <text-input
            :value="description"
            :disabled="disabled"
            @changed="description = $event"
          />
        </form-field>
        <checkbox-with-label v-if="showIsTemplateOption"
          :value="isTemplate"
          :text="'releases.template' | translate"
          :disabled="disabled"
          @checked="isTemplate = !$event"
        />
        <checkbox-with-label v-if="showIsTemplateOption && isTemplate && showUpdateTemplateOperationDataOption"
          :value="updateTemplateOperationData && isTemplate"
          :text="'releases.updateoperationdata' | translate"
          :disabled="disabled || !isTemplate"
          @checked="updateTemplateOperationData = !$event"
        />
        <checkbox-with-label v-if="showArchiveOption"
          :value="archive"
          :text="'release.archive' | translate"
          :disabled="disabled"
          @checked="archive = !$event"
        />
        <form-field v-if="showArchiveOption" :text="'release.id' | translate">
          <div class="label">
            {{releaseId}}
          </div>
        </form-field>
      </v-flex>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

import { ReleaseProperties } from '../../../../../store/modules/loadProgramUi/models'

@Component
export default class SetReleasePropertiesDialog extends Vue {
  @Prop({type: Boolean}) public show: boolean
  @Prop({type: Boolean}) public disabled: boolean
  @Prop({type: Boolean, default: false}) public showIsTemplateOption: boolean
  @Prop({type: Boolean, default: false}) public showUpdateTemplateOperationDataOption: boolean
  @Prop({type: Boolean, default: false}) public showArchiveOption: boolean
  @Prop({type: String}) public initialName: string
  @Prop({type: String}) public initialDescription: string
  @Prop({type: Boolean}) public initialIsTemplate: boolean
  @Prop({type: String}) public releaseId: string

  public name = ''
  public description = ''
  public isTemplate = false
  public updateTemplateOperationData = false
  public archive = true

  @Watch('show')
  public setDefaults() {
    if (this.show) {
      this.name = this.initialName || ''
      this.description = this.initialDescription || ''
      this.isTemplate = this.initialIsTemplate || false
      this.updateTemplateOperationData = false
    }
  }

  @Emit('confirmed') 
  public onOk () {
    return {
      name: this.name.trim(),
      description: this.description.trim(),
      isTemplate: this.isTemplate,
      archive: this.archive,
      updateTemplateOperationData: this.isTemplate && this.updateTemplateOperationData,
    } as ReleaseProperties
  }

  @Emit('cancelled') 
  public onCancelled () {
    return true
  }
}
</script>
<style lang="scss" scoped>
.field {
  height: 70px;
  .text-input {
    padding-left: 10px;
  }
  .label {
    padding-left: 10px;
  }
}
.checkboxWithLabel {
  margin-left: -5px;
}
</style>