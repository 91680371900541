<template>
  <modal-dialog :show="show"
    :header="`navigation.plcSystemInformation` | translate"
    :button1-text="`OK`"
    @button1Pressed="onClose"
  >
    <v-layout column>
      <form-field :text="'select.plc.name' | translate">
        <text-input
          :value="name"
          :disabled="true"
        />
      </form-field>
      <form-field :text="'select.plc.type' | translate">
        <text-input
          :value="type"
          :disabled="true"
        />
      </form-field>
      <form-field :text="'plcSystemInformation.serialNumber' | translate">
        <text-input
          :value="serialNumber"
          :disabled="true"
        />
      </form-field>
      <form-field :text="'plcSystemInformation.twinCatVersion' | translate">
        <text-input
          :value="twinCatVersion"
          :disabled="true"
        />
      </form-field>
      <form-field :text="'plcSystemInformation.totalMemory' | translate">
        <text-input
          :value="totalMemory"
          :disabled="true"
        />
      </form-field>
      <form-field :text="'plcSystemInformation.totalDiskspace' | translate">
        <text-input
          :value="totalDiskspace"
          :disabled="true"
        />
      </form-field>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import convert from 'convert-units'
import { roundToDecimals } from '../../../../../../../eco-domain-store-modules/src/utils'

import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'

@Component({
  components: {
  },
})
export default class PlcSystemInformationDialog extends Vue {
  @Prop() public show: boolean
  @Prop() public plc: PlcModel

  public get name() {
    return this.plc?.name || 'n/a'
  }

  public get type() {
    return this.plc?.plcType || 'n/a'
  }

  public get serialNumber() {
    return this.plc?.serialNumber || 'n/a'
  }

  public get twinCatVersion() {
    return this.plc?.twinCatVersion || 'n/a'
  }

  public get totalMemory() {
    return (this.plc?.totalMemoryInBytes > 0) ? this.formatMemory(this.plc?.totalMemoryInBytes): 'n/a'
  }

  public get totalDiskspace() {
    return (this.plc?.totalDiskSpaceInBytes > 0) ? this.formatMemory(this.plc?.totalDiskSpaceInBytes): 'n/a'
  }

  public formatMemory(bytes: number) {
    const best = convert(bytes).from('B').toBest()
    return `${roundToDecimals(best.val, 1)} ${best.unit}`
  }

  @Emit('closed')
  public onClose() {
    return
  }
}
</script>
<style lang="scss" scoped>
.field {
  height: 70px;
  .text-input {
    padding-left: 10px;
    padding-right: 10px;
  }
  .label {
    padding-left: 10px;
  }
}
</style>