import { UserSettingsModel } from './models'

export enum UserSettingsAction {
  loadCurrentUserSettings = 'loadCurrentUserSettings',
  loadMfaConfiguration = 'loadMfaConfiguration',
  setEmailMfaEnabled = 'setEmailMfaEnabled',
}

export enum UserSettingsMutation {
  setCurrentUserFeatureSwitches = 'setCurrentUserFeatureSwitches',
  setEmailMfaEnabled = 'setEmailMfaEnabled',
}

export enum UserSettingsGetter {
  currentUserSettings = 'currentUserSettings',
  isFeatureOn = 'isFeatureOn',
  isEmailMfaEnabled = 'isEmailMfaEnabled',
}

export interface UserSettingsState {
  currentUserSettings: UserSettingsModel
}
