<template>
    <div class="io">
        <div class="indicatorsBlock"
            v-if="pinAmount <= 8">
        <span
            :class="{ output: isOutput, input: isInput }"
            v-for="(pin, index) in pinAmount" :key="index" />
        </div>
        <div class="indicatorsBlock"
            v-else>
            <span
                :class="{ output: isOutput, input: isInput, indicator: true }">
                <div class="pinAmount">{{ pinAmount }}</div>
            </span>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AvailableIo extends Vue {
    @Prop(Number) public pinAmount: number
    @Prop(Boolean) public isOutput: boolean
    @Prop(Boolean) public isInput: boolean
}
</script>

<style lang="scss" scoped>
.io {
    display: flex;
    flex-direction: column;
    width:50px;
    .indicatorsBlock {
        width: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    span {
        display: inline-block;
        width: 18px;
        height: 3px;
        margin: 3px 0px;
        border-radius: 1px;
        .pinAmount {
            font-size: 1.5em;
            width: 100%;
            height: 100%;
            line-height: 36px;
            text-align: center;
            color: white;
        }
        &.output {
            background: #c2043e;
        }
        &.input {
            background: #009ee3;
        }
        &.indicator {
            height: 36px;
            width: 45px;
        }
    }
}
</style>
