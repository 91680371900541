import { AuditLogUiAction, AuditLogUiGetter, AuditLogUiMutation, AuditLogUiState } from '@/store/modules/auditLogUi/types'
import { RootState } from '@/store/types'
import { AuditLogAction } from '@ecocoach/domain-store-modules/src/auditLog/types'
import AppDataStorageService from '@ecocoach/domain-store-modules/src/services/appdatastorage.service'
import { intersectArrays } from '@ecocoach/domain-store-modules/src/utils'
import { DropdownOption } from '@ecocoach/shared-components/src'
import { ActionTree } from 'vuex'
import { AuditLogTableFilterSettings, AuditLogTableFilterSettingsStorageKey } from './models'

export const actions: ActionTree<AuditLogUiState, RootState> = {
  async [AuditLogUiAction.openAuditLog] ({ commit, dispatch }, plcId: string): Promise<void> {
    try {
      commit(AuditLogUiMutation.setInteracted, true)
      await dispatch(`auditLog/${AuditLogAction.loadAuditLogEntries}`, plcId, { root: true })
      commit(AuditLogUiMutation.setSelectedPlcId, plcId)
    } finally {
      commit(AuditLogUiMutation.setInteracted, false)
    }
  },
  async [AuditLogUiAction.closeAuditLog] ({ commit }): Promise<void> {
    commit(AuditLogUiMutation.setSelectedPlcId, '')
  },
  async [AuditLogUiAction.loadFilterSettings] ({ state, getters }): Promise<void> {
    const loadedSettings = await AppDataStorageService.getObject<AuditLogTableFilterSettings>(AuditLogTableFilterSettingsStorageKey)
    const validActionTypes = (getters[AuditLogUiGetter.actionTypeOptions] as DropdownOption[]).map(o => o.id as string)
    state.filterSettings.userIdentifier = loadedSettings?.userIdentifier ?? ''
    state.filterSettings.actionTypes = intersectArrays(validActionTypes, loadedSettings?.actionTypes ?? [])
    state.filterSettings.date = loadedSettings?.date ?? ''
    state.filterSettings.actionText = loadedSettings?.actionText ?? ''
    state.filterSettings.actionDetails = loadedSettings?.actionDetails ?? ''
  },
  async [AuditLogUiAction.updateFilterSettings] ({ state }, payload: { property: string, value: any }): Promise<void> {
    state.filterSettings[payload.property] = payload.value
    await AppDataStorageService.setObject(AuditLogTableFilterSettingsStorageKey, state.filterSettings)
  },
}
