<template>
  <form-field :text="'Default log level'">
    <div class="field-row">
      <autocomplete-select 
        :options="logLevelOptions"
        option-value="id"
        option-label="label"
        :selected-value="defaultLogLevel"
        :disabled="isInteracted || isLoading"
        @selected="onDefaultLogLevelSelected($event)"
      />
      <base-text-button @buttonClicked="showAdvancedDialog = true">Advanced</base-text-button>
    </div>
    <advanced-log-config-dialog :show="showAdvancedDialog" :destination="destination" @closed="showAdvancedDialog = false" />
  </form-field>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Card from '../../../../ui/Card.vue'

import AdvancedLogConfigDialog from './AdvancedLogConfigDialog.vue'

import { DropdownOption } from '@ecocoach/shared-components/src'
import { LogDestination, LoggerConfigurationModel, LogLevel } from '../../../../../store/modules/logToolUi/models'

const LogToolUi = namespace('logToolUi')

@Component({
  components: {
    AdvancedLogConfigDialog,
    Card,
  },
})
export default class LogConfigEditor extends Vue { 
  @Prop() public destination: LogDestination
  @LogToolUi.Getter public isInteracted: boolean
  @LogToolUi.Getter public isLoading: boolean
  @LogToolUi.Getter public defaultLogLevelForDestination: (destination: LogDestination) => LogLevel
  @LogToolUi.Getter public logLevelOptions: DropdownOption[]
  @LogToolUi.Action public setLogLevel: (payload: LoggerConfigurationModel) => Promise<void>

  public showAdvancedDialog = false

  public get defaultLogLevel() {
    return this.defaultLogLevelForDestination(this.destination)
  }

  public onDefaultLogLevelSelected(logLevel: LogLevel) {
    this.setLogLevel({
      logDestination: this.destination,
      logLevel,
      logSource: '',
    })
  }
}
</script>
<style lang="scss" scoped>
.field {
  height: 70px;
  .field-row {
    display: flex;
  }
}
.v-btn {
  border: 1px solid white;
  margin: 0px;
}
</style>