<template>
  <div class="manualMappingItems">
    <manual-mapping-solution-hardware-item
      v-for="mapping in mappings"
      :key="mapping.id"
      :index="index"
      :name="name"
      :description="endpointDescription(mapping)"
      :is-mapped="true"
      :disabled="isAutoMappingStarted || isManualMappingStarted"
      @click="$emit('click', mapping)"
    />
  </div>
</template>
<script lang="ts">
import ManualMappingSolutionHardwareItem from '@/components/views/ecoSetupTool/steps/mapHardware/ManualMappingSolutionHardwareItem.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { DeviceMappingModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

import { SolutionHardwareDefinitionEndpointModel } from '../../../../../../../eco-domain-store-modules/src/systemConfiguration/models'

const MappingUi = namespace('mappingUi')
const Resource = namespace('resource')

@Component ({
  components: {
    ManualMappingSolutionHardwareItem,
  },
})
export default class ManualMappingSolutionHardwareUnmappedEndpoint extends Vue {
  @Prop() public endpoint: SolutionHardwareDefinitionEndpointModel
  @Prop() public mappings: DeviceMappingModel[]
  @Prop() public index: string
  @MappingUi.Getter public manualMappingSolutionHardwareEndpointDescription
  @MappingUi.Getter public isAutoMappingStarted
  @MappingUi.Getter public isManualMappingStarted
  @Resource.Getter public dictionary

  public get name() {
    return this.endpoint.name || this.dictionary(this.endpoint.nameResourceId)
  }

  public endpointDescription(mapping: DeviceMappingModel): string {
    return this.manualMappingSolutionHardwareEndpointDescription(mapping)
  }
}
</script>
