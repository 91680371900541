import { GetterTree } from 'vuex'
import { EnergyStatusItemType } from '../common'
import { ContributionPercentageModel, EnergyStatusDevice, EnergyStatusDeviceModel } from './models'
import { EnergyStatusGetter, EnergyStatusState } from './types'

const InvalidValue = 1e300
const InvalidDisplayValue = 'n/a'

const producerTypes = new Set([
  EnergyStatusItemType.BufferPower,
  EnergyStatusItemType.GridPower,
  EnergyStatusItemType.ProducerPower,
])

const consumerTypes = new Set([
  EnergyStatusItemType.ConsumerPower,
  EnergyStatusItemType.HousePower,
])

const isNominalProducer = (device: EnergyStatusDevice) => device.items.some(item => producerTypes.has(item.type))

const isNominalConsumer = (device: EnergyStatusDevice) => device.items.some(item => consumerTypes.has(item.type))

export const getters: GetterTree<EnergyStatusState, {}> = {
  [EnergyStatusGetter.producers]: ({ energyStatusDevices }): EnergyStatusDeviceModel[] => {
    return energyStatusDevices
      .filter(isNominalProducer)
      .map(device => {
        const powerItem = device.items.find(item => item.unit === 'kW')!
        const powerValue = powerItem?.value !== InvalidValue ? (powerItem?.value ?? null) : null
        const percentageItem = device.items.find(item => item.unit === '%')
        const percentageValue = percentageItem?.value !== InvalidValue ? (percentageItem?.value ?? null) : null
        return {
          id: device.deviceId,
          label: device.name,
          icon: device.iconResourceId,
          powerGradient: powerItem?.colorGradient ?? null,
          percentageGradient: percentageItem ?.colorGradient ?? null,
          powerValue,
          percentageValue,
          powerDisplayValue: powerValue !== null ? `${Math.abs(powerValue).toFixed(powerValue === 0 ? 0 : 1)} ${powerItem?.unit}` : InvalidDisplayValue,
          percentageDisplayValue: percentageValue !== null ? `${percentageValue.toFixed(0)} ${percentageItem?.unit}` : '',
        } as EnergyStatusDeviceModel
      })
  },
  [EnergyStatusGetter.consumers]: ({ energyStatusDevices }): EnergyStatusDeviceModel[] => {
    return energyStatusDevices
      .filter(isNominalConsumer)
      .map(device => {
        const powerItem = device.items.find(item => item.unit === 'kW')!
        const powerValue = powerItem?.value !== InvalidValue ? (powerItem?.value ?? null) : null
        return {
          id: device.deviceId,
          label: device.name,
          icon: device.iconResourceId,
          powerGradient: powerItem?.colorGradient ?? null,
          percentageGradient: null,
          powerValue,
          percentageValue: null,
          powerDisplayValue: powerValue !== null ? `${Math.abs(powerValue).toFixed(powerValue === 0 ? 0 : 1)} ${powerItem?.unit}` : InvalidDisplayValue,
          percentageDisplayValue: '',
        } as EnergyStatusDeviceModel
      })
  },
  [EnergyStatusGetter.productionContributions]: (_, localGetters): ContributionPercentageModel[] => {
    const nominalProducers = localGetters[EnergyStatusGetter.producers] as EnergyStatusDeviceModel[]
    const nominalConsumers = localGetters[EnergyStatusGetter.consumers] as EnergyStatusDeviceModel[]
    const effectiveProducers = nominalProducers.filter(p => !!p.powerValue && p.powerValue > 0).concat(nominalConsumers.filter(c => !!c.powerValue && c.powerValue < 0))
    const totalProduction = effectiveProducers.reduce((sum, cur) => sum + Math.abs(cur.powerValue ?? 0), 0)
    return effectiveProducers.map(producer => {
      return {
        id: producer.id,
        gradient: producer.powerGradient,
        percentageValue: (!!totalProduction && producer.powerValue != null) ? Math.abs(producer.powerValue) / totalProduction : null,
      }
    })
  },
  [EnergyStatusGetter.consumptionContributions]: (_, localGetters): ContributionPercentageModel[] => {
    const nominalProducers = localGetters[EnergyStatusGetter.producers] as EnergyStatusDeviceModel[]
    const nominalConsumers = localGetters[EnergyStatusGetter.consumers] as EnergyStatusDeviceModel[]
    const effectiveConsumers = nominalProducers.filter(p => !!p.powerValue && p.powerValue < 0).concat(nominalConsumers.filter(c => !!c.powerValue && c.powerValue > 0))
    const totalConsumption = effectiveConsumers.reduce((sum, cur) => sum + Math.abs(cur.powerValue ?? 0), 0)
    return effectiveConsumers.map(consumer => {
      return {
        id: consumer.id,
        gradient: consumer.powerGradient,
        percentageValue: (!!totalConsumption && consumer.powerValue != null) ? Math.abs(consumer.powerValue) / totalConsumption : null,
      }
    })
  },
}
