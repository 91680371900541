<template>
    <v-container fluid>
        <v-layout>
            <div class="alarm-information">
                <div v-if="hasServiceAlarm">
                    <span>{{ dictionary('alarm.service.solving') }}</span><br/>
                    <span>{{ dictionary('alarm.timestamp') }}: {{ serviceAlarmTime }} {{ dictionary('alarm.clock') }}</span>
                    <br/><br/>
                </div>
                <div v-if="messageAlarmsText != ''">
                    <span>{{ messageAlarmsText }}</span>
                    <span v-if="!hasServiceAlarm"><br/>{{ dictionary('alarm.timestamp') }}: {{ messageAlarmTime }} {{ dictionary('alarm.clock') }}</span>
                    <br/><br/>
                </div>
                <div v-if="isPlcOffline">
                    <span>{{ dictionary('alarm.plc.offline') }}</span><br/>
                    <span>"{{ plcName }}" {{ dictionary('alarm.plc.offline.since') }}:   {{ plcOfflineTimeStamp }} {{ dictionary('alarm.clock') }}</span><br/>
                </div>
            </div>
            <div class="info-icon">
                <v-icon x-large>info</v-icon>
            </div>
        </v-layout>
    </v-container>
</template>

<script lang="ts">
import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const App = namespace('app')
const AlarmUi = namespace('alarmUi')
const Resource = namespace('resource')

@Component
export default class AlarmContent extends Vue {
    @App.Getter public selectedPlc: PlcModel
    @AlarmUi.Getter public hasServiceAlarm
    @AlarmUi.Getter public messageAlarmsText
    @AlarmUi.Getter public serviceAlarmTime
    @AlarmUi.Getter public messageAlarmTime
    @AlarmUi.Getter public isPlcOffline 
    @AlarmUi.Getter public plcOfflineTimeStamp
    @Resource.Getter public dictionary

    public get plcName(): string {
      return this.selectedPlc && this.selectedPlc.name || ''
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 0;
    margin: 0;
}
.alarm-information {
    flex: 1;
}
.info-icon {
    padding: 0 16px;
    justify-content: flex-end;
}
</style>