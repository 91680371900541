import { actionTypeText, toViewModel } from '@/store/modules/auditLogUi/helpers'
import { AuditLogEntryViewModel, AuditLogTableFilterSettings } from '@/store/modules/auditLogUi/models'
import { AuditLogUiGetter, AuditLogUiState } from '@/store/modules/auditLogUi/types'
import { RootState } from '@/store/types'
import { AuditActionType } from '@ecocoach/domain-store-modules/src/auditLog/models'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { filterDate, matchSubstringCaseInsensitive } from '@ecocoach/domain-store-modules/src/utils'
import { DropdownOption } from '@ecocoach/shared-components/src'
import { GetterTree } from 'vuex'

export const getters: GetterTree<AuditLogUiState, RootState> = {
  [AuditLogUiGetter.isInteracted]({ interacted }): boolean {
    return interacted
  },
  [AuditLogUiGetter.selectedPlcId] ({ selectedPlcId }) {
    return selectedPlcId
  },
  [AuditLogUiGetter.filterSettings] ({ filterSettings }): AuditLogTableFilterSettings {
    return filterSettings
  },  
  [AuditLogUiGetter.entries] ({ selectedPlcId }, __, rootState, rootGetters): AuditLogEntryViewModel[] {
    return rootState.auditLog.auditLogEntries
      .filter(a => a.plcId === selectedPlcId)
      .map(a => toViewModel(a, rootGetters[`resource/${ResourceGetter.resolveStringResource}`]))
  },
  [AuditLogUiGetter.filteredEntries] ({ filterSettings }) {
    return (alarms: AuditLogEntryViewModel[]): AuditLogEntryViewModel[] => {
      let filteredEntries = [...alarms]
      if (filterSettings.userIdentifier) {
        filteredEntries = filteredEntries.filter(item => matchSubstringCaseInsensitive(item.userIdentifier, filterSettings.userIdentifier))
      }
      if (filterSettings.actionTypes.length) {
        filteredEntries = filteredEntries.filter(item => filterSettings.actionTypes.includes(item.actionType))
      }
      if (filterSettings.date) {
        filteredEntries = filteredEntries.filter(item => filterDate(item.timeStampInUtc, filterSettings.date))
      }
      if (filterSettings.actionText) {
        filteredEntries = filteredEntries.filter(item => matchSubstringCaseInsensitive(item.actionText, filterSettings.actionText))
      }
      if (filterSettings.actionDetails) {
        filteredEntries = filteredEntries.filter(item => matchSubstringCaseInsensitive(item.actionDetails, filterSettings.actionDetails))
      }
      return filteredEntries
    }
  },
  [AuditLogUiGetter.actionTypeOptions] (_, __, ___, rootGetters): DropdownOption[] {
    return [
      { id: AuditActionType.ControlCommandExecution, label: actionTypeText(AuditActionType.ControlCommandExecution, rootGetters[`resource/${ResourceGetter.resolveStringResource}`])},
    ]
  },
}
