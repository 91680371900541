import { actions } from '@/store/modules/consumptionUi/actions'
import { getters } from '@/store/modules/consumptionUi/getters'
import { mutations } from '@/store/modules/consumptionUi/mutations'
import { state } from '@/store/modules/consumptionUi/state'
import { ConsumptionUiAction, ConsumptionUiGetter, ConsumptionUiMutation, ConsumptionUiState } from '@/store/modules/consumptionUi/types'
import { RootState } from  '@/store/types'
import { Module } from 'vuex'

const namespaced: boolean = true
export const consumptionUi: Module<ConsumptionUiState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export const types = {
  action: ConsumptionUiAction,
  mutation: ConsumptionUiMutation,
  getter: ConsumptionUiGetter,
}

export const domain = 'consumptionUi'

export const vuexModule = {
  [domain]: consumptionUi,
}