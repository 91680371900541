import { RootState } from '@/store/types'
import { nextAvailableSortOrder } from '@ecocoach/domain-store-modules/src/utils'
import { ActionTree } from 'vuex'
import { RoomUiAction, RoomUiMutation, RoomUiState } from './types'

export const actions: ActionTree<RoomUiState, RootState> = {
  async [RoomUiAction.createRoom] ({ commit, dispatch, state, rootState }): Promise<void> {
    try {
      commit(RoomUiMutation.setInteracted, true)
      await dispatch('plcConfiguration/createRoom', {
        ...state.roomInput,
        sortOrder: nextAvailableSortOrder(rootState.plcConfiguration.rooms),
      }, { root: true })
      commit(RoomUiMutation.resetRoomInput)
    } finally {
      commit(RoomUiMutation.setInteracted, false)
    }
  },
  async [RoomUiAction.editRoom] ({ state, rootState }, payload: string): Promise<void> {
    const room = rootState.plcConfiguration.rooms.find(r => r.id === payload)!
    state.roomInput = {
      id: room.id,
      name: room.name,
      icon: room.icon,
      sortOrder: room.sortOrder,
    }
  },
  async [RoomUiAction.saveRoom] ({ commit, dispatch, state }): Promise<void> {
    try {
      commit(RoomUiMutation.setInteracted, true)
      await dispatch('plcConfiguration/updateRoom', state.roomInput, { root: true })
      commit(RoomUiMutation.resetRoomInput)
    } finally {
      commit(RoomUiMutation.setInteracted, false)
    }
  },
  async [RoomUiAction.cancelRoom] ({ commit }): Promise<void> {
    commit(RoomUiMutation.resetRoomInput)
  },
  async [RoomUiAction.deleteRoom] ({ commit, dispatch, state }): Promise<void> {
    try {
      commit(RoomUiMutation.setInteracted, true)
      await dispatch('plcConfiguration/deleteRoom', state.roomInput.id, { root: true })
      commit(RoomUiMutation.resetRoomInput)
    } finally {
      commit(RoomUiMutation.setInteracted, false)
    }
  },
  async [RoomUiAction.sortRooms] ({ commit, dispatch }, payload: string[]): Promise<void> {
    try {
      commit(RoomUiMutation.setInteracted, true)
      await dispatch('plcConfiguration/sortRooms', payload, { root: true })
    } finally {
      commit(RoomUiMutation.setInteracted, false)
    }
  },
  async [RoomUiAction.initializeRoomSelection] ({ commit, getters }): Promise<void> {
    const selectedRoom = getters.rooms.find(room => room.id === getters.selectedRoomId)
    if (!selectedRoom) {
      const firstRoom = getters.rooms[0]
      commit(RoomUiMutation.setSelectedRoomId, firstRoom && firstRoom.id || '')
    }
  },
}
