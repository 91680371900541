import { actions } from '@/store/modules/manageAlarmsUi/actions'
import { getters } from '@/store/modules/manageAlarmsUi/getters'
import { mutations } from '@/store/modules/manageAlarmsUi/mutations'
import { state } from '@/store/modules/manageAlarmsUi/state'
import { ManageAlarmsUiAction, ManageAlarmsUiGetter, ManageAlarmsUiMutation, ManageAlarmsUiState } from '@/store/modules/manageAlarmsUi/types'
import { RootState } from  '@/store/types'
import { Module } from 'vuex'

const namespaced: boolean = true
export const manageAlarmsUi: Module<ManageAlarmsUiState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export const types = {
  action: ManageAlarmsUiAction,
  mutation: ManageAlarmsUiMutation,
  getter: ManageAlarmsUiGetter,
}

export const domain = 'manageAlarmsUi'

export const vuexModule = {
  [domain]: manageAlarmsUi,
}