import { NoteUiGetter, NoteUiState } from '@/store/modules/noteUi/types'
import { RootState } from '@/store/types'
import { sortedBy } from '@ecocoach/domain-store-modules/src/utils'
import { GetterTree } from 'vuex'
import { formatTimeStamp } from './helpers'
import { NoteViewModel } from './models'

export const getters: GetterTree<NoteUiState, RootState> = {
  [NoteUiGetter.isInteracted] ({ interacted }): boolean {
    return interacted
  },
  [NoteUiGetter.isLoading] ({ loading }): boolean {
    return loading
  },
  [NoteUiGetter.plcId] (_, __, rootState): string {
    return rootState.note.plcId
  },
  [NoteUiGetter.notesForPlc] (_, __, rootState): NoteViewModel[] {
    return sortedBy(rootState.note.notesForPlc.map(note => {
      return {
        ...note,
        createdBy: note.creatorEmail,
        createdAt: formatTimeStamp(note.createdTimestampInUtc),
        modifiedBy: note.modifierEmail ?? '',
        modifiedAt: note.modifiedTimestampUtc ? formatTimeStamp(note.modifiedTimestampUtc) : '',
      } as NoteViewModel
    }), note => note.createdTimestampInUtc, true)
  },
}
