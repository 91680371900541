import axios from 'axios'
import { toLowerCaseKeys } from '../utils'

export const BACKEND_ENVIRONMENT_STORAGE_KEY = 'backendEnvironment'

export enum BackendEnvironmentTypes {
  PRODUCTION = 'production',
  PREPRODUCTION = 'preproduction',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
  FOOBAR = 'foobar',
}

export interface BackendConfiguration {
  projectApi: string
  functionBlockCommandApi: string
  functionBlockQueryApi: string
  resourceApi: string
  solutionHardwareQueryApi: string
  controlStateHub: string
  releaseStateHub: string
  comissioningStateHub: string
  chartQueryApi: string
  consumptionQueryApi: string
  consumptionCommandApi: string
  alarmStateHub: string
  plcStateHub: string
  logHub: string
  energyStatusHub: string
  kioskHub: string
  eunomiaApi: string
  plcOperationQueryApi: string
  plcOperationCommandApi: string
  plcConfigurationQueryApi: string
  plcConfigurationCommandApi: string
  systemConfigurationQueryApi: string
  systemConfigurationCommandApi: string
  userSettingsApi: string
}

export const BackendEnvironmentConfiguration = {
  [BackendEnvironmentTypes.FOOBAR]: {
    projectApi: 'https://localhost:50508',
    functionBlockCommandApi: 'https://localhost:50502',
    functionBlockQueryApi: 'https://localhost:50503',
    resourceApi: 'https://developmentresourceapi.ecocoa.ch',
    solutionHardwareQueryApi: 'https://localhost:50505',
    controlStateHub: 'https://developmentpushqueryapi.ecocoa.ch/ControlStateHub',
    releaseStateHub: 'https://developmentpushqueryapi.ecocoa.ch/ReleaseStateHub',
    comissioningStateHub: 'https://developmentpushqueryapi.ecocoa.ch/CommissioningStateHub',
    chartQueryApi: 'https://developmentchartqueryapi.ecocoa.ch',
    consumptionQueryApi: 'https://developmentconsumptionqueryapi.ecocoa.ch',
    consumptionCommandApi: 'https://developmentconsumptioncommandapi.ecocoa.ch',
    alarmStateHub: 'https://developmentpushqueryapi.ecocoa.ch/AlarmStateHub',
    plcStateHub: 'https://developmentpushqueryapi.ecocoa.ch/plcStateHub',
    logHub: 'https://developmentpushqueryapi.ecocoa.ch/logHub',
    energyStatusHub: 'https://developmentpushqueryapi.ecocoa.ch/energyStatusHub',
    kioskHub: 'https://developmentpushqueryapi.ecocoa.ch/kioskHub',
    eunomiaApi: 'https://developmenteunomia.ecocoa.ch',
    plcOperationCommandApi: 'https://localhost:50512',
    plcOperationQueryApi: 'https://localhost:50513',
    plcConfigurationQueryApi: 'https://localhost:50511',
    plcConfigurationCommandApi: 'https://localhost:50510',
    systemConfigurationQueryApi: 'https://localhost:50514',
    systemConfigurationCommandApi: 'https://localhost:50515',
    userSettingsApi: 'https://localhost:50510',
  } as BackendConfiguration,
  [BackendEnvironmentTypes.DEVELOPMENT]: {
    projectApi: 'https://developmentprojectapi.ecocoa.ch',
    functionBlockCommandApi: 'https://developmentfunctionblockcommandapi.ecocoa.ch',
    functionBlockQueryApi: 'https://developmentfunctionblockqueryapi.ecocoa.ch',
    resourceApi: 'https://developmentresourceapi.ecocoa.ch',
    solutionHardwareQueryApi: 'https://developmentsolutionhardwarequeryapi.ecocoa.ch',
    controlStateHub: 'https://developmentpushqueryapi.ecocoa.ch/ControlStateHub',
    releaseStateHub: 'https://developmentpushqueryapi.ecocoa.ch/ReleaseStateHub',
    comissioningStateHub: 'https://developmentpushqueryapi.ecocoa.ch/CommissioningStateHub',
    chartQueryApi: 'https://developmentchartqueryapi.ecocoa.ch',
    consumptionQueryApi: 'https://developmentconsumptionqueryapi.ecocoa.ch',
    consumptionCommandApi: 'https://developmentconsumptioncommandapi.ecocoa.ch',
    alarmStateHub: 'https://developmentpushqueryapi.ecocoa.ch/AlarmStateHub',
    plcStateHub: 'https://developmentpushqueryapi.ecocoa.ch/plcStateHub',
    logHub: 'https://developmentpushqueryapi.ecocoa.ch/logHub',
    energyStatusHub: 'https://developmentpushqueryapi.ecocoa.ch/energyStatusHub',
    kioskHub: 'https://developmentpushqueryapi.ecocoa.ch/kioskHub',
    eunomiaApi: 'https://developmenteunomia.ecocoa.ch',
    plcOperationCommandApi: 'https://developmentplcoperationcommandapi.ecocoa.ch',
    plcOperationQueryApi: 'https://developmentplcoperationqueryapi.ecocoa.ch',
    plcConfigurationQueryApi: 'https://developmentplcconfigurationqueryapi.ecocoa.ch',
    plcConfigurationCommandApi: 'https://developmentplcconfigurationcommandapi.ecocoa.ch',
    systemConfigurationQueryApi: 'https://developmentsystemconfigurationqueryapi.ecocoa.ch',
    systemConfigurationCommandApi: 'https://developmentsystemconfigurationcommandapi.ecocoa.ch',
    userSettingsApi: 'https://developmentusersettingsapi.ecocoa.ch',
  } as BackendConfiguration,
  [BackendEnvironmentTypes.STAGING]: {
    projectApi: 'https://stagingprojectapi.ecocoa.ch',
    functionBlockCommandApi: 'https://stagingfunctionblockcommandapi.ecocoa.ch',
    functionBlockQueryApi: 'https://stagingfunctionblockqueryapi.ecocoa.ch',
    resourceApi: 'https://stagingresourceapi.ecocoa.ch',
    solutionHardwareQueryApi: 'https://stagingsolutionhardwarequeryapi.ecocoa.ch',
    controlStateHub: 'https://stagingpushqueryapi.ecocoa.ch/ControlStateHub',
    releaseStateHub: 'https://stagingpushqueryapi.ecocoa.ch/ReleaseStateHub',
    comissioningStateHub: 'https://stagingpushqueryapi.ecocoa.ch/CommissioningStateHub',
    chartQueryApi: 'https://stagingchartqueryapi.ecocoa.ch',
    consumptionQueryApi: 'https://stagingconsumptionqueryapi.ecocoa.ch',
    consumptionCommandApi: 'https://stagingconsumptioncommandapi.ecocoa.ch',
    alarmStateHub: 'https://stagingpushqueryapi.ecocoa.ch/AlarmStateHub',
    plcStateHub: 'https://stagingpushqueryapi.ecocoa.ch/plcStateHub',
    logHub: 'https://stagingpushqueryapi.ecocoa.ch/logHub',
    energyStatusHub: 'https://stagingpushqueryapi.ecocoa.ch/energyStatusHub',
    kioskHub: 'https://stagingpushqueryapi.ecocoa.ch/kioskHub',
    eunomiaApi: 'https://stagingeunomia.ecocoa.ch',
    plcOperationCommandApi: 'https://stagingplcoperationcommandapi.ecocoa.ch',
    plcOperationQueryApi: 'https://stagingplcoperationqueryapi.ecocoa.ch',
    plcConfigurationQueryApi: 'https://stagingplcconfigurationqueryapi.ecocoa.ch',
    plcConfigurationCommandApi: 'https://stagingplcconfigurationcommandapi.ecocoa.ch',
    systemConfigurationQueryApi: 'https://stagingsystemconfigurationqueryapi.ecocoa.ch',
    systemConfigurationCommandApi: 'https://stagingsystemconfigurationcommandapi.ecocoa.ch',
    userSettingsApi: 'https://stagingusersettingsapi.ecocoa.ch',
  } as BackendConfiguration,
  [BackendEnvironmentTypes.PRODUCTION]: {
    projectApi: 'https://productionprojectapi.ecocoa.ch',
    functionBlockCommandApi: 'https://productionfunctionblockcommandapi.ecocoa.ch',
    functionBlockQueryApi: 'https://productionfunctionblockqueryapi.ecocoa.ch',
    resourceApi: 'https://productionresourceapi.ecocoa.ch',
    solutionHardwareQueryApi: 'https://productionsolutionhardwarequeryapi.ecocoa.ch',
    controlStateHub: 'https://productionpushqueryapi.ecocoa.ch/ControlStateHub',
    releaseStateHub: 'https://productionpushqueryapi.ecocoa.ch/ReleaseStateHub',
    comissioningStateHub: 'https://productionpushqueryapi.ecocoa.ch/CommissioningStateHub',
    chartQueryApi: 'https://productionchartqueryapi.ecocoa.ch',
    consumptionQueryApi: 'https://productionconsumptionqueryapi.ecocoa.ch',
    consumptionCommandApi: 'https://productionconsumptioncommandapi.ecocoa.ch',
    alarmStateHub: 'https://productionpushqueryapi.ecocoa.ch/AlarmStateHub',
    plcStateHub: 'https://productionpushqueryapi.ecocoa.ch/plcStateHub',
    logHub: 'https://productionpushqueryapi.ecocoa.ch/logHub',
    energyStatusHub: 'https://productionpushqueryapi.ecocoa.ch/energyStatusHub',
    kioskHub: 'https://productionpushqueryapi.ecocoa.ch/kioskHub',
    eunomiaApi: 'https://productioneunomia.ecocoa.ch',
    plcOperationCommandApi: 'https://productionplcoperationcommandapi.ecocoa.ch',
    plcOperationQueryApi: 'https://productionplcoperationqueryapi.ecocoa.ch',
    plcConfigurationQueryApi: 'https://productionplcconfigurationqueryapi.ecocoa.ch',
    plcConfigurationCommandApi: 'https://productionplcconfigurationcommandapi.ecocoa.ch',
    systemConfigurationQueryApi: 'https://productionsystemconfigurationqueryapi.ecocoa.ch',
    systemConfigurationCommandApi: 'https://productionsystemconfigurationcommandapi.ecocoa.ch',
    userSettingsApi: 'https://productionusersettingsapi.ecocoa.ch',
  } as BackendConfiguration,
  [BackendEnvironmentTypes.PREPRODUCTION]: {
    oauthClientId: '34eb5b44-232d-4c2e-b0ea-068da8bfe7dd',
    oauthAuthority: 'https://ecointegrationcloud.b2clogin.com/ecointegrationcloud.onmicrosoft.com',
    oauthScopes: ['offline_access', 'openid', 'https://ecointegrationcloud.onmicrosoft.com/apis/user_impersonation'],
    oauthSignInPolicy: 'B2C_1_default',
    oauthRecoveryPolicy: 'B2C_1_recovery',
    projectApi: 'https://productionprojectapi-preproduction.ecocoa.ch',
    functionBlockCommandApi: 'https://productionfunctionblockcommandapi.ecocoa.ch',
    functionBlockQueryApi: 'https://productionfunctionblockqueryapi.ecocoa.ch',
    resourceApi: 'https://productionresourceapi.ecocoa.ch',
    solutionHardwareQueryApi: 'https://productionsolutionhardwarequeryapi-preproduction.ecocoa.ch',
    controlStateHub: 'https://productionpushqueryapi-preproduction.ecocoa.ch/ControlStateHub',
    releaseStateHub: 'https://productionpushqueryapi-preproduction.ecocoa.ch/ReleaseStateHub',
    comissioningStateHub: 'https://productionpushqueryapi-preproduction.ecocoa.ch/CommissioningStateHub',
    chartQueryApi: 'https://productionchartqueryapi.ecocoa.ch',
    consumptionQueryApi: 'https://productionconsumptionqueryapi.ecocoa.ch',
    consumptionCommandApi: 'https://productionconsumptioncommandapi.ecocoa.ch',
    alarmStateHub: 'https://productionpushqueryapi-preproduction.ecocoa.ch/AlarmStateHub',
    plcStateHub: 'https://productionpushqueryapi-preproduction.ecocoa.ch/plcStateHub',
    logHub: 'https://productionpushqueryapi-preproduction.ecocoa.ch/logHub',
    energyStatusHub:  'https://productionpushqueryapi-preproduction.ecocoa.ch/energyStatusHub',
    kioskHub:  'https://productionpushqueryapi-preproduction.ecocoa.ch/kioskHub',
    eunomiaApi: 'https://productioneunomia.ecocoa.ch',
    plcOperationCommandApi: 'https://productionplcoperationcommandapi-preproduction.ecocoa.ch',
    plcOperationQueryApi: 'https://productionplcoperationqueryapi-preproduction.ecocoa.ch',
    plcConfigurationQueryApi: 'https://productionplcconfigurationqueryapi-preproduction.ecocoa.ch',
    plcConfigurationCommandApi: 'https://productionplcconfigurationcommandapi-preproduction.ecocoa.ch',
    systemConfigurationQueryApi: 'https://productionsystemconfigurationqueryapi-preproduction.ecocoa.ch',
    systemConfigurationCommandApi: 'https://productionsystemconfigurationcommandapi-preproduction.ecocoa.ch',
    userSettingsApi: 'https://productionusersettingsapi-preproduction.ecocoa.ch',
  } as BackendConfiguration,
}

export const StatusCode = {
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  Conflict: 409,
  InternalServerError: 500,
}

export interface ApiServiceOptions {
  appId: string
  environment: string
}

const ApiService = {
  _environment: '',
  _appId: '',
  _401interceptor: -99,
  _errorInterceptor: null as (number | null),

  init(options: ApiServiceOptions) {
    this._appId = options.appId
    this._environment = options.environment
  },
  selectedBackendEnvironment(): string {
    return this._environment
  },
  backendEnvironmentConfiguration(): BackendConfiguration {
    return BackendEnvironmentConfiguration[this.selectedBackendEnvironment() || 'production']
  },
  registerUnauthorizedHandler(handler: (() => void)) {
    if (this._401interceptor !== null) {
      // unregister maybe already registered handler
      axios.interceptors.request.eject(this._401interceptor)
    }
    this._401interceptor = axios.interceptors.response.use((response) => {
      return response
    }, (error) => {
      if (error.response && error.response.status === StatusCode.Unauthorized) {
        handler()
      }
      return Promise.reject(error)
    })
  },
  registerErrorHandler(handler: ((errorContext: { statusCode?: number, payload?: any }) => void)) {
    if (this._errorInterceptor !== null) {
      // unregister maybe already registered handler
      axios.interceptors.request.eject(this._errorInterceptor)
    }
    this._errorInterceptor = axios.interceptors.response.use((response) => {
      return response
    }, async (error) => {
      const statusCode = error.response && error.response.status
      if (statusCode !== StatusCode.Unauthorized) { // don't handle unauthorized, it is handled by the 401interceptor
        // in case of blob and text requests, try to parse the response body as object
        const isJsonBlob = (data) => data instanceof Blob && data.type === 'application/json'
        const responseData = isJsonBlob(error.response?.data) ? await (error.response?.data)?.text() : error.response?.data || {}
        const responseJson = (typeof responseData === 'string') ? JSON.parse(responseData) : responseData
        const payload = typeof responseJson === 'object' ? toLowerCaseKeys(responseJson) : {}
        handler({ statusCode, payload })
      }
      return Promise.reject(error)
    })
  },
  setHeader(token: string) {
    // tslint:disable:no-string-literal
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.common['Accept'] = 'application/json application/text'
    axios.defaults.headers.common['Content-Type'] = 'application/json application/json-patch+json'
  },

  removeHeader() {
    axios.defaults.headers.common = {}
  },

  get(url, options?: { responseType?: 'blob' }) {
    return options ? axios.get(url, options) : axios.get(url)
  },

  post(url, data) {
    return axios.post(url, data)
  },

  put(url, data) {
    return axios.put(url, data)
  },

  delete(url, data) {
    return axios.delete(url, data)
  },
}
export default ApiService