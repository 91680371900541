<template>
  <div class="plc-version">
    <div :title="plc.ecoCloudConnectorVersion" class="version-label">{{ plc.ecoCloudConnectorVersion }}</div>
    <v-tooltip bottom class="update-button">  
      <v-btn slot="activator" icon small v-if="plc.eccUpdateAvailable"
        :disabled="!plc.eccUpdateEnabled || isInteracted"
        @click.stop="onUpdate(plc.id)"
      >
        <v-icon>system_update_alt</v-icon>
      </v-btn>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { PlcViewModel } from '../../../../../store/modules/selectPlcUi/models'

import { PlcType } from '@ecocoach/domain-store-modules/src/common'

import { ComponentFirmwareReleaseModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'

const Resource = namespace('resource')
const SelectPlcUi = namespace('selectPlcUi')

@Component({
  components: {
  },
})
export default class PlcTableVersion extends Vue {
  @Prop() public plc: PlcViewModel
  @SelectPlcUi.Getter public isInteracted: boolean
  @SelectPlcUi.Getter public activeEccReleaseVersion: (plcType: PlcType) => ComponentFirmwareReleaseModel | undefined
  @Resource.Getter public dictionary

  @Emit('update')
  public onUpdate(plcId: string) {
    return plcId
  }

  public get tooltip() {
    return `${this.dictionary('common.button.updateToLatestEcoCloudConnector')} (${this.activeEccReleaseVersion(this.plc.plcType)?.version ?? 'n/a'})`
  }
}
</script>
<style lang="scss" scoped>
.plc-version {
  display: flex;
  align-items: center;
  .version-label {
    flex: 1;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .update-button {
    flex: 0;
  }
}
</style>
