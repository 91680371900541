import { MutationTree } from 'vuex'
import { SolutionHardwareClampLines, SolutionHardwareInstanceViewModel } from './models'
import { SolutionHardwareUiMutation, SolutionHardwareUiState } from './types'

export const mutations: MutationTree<SolutionHardwareUiState> = {
  [SolutionHardwareUiMutation.setClampLines](state, payload: SolutionHardwareClampLines) {
    state.clampLines = payload
  },
  [SolutionHardwareUiMutation.setModules](state, payload: SolutionHardwareInstanceViewModel[]) {
    state.modules = payload
  },
  [SolutionHardwareUiMutation.setInteracted](state, payload: boolean) {
    state.interacted = payload
  },
}
