import { RootState } from '@/store/types'
import { PlcType } from '@ecocoach/domain-store-modules/src/common'
import { sortedBy, sortedByName } from '@ecocoach/domain-store-modules/src/utils'
import { DropdownOption } from '@ecocoach/shared-components/src'
import { GetterTree } from 'vuex'
import { ProjectWithPlcs } from './models'
import { ProjectManagementUiGetter, ProjectManagementUiState } from './types'

export const getters: GetterTree<ProjectManagementUiState, RootState> = {
  [ProjectManagementUiGetter.isInteracted] ({ interacted }): boolean {
    return interacted
  },
  [ProjectManagementUiGetter.projectsWithPlcs](_, __, rootState): ProjectWithPlcs[] {
    return sortedBy(rootState.project.projects.map(project => {
      return {
        project,
        plcs: sortedByName(project.plcs),
      } as ProjectWithPlcs
    }), p => p.project.name)
  },
  [ProjectManagementUiGetter.plcTypeOptions] (): DropdownOption[] {
    return [
      { id: PlcType.UNDEFINED, label: '-' },
      { id: PlcType.ECO_BASIC_CONTROL_SMALL, label: `${PlcType.ECO_BASIC_CONTROL_SMALL} (CX8190)` },
      { id: PlcType.ECO_BASIC_CONTROL_MEDIUM, label: `${PlcType.ECO_BASIC_CONTROL_MEDIUM} (CX9020)` },
      { id: PlcType.ECO_BASIC_CONTROL_LARGE, label: `${PlcType.ECO_BASIC_CONTROL_LARGE} (CX5130)` },
      { id: PlcType.ECO_BASIC_CONTROL_LIGHT, label: `${PlcType.ECO_BASIC_CONTROL_LIGHT} (IOT-GW30)` },
    ]
  },
}