<template>
  <card :header="`Function definition: ${resolveStringResource(functionDefinition.nameResourceId)}`" :chipText="chipText" :chipColor="chipColor">
    <template v-slot:actions>
      <description-popover
        class="popUpButton"
        :header="functionDefinition.nameResourceId | translate"
        :content="`Version: ${functionDefinition.version}<br>${resolveStringResource(functionDefinition.descriptionResourceId)}`"
      />
    </template>
    <v-layout row wrap class="cardbody" >
      <!-- left part  -->
      <v-flex xs4>
        <form-field text="XML file" v-if="sourceLanguage === 'StructuredText'">
          <file-select
            id="select-xml-update"
            file-type=".xml"
            placeholder="Update PLCopenXML file"
            @changed="onXmlFileSelected"
          />
        </form-field>
        <form-field text="Package version" v-if="sourceLanguage === 'CSharp'">
          <autocomplete-select 
            :options="functionDefinitionPackageOptions(functionDefinition.internalName)"
            option-value="id"
            option-label="label"
            :selected-value="functionDefinition.functionBlockPackageInfoId"
            @selected="onPackageSelected"
          />
        </form-field>
        <form-field text="ID">
          <div class="label" :title="functionDefinition.id">
            {{ functionDefinition.id}}
          </div>
        </form-field>
        <form-field text="Internal name">
          <div class="label" :title="functionDefinition.internalName">
            {{ functionDefinition.internalName }}
          </div>
        </form-field>
      </v-flex>
      <!-- middle part  -->
      <v-flex xs4>
        <form-field text="Version">
          <text-input
            :value="functionDefinition.version"
            @changed="setProperty('version', $event)"
          />
        </form-field>
        <form-field text="Category">
          <autocomplete-select 
            :options="functionDefinitionCategoryOptions"
            option-value="id"
            option-label="label"
            :selected-value="functionDefinition.functionBlockDefinitionCategoryId"
            @selected="setProperty('functionBlockDefinitionCategoryId', $event)"
          />
        </form-field>
        <form-field text="Icon">
          <div class="iconSelect">
            <icon-chooser-popover
              :icons="deviceIcons"
              :show-label="false"
              :selected="functionDefinition.iconResourceId"
              :icon-size="30"
              @iconSelected="setProperty('iconResourceId', $event)"
            />          
          </div>
        </form-field>
      </v-flex>
      <!-- right part  -->
      <v-flex xs4>
        <form-field text="TwinCat library dependency" v-if="architectureType === 'Classic'">
          <autocomplete-select 
            :options="functionDefinitionTwinCatLibraryDependencyOptions"
            option-value="id"
            option-label="label"
            :selected-value="functionDefinition.twinCatLibraryDependency"
            @selected="setProperty('twinCatLibraryDependency', $event)"
          />
        </form-field>
        <form-field text="Cycle time" v-if="architectureType === 'ObjectOriented' && sourceLanguage == 'StructuredText'">
          <autocomplete-select 
            :options="functionDefinitionCycleTimeOptions"
            option-value="id"
            option-label="label"
            :selected-value="functionDefinition.cycleTimeInMilliseconds"
            @selected="setProperty('cycleTimeInMilliseconds', $event)"
          />
        </form-field>
        <form-field text="autoMappingSlots json">
          <text-input
            :value="autoMappingSlotsValue"
            optimize-for-json="true"
            @changed="autoMappingSlotsInputChanged"
          />
        </form-field>
        <form-field text="Messages enum internal name">
          <div class="label" :title="functionDefinition.messagesEnumInternalName">
            {{ functionDefinition.messagesEnumInternalName || 'n/a' }}
          </div>
        </form-field>
        <form-field text="Tags" v-if="sourceLanguage === 'CSharp'">
          <autocomplete-select 
            :multiple="true"
            :options="functionDefinitionLicenseTagOptions"
            option-value="id"
            option-label="label"
            :selected-value="functionDefinition.licenseTags"
            placeholdertext="Select tags"
            @selected="setProperty('licenseTags', $event)"
          />
        </form-field>        
      </v-flex>
    </v-layout>
    <div v-if="functionDefinition.instanceResources.length > 0">Software mapping source instances</div>
    <v-layout row wrap align-start class="cardbody" v-if="architectureType === 'ObjectOriented'">
      <v-flex xs4 v-for="instance in functionDefinition.instanceResources" :key="instance.endpointPath">
        <form-field :text="`Instance ${instance.endpointPath} name`">
          <string-resource-select
            :options="filteredSystemConfigurationResources('SoftwareMappingSourceInstance_', '_Name', false)"
            :selected-value="instance.resourceId"
            category="SystemConfiguration"
            prefix="SoftwareMappingSourceInstance_"
            postfix="_Name"
            :default-value="instance.endpointPath"
            @selected="onInstanceResourceIdSelected(instance.endpointPath, $event)"
            @created="onInstanceResourceCreated(instance.endpointPath, $event)"
          />
        </form-field>
      </v-flex>
    </v-layout>  
    <v-layout column class="cardbody" >
      <v-flex>
        <form-field text="Predecessors" class="flexible">
          <autocomplete-select 
            :multiple="true"
            :options="predecessorFunctionBlocksOptions"
            option-value="id"
            option-label="label"
            :selected-value="functionDefinition.predecessorFunctionBlockIds"
            placeholdertext="Select predecessors (optional)"
            @selected="setProperty('predecessorFunctionBlockIds', $event)"
          />
        </form-field>
      </v-flex>
    </v-layout>  
  </card>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Card from '../../ui/Card.vue'
import CardAction from '../../ui/CardAction.vue'
import IconChooserPopover from '../../ui/IconChooserPopover.vue'
import StringResourceSelect from './StringResourceSelect.vue'

import { CreateResourceInput, ResourceCategory, ResourceModel } from '../../../../../eco-domain-store-modules/src/resource/models'
import { FunctionDefinitionViewModel, IconDropdownOption } from '../../../store/modules/functionDefinitionEditorUi/models'

import { DropdownOption } from '../../../../../shared-components/src'

import { ArchitectureType, SourceLanguage } from '../../../../../eco-domain-store-modules/src/common'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')
const Resource = namespace('resource')

@Component({
  components: {
    Card,
    CardAction,
    IconChooserPopover,
    StringResourceSelect,
  },
})
export default class FunctionBlockDetail extends Vue {
  @FunctionDefinitionEditorUi.Action public updateFunctionDefinitionFromXmlFile: (file: File) => Promise<void>
  @FunctionDefinitionEditorUi.Action public updateFunctionDefinitionFromPackage: (functionBlockPackageInfoId: string) => Promise<void>
  @FunctionDefinitionEditorUi.Action public showError: (error: string) => Promise<void>
  @FunctionDefinitionEditorUi.Getter public functionDefinition: FunctionDefinitionViewModel
  @FunctionDefinitionEditorUi.Getter public functionDefinitionCategoryOptions: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public functionDefinitionTwinCatLibraryDependencyOptions: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public functionDefinitionCycleTimeOptions: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public functionDefinitionIconResources: IconDropdownOption[]
  @FunctionDefinitionEditorUi.Getter public filteredSystemConfigurationResources:
    (prefix: string, postfix: string, allowEmpty: boolean) => DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public predecessorFunctionBlocksOptions: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public architectureType: ArchitectureType
  @FunctionDefinitionEditorUi.Getter public sourceLanguage: SourceLanguage
  @FunctionDefinitionEditorUi.Getter public functionDefinitionLicenseTagOptions: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public functionDefinitionPackageOptions: (internalName: string) => DropdownOption[]
  @FunctionDefinitionEditorUi.Mutation public setFunctionDefinitionProperty: (payload: { property: string, value: any }) => void
  @FunctionDefinitionEditorUi.Mutation public setFunctionDefinitionInstanceResourceProperty: (payload: { id: string, value: string }) => void
  @FunctionDefinitionEditorUi.Action public createResource: (payload: CreateResourceInput) => Promise<void>
  @Resource.Getter public resolveStringResource: (key: string) => string
  @Resource.Getter public resourcesOfCategory: (category: ResourceCategory) => ResourceModel[]

  public get deviceIcons() {
    return this.resourcesOfCategory(ResourceCategory.DEVICE_ICONS)
  }
  
  public onXmlFileSelected(file: File) {
    this.updateFunctionDefinitionFromXmlFile(file)
  }

  public onPackageSelected(id: string) {
    this.updateFunctionDefinitionFromPackage(id)
  }

  public setProperty(property: string, value: any) {
    this.setFunctionDefinitionProperty({
      property,
      value,
    })
  }

  public get autoMappingSlotsValue() {
    return JSON.stringify(this.functionDefinition.autoMappingSlots || {})
  }

  public autoMappingSlotsInputChanged(value: string) {
    try {
      const autoMapping = JSON.parse(value)
      this.setFunctionDefinitionProperty({
        property: 'autoMappingSlots',
        value: autoMapping,
      })
    } catch (err) {
      const error = `Invalid JSON: ${err}`
      this.showError(error)
    }
  }

  public onInstanceResourceIdSelected(id: string, resourceId: string) {
    this.setFunctionDefinitionInstanceResourceProperty({
      id,
      value: resourceId,
    })
  }

  public onInstanceResourceCreated(id: string, input: CreateResourceInput) {
    this.createResource(input)
    this.setFunctionDefinitionInstanceResourceProperty({
      id,
      value: input.id,
    })
  }

  public get chipText() {
    return this.architectureType === ArchitectureType.ObjectOriented
      ? (this.sourceLanguage === SourceLanguage.CSharp ? 'C#' : 'OO')
      : 'Classic'
  }

  public get chipColor() {
    return this.architectureType === ArchitectureType.ObjectOriented ? 'green' : 'blue'
  }

}
</script>
<style lang="scss" scoped>
  .cardbody {
    margin-left: -10px;
  }
  .popUpButton {
    flex: 0;
    padding-left: 10px;
  }
  .field:not(.flexible) {
    height: 70px;
  }
  .field .text-input {
      padding-left: 10px;
  }
  .field .label {
    padding-left: 10px;
    overflow: hidden;
    max-height: 40px;
  }
  .field {
    .iconSelect {
      margin-left: 10px;
    }
  }
</style>