<template>
  <div>
    <div class="field-container">
      <div class="select">
        <autocomplete-select 
          :options="options"
          option-value="id"
          option-label="label"
          :selected-value="selectedValue"
          :disabled="disabled"
          @selected="onSelected"
        />
      </div>
      <div class="create">
        <v-tooltip bottom>
          <icon-button
            slot="activator"
            type="materialIcon"
            material-icon-name="add"
            :width="15"
            :height="15"
            :enabled="!disabled"
            @buttonClicked="showDialog"
          />
          <span>Create new resource key</span>
        </v-tooltip>
      </div>
    </div>
    <add-string-resource-dialog
      :show="isDialogShown"
      :category="category"
      :prefix="prefix"
      :postfix="postfix"
      :default-value="defaultValue"
      @created="onCreated"
      @closed="onClosed"
    />
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import AddStringResourceDialog from './AddStringResourceDialog.vue'

import { CreateResourceInput } from '../../../../../eco-domain-store-modules/src/resource/models'

@Component({
  components: {
    AddStringResourceDialog,
  },
})
export default class StringResourceSelect extends Vue {
  @Prop({type: Array}) public options: Array<{id: string, label: string}>
  @Prop({type: String}) public selectedValue: string
  @Prop({type: String}) public header: string
  @Prop({type: String}) public category: string
  @Prop({type: String}) public prefix: string
  @Prop({type: String}) public postfix: string
  @Prop({type: String}) public defaultValue: string
  @Prop({type: Boolean, default: false}) public disabled: boolean

  public isDialogShown: boolean = false

  public mounted() {
    this.isDialogShown = false
  }

  @Emit('selected')
  public onSelected(value: string) {
    return value
  }

  @Emit('created') 
  public onCreated(value: CreateResourceInput) {
    this.isDialogShown = false
    return value
  }

  public showDialog() {
    this.isDialogShown = true
  }

  public onClosed () {
    this.isDialogShown = false
  }
}
</script>
<style lang="scss" scoped>
  .field-container {
    display: flex;
    align-items: center;
    .select {
      flex: 1;
      .autocomplete-input {
        padding-top: 0px;
      }
    }
    .create {
      flex: 0;
      margin-top: -18px;
      margin-left: -18px;
    }
  }
</style>
