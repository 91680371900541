
<template>
    <v-expansion-panel v-model="isExpanded" :expand="true" class="tree-view">
        <v-expansion-panel-content>
            <div slot="header" :title="title" class="label-with-icon">
                <svg-icon :icon="icon" width="18" height="18" class="icon"/>
                <div class="label">{{ title }}</div>
            </div>
            <v-card>
                <v-list>
                    <slot></slot>
                </v-list>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import SvgIcon from './SvgIcon.vue'

@Component({
  components: {
    SvgIcon,
  },
})
export default class TreeViewGroup extends Vue {
    @Prop({type: String}) public title: string
    @Prop({type: String}) public icon: string
    @Prop({type: Boolean}) public expanded: boolean

    public isExpanded = [false]

    public mounted() {
      this.isExpanded = [this.expanded]
    }

    @Watch('expanded')
    public expandedChanged(value: boolean) {
      this.isExpanded = [value]
    }
}
</script>

<style lang="scss">
@media screen and (max-width: 1364px) {
  .v-expansion-panel__header {
    font-size: 16px !important;    
  }
}
.label-with-icon {
    display: flex;
    .icon {
        margin-right: 10px;
        margin-top: 5px;
    }
    .label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
