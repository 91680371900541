<template>
  <div class="virtual-list-container">
    <virtual-list v-if="!!logEntries.length" class="virtual-list"
      :data-key="'id'"
      :data-sources="logEntries"
      :data-component="item"
    />
    <div v-else>No data available</div>
  </div>
</template>
<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import VirtualList from 'vue-virtual-scroll-list'

import VirtualLogEntriesTableItem from './VirtualLogEntriesTableItem.vue'

import { LogEntryModel } from '../../../../../store/modules/logToolUi/models'

const LogToolUi = namespace('logToolUi')

@Component({
  components: {
    VirtualList,
    VirtualLogEntriesTableItem,
  },
})
export default class VirtualLogEntriesTable extends Vue {
  @Prop() public logEntries: LogEntryModel[]
  @LogToolUi.Getter public isInteracted: boolean
  @LogToolUi.Getter public isLoading: boolean

  @Emit('clear')
  public clear() {
    return
  }

  public get item() {
    return VirtualLogEntriesTableItem
  }
}
</script>
<style lang="scss" scoped>
.virtual-list-container {
  height: 100%;
  .virtual-list {
    height: 100%;
    overflow-y: auto;
  }
}
</style>
