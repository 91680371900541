import { SelectPlcUiState } from './types'

export const state: SelectPlcUiState = {
  interacted: false,
  filterSettings: {
    projectIds: [],
    plcName: '',
    plcTypes: [],
    versions: [],
    statuses: [],
    alarms:[],
  },
  numberOfMessagesInQueue: 0,
  supportData: {
    plcId: '',
    architectureType: null,
    functionBlockDefinitions: [],
  },
}
