import { AuditLogEntryModel } from '@ecocoach/domain-store-modules/src/auditLog/models'

export interface AuditLogEntryViewModel extends AuditLogEntryModel {
  dateTimeFormatted: string
  actionTypeText: string
  actionText: string
  actionDetails: string
}

export interface AuditLogTableFilterSettings {
  userIdentifier: string
  actionTypes: string[]
  date: string
  actionText: string
  actionDetails: string
}

export const AuditLogTableFilterSettingsStorageKey = 'auditLogFilterSettings'
