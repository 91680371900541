<template>
    <v-navigation-drawer app :mini-variant-width="100" class="custom" :mini-variant="navigationDrawerMinimized" v-model="drawer" stateless hide-overlay>
        <v-toolbar flat class="transparent">
            <v-list class="pa-0">
                <v-list-tile class="logo">
                    <v-list-tile-content @click.stop="navigationDrawerMinimized = !navigationDrawerMinimized">
                        <img v-if="navigationDrawerMinimized" src="../../assets/ecocoach.logo.mobile.png"/>
                        <img v-else src="../../assets/ecocoach.logo.png"/>
                    </v-list-tile-content>
                    <v-list-tile-action>
                        <v-btn icon @click.native.stop="navigationDrawerMinimized = !navigationDrawerMinimized">
                            <v-icon>chevron_left</v-icon>
                        </v-btn>
                    </v-list-tile-action>
                </v-list-tile>
            </v-list>
        </v-toolbar>
        <v-list class="navigation">
            <v-list-tile 
              v-for="route in routes" 
              :key="route.name" 
              @click="$router.push({ name: route.name })" 
              :class="{'activeRoute': highlightItem(route.name), 'addSpaceAbove': route.name === 'account'}"
            >
                <v-list-tile-action>
                    <v-icon v-if="route.meta.icon && !route.meta.icon.includes('/')" outline :title="$options.filters.translate(route.meta.title)">{{ route.meta.icon }}</v-icon> 
                    <img v-if="route.meta.icon && route.meta.icon.includes('/')" :src='route.meta.icon' width="24" :title="$options.filters.translate(route.meta.title)" />
                </v-list-tile-action>
                <v-list-tile-content>
                    <v-list-tile-title :class="route.meta.icon ? '' : 'sub-navi-item'">{{ route.meta.title | translate }}</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>
        </v-list>
        <side-alarms />
    </v-navigation-drawer>
</template>

<script lang="ts">
import { namespace } from 'vuex-class'

import SideAlarms from '@/components/ui/SideAlarms.vue'
import { Component, Vue } from 'vue-property-decorator'

const { Action, Getter } = namespace('app')
const ConsumptionUi = namespace('consumptionUi')

@Component({
  components: {
    SideAlarms,
  },
})
export default class SideNavigation extends Vue {
    @Getter public isNavigationDrawerMinimized: boolean
    @Action public setNavigationDrawerMinimized: (minimized: boolean) => Promise<void>
    @ConsumptionUi.Getter public showOneClickSubNavigation: boolean
    public drawer = true

    public get navigationDrawerMinimized(): boolean {
      return this.isNavigationDrawerMinimized
    }

    public set navigationDrawerMinimized(minimized: boolean) {
      this.setNavigationDrawerMinimized(minimized)
    }
    
    get routes () {
      const router: any = this.$router
      return router.options.routes
        .sort((current, next) => current.meta.sortOrder - next.meta.sortOrder)
        .filter(route => {
          // eslint-disable-next-line no-prototype-builtins
          return route.meta.hasOwnProperty('requirements')
            ? route.meta.requirements
              .map(requirement => this.$store.getters[requirement])
              .filter(unfulfilled => unfulfilled === false)
              .length === 0
            : true
        })
    }

    public highlightItem(checkRoute) {
      const currentRoute: string = this.$route.path.split('/')[1] // selects relevant route in url 
      if(!currentRoute.includes('ecoOneClick')) {
        return currentRoute === checkRoute
      } else {
        // top oneClick route is called 'ecoOneClick'
        // sub oneClick routes start with 'ecoOneClick-'
        if(this.showOneClickSubNavigation) {
          return checkRoute.includes(currentRoute)
        } else {
          return currentRoute.startsWith('ecoOneClick-') && checkRoute === 'ecoOneClick' 
        }
      }
    }
}
</script>

<style lang="scss" scoped>
.logo {
    .v-list__tile {
        height: 80px;
        .v-list__tile__content {
            opacity: 1 !important;
            img {
                height: 24px;
                margin-left: 5px;
            }
        }
        .v-list__tile__action {
            button {
                color: #fff;
                margin: 0;
                width: 24px;
            }
        }
    }
    cursor: pointer;
}

.activeRoute {
    background-color: #1a3856;
}

.addSpaceAbove {
    margin-top: 48px;
}

.v-navigation-drawer .v-list {
    background: rgba(0, 0, 0, 0) !important;
    .v-list__tile {
        color: #fff;
        .v-list__tile__action > i {
            color: #fff;
        }
        .v-list__tile__content {
            .v-list__tile__title {
                color: white;
                text-transform: uppercase;
                font-size: 18px;
            }
        }
    }
}
.v-list.navigation {
    .v-list__tile {
        .v-list__tile__action {
            min-width: 64px;
            text-align: center;
            justify-content: center;
            align-items: center;
        }
    }
}
.custom {
  color: white;
  font-family: 'Titillium Web', sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #1a3856 !important;
  background: #1a3856;
  background: linear-gradient(to bottom, #1a3856, #080e19);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0,#1a3856),
    color-stop(.4,#080e19)
	);
	box-shadow: 0 0 68px #000;
}
.sub-navi-item {
    font-size: 16px!important;
    text-transform: none!important;
}
</style>
