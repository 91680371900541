export enum NoteUiAction {
  openNotesForPlc = 'openNotesForPlc',
  closeNotesForPlc = 'closeNotesForPlc',
  createNoteForPlc = 'createNoteForPlc',
  updateNoteForPlc = 'updateNoteForPlc',
  deleteNoteForPlc = 'deleteNoteForPlc',
}

export enum NoteUiMutation {
  setInteracted = 'setInteracted',
  setLoading = 'setLoading',
}

export enum NoteUiGetter {
  isInteracted = 'isInteracted',
  isLoading = 'isLoading',
  plcId = 'plcId',
  notesForPlc = 'notesForPlc',
}

export interface NoteUiState {
  interacted: boolean
  loading: boolean
}
