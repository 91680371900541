import { RootState } from '@/store/types'
import { RoomModel } from '@ecocoach/domain-store-modules/src/plcConfiguration/models'
import { GetterTree } from 'vuex'
import { RoomUiGetter, RoomUiState } from './types'

export const getters: GetterTree<RoomUiState, RootState> = {
  [RoomUiGetter.rooms](_, __, rootState): RoomModel[] {
    return rootState.plcConfiguration.rooms
  },
  [RoomUiGetter.selectedRoomId]({ selectedRoomId }): string {
    return selectedRoomId
  },
  [RoomUiGetter.getRoomInputProperty]({ roomInput }): any {
    return (property: string) => roomInput[property]
  },
  [RoomUiGetter.isEditingInProgress]({ roomInput}): boolean {
    return !!roomInput.id
  },
  [RoomUiGetter.isInteracted] ({ interacted }): boolean {
    return interacted
  },
}