import { AlarmLogUiMutation, AlarmLogUiState } from '@/store/modules/alarmLogUi/types'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<AlarmLogUiState> = {
  [AlarmLogUiMutation.setInteracted](state, payload: boolean) {
    state.interacted = payload
  },
  [AlarmLogUiMutation.setSelectedPlcId](state, payload: string) {
    state.selectedPlcId = payload
  },
}