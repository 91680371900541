<template>
  <popover :header="header" class="description-popover">
    <div
      class="text"
      v-html="content">
    </div>
  </popover>
</template>

<script lang="ts">
import Vue from 'vue'
import Popover from './Popover.vue'

export default Vue.extend({
  name: 'DescriptionPopover',
  components: {
      Popover,
  },
  props: {
    /**
     * the popover header
     */
    header: {
      type: String,
      default: '',
    },
    /** 
     * the popover content
     */
    content: {
      type: String,
      default: '',
    },
  },
  methods: {
  },
})
</script>

<style lang="css">
.description-popover .popup-opener {
    text-align: right !important;
}
</style>

<docs>
A popover, supports html in text

Usage example
```jsx
<v-app style="height: 32px; background:transparent;">
  <description-popover
    header="The popover title"
    content="The <b>popover</b> content"
  />
</v-app>
```
</docs>
