import { AlarmLogUiAction, AlarmLogUiMutation, AlarmLogUiState } from '@/store/modules/alarmLogUi/types'
import { RootState } from '@/store/types'
import { LoadCustomPlcAlarmsPayload } from '@ecocoach/domain-store-modules/src/alarm/models'
import { AlarmAction } from '@ecocoach/domain-store-modules/src/alarm/types'
import { PlcOperationAction } from '@ecocoach/domain-store-modules/src/plcOperation/types'
import AppDataStorageService from '@ecocoach/domain-store-modules/src/services/appdatastorage.service'
import { ActionTree } from 'vuex'
import { AppAction } from '../app/types'
import { ParametrizationUiMutation } from '../parametrizationUi/types'
import { WizardAction, WizardGetter, WizardMutation } from '../wizard/types'
import { AlarmsTableFilterSettings, AlarmTableFilterSettingsStorageKey, AlarmViewModel } from './models'
import { AlarmDefinitionAction } from '@ecocoach/domain-store-modules/src/alarmDefinition/types'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'

export const actions: ActionTree<AlarmLogUiState, RootState> = {
  async [AlarmLogUiAction.openAlarmLogForPlc] ({ commit, dispatch, rootState }, plcId: string): Promise<void> {
    try {
      commit(AlarmLogUiMutation.setInteracted, true)
      const projectId = rootState.plc.plcs.find(p => p.id === plcId)?.projectId
      const language = rootState.app.selectedLanguage
      await Promise.all([
        dispatch(`plcOperation/${PlcOperationAction.loadConfigurationByPlcId}`, { plcId, language }, { root:true }),
        dispatch(`alarmDefinition/${AlarmDefinitionAction.loadAlarmDefinitions}`, { plcId, language }, { root: true }),
        dispatch(`alarm/${AlarmAction.loadCustomPlcAlarmsForPlc}`, { plcId, projectId } as LoadCustomPlcAlarmsPayload, { root: true }),
      ])
      commit(AlarmLogUiMutation.setSelectedPlcId, plcId)
    } finally {
      commit(AlarmLogUiMutation.setInteracted, false)
    }
  },
  async [AlarmLogUiAction.closeAlarmLog] ({ commit }): Promise<void> {
    commit(AlarmLogUiMutation.setSelectedPlcId, '')
  },
  async [AlarmLogUiAction.loadFilterSettings] ({ state }): Promise<void> {
    const loadedSettings = await AppDataStorageService.getObject<AlarmsTableFilterSettings>(AlarmTableFilterSettingsStorageKey)
    state.filterSettings.states = loadedSettings?.states ?? []
    state.filterSettings.deviceName = loadedSettings?.deviceName ?? ''
    state.filterSettings.name = loadedSettings?.name ?? ''
    state.filterSettings.message = loadedSettings?.message ?? ''
    state.filterSettings.openedDate = loadedSettings?.openedDate ?? ''
    state.filterSettings.closedDate = loadedSettings?.closedDate ?? ''
  },
  async [AlarmLogUiAction.updateFilterSettings] ({ state }, payload: { property: string, value: any }): Promise<void> {
    state.filterSettings[payload.property] = payload.value
    await AppDataStorageService.setObject(AlarmTableFilterSettingsStorageKey, state.filterSettings)
  },
  async [AlarmLogUiAction.navigateToParametrization] ({ commit, dispatch, rootState, rootGetters },
    payload: { alarm: AlarmViewModel, router }): Promise<void> {
    try {
      commit(AlarmLogUiMutation.setInteracted, true)

      // select plc / active configuration
      await dispatch(`app/${AppAction.selectPlc}`, payload.alarm.plcId, { root: true })
      await dispatch(`app/${AppAction.loadActiveRelease}`, payload.alarm.plcId, { root: true })

      // check if device exists
      const device = rootState.plcOperation.devicesLookup.get(payload.alarm.deviceId)
      if (!device) {
        commit(`wizard/${WizardMutation.setToastContent}`,  rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('error.not.go.to.device'), {root:true})
        commit(`wizard/${WizardMutation.showToast}`, undefined, {root:true})
        return
      }

      // close dialog and go to step
      await dispatch(AlarmLogUiAction.closeAlarmLog)
      const step = rootGetters[`wizard/${WizardGetter.wizardSteps}`].find(s => s.name === 'configureDevices')
      await dispatch(`wizard/${WizardAction.goToStep}`, {step, router: payload.router}, { root: true })

      // select room and device
      commit(`parametrizationUi/${ParametrizationUiMutation.setSelectedRoomId}`, device.roomId, { root:true })
      commit(`parametrizationUi/${ParametrizationUiMutation.setSelectedDeviceId}`, device.id, { root:true })

    } finally {
      commit(AlarmLogUiMutation.setInteracted, false)
    }
  },
}
