import { ActionTree } from 'vuex'
import CacheService, { CacheOptions } from '../services/cache.service'
import api from './api'
import { loadResourcesFromApi, resourceTypeForCategory } from './helpers'
import { CreateResourceInput, ResourceCategory, ResourceModel } from './models'
import { ResourceAction, ResourceMutation, ResourceState } from './types'

export const actions: ActionTree<ResourceState, {}> = {
  async [ResourceAction.loadResources] ({ commit }, payload: { language: string, categories: ResourceCategory[] }): Promise<void> {
    await Promise.all(payload.categories.map(async category => {
      await CacheService.load({
        action: ResourceAction.loadResources,
        parameters: [category, payload.language],
        load: () => loadResourcesFromApi(category, payload.language),
        commit: data => commit(ResourceMutation.upsertResources, { category, resources: data }),
      } as CacheOptions<ResourceModel[]>)
    }))
  },
  async [ResourceAction.createResource] ({ commit }, payload: CreateResourceInput): Promise<void> {
    const category = payload.category
    const resourceType = resourceTypeForCategory(category)
    const resource: ResourceModel = {
      id: payload.id, 
      value: payload.defaultValue ?? payload.id,
    }
    await api.createResource(resource.id, category, resourceType, payload.defaultValue)
    commit(ResourceMutation.upsertResources, { category, resources: [resource] })
  },
}
