<template>
  <v-flex class="controlComponent">
    <eco-action-control v-if="control.type === controlType.Action"
      :id="control.id"
      :context="context"
      :attributes="control.attributes"
      :readonly="control.readonly || false"
    />
    <eco-actions-control v-else-if="control.type === controlType.Actions"
      :id="control.id"
      :context="context"
      :attributes="control.attributes"
      :readonly="control.readonly || false"
    />
    <eco-toggle-control v-else-if="control.type === controlType.Toggle"
      :id="control.id"
      :context="context"
      :attributes="control.attributes"
      :state="control.state"
      :pending-state="control.pendingState"
      :readonly="control.readonly || false"
    />
    <eco-consumption-process-toggle-control v-else-if="control.type === controlType.ConsumptionProcessToggle"
      :id="control.id"
      :context="context"
      :attributes="control.attributes"
      :state="control.state"
      :pending-state="control.pendingState"
      :readonly="control.readonly || false"
    />
    <eco-toggles-control v-else-if="control.type === controlType.Toggles"
      :id="control.id"
      :context="context"
      :attributes="control.attributes"
      :state="control.state"
      :pending-state="control.pendingState"
      :readonly="control.readonly || false"
    />
    <eco-boolean-display-control v-else-if="control.type === controlType.BooleanDisplay"
      :id="control.id"
      :context="context"
      :attributes="control.attributes"
      :state="control.state"
      :readonly="control.readonly || false"
    />
    <eco-numeric-display-control v-else-if="control.type === controlType.NumericDisplay"
      :id="control.id"
      :context="context"
      :attributes="control.attributes"
      :state="control.state"
      :readonly="control.readonly || false"
    />
    <eco-enum-display-control v-else-if="control.type === controlType.EnumDisplay"
      :id="control.id"
      :context="context"
      :attributes="control.attributes"
      :state="control.state"
      :readonly="control.readonly || false"
    />
    <eco-time-display-control v-else-if="control.type === controlType.TimeDisplay"
      :id="control.id"
      :context="context"
      :attributes="control.attributes"
      :state="control.state"
      :readonly="control.readonly || false"
    />
    <eco-numeric-input-control v-else-if="control.type === controlType.NumericInput"
      :id="control.id"
      :context="context"
      :attributes="control.attributes"
      :state="control.state"
      :pending-state="control.pendingState"
      :readonly="control.readonly || false"
    />
    <eco-text-display-control v-else-if="control.type === controlType.TextDisplay"
      :id="control.id"
      :context="context"
      :attributes="control.attributes"
      :state="control.state"
      :readonly="control.readonly || false"
    />
    <eco-text-input-control v-else-if="control.type === controlType.TextInput"
      :id="control.id"
      :context="context"
      :attributes="control.attributes"
      :state="control.state"
      :pending-state="control.pendingState"
      :readonly="control.readonly || false"
    />
    <eco-enum-input-control v-else-if="control.type === controlType.EnumInput"
      :id="control.id"
      :context="context"
      :attributes="control.attributes"
      :state="control.state"
      :pending-state="control.pendingState"
      :readonly="control.readonly || false"
    />
    <eco-links-control v-else-if="control.type === controlType.Links"
      :id="control.id"
      :context="context"
      :attributes="control.attributes"
      :state="control.state"
      :pending-state="control.pendingState"
      :readonly="control.readonly || false"
    />
    <eco-device-messages-control v-else-if="control.type === controlType.DeviceMessages"
      :id="control.id"
      :context="context"
      :attributes="control.attributes"
      :state="control.state"
      :readonly="control.readonly || false"
    />
    <eco-state-of-charge-configuration-control v-else-if="control.type === controlType.StateOfChargeConfiguration"
      :id="control.id"
      :context="context"
      :attributes="control.attributes"
      :state="control.state"
      :pending-state="control.pendingState"
      :readonly="control.readonly || false"
    />
    <eco-array-container-control v-else-if="control.type === controlType.ArrayContainer"
      :context="context"
      :attributes="control.attributes"
      :control="control"
      :potential-descendent-controls="potentialDescendentControls"
    />
  </v-flex>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ControlContextV2, ControlTypeV2, ControlViewModelV2 } from './v2/models'

@Component({
  components: {
    // avoid circular references in recursive components
    EcoActionControl: () => import('./v2/EcoActionControl.vue'),
    EcoActionsControl: () => import('./v2/EcoActionsControl.vue'),
    EcoArrayContainerControl: () => import('./v2/EcoArrayContainerControl.vue'),
    EcoBooleanDisplayControl: () => import('./v2/EcoBooleanDisplayControl.vue'),
    EcoConsumptionProcessToggleControl: () => import('./v2/EcoConsumptionProcessToggleControl.vue'),
    EcoDeviceMessagesControl: () => import('./v2/EcoDeviceMessagesControl.vue'),
    EcoEnumDisplayControl: () => import('./v2/EcoEnumDisplayControl.vue'),
    EcoEnumInputControl: () => import('./v2/EcoEnumInputControl.vue'),
    EcoLinksControl: () => import('./v2/EcoLinksControl.vue'),
    EcoNumericDisplayControl: () => import('./v2/EcoNumericDisplayControl.vue'),
    EcoNumericInputControl: () => import('./v2/EcoNumericInputControl.vue'),
    EcoStateOfChargeConfigurationControl: () => import('./v2/EcoStateOfChargeConfigurationControl.vue'),
    EcoTextDisplayControl: () => import('./v2/EcoTextDisplayControl.vue'),
    EcoTextInputControl: () => import('./v2/EcoTextInputControl.vue'),
    EcoTimeDisplayControl: () => import('./v2/EcoTimeDisplayControl.vue'),
    EcoToggleControl: () => import('./v2/EcoToggleControl.vue'),
    EcoTogglesControl: () => import('./v2/EcoTogglesControl.vue'),
  },
})
export default class ControlComponentV2 extends Vue {
  @Prop() public context: ControlContextV2
  @Prop() public control: ControlViewModelV2
  @Prop() public potentialDescendentControls: ControlViewModelV2[]

  public controlType = ControlTypeV2
}
</script>

<style lang="scss" scoped>
</style>