<template>
  <v-flex class="color-select">
    <v-select
      :items="options"
      :item-value="optionValue"
      :value="selectedValue"
      :placeholder="placeholdertext"
      :disabled="disabled"
      @change="emitSelected($event)"
      solo
      :menu-props="{ contentClass: 'color-select-menu' }"
    >
    <template slot="selection" slot-scope="data">
        <div class="color-display" :style="{background: data.item[optionColor]}">
          <!-- shows the primaryColor -->
        </div>
      <div 
        class="color-select-label"
        :class="{ disabled: disabled }"
      > 
        {{ data.item[optionLabel] }}
      </div>
     </template>
      <template slot="item" slot-scope="data">
        <div class="color-display" :style="{background: data.item[optionColor]}">
          <!-- shows the primaryColor -->
        </div>
        <v-list-tile-content>
          <v-list-tile-title class="color-select-label"> 
            {{ data.item[optionLabel] }}
          </v-list-tile-title>
        </v-list-tile-content>
      </template>
    </v-select>
  </v-flex>
</template>


<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ColorSelect',
  props: {
    /**
     * array of strings or objects to be used by the drop down menu
     */
    options: {
      type: Array,
      default: [],
    },
    /**
     * the selected value
     */
    selectedValue: {
      type: String,
      default: '',
    },
    /**
     * the property name of the value string inside the options object
     */
    optionValue: {
      type: String,
      default: '',
    },
    /**
     * property with the hexadezimal color code to display
     */
    optionColor: {
      type: String,
      default: '',
    },
    /**
     * property name of the displayed string inside the options object
     */
    optionLabel: {
      type: String,
      default: '',
    },
    /**
     * disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * optional placeholder text, displayed in case nothing is selected
     */
    placeholdertext: {
      type: String,
      default: '',
    },
  },
  methods: {
    emitSelected(value: string): void {
      /**
       * Selected event.
       *
       * @event selected
       * @type {string}
       */
      this.$emit('selected', value)
    },
  },
})
</script>
<style lang="scss">
.color-display{
  min-height: 30px;
  min-width: 30px;
}
.color-select {
  margin: 0px;
  padding: 10px;
}
.color-select > .v-text-field--solo>.v-input__control>.v-input__slot {
  background-color: transparent !important;
  box-shadow: none !important;
}
.color-select > .v-input > .v-input__control > .v-text-field__details {
  display: none;
}
.color-select > .v-select .v-select__selections {
  border-width: $default-border-width;
  border-style: solid;
  border-color: $fore-color-primary;
  color: $fore-color-primary !important;
  font-family: $font-family-primary;
  &.disabled {
    color: $fore-color-disabled !important;
  }
}
.color-select .v-select__selection--disabled {
    color: $fore-color-disabled !important;
}
.color-select > .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
  color: $fore-color-primary;
  padding: 0px;
}
.color-select .v-input {
  font-size: inherit;
}
.color-select .v-icon {
  color: $fore-color-primary !important;
}
.color-select ::placeholder {
  color: $fore-color-disabled !important;
}
.color-select-label {
  padding-left: 5px;
  &.disabled {
    color: $fore-color-disabled !important;
  }
}
.color-select-color {
  color: $fore-color-primary;
  &.disabled {
    opacity: 0.4;
  }
}
.color-select-menu .v-list {
  background: $bg-primary !important;
}
.color-select-menu .v-list .v-list__tile--active.primary--text {
  color: white;
  font-weight: bold;
  font-size: inherit;
}
.color-select-menu .v-list .v-list__tile {
  color: $fore-color-primary !important;
  font-size: inherit;
}
</style>

<docs>
An drop down to select a color

Usage example
```js
new Vue({
  data: function() {
    return {
      options: [
        {id: "gradient-1", label: "green", primaryColor: "#078131"},
        {id: "gradient-10", label: "red", primaryColor:"#790320"}
      ],
      selectedValue: 'gradient-1',
    }
  },
  methods: {
    onSelected(value) {
      this.selectedValue = value
    }
  },
  template: `<v-app style="height: 48px;background:transparent">
    <color-select
      :options="options"
      :option-value="'id'"
      :option-label="'label'"
      :option-color="'primatyColor'"
      :selected-value="selectedValue"
      @selected="onSelected"/>
  </v-app>`
})
```

Disabled
```jsx
<v-app style="height: 48px;background:transparent">
  <color-select
    :options="[{id: 'id1', label: 'label1'}]"
    :option-value="'id'"
    :option-label="'label'"
    :option-color="'primaryColor'"
    selected-value="id1"
    disabled
  />
</v-app>
```

Placeholder text
```jsx
<v-app style="height: 48px;background:transparent">
  <color-select
    :options="[{id: '1', label:'label1'}]"
    :option-value="'id'"
    :option-label="'label'"
    :option-color="'primatyColor'"
    placeholdertext="Select something please!"
  />
</v-app>
```
</docs>
