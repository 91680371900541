<template>
  <modal-dialog
    :show="show"
    :header="'navigation.useragreements' | translate"
    :button1-text="'common.button.next' | translate"
    :button1-disabled="hasUnacceptedUserAgreements"
    @button1Pressed="onOk"
  >
    <v-layout column>
      <v-flex class="accept-message">
        {{'useragreements.accept.message' | translate }}
      </v-flex>
      <v-flex v-for="userAgreement in userAgreements" :key="userAgreement.type">
        <v-layout row align-center justify-content>
          <v-flex shrink>
            <checkbox-with-label
              :value="userAgreement.accepted"
              :text="userAgreement.name"
              :disabled="true"
            />
          </v-flex>
          <v-flex grow/>
          <v-flex shrink>
            <base-text-button
              @buttonClicked="showUserAgreement(userAgreement)"
            >
              {{ 'common.button.show' | translate }}
            </base-text-button>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <modal-dialog v-if="userAgreementToShow" 
      :show="userAgreementToShow"
      :header="userAgreementToShow.name"
      :button1-text="'common.button.close' | translate"
      @button1Pressed="closeUserAgreement"
      :button2-text="'common.button.accept' | translate"
      @button2Pressed="onAcceptUserAgreementToShow"
      :button2-disabled="isInteracted || userAgreementToShow.accepted"
    >
      <div 
        class="user-agreement-content"
        v-html="userAgreementToShow.content">
      </div>
    </modal-dialog>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { UserAgreementViewModel } from '../../store/modules/app/models'

const App = namespace('app')

@Component
export default class AcceptUserAgreementsDialog extends Vue {
  @Prop({type: Boolean}) public show: boolean
  @App.Getter public isInteracted: boolean
  @App.Getter public userAgreements: UserAgreementViewModel[]
  @App.Action public acceptUserAgreement: (type: string) => Promise<void>

  public userAgreementToShow: UserAgreementViewModel | null = null

  @Emit('closed') 
  public onOk () {
    return
  }

  private get hasUnacceptedUserAgreements(): boolean {
    return this.userAgreements.some((_) => !_.accepted)
  }

  public showUserAgreement(userAgreement: UserAgreementViewModel) {
    this.userAgreementToShow = userAgreement
  }

  public async onAcceptUserAgreementToShow() {
    await this.acceptUserAgreement(this.userAgreementToShow!.type)
    this.closeUserAgreement()
  }

  public closeUserAgreement() {
    this.userAgreementToShow = null
  }
}
</script>
<style lang="scss" scoped>
.accept-message {
  padding: 0px 0px 15px 15px;
}
.v-btn {
  border: 1px solid white;
  margin-right: 0px;
  margin-left: 16px;
}
.user-agreement-content { 
  text-align: left;
  word-wrap: break-word;
  padding: 15px;
  height: 400px;
  overflow-y: scroll;
}
</style>

<style lang="css">
.user-agreement-content section.allgemeine ol {
  counter-reset: li;
}
.user-agreement-content ol, .user-agreement-content ul {
  list-style: none;
  padding-left: 0px;
}
.user-agreement-content p {
  margin-bottom: 0px;
}
.user-agreement-content section.allgemeine .top-list>li:before {
  content: counter(li) ". ";
}
.user-agreement-content section.allgemeine li:before {
  counter-increment: li;
  content: counters(li,".") " ";
}
.user-agreement-content section.allgemeine .top-list>li {
  margin-top: 15px;
}
.user-agreement-content section.allgemeine .top-list>li span.clause-title {
  font-weight: 700;
}
.user-agreement-content section.datenschutz>ol>li {
  font-weight: 700;
  margin-top: 15px;
}
.user-agreement-content section.datenschutz>ol>li>div {
  font-weight: 300;
}
.user-agreement-content td {
  vertical-align: baseline;
}
</style>