<template>
    <!-- see decision-log when to show icon / dialog etc... -->
    
    <div class="side-alarms" v-if="isDisturbed || showPlcStateAlarm($router.currentRoute.fullPath)">
        <div class="alarm-navi" @click.stop="toggleDialog()">
            <v-icon outline>notifications_active</v-icon>      
        </div>

        <modal-dialog 
            :show="isDialogOpen"
            :header="$options.filters.translate('alarm.service.disturbed')" 
            button1Text="OK"
            @button1Pressed="closeAlarmDialog"
            width=600
        >
            <alarm-content />
        </modal-dialog>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AlarmContent from './AlarmContent.vue'

const AlarmUi = namespace('alarmUi')
const Wizard = namespace('wizard')

@Component({
  components: {
    AlarmContent,
  },
})
export default class SideAlarms extends Vue {
    @AlarmUi.Action public openAlarmDialog
    @AlarmUi.Action public closeAlarmDialog
    @AlarmUi.Getter public isDisturbed
    @AlarmUi.Getter public isDialogOpen
    @AlarmUi.Getter public showPlcStateAlarm
    @AlarmUi.Getter public isPlcOffline
    @AlarmUi.Getter public isAlarmTriggered
    @Wizard.Getter public getActiveStep

    public mounted() {
      if(this.isDisturbed) {
        this.openAlarmDialog()
      }
    }

    @Watch('isPlcOffline')
    public isPlcOfflineChanged(value: boolean) {
      if (value) {
        const activeStepName = this.getActiveStep.name
        if(activeStepName === 'loadProgram' || activeStepName === 'configureDevices') {
          this.openAlarmDialog()
        }
      }
    }

    @Watch('isAlarmTriggered')
    public triggerChanged(value) {
      if(value) { // open dialog with trigger from false to true
        this.openAlarmDialog()
      }
    }

    public toggleDialog () {
      if(this.isDialogOpen) {
        this.closeAlarmDialog()
      } else {
        this.openAlarmDialog()
      }
    }
}
</script>

<style lang="scss" scoped>
.side-alarms {
    width: 64px;
    padding-left: 32px;
    margin-top: 30px;
    cursor: pointer;
    .alarm-navi {
        width: 100%;
        text-align: center;
    }
}

</style>