import { AppDisplayLevels, ConfigurationToolCategories, ControlKinds, ControlTypes, EnergyDisplayLevels } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { ControlDefinitionModelV2, FunctionBlockDefinitionV2, UploadNewDefinitionsInput, UploadNewDefinitionsInputV2, UploadNewDefinitionsOutput, UploadNewDefinitionsOutputV2 } from '@ecocoach/domain-store-modules/src/systemConfiguration/models'
import { DropdownOption } from '@ecocoach/shared-components/src'

export interface FunctionDefinitionViewModel extends FunctionBlockDefinitionV2 {
  predecessorFunctionBlockIds: string[]
  targetInstances: Array<{ // v2
    endpointPath: string
    sourceInterfaces: Array<{
      sourceInterfaceId: string
      internalName: string,
    }>,
  }>
  messagesEnumValues: Array<{
    name: string
    value: string,
  }>
}

export const DEFAULT_POLLING_INTERVAL_MS = 5000
export const DEFAULT_MEASURING_POINT_READ_INTERVAL_S = 900

export enum EndpointPropertyName {
  ActivateEndpoint = 'activateEndpoint',
  AdoptColorEndpoint = 'adoptColorEndpoint',
  Button1Endpoint = 'button1Endpoint',
  Button2Endpoint = 'button2Endpoint',
  ReadEndpoint = 'readEndpoint',
  RgbArrayReadEndpoint = 'rgbArrayReadEndpoint',
  RgbArrayWriteEndpoint = 'rgbArrayWriteEndpoint',
  SwitchOffEndpoint = 'switchOffEndpoint',
  SwitchOffEndpoint2 = 'switchOffEndpoint2',
  SwitchOnEndpoint = 'switchOnEndpoint',
  SwitchOnEndpoint2 = 'switchOnEndpoint2',
  SwitchOutputEndpoint = 'switchOutputEndpoint',
  SwitchOutputEndpoint2 = 'switchOutputEndpoint2',
  WriteEndpoint = 'writeEndpoint',
}

export enum EndpointIsMethodPropertyName {
  ActivateEndpointIsMethod = 'activateEndpointIsMethod',
  Button1IsMethod = 'button1IsMethod',
  Button2IsMethod = 'button2IsMethod',
  OffEndpointIsMethod = 'offEndpointIsMethod',
  OffEndpoint2IsMethod = 'offEndpoint2IsMethod',
  OnEndpointIsMethod = 'onEndpointIsMethod',
  OnEndpoint2IsMethod = 'onEndpoint2IsMethod',
}

export interface IconDropdownOption extends DropdownOption {
  icon: string
}

type EndpointProperties = { [P in EndpointPropertyName]: string }
type EndpointIsMethodProperties = { [P in EndpointIsMethodPropertyName]: boolean | undefined }

export interface ControlDefinitionViewModel extends EndpointProperties, EndpointIsMethodProperties {
  id: string
  kind: ControlKinds
  type: ControlTypes
  obsolete: boolean
  appDisplayLevel: AppDisplayLevels
  configurationToolCategory: ConfigurationToolCategories
  energyDisplayLevel: EnergyDisplayLevels
  energyDisplaySummary: boolean
  minValue: number
  maxValue: number
  interval: number
  colorGradient: string
  unit: string
  nameResourceId: string
  descriptionResourceId: string
  softwareMappingGlobalVariableId: string
  dropdownValueList: Array< { value: number, resourceId: string } >
  isMeterDataAggregationEnabled: boolean
  isDynamicMeterControl: boolean
  isMeterActivationControl: boolean
  toSubscribe: boolean
  pollInterval: number
  isExisting: boolean
}

export interface ControlDefinitionViewModelV2 extends ControlDefinitionModelV2 {
  obsolete: boolean
  isExisting: boolean
}

export enum ControlCommandType {
  InvokeMethodEndpoint = 'InvokeMethodEndpoint',
  SetBoolEndpoint = 'SetBoolEndpoint',
  SetNumericEndpoint = 'SetNumericEndpoint',
  SetStringEndpoint = 'SetStringEndpoint',
  SetLinkSources = 'SetLinkSources',
  SetConsumptionProcess = 'SetConsumptionProcess',
}

export interface UploadDefinitionResult {
  dryRun: boolean,
  success: boolean,
  uploadInput: UploadNewDefinitionsInput | UploadNewDefinitionsInputV2,
  uploadOutput: UploadNewDefinitionsOutput | UploadNewDefinitionsOutputV2,
}

export interface ControlDefinitionDefaultValueInput {
  controlDefinitionId: string
  command: string
  defaultValue: any
}

export const ArchitectureTypeStorageKey = 'architectureType'
export const SourceLanguageStorageKey = 'sourceLanguage'
export const PreserveProjectFilesInUranusStorageKey = 'preserveProjectFilesInUranus'
