export interface AuditLogEntryModel {
  id: string
  userIdentifier: string
  actionType: AuditActionType
  timeStampInUtc: string
  userAction: AuditActionControlCommandExecution
  plcId: string
  projectId: string
}

export enum AuditActionType {
  ControlCommandExecution = 'ControlCommandExecution',
}

export interface AuditActionControlCommandExecution {
  roomName: string
  deviceName: string
  controlNameResourceId: string
  resourceId: string
  controlCommand: string
  parameter: any
}
