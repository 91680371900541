<template>
    <v-flex xs12 row wrap align-center class="string-filter-component">
        <input
            type="text"
            class="base-text-box"
            :class="{ disabled: disabled }"
            v-model="currentString"
            :disabled="disabled"
            :placeholder="placeholder"
            @input="emitStringChanged"
            @keyup.esc.exact="resetCurrentString">
        <div class="reset-string-button">
            <icon-button 
                @buttonClicked="resetCurrentString"
                type="materialIcon"
                materialIconName="clear"
                :height="25"
                :width="25"
                :enabled="!disabled && currentString !== ''"/>
        </div>
    </v-flex>
</template>
<script lang="ts">
import Vue from 'vue'

import IconButton from './IconButton.vue'

export default Vue.extend({
  name: 'StringFilterComponent',
  components: {
    IconButton,
  },
  props: {
    /** 
     * initial value of the input
     */
    value: {
      type: String,
      default: '',
    },
    /** 
     * disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** 
     * optional placeholder text, displayed in case nothing is input
     */
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentString: this.value,
    }
  },
  methods: {
    emitStringChanged() {
      /**
       * changed event
       *
       * @event changed
       * @type {string}
       */
      this.$emit('changed', this.currentString)
    },
    /**
     * resets the textbox
     */
    resetCurrentString() {
      if (this.currentString !== '') {
        this.currentString = ''
        this.emitStringChanged()
      }
    },
  },
})
</script>
<style lang="scss">
.string-filter-component {
    margin: $margin-default;
    display: flex;
    flex-direction: row;
    margin: 0px;
    padding: 10px 10px;
    .base-text-box {
        flex-grow: 1;
    }
    .reset-string-button {
        width: 30px;
    }
    input {
        font-family: $font-family-primary;
        height: 100%;
        width: 100%;
        border-width: $default-border-width;
        border-style: solid;
        border-color: $fore-color-primary;
        padding: $default-padding;
        color: $fore-color-primary;
        background: transparent;
        outline: none;
        &.disabled {
          color: $fore-color-disabled;
        }
    }
    ::placeholder {
      color: $fore-color-disabled;
    }
}
</style>

<docs>
A text filter input control

Usage example
```js
new Vue({
  methods: {
    onChange(text) {
      console.log(text)
    }
  },
  template: `<string-filter-component 
    placeholder="Filter..."
    @changed="onChange"/>`
})
```

Initial value
```jsx
<string-filter-component value="foo" />
```

Disabled
```jsx
<string-filter-component value="read-only" :disabled="true" />
```
</docs>