import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { state } from './state'
import { ResourceAction, ResourceGetter, ResourceMutation, ResourceState } from './types'

import { Module } from 'vuex'

const namespaced: boolean = true
export const resource: Module<ResourceState,any> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action:  ResourceAction,
  mutation: ResourceMutation,
  getter: ResourceGetter,
}

export const domain = 'resource'

export const vuexModule = {
  [domain]: resource,
}