import { ConsumptionUiState } from '@/store/modules/consumptionUi/types'
import { ConsumptionReportAggregationInterval, MinergieVisualizationInterval } from '@ecocoach/domain-store-modules/src/consumption/models'

export const state: ConsumptionUiState = {
  interacted: false,
  selectedAccountId: '',
  accountFilterString: '',
  meterFilterString: '',
  userFilterString: '',
  selectedReportAggregationInterval: ConsumptionReportAggregationInterval.FIFTEEN_MINUTES,
  selectedReportConsumptionAccountId: '',
  selectedReportFromDate: '',
  selectedReportToDate: '',
  selectedVisualizationInterval: MinergieVisualizationInterval.MONTHLY,
  selectedVisualizationConsumptionAccountId: '',
  navigationMinimized: true,
}
