<template>
  <div class="create-new-release">
    <div class="overview">
      <template-option-selector :active="templateOptionActive" @selected="useTemplateOption" />
      <new-option-object-oriented :active="newOptionObjectOrientedActive" @selected="useNewOptionObjectOriented"/>
      <new-option-classic :active="newOptionClassicActive" @selected="useNewOptionClassic" />
    </div>
    <template-option-content v-if="templateOptionActive"/>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import NewOptionClassic from './NewOptionClassic.vue'
import NewOptionObjectOriented from './NewOptionObjectOriented.vue'
import TemplateOptionContent from './TemplateOptionContent.vue'
import TemplateOptionSelector from './TemplateOptionSelector.vue'

@Component({
  components: {
    NewOptionClassic,
    NewOptionObjectOriented,
    TemplateOptionContent,
    TemplateOptionSelector,
  },
})
export default class CreateNewRelease extends Vue {
  public templateOptionActive = true
  public newOptionClassicActive = false
  public newOptionObjectOrientedActive = false

  public async mounted() {
    this.useTemplateOption()
  }

  public useTemplateOption() {
    this.templateOptionActive = true
    this.newOptionClassicActive = false
    this.newOptionObjectOrientedActive = false
  }

  public useNewOptionClassic() {
    this.templateOptionActive = false
    this.newOptionClassicActive = true
    this.newOptionObjectOrientedActive = false
  }

  public useNewOptionObjectOriented() {
    this.templateOptionActive = false
    this.newOptionClassicActive = false
    this.newOptionObjectOrientedActive = true
  }
}
</script>
<style lang="scss" scoped>
.create-new-release {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 560px;
}
.overview {
  flex: 0;
  min-height: 290px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

</style>