import { FunctionBlockCategoryModel, FunctionBlockModel, FunctionBlockPackageInfoModel, GlobalVariableModel, SolutionHardwareDefinitionCategoryModel, SolutionHardwareDefinitionModel } from './models'

export enum SystemConfigurationAction {
  loadSystemConfiguration = 'loadSystemConfiguration',
  loadGlobalVariables = 'loadGlobalVariables',
  loadPackageInfo = 'loadPackageInfo',
  setFunctionBlockActive = 'setFunctionBlockActive',
  setFunctionBlockObsolete = 'setFunctionBlockObsolete',
}

export enum SystemConfigurationMutation {
  setFunctionBlocks = 'setFunctionBlocks',
  setFunctionBlockCategories = 'setFunctionBlockCategories',
  setSolutionHardwareDefinitions = 'setSolutionHardwareDefinitions',
  setSolutionHardwareDefinitionCategories = 'setSolutionHardwareDefinitionCategories',
  setGlobalVariables = 'setGlobalVariables',
  setPackageInfo = 'setPackageInfo',
  setFunctionBlockActive = 'setFunctionBlockActive',
  setFunctionBlockObsolete = 'setFunctionBlockObsolete',
  setFunctionBlockReleaseNotes = 'setFunctionBlockReleaseNotes',
}

export enum SystemConfigurationGetter {
  functionBlocks = 'functionBlocks',
  functionBlockById = 'functionBlockById',
  functionBlockCategories = 'functionBlockCategories',
  functionBlockCategoryById = 'functionBlockCategoryById',
  solutionHardwareDefinitions = 'solutionHardwareDefinitions',
  solutionHardwareDefinitionById = 'solutionHardwareDefinitionById',
  solutionHardwareDefinitionCategories = 'solutionHardwareDefinitionCategories',
  solutionHardwareDefinitionCategoryById = 'solutionHardwareDefinitionCategoryById',
  globalVariables = 'globalVariables',
  globalVariableById = 'globalVariableById',
}

export interface SystemConfigurationState {
  functionBlocks: FunctionBlockModel[],
  functionBlockCategories: FunctionBlockCategoryModel[],
  solutionHardwareDefinitions: SolutionHardwareDefinitionModel[],
  solutionHardwareDefinitionCategories: SolutionHardwareDefinitionCategoryModel[],
  globalVariables: GlobalVariableModel[],
  packageInfo: FunctionBlockPackageInfoModel[]
}
