export interface EndpointRecordingConfigurationModel {
  id: string
  qualifiedEndpointPath: string
  intervalInMilliseconds: number
  startDateTimeUtc: string
  endDateTimeUtc: string
  numEntries: number
}

export interface EndpointRecordingViewModel extends EndpointRecordingConfigurationModel {
  deviceName: string
  endpointDisplayName: string
  interval: string
  started: string
  ended: string
  stillRunning: boolean
}

export interface EndpointRecordingConfigurationOptions {
  intervalInMilliseconds: number
  startDateTimeUtc: string
  endDateTimeUtc: string
}

export interface GetLogsFromDbPayload {
  logLevel: LogLevel
  numEntries: number
}

export interface LoggerConfigurationsPayload {
  logConfigs: LoggerConfigurationModel[]
}

export interface LoggerConfigurationModel {
  logLevel: LogLevel
  logDestination: LogDestination
  logSource: string
  isActive?: boolean
}

export interface LogEntryModel {
  id: string
  logLevel: LogLevel
  logSource: string
  correlationId: string
  logMessage: string
  logTimestamp: string
  processId: number
  threadId: number
  threadName: number
  isBackgroundThread: boolean
  isThreadPoolThread: boolean
}

export interface LogEntriesPayload {
  logEntries: LogEntryModel[]
}

export enum LogLevel {
  None = 'None',
  Error = 'Error',
  Warning = 'Warning',
  Information = 'Information',
  Debug = 'Debug',
}

export enum LogDestination {
  Db = 'Db',
  Console = 'Console',
  Cloud = 'Cloud',
}

export interface EndpointRecordingDataInput {
  id?: string
  startDateTimeUtc?: string
  endDateTimeUtc?: string
}

export const logHubId = 'logHub'
