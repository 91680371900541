<template>
  <v-list-tile @click="handleClick" :disabled="disabled" class="grid-action-menu-item" :class="{ disabled: disabled}">
    <v-list-tile-title>{{ label }}</v-list-tile-title>
  </v-list-tile>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class MoreMenuItem extends Vue {
  @Prop({ default: ''}) public label: string
  @Prop({ default: false}) public disabled: boolean

  @Emit('click') 
  public emitClick() {
    return
  }

  public handleClick() {
    if (!this.disabled) {
      this.emitClick()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
