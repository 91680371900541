import ApiService from '@ecocoach/domain-store-modules/src/services/api.service'
import { EndpointRecordingConfigurationModel, EndpointRecordingConfigurationOptions } from '../models'

export default {
  async createPlcEndpointRecordingConfiguration(
    plcId: string, qualifiedEndpointPath: string, options: EndpointRecordingConfigurationOptions)
    : Promise<EndpointRecordingConfigurationModel[]> {
    const response = await ApiService.post(
      `${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/EndpointRecording/${plcId}/configuration/endpoint`, {
        qualifiedEndpointPath,
        ...options,
      })
    return response.data.value
  },
  async createDeviceEndpointRecordingConfiguration(
    plcId: string, deviceId: string, endpointPath: string, options: EndpointRecordingConfigurationOptions)
    : Promise<EndpointRecordingConfigurationModel[]> {
    const response = await ApiService.post(
      `${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/EndpointRecording/${plcId}/configuration/device/${deviceId}`, {
        endpointPath,
        ...options,
      })
    return response.data.value
  },
  async createControlEndpointsRecordingConfiguration(
    plcId: string, controlId: string, options: EndpointRecordingConfigurationOptions)
    : Promise<EndpointRecordingConfigurationModel[]> {
    const response = await ApiService.post(
      `${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/EndpointRecording/${plcId}/configuration/control/${controlId}`, {
        ...options,
      })
    return response.data.value
  },
  async deleteEndpointRecordingConfiguration(plcId: string, id: string): Promise<void> {
    await ApiService.delete(
      `${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/EndpointRecording/${plcId}/configuration/${id}`, {})
  },
  async deleteAllEndpointRecordingConfigurations(plcId: string): Promise<void> {
    await ApiService.delete(
      `${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/EndpointRecording/${plcId}/configuration`, {})
  },
  async readPlcEndpoint(plcId: string, qualifiedEndpointPath: string) {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/EndpointRecording/${plcId}/read/endpoint`, {
      qualifiedEndpointPath,
    })
  },
  async readDeviceEndpoint(plcId: string, deviceId: string, endpoint: string) {
    await ApiService.post(
      `${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/EndpointRecording/${plcId}/read/device/${deviceId}`, {
        endpoint,
      })
  },
  async readControlEndpoints(plcId: string, controlId: string) {
    await ApiService.post(
      `${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/EndpointRecording/${plcId}/read/control/${controlId}`, {})
  },
}
