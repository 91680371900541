<template>
  <v-data-table :headers="headers" :items="alarms" :pagination.sync="pagination" item-key="alarmInstanceId"
    class="alarm-table"
    :custom-filter="filteredAlarms"
    :search="''"
    :rows-per-page-items="[10]"
  >
    <template v-slot:headers="props">
      <tr class="table-header-row">
        <th v-for="header in props.headers" :key="header.value" 
          :class="[
            'column', 
            header.align === 'center' ? 'text-xs-center' : 'text-xs-left',
            header.sortable !== false ? 'sortable' : '',
            pagination.descending ? 'desc' : 'asc', 
            header.value === pagination.sortBy ? 'active' : ''
          ]"
          :style="{ 
            display: header.hidden === true ? 'none' : 'table-cell',
            width: header.width,
          }"
        >
          <div class="table-header" @click="changeSort(header)">
            {{ header.text }}
            <v-icon v-if="header.sortable !== false" small>arrow_upward</v-icon>
          </div>
          <table-filter-text-search v-if="header.type === 'textsearch'"
            :value="header.search"
            :placeholder="header.placeholder"
            @changed="header.changed"
          />
          <table-filter-multi-select v-if="header.type === 'multiselect'"
            :options="header.options"
            :values="header.selectedValues"
            :placeholder="header.placeholder"
            @selected="header.selected"
            @cleared="header.cleared"
          />
          <table-filter-date v-if="header.type === 'date'"
            :value="header.filter"
            :placeholder="header.placeholder"
            :language="header.language"
            @changed="header.changed"
          />
        </th>
      </tr>
    </template>
    <template v-slot:items="props">
      <tr :key="props.item.alarmInstanceId" @click="onClick(props.item)">
        <td>
          <alarm-table-status :alarm="props.item"/>
        </td>
        <td>{{ props.item.opened }}</td>
        <td>{{ props.item.closed }}</td>
        <td>{{ props.item.deviceName }}</td>
        <td>{{ props.item.displayName }}</td>
        <td>
          <alarm-table-value :alarm="props.item"/>
        </td>
        <td>{{ props.item.displayMessage }}</td>
      </tr>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import TableFilterDate from '../../../../ui/TableFilterDate.vue'
import TableFilterMultiSelect from '../../../../ui/TableFilterMultiSelect.vue'
import TableFilterTextSearch from '../../../../ui/TableFilterTextSearch.vue'

import AlarmTableStatus from './AlarmTableStatus.vue'
import AlarmTableValue from './AlarmTableValue.vue'

import { AlarmsTableFilterSettings, AlarmViewModel } from '../../../../../store/modules/alarmLogUi/models'

import { DropdownOption } from '../../../../../../../shared-components/src'

const App = namespace('app')
const Resource = namespace('resource')
const AlarmLogUi = namespace('alarmLogUi')

@Component({
  components: {
    AlarmTableStatus,
    AlarmTableValue,
    TableFilterDate,
    TableFilterMultiSelect,
    TableFilterTextSearch,
  },
})
export default class AlarmTable extends Vue {
  @AlarmLogUi.Getter public isInteracted: boolean
  @AlarmLogUi.Getter public filterSettings: AlarmsTableFilterSettings
  @AlarmLogUi.Getter public alarms: AlarmViewModel[]
  @AlarmLogUi.Getter public filteredAlarms: (alarms: AlarmViewModel[]) => AlarmViewModel[]
  @AlarmLogUi.Getter public alarmStatusOptions: DropdownOption
  @AlarmLogUi.Action public updateFilterSettings: (payload: { property: string, value: any }) => Promise<void>
  @AlarmLogUi.Action public navigateToParametrization: (payload: { alarm: AlarmViewModel, router }) => Promise<void>
  
  @App.Getter public selectedLanguage: string

  @Resource.Getter public dictionary

  public pagination = {
    sortBy: 'openedTimestamp',
    descending: true,
    rowsPerPage: 10,
  }

  public changeSort(column) {
    if (column.sortable === false) {
      return
    }
    if (this.pagination.sortBy === column.value) {
      this.pagination.descending = !this.pagination.descending
    } else {
      this.pagination.sortBy = column.value
      this.pagination.descending = false
    }
  }

  public get headers() {
    return [
      { 
        text: this.dictionary('alarm.status'),
        value: 'isOpen',
        type: 'multiselect',
        options: this.alarmStatusOptions,
        selectedValues: this.filterSettings.states,
        placeholder: this.dictionary('common.filter'),
        selected: (values: boolean[]) => this.updateFilterSettings({ 
          property: 'states',
          value: values,
        }),
        cleared: () => this.updateFilterSettings({ 
          property: 'states',
          value: [],
        }),
        width: '10%',
      },
      { 
        text: this.dictionary('alarm.opened'),
        value: 'openedTimestamp',
        type: 'date',
        filter: this.filterSettings.openedDate,
        placeholder: this.dictionary('common.filter'),
        changed: (filter: string) => this.updateFilterSettings({ 
          property: 'openedDate',
          value: filter,
        }),
        language: this.selectedLanguage,
        align: 'left',
        sortable: true,
        width: '20%',
      },
      { 
        text: this.dictionary('alarm.closed'),
        value: 'closedTimestamp',
        type: 'date',
        filter: this.filterSettings.closedDate,
        placeholder: this.dictionary('common.filter'),
        changed: (filter: string) => this.updateFilterSettings({ 
          property: 'closedDate',
          value: filter,
        }),
        language: this.selectedLanguage,
        align: 'left',
        sortable: true,
        width: '20%',
      },
      {
        text: this.dictionary('alarmdefinition.deviceName'),
        value: 'deviceName',
        type: 'textsearch',
        search: this.filterSettings.deviceName,
        placeholder: this.dictionary('common.search'),
        changed: (searchString: string) => this.updateFilterSettings({ 
          property: 'deviceName',
          value: searchString,
        }),
        width: '20%',
      }, 
      {
        text: this.dictionary('alarmdefinition.name'),
        value: 'displayName',
        type: 'textsearch',
        search: this.filterSettings.name,
        placeholder: this.dictionary('common.search'),
        changed: (searchString: string) => this.updateFilterSettings({ 
          property: 'name',
          value: searchString,
        }),
        width: '20%',
      },    
      { 
        text: this.dictionary('alarm.peakValue'),
        value: 'closed',
        align: 'left',
        sortable: false,
        width: '20%',
      },
      {
        text: this.dictionary('alarmdefinition.message'),
        value: 'displayMessage',
        type: 'textsearch',
        search: this.filterSettings.message,
        placeholder: this.dictionary('common.search'),
        changed: (searchString: string) => this.updateFilterSettings({ 
          property: 'message',
          value: searchString,
        }),
        width: '20%',
      },    
    ]
  }

  public onClick(alarm: AlarmViewModel){
    this.navigateToParametrization({
      alarm,
      router: this.$router,
    })
  }
}
</script>
<style lang="scss" scoped>
th, td {
  padding: 0px 15px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
thead tr {
  border-bottom: 1px solid white!important;
}
th:first-child, td:first-child {
  padding-left: 0px !important;
}
.table-header-row {
  vertical-align: top;
}
.table-header {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
tr.selected {
  background: #c2043e !important;
}
</style>
<style lang="css">
.alarm-table .theme--dark.v-table {
  background-color: transparent !important;
  table-layout: fixed;
  width: 100%;
}
.alarm-table .v-datatable__actions {
  background-color: transparent !important;
}
.alarm-table .autocomplete-input input{
  cursor: pointer !important;
}
</style>