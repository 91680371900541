<template>
  <div class="autoMappingItems">
    <auto-mapping-item
      v-for="autoMapping in autoMappings"
      :key="autoMapping.id"
      :auto-mapping-slot="autoMappingSlot"
      :auto-mapping="autoMapping"
      :is-mapped="true"
      :disabled="isAutoMappingStarted || isManualMappingStarted"
      @click="$emit('click', autoMapping)"
    />
  </div>
</template>
<script lang="ts">
import AutoMappingItem from '@/components/views/ecoSetupTool/steps/mapHardware/AutoMappingItem.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { AutoMappingModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

import { AutoMappingSlotModel } from '../../../../../../../eco-domain-store-modules/src/systemConfiguration/models'

const MappingUi = namespace('mappingUi')

@Component ({
  components: {
    AutoMappingItem,
  },
})
export default class AutoMappingMappedSlot extends Vue {
  @Prop() public autoMappingSlot: AutoMappingSlotModel
  @Prop() public autoMappings: AutoMappingModel[]
  @MappingUi.Getter public isAutoMappingStarted
  @MappingUi.Getter public isManualMappingStarted
}
</script>

<style lang="scss">
</style>