export interface ReleaseUiState {
  interacted: boolean
  releaseUpgradeConfirmationDialogVisible: boolean
  releasesLoaded: boolean
}

export enum ReleaseUiAction {
  setProperties = 'setProperties',
  createRelease = 'createRelease',
  importRelease = 'importRelease',
  copyRelease = 'copyRelease',
  copyReleaseTemplate = 'copyReleaseTemplate',
  deleteRelease = 'deleteRelease',
  upgradeRelease = 'upgradeRelease',
  loadReleaseDetails = 'loadReleaseDetails',
  downloadRelease = 'downloadRelease',
  exportRelease = 'exportRelease',
  clearReleaseUpgradeLogEntries = 'clearReleaseUpgradeLogEntries',
}

export enum ReleaseUiMutation {
  setInteracted = 'setInteracted',
  showReleaseUpgradeConfirmationDialog = 'showReleaseUpgradeConfirmationDialog',
  hideShowReleaseUpgradeConfirmationDialog = 'hideShowReleaseUpgradeConfirmationDialog',
  setReleasesLoaded = 'setReleasesLoaded', 
}

export enum ReleaseUiGetter {
  isInteracted = 'isInteracted',
  releaseById = 'releaseById',
  needsNewRelease = 'needsNewRelease',
  hasNewWithoutCompleted = 'hasNewWithoutCompleted',
  hasNewWithCompleted = 'hasNewWithCompleted',
  hasOnlyCompleted = 'hasOnlyCompleted',
  currentNewRelease = 'currentNewRelease',
  completedReleasesToShow = 'completedReleasesToShow',
  timeStampToDate = 'timeStampToDate',
  timeStampToDateWithTime = 'timeStampToDateWithTime',
  isCurrentlyDeployed = 'isCurrentlyDeployed',
  currentRelease = 'currentRelease',
  currentReleaseShouldBeUpgraded = 'currentReleaseShouldBeUpgraded',
  currentReleaseHasInTestDevices = 'currentReleaseHasInTestDevices',
  releaseUpgradeConfirmationDialogVisible = 'releaseUpgradeConfirmationDialogVisible',
  releaseUpgradeLogEntries = 'releaseUpgradeLogEntries',
}
