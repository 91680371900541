import { RootState } from '@/store/types'
import { DeviceModel } from '@ecocoach/domain-store-modules/src/plcConfiguration/models'
import { PlcConfigurationAction } from '@ecocoach/domain-store-modules/src/plcConfiguration/types'
import { ActionTree } from 'vuex'
import { ResourceGetter } from '../../../../../eco-domain-store-modules/src/resource/types'
import { DeviceViewModel } from './models'
import { DeviceUiAction, DeviceUiMutation, DeviceUiState } from './types'

export const actions: ActionTree<DeviceUiState, RootState> = {
  async [DeviceUiAction.createDevice] ({ commit, dispatch, rootState, rootGetters }, functionBlockDefinitionId: string): Promise<void> {
    try {
      commit(DeviceUiMutation.setInteracted, true)
      const functionBlock = rootState.systemConfiguration.functionBlocks.find(fb => fb.id === functionBlockDefinitionId)!
      const functionBlockCategory = rootState.systemConfiguration.functionBlockCategories.find(c => c.id === functionBlock.categoryId)!
      const input = {
        functionBlockDefinitionId,
        name: functionBlock.name || rootGetters[`resource/${ResourceGetter.dictionary}`](functionBlock.nameResourceId),
        icon: functionBlock.iconResourceId || functionBlockCategory.iconResourceId,
        roomId: rootState.roomUi.selectedRoomId,
      } as DeviceModel
      const language = rootState.app.selectedLanguage
      await dispatch(`plcConfiguration/${PlcConfigurationAction.createDevice}`, { input, language }, { root: true })
    } finally {
      commit(DeviceUiMutation.setInteracted, false)
    }
  },
  async [DeviceUiAction.saveDevice] ({ commit, dispatch, rootState }, device: DeviceViewModel): Promise<void> {
    try {
      commit(DeviceUiMutation.setInteracted, true)
      const storedDevice = rootState.plcConfiguration.devices.find(d => d.id === device.id)!
      const input = {
        ...storedDevice,
        name: device.name,
        icon: device.icon,
      } as DeviceModel
      await dispatch(`plcConfiguration/${PlcConfigurationAction.updateDevice}`, input, { root: true })
    } finally {
      commit(DeviceUiMutation.setInteracted, false)
    }
  },
  async [DeviceUiAction.deleteDevice] ({ commit, dispatch }, deviceId: string): Promise<void> {
    try {
      commit(DeviceUiMutation.setInteracted, true)
      await dispatch(`plcConfiguration/${PlcConfigurationAction.deleteDevice}`, deviceId, { root: true })
    } finally {
      commit(DeviceUiMutation.setInteracted, false)
    }
  },
  async [DeviceUiAction.sortDevices] ({ commit, dispatch }, deviceIds: string[]): Promise<void> {
    try {
      commit(DeviceUiMutation.setInteracted, true)
      await dispatch(`plcConfiguration/${PlcConfigurationAction.sortDevices}`, deviceIds, { root: true })
    } finally {
      commit(DeviceUiMutation.setInteracted, false)
    }
  },
}
