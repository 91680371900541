import { MutationTree } from 'vuex'
import { EnergyStatusChanged, EnergyStatusDevice, EnergyStatusItem } from './models'
import { EnergyStatusMutation, EnergyStatusState } from './types'

export const mutations: MutationTree<EnergyStatusState> = {
  [EnergyStatusMutation.setProjectId](state, payload: string) {
    state.projectId = payload
  },
  [EnergyStatusMutation.setProjectName](state, payload: string) {
    state.projectName = payload
  },
  [EnergyStatusMutation.setKioskMode](state, payload: boolean) {
    state.isKioskMode = payload
  },
  [EnergyStatusMutation.setEnergyStatusData](state, payload: EnergyStatusDevice[]) {
    state.energyStatusDevices = payload
  },
  [EnergyStatusMutation.setEnergyStatusValue](state, payload: EnergyStatusChanged) {
    const item = state.energyStatusDevices.find(d => d.items?.find(c => c.id === payload.id))
      ?.items?.find(i => i.id === payload.id)
    if (item) {
      item.value = payload.value ?? null
    }
  },
  [EnergyStatusMutation.setEnergyStatusItemsFilter](state, filter: (energyStatusItem: EnergyStatusItem) => boolean) {
    state.energyStatusItemsFilter = filter
  },
}
