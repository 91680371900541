import ApiService from '../../services/api.service'
import { Permission } from '../models'
import { permissionConverter } from '../permissionConverter'

export default {
  async setProjectAuthorization(projectId: string, userIdentifier: string, permissions: Permission[]): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().projectApi}/changeAuthorization`, {
      id: projectId,
      userIdentifier,
      role: permissionConverter.projectPermissionsToRoleString(permissions),
    })
  },
  async setConsumptionAuthorization(projectId: string, userIdentifier: string, permissions: Permission[]): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().consumptionCommandApi}/authorize`, {
      projectId,
      userIdentifier,
      permission: permissionConverter.consumptionPermissionsToRoleString(permissions),
    })
  },
  async setPlcAuthorization(plcId: string, userIdentifier: string, permissions: Permission[]): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().projectApi}/authorize/plc`, {
      plcId,
      userIdentifier,
      permission: permissionConverter.plcPermissionsToRoleString(permissions),
    })
  },
  async setDeviceAuthorizations(deviceIds: string[], userIdentifier: string, permissions: Permission[]): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/authorize/devices`, {
      deviceIds,
      userIdentifier,
      permission: permissionConverter.devicePermissionsToRoleString(permissions),
    })
  },
  async setAlarmDefinitionAuthorizations(alarmDefinitionIds: string[], userIdentifier: string, permissions: Permission[]): Promise<void> {
    await ApiService.post(`${ApiService.backendEnvironmentConfiguration().plcOperationCommandApi}/authorize/alarmDefinitions`, {
      alarmDefinitionIds,
      userIdentifier,
      permission: permissionConverter.alarmDefinitionPermissionsToRoleString(permissions),
    })
  },
}