declare let device: any
declare global {
    interface Window {
      cordova?: any,
    }
  }

// from https://0x123.com/iOS-Model-List/ (status: 2023-10-23)
const iPadMiniDeviceModels = new Set([
  'iPad2,5',
  'iPad2,6',
  'iPad2,7',
  'iPad4,4',
  'iPad4,5',
  'iPad4,6',
  'iPad4,7',
  'iPad4,8',
  'iPad4,9',
  'iPad5,1',
  'iPad5,2',
  'iPad11,1',
  'iPad11,2',
  'iPad14,1',
  'iPad14,2',
])

// model keys from https://0x123.com/iOS-Model-List/ (status: 2023-10-23)
// safe area info from https://useyourloaf.com/blog/iphone-15-screen-sizes/
const iPhoneModelsWithSaveArea = [
  'iPhone10,3',
  'iPhone11',
  'iPhone12',
  'iPhone13',
  'iPhone14',
  'iPhone15',
  'iPhone16',
]

const EnvironmentService = {
  get devicePlatform(): 'android' | 'ios' | '' {
    return window.cordova ? device.platform.toLowerCase() : ''   
  },
  get deviceModel(): string {
    return window.cordova ? device.model : ''   
  },
  get isRunningOnAndroid(): boolean {
    return this.devicePlatform === 'android'
  },
  get isRunningOnIos(): boolean {
    return this.devicePlatform === 'ios'
  },
  get isRunningOnWeb(): boolean {
    return this.devicePlatform === ''
  },
  get isRunningLocally() {
    return this.isRunningOnWeb && window.location.origin.includes('localhost')
  },
  get hasIosSafeArea() {
    return iPhoneModelsWithSaveArea.some(model => this.deviceModel.includes(model))
  },
  get isIpadMini() {
    return iPadMiniDeviceModels.has(this.deviceModel)
  },
  // 2-letter language code without country postfix
  get deviceLanguage(): string {
    return window.navigator.language && window.navigator.language.slice(0, 2) || ''
  }, 
  determineLanguage(languages: string[], storedLanguage: string): string {
    const fallbackLanguage = 'de'
    const deviceLanguage = EnvironmentService.deviceLanguage
    const desiredLanguage = storedLanguage || deviceLanguage
    const matchingSupportedLanguage = languages.find((l) => l.startsWith(desiredLanguage))
    const matchingSupportedLanguageCode = matchingSupportedLanguage && matchingSupportedLanguage || ''
    const appliedLanguage = matchingSupportedLanguageCode ? matchingSupportedLanguageCode : fallbackLanguage
    return appliedLanguage
  },
}

export default EnvironmentService

export { EnvironmentService }
