import { Module } from 'vuex'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'
import { ProjectAction, ProjectMutation, ProjectState } from './types'

const namespaced: boolean = true
export const project: Module<ProjectState,any> = {
  namespaced,
  state,
  actions,
  mutations,
}

export const types = {
  action:  ProjectAction,
  mutation: ProjectMutation,
}

export const domain = 'project'

export const vuexModule = {
  [domain]: project,
}