<template>
  <div class="edit-with-releases">
    <div class="overview">
      <editing-option :active="editOptionActive" @selected="useEditReleaseOption" />
      <old-option-selector :active="oldOptionActive" @selected="useOldReleaseOption" />
      <new-option-object-oriented :active="false" @selected="showWarning = true"/>
      <new-option-classic :active="false" @selected="showWarning = true" />
      <incomplete-release-warning-dialog :show="showWarning" @closed="showWarning = false" />
    </div>
    <old-option-content v-if="oldOptionActive" :prevent-copy="true" @copyPrevented="showWarning = true" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import EditingOption from './EditingOption.vue'
import IncompleteReleaseWarningDialog from './IncompleteReleaseWarningDialog.vue'
import NewOptionClassic from './NewOptionClassic.vue'
import NewOptionObjectOriented from './NewOptionObjectOriented.vue'
import OldOptionContent from './OldOptionContent.vue'
import OldOptionSelector from './OldOptionSelector.vue'

@Component({
  components: {
    EditingOption,
    IncompleteReleaseWarningDialog,
    NewOptionClassic,
    NewOptionObjectOriented,
    OldOptionContent,
    OldOptionSelector,
  },
})
export default class EditWithReleases extends Vue {
  public editOptionActive = true
  public oldOptionActive = false
  public showWarning = false

  public useEditReleaseOption() {
    this.editOptionActive = true
    this.oldOptionActive = false
  }

  public useOldReleaseOption() {
    this.editOptionActive = false
    this.oldOptionActive = true
  }
}
</script>
<style lang="scss" scoped>
.edit-with-releases {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 560px;
}
.overview {
  color: white;
  flex: 0;
  min-height: 290px;
  display: flex;
  flex-direction: row; 
  justify-content: center;
}
</style>