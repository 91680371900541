<template>
  <div class="consumption-meters">
    <div class="meter-head">
      <div class="meter-title">
        {{ 'oneclick.meter.name' | translate }}
      </div>
    </div>
    <div class="name-filter">
      <text-input
        :value="adminMeterFilterString"
        :placeholder="'oneclick.filter.name' | translate"
        @input="setMeterFilterString"
      />
    </div>
    <div :class="adminMeters.length > 0 ? 'meter-content' : ''">
      <div 
        v-for="meter in adminMeters" 
        :key="meter.meterId"
        class="meters">
        <div class="meter-description" :class="meter.disabled ? 'other-assigned' : ''">
          <div class="meter-name">
            {{ meter.name }}
          </div>
          <div class="assigned-account" v-if="meter.disabled">
            {{ meter.accountName }} 
          </div>
        </div>
        <div class="meter-selection">
          <icon-button v-if="!meter.selected"
            type="materialIcon"
            materialIconName="check_box_outline_blank"
            :width="25"
            :height="25"
            :enabled="!meter.disabled && !isInteracted"
            @buttonClicked="assignMeter(meter.meterId)"
          />
          <icon-button v-else
            type="materialIcon"
            materialIconName="check_box"
            :width="25"
            :height="25"
            :enabled="!meter.disabled && !isInteracted"
            @buttonClicked="unassignMeter(meter.meterId)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { MeterViewModel } from '../../../../store/modules/consumptionUi/models'

const ConsumptionUi = namespace ('consumptionUi')

@Component({
  components: {
  },
})
export default class ConsumptionMeters extends Vue {
  @ConsumptionUi.Getter public isInteracted: boolean
  @ConsumptionUi.Getter public adminMeters: MeterViewModel[]
  @ConsumptionUi.Getter public adminMeterFilterString: string
  @ConsumptionUi.Mutation public setMeterFilterString: (filter: string) => void
  @ConsumptionUi.Action public assignMeter: (meterId: string) => Promise<void>
  @ConsumptionUi.Action public unassignMeter: (meterId: string) => Promise<void>
}
</script>
<style lang="scss" scoped>
.consumption-meters {
  width: 100%;
  text-align: left;
  .meters {
    display: flex;
    flex-direction: row;
    flex: 1;
    border-bottom: 1px solid white;
    .meter-description {
      justify-content: flex-start;
      padding-right: 8px;
      flex: 1 1 92%;
      min-height: 45px;
      padding: 10px;
      .meter-name {
        align-items: center;
      }
    }
    .meter-selection {
      justify-content: flex-end;
      flex: 0 0 8%;
    }
  }
}
.other-assigned {
  color: grey;
}
.meter-head {
  .meter-title{
    font-size: 24px;
    line-height: 48px;
    height: 48px;
  }
}

</style>