import { actions } from '@/store/modules/projectManagementUi/actions'
import { getters } from '@/store/modules/projectManagementUi/getters'
import { mutations } from '@/store/modules/projectManagementUi/mutations'
import { state } from '@/store/modules/projectManagementUi/state'
import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { ProjectManagementUiAction, ProjectManagementUiGetter, ProjectManagementUiMutation, ProjectManagementUiState } from './types'

const namespaced: boolean = true
export const projectManagementUi: Module<ProjectManagementUiState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action: ProjectManagementUiAction, 
  mutation: ProjectManagementUiMutation,
  getter: ProjectManagementUiGetter,
}

export const domain = 'projectManagementUi'

export const vuexModule = {
  [domain]: projectManagementUi,
}