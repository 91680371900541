<template>
  <div class="table-filter-date">
    <!-- <date-select/> -->
    <div class="table-filter-date">
      <v-menu v-model="open" 
        bottom @click.native.stop 
        :close-on-content-click="false"
        persistent
      >
      <div slot="activator" class="filter-label" :class="{ placeholder: !value }"> {{displayValue}} </div>
        <v-layout column class="table-filter-date-popup">
          <form-field :text="'date.operator' | translate">
            <autocomplete-select 
              :options="operators"
              option-value="id"
              option-label="label"
              :selected-value="selectedOperator"
              @selected="onOperatorSelected"
            />
          </form-field>
          <v-date-picker no-title scrollable
            :value="selectedDate"
            :max="today"
            :first-day-of-week="1"
            :locale="language"
            @input="onDateSelected"
          />
          <base-text-button @buttonClicked="open = false">
            {{'common.button.close' | translate }}
          </base-text-button>
        </v-layout>
      </v-menu>
      <icon-button v-if="value"
        @buttonClicked="onCleared"
        type="materialIcon"
        materialIconName="clear"
        :height="25"
        :width="25"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import moment from 'moment'

const Resource = namespace('resource')

@Component({
  components: {
  },
})
export default class TableFilterDate extends Vue {
  @Prop() public value: string
  @Prop() public placeholder: string
  @Prop() public language: string
  @Resource.Getter public dictionary

  public open = false

  @Emit('changed')
  public onChanged(filter: string) {
    return filter
  }

  public get displayValue() {
    return this.value ? `${this.selectedOperator} ${moment.utc(this.selectedDate).format('DD.MM.YYYY')}` : this.placeholder
  }

  public get selectedOperator() {
    return this.value ? this.value[0] : '>'
  }

  public get selectedDate() {
    return this.value ? this.value.substring(1) : this.today
  }

  public get today() {
    return moment().format('YYYY-MM-DD')
  }

  public get operators() {
    return [
      { id: '>', label: this.dictionary('date.after') },
      { id: '<', label: this.dictionary('date.before') },
      { id: '=', label: this.dictionary('date.equals') },
    ]
  }

  public onDateSelected(date: string) {
    this.onChanged(`${this.selectedOperator}${date}`)
  }

  public onOperatorSelected(operator: string) {
    this.onChanged(`${operator}${this.selectedDate}`)
  }

  public onCleared() {
    this.onChanged('')
  }
}
</script>
<style lang="scss" scoped>
.table-filter-date {
  display: flex;
  align-content: center;
  align-items: center;
  height: 25px;
  .filter-label {
    &.placeholder {
      opacity: 0.5;
    }
  } 
}
.table-filter-date-popup {
  background-color: #1a3856;
}
</style>
<style lang="css">
.table-filter-date-popup .v-picker, .table-filter-date-popup .v-picker .v-picker__body {
  background-color: transparent !important
}
.table-filter-date-popup .accent {
    background-color: #fa0f36 !important;
    border-color: #fa0f36 !important;
}
</style>