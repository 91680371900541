<template>
  <table :title="dictionary('manage.plc.add')" border="0" class="plcTable">
    <tr class="headerRow">
      <th valign="middle" class="descriptionCol">
        <text-input
          :value="plcNameInput"
          @input="plcNameInput = $event"
        />
        <div class="plc-type-dropdown">
          <base-single-select
            :options="plcTypeOptions"
            option-value="id"
            option-label="label"
            :selected-value="plcTypeInput"
            @selected="plcTypeInput = $event"
            :disabled="isInteracted"
          />
        </div>
      </th>
      <th valign="middle" class="actionIcon">
        <div class="creationContainer" >
          <v-icon 
            v-if="!isInteracted"
            @click.stop="onCreate">
            add
          </v-icon>
        </div>
        <div v-if="pending">
          <BaseSpinner />
        </div> 
      </th>
    </tr>
  </table>
</template>

<script lang="ts">
import BaseSpinner from '@/components/ui/BaseSpinner.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { DropdownOption } from '../../../../../shared-components/src'

import { PlcType } from '@ecocoach/domain-store-modules/src/common'

import { PlcModel } from '@ecocoach/domain-store-modules/src/project/models'

const ProjectManagementUi = namespace('projectManagementUi')
const Resource = namespace('resource')
const App = namespace('app')

@Component({
  components: { BaseSpinner },
})
export default class AddPlcComponent extends Vue {
  @Prop({type: String, required: true }) public projectId: string
  @ProjectManagementUi.Action public createPlc
  @ProjectManagementUi.Getter public plcTypeOptions: DropdownOption[]
  @ProjectManagementUi.Getter public isInteracted: boolean
  @Resource.Getter public dictionary
  @App.Getter public userIdentifier: string

  public pending = false
  public plcNameInput = ''
  public plcTypeInput = PlcType.UNDEFINED

  public async onCreate() {
    if (this.plcTypeInput === PlcType.UNDEFINED) {
      this.$store.commit('wizard/setToastContent', this.dictionary('error.plc.type.undefined'), {root: true})
      this.$store.commit('wizard/showToast', {root: true})      
      return
    }
    if (this.plcNameInput.trim() === '') {
      this.$store.commit('wizard/setToastContent', this.dictionary('error.plc.name.empty'), {root: true})
      this.$store.commit('wizard/showToast', {root: true})
      return
    }
    this.pending = true
    await this.createPlc({
      projectId: this.projectId,
      name: this.plcNameInput.trim(),
      plcType: this.plcTypeInput,
    } as PlcModel).finally(() => this.pending = false)

    this.plcNameInput = ''
    this.plcTypeInput = PlcType.UNDEFINED
  }
}
</script>

<style lang="scss" scoped>
.plcTable {
  // margin-bottom: -15px;
  width: 100%;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-spacing: 0px;
  text-align: right;
  .headerRow {
      background: linear-gradient(to bottom, #20476e, #20476e); 
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #20476e), color-stop(0.6, #20476e));
  }
  th {
    padding: 10px 10px;
    font-weight: normal;
    &.descriptionCol {
      font-weight: bold;
      display: flex;
      align-items: center;
      
      .changeTextField {
        width: 55%;
        height: 34px;
      }
      .plc-type-dropdown {
        width: 40%;
      }
    }
    &.actionIcon {
      font-weight: bold;
      width: 150px;
      cursor: pointer;
      .material-icons {
        max-width: 24px;
        color: white;
        margin-left: 5px;
      }
    }
    .creationContainer {
      display: flex;
      flex-direction: row;
    }
  }
}
</style>