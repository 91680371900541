<template>
  <v-layout column wrap align-center class="consumption-exports">
    <wizard-step-header 
      :title="'navigation.ecoOneClick.export' | translate" 
      :sub-title="'oneclick.export.subtitle'  | translate"
    />
    <v-flex xs12 class="report-export">
      <v-layout class="selection" justify-center row wrap>
        <v-flex  xs12 md5><v-card-text :class="{'small': !$vuetify.breakpoint.mdAndUp}">{{ 'consumption.project' | translate }}</v-card-text></v-flex>
        <v-flex  xs12 md7>
          <autocomplete-select
            :options="exportProjects"
            option-value="id"
            option-label="label"
            :selected-value="selectedProjectId"
            @selected="selectExportProject"
            class="auto-select"
          />
        </v-flex>
      </v-layout>
      <v-layout class="selection" justify-center row wrap>
        <v-flex  xs12 md5><v-card-text :class="{'small': !$vuetify.breakpoint.mdAndUp}">{{ 'consumption.interval' | translate }}</v-card-text></v-flex>
        <v-flex  xs12 md7>
          <base-single-select
            :options="exportIntervals"
            option-value="id"
            option-label="label"
            :selected-value="selectedReportAggregationInterval"
            @selected="setSelectedReportAggregationInterval"
            class="single-select"
          />
        </v-flex>
      </v-layout>
      <v-layout class="selection" justify-center row wrap>
        <v-flex  xs12 md5><v-card-text :class="{'small': !$vuetify.breakpoint.mdAndUp}">{{ 'consumption.consumptionaccount' | translate }}</v-card-text></v-flex>
        <v-flex  xs12 md7>
          <autocomplete-select 
            :options="exportAccounts"
            option-value="id"
            option-label="label"
            :selected-value="selectedReportConsumptionAccountId"
            @selected="setSelectedReportConsumptionAccountId"
            class="auto-select"
          />
        </v-flex>
      </v-layout>
      <v-layout class="selection" justify-center row wrap>
        <v-flex  xs12 md5 class="calendar-label"><v-card-text :class="{'small': !$vuetify.breakpoint.mdAndUp}">{{ 'consumption.from' | translate }}</v-card-text></v-flex>
        <v-flex  xs12 md7 class="calendar-padding">
          <date-select
            :label="'consumption.date' | translate"
            :language="selectedLanguage"
            @changed="setSelectedReportFromDate"
          />
        </v-flex>
      </v-layout>
      <v-layout class="selection" justify-center row wrap>
        <v-flex  xs12 md5 class="calendar-label"><v-card-text :class="{'small': !$vuetify.breakpoint.mdAndUp}">{{ 'consumption.to' | translate }}</v-card-text></v-flex>
        <v-flex  xs12 md7 class="calendar-padding">
          <date-select
            :label="'consumption.date' | translate"
            :language="selectedLanguage"
            @changed="setSelectedReportToDate"
          />
        </v-flex>
      </v-layout>
      <v-layout class="selection" justify-center row wrap>
        <v-flex  xs12 md5></v-flex>
        <v-flex  xs12 md7>
          <base-text-button class="report-button" :enabled="!isInteracted" @buttonClicked="downloadReport"> 
            {{ 'consumption.export.data' | translate }}
          </base-text-button>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { ConsumptionReportAggregationInterval } from '@ecocoach/domain-store-modules/src/consumption/models'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { DropdownOption } from '../../../../../../shared-components/src'

const ConsumptionUi = namespace ('consumptionUi')

@Component({
  components: {

  },
})
export default class ConsumptionExports extends Vue {
  @ConsumptionUi.Getter public isInteracted: boolean
  @ConsumptionUi.Getter public exportProjects: DropdownOption[]
  @ConsumptionUi.Getter public selectedProjectId: string
  @ConsumptionUi.Getter public exportAccounts: DropdownOption[]
  @ConsumptionUi.Getter public exportIntervals: DropdownOption[]
  @ConsumptionUi.Getter public selectedReportAggregationInterval: ConsumptionReportAggregationInterval
  @ConsumptionUi.Getter public selectedReportConsumptionAccountId: string
  @ConsumptionUi.Getter public selectedReportFromDate: string
  @ConsumptionUi.Getter public selectedReportToDate: string
  @ConsumptionUi.Mutation public setSelectedReportAggregationInterval: (interval: ConsumptionReportAggregationInterval) => void
  @ConsumptionUi.Mutation public setSelectedReportFromDate: (date: string) => void
  @ConsumptionUi.Mutation public setSelectedReportToDate: (date: string) => void
  @ConsumptionUi.Mutation public setSelectedReportConsumptionAccountId: (id: string) => void
  @ConsumptionUi.Action public initializeExport: () => Promise<void>
  @ConsumptionUi.Action public selectExportProject: (projectId: string) => Promise<void>
  @ConsumptionUi.Action public downloadReport: () => Promise<void>

  public async mounted() {
    await this.initializeExport()
  }
}
</script>
<style lang="scss" scoped>
header {
  padding: 30px 0 0 25px;
  height: 100px;
}
.consumption-exports {
  padding-top: 10px;
  .report-export {
    padding-top: 10px;
    padding-left: 15px;
    .selection {
      display: flex;
      flex-direction: row;
      .small {
        padding: 10px;
      }
      .auto-select {
        margin-top: 10px;
        padding-top: 0px;
      }
      .single-select {
        padding-right: 10px;
        margin-bottom: 0px;
      }
      .calendar-padding {
        margin-top: -5px;
        padding: 0px 8px;
        padding-right: 37px;
      }
      .report-button {
        margin-left: 10px;
      }
    }
  }
}
.v-btn {
  border: 1px solid white;
}
</style>