import { MutationTree } from 'vuex'
import { remove, upsert } from '../utils'
import { NoteModel } from './models'
import { NoteMutation, NoteState } from './types'

export const mutations: MutationTree<NoteState> = {
  [NoteMutation.setPlcId](state, payload: string) {
    state.plcId = payload
  },  
  [NoteMutation.setNotesForPlc](state, payload: NoteModel[]) {
    state.notesForPlc = payload
  },
  [NoteMutation.upsertNoteForPlc](state, payload: NoteModel) {
    upsert(state.notesForPlc, payload)
  },
  [NoteMutation.removeNoteForPlc](state, payload: string) {
    remove(state.notesForPlc, payload)
  },
}
