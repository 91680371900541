<template>
  <div class="slots">
    <auto-mapping-unmapped-slot
      v-if="addMappingActionVisible"
      :auto-mapping-slot="autoMappingSlot"
      :is-currently-mapping="isCurrentlyMapping"
      :disabled="!addMappingActionEnabled"
      @click="onMapped"
    />
    <auto-mapping-mapped-slot
      :auto-mapping-slot="autoMappingSlot"
      :auto-mappings="autoMappingsForSlot"
      @click="onUnmapped"
    />
  </div>
</template>
<script lang="ts">
import AutoMappingMappedSlot from '@/components/views/ecoSetupTool/steps/mapHardware/AutoMappingMappedSlot.vue'
import AutoMappingUnmappedSlot from '@/components/views/ecoSetupTool/steps/mapHardware/AutoMappingUnmappedSlot.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { AutoMappingModel, SolutionHardwareInstanceModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

import { AutoMappingSlotModel, SolutionHardwareDefinitionModel } from '../../../../../../../eco-domain-store-modules/src/systemConfiguration/models'

const MappingUi = namespace('mappingUi')

@Component ({
  components: {
    AutoMappingMappedSlot,
    AutoMappingUnmappedSlot,
  },
})
export default class AutoMappingSolutionHardwareSlot extends Vue {
  @Prop() public autoMappingSlot: AutoMappingSlotModel
  @Prop() public solutionHardwareInstance: SolutionHardwareInstanceModel
  @Prop() public solutionHardwareDefinition: SolutionHardwareDefinitionModel
  @MappingUi.Getter public existingAutoMappingsForSolutionHardwareInstanceSlot
  @MappingUi.Getter public isAutoMappingStarted
  @MappingUi.Getter public isManualMappingStarted
  @MappingUi.Getter public doesSolutionHardwareSlotAcceptAdditionalAutoMappings
  @MappingUi.Getter public isCurrentlyAutoMappingSolutionHardwareSlot
  @MappingUi.Getter public isMatchingSolutionHardwareAutoMappingSlot
  @MappingUi.Action public addSolutionHardwareToCurrentAutoMapping
  @MappingUi.Action public removeAutoMapping

  public get addMappingActionVisible(): boolean {
    return this.doesSolutionHardwareSlotAcceptAdditionalAutoMappings(
      this.solutionHardwareInstance.id,
      this.autoMappingSlot)
  }

  public get addMappingActionEnabled(): boolean {
    if (this.isManualMappingStarted) {
      return false
    }
    if (!this.isAutoMappingStarted || this.isCurrentlyMapping) {
      return true
    }
    return this.isMatchingSolutionHardwareAutoMappingSlot(
      this.solutionHardwareInstance.id,
      this.autoMappingSlot)
  }
  
  public get isCurrentlyMapping(): boolean {
    return this.isCurrentlyAutoMappingSolutionHardwareSlot(
      this.solutionHardwareInstance.id,
      this.autoMappingSlot.internalName)
  }

  public get autoMappingsForSlot(): AutoMappingModel[] {
    return this.existingAutoMappingsForSolutionHardwareInstanceSlot(
      this.solutionHardwareInstance.id,
      this.autoMappingSlot.internalName)
  }

  public onMapped() {
    this.addSolutionHardwareToCurrentAutoMapping({
      interface: this.autoMappingSlot.interfaceType,
      id: this.solutionHardwareInstance.id,
      slot: this.autoMappingSlot.internalName,
    })
  }

  public onUnmapped(autoMapping: AutoMappingModel) {
    this.removeAutoMapping(autoMapping)
  }
}
</script>

<style lang="scss" scoped>
.slots {
  .slot {
    display:flex;
    .label {
      flex: 1;
    }
    .action {
      flex: 0;
    }
  }
}
</style>