<template>
  <div class="alarm-status">
    <icon-with-badge :material-icon="icon" :badge="badge" :badge-color="badgeColor" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import IconWithBadge from '../../../../ui/IconWithBadge.vue'

import { AlarmViewModel } from '../../../../../store/modules/alarmLogUi/models'

import { AlarmLevel } from '../../../../../../../eco-domain-store-modules/src/common'

@Component({
  components: {
    IconWithBadge,
  },
})
export default class AlarmTableStatus extends Vue {
  @Prop() public alarm: AlarmViewModel

  public get icon() {
    return this.alarm.isOpen ? 'notifications_active' : 'notifications_off'
  }

  public get badge() {
    if (!this.alarm.isOpen) {
      return ''
    }
    switch (this.alarm.level) {
    case AlarmLevel.Critical: return '!'
    case AlarmLevel.Information: return 'i'
    default: return '!'
    }
  }

  public get badgeColor() {
    switch (this.alarm.level) {
    case AlarmLevel.Critical: return 'red'
    case AlarmLevel.Information: return 'blue'
    default: return 'red'
    }
  }
}
</script>
<style lang="scss" scoped>
.alarm-status {
  margin-left: 10px;
}
</style>
