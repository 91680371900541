<template>
  <modal-dialog v-if="releaseUpgradeLogEntries.length"
    :show="true"
    :width="900"
    :header="'release.upgradeLog' | translate"
    button1-text="OK"
    @button1Pressed="onClose"
  >
    <v-layout column>
      <v-data-table :headers="headers" :items="releaseUpgradeLogEntries" item-key="deviceId"
        class="release-upgrade-log-table"
        :rows-per-page-items="[5]"
      >
        <template v-slot:items="props">
          <tr :key="props.item.deviceId">
            <td :title="`${props.item.deviceName}`">{{ props.item.deviceName }}</td>
            <td :title="`${props.item.roomName}`">{{ props.item.roomName }}</td>
            <td :title="`${oldFunctionBlockText(props.item)}`">{{ oldFunctionBlockText(props.item) }}</td>
            <td :title="`${newFunctionBlockText(props.item)}`">{{ newFunctionBlockText(props.item) }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { DeviceUpgradeInfoItem } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

const ReleaseUi = namespace('releaseUi')
const Resource = namespace('resource')

@Component({
  components: {
  },
})
export default class ReleaseUpgradeLogDialog extends Vue { 
  @ReleaseUi.Getter public releaseUpgradeLogEntries: DeviceUpgradeInfoItem[]
  @ReleaseUi.Action public clearReleaseUpgradeLogEntries: () => Promise<void>
  @Resource.Getter public resolveStringResource: (key: string) => string

  public get headers() {
    return [
      {
        text: this.resolveStringResource('alarmdefinition.deviceName'),
        value: 'deviceName',
        align: 'left',
        width: '25%',
      },
      {
        text: this.resolveStringResource('alarmdefinition.roomName'),
        value: 'roomName',
        align: 'left',
        width: '25%',
      },
      {
        text: this.resolveStringResource('release.oldFunctionBlock'),
        value: 'oldFunctionBlockName',
        align: 'left',
        width: '25%',
      },
      {
        text: this.resolveStringResource('release.newFunctionBlock'),
        value: 'newFunctionBlockName',
        align: 'left',
        width: '25%',
      },
    ]
  }

  public oldFunctionBlockText(item: DeviceUpgradeInfoItem) {
    return `${item.oldFunctionBlockName}, v${item.oldFunctionBlockVersion}`
  }

  public newFunctionBlockText(item: DeviceUpgradeInfoItem) {
    return `${item.newFunctionBlockName}, v${item.newFunctionBlockVersion}`
  }

  public onClose() {
    this.clearReleaseUpgradeLogEntries()
  }
}
</script>
<style lang="scss" scoped>
th, td {
  overflow: hidden;
  text-overflow: ellipsis;
}
thead tr {
  border-bottom: 1px solid white!important;
}
</style>
<style lang="css">
.release-upgrade-log-table .theme--dark.v-table {
  background-color: transparent !important;
  table-layout: fixed;
  width: 100%;
}
.release-upgrade-log-table .v-datatable__actions {
  background-color: transparent !important;
}
</style>