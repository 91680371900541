import { AppAction, AppGetter, AppMutation, AppState } from '@/store/modules/app/types'
import { RootState } from '@/store/types'
import { AlarmAction } from '@ecocoach/domain-store-modules/src/alarm/types'
import { AuthorizationAction } from '@ecocoach/domain-store-modules/src/authorization/types'
import { ConsumptionAction } from '@ecocoach/domain-store-modules/src/consumption/types'
import { ApiErrorConverterResult } from '@ecocoach/domain-store-modules/src/helpers/apiErrorConverter'
import { plcStateHubId } from '@ecocoach/domain-store-modules/src/plc/models'
import { PlcAction } from '@ecocoach/domain-store-modules/src/plc/types'
import { configurationReleaseStateHubId } from '@ecocoach/domain-store-modules/src/plcConfiguration/actions'
import { PlcConfigurationAction } from '@ecocoach/domain-store-modules/src/plcConfiguration/types'
import { controlStateHubId } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { PlcOperationAction } from '@ecocoach/domain-store-modules/src/plcOperation/types'
import { ProjectAction } from '@ecocoach/domain-store-modules/src/project/types'
import { ResourceCategory } from '@ecocoach/domain-store-modules/src/resource/models'
import { ResourceAction } from '@ecocoach/domain-store-modules/src/resource/types'
import AppDataStorageService from '@ecocoach/domain-store-modules/src/services/appdatastorage.service'
import { SystemConfigurationAction } from '@ecocoach/domain-store-modules/src/systemConfiguration/types'
import { UserAgreementAction } from '@ecocoach/domain-store-modules/src/userAgreement/types'
import { UserSettingsAction } from '@ecocoach/domain-store-modules/src/userSettings/types'
import moment from 'moment'
import { ActionContext, ActionTree } from 'vuex'
import { AlarmLogUiAction } from '../alarmLogUi/types'
import { AuditLogUiAction } from '../auditLogUi/types'
import { ConsumptionUiAction } from '../consumptionUi/types'
import { ManageAlarmsUiAction } from '../manageAlarmsUi/types'
import { SelectPlcUiAction } from '../selectPlcUi/types'
import { NavigationDrawerMinimizedStorageKey } from './models'
import api from './api'

const resourceCategories = [
  ResourceCategory.APP_STRINGS,
  ResourceCategory.APP_ICONS,
  ResourceCategory.DEVICE_ICONS,
  ResourceCategory.SOLUTION_HARDWARE_ICONS,
  ResourceCategory.ROOM_ICONS,
  ResourceCategory.PLC_IMAGES,
  ResourceCategory.TERMINAL_IMAGES,
  ResourceCategory.MODULE_IMAGES,
  ResourceCategory.SYSTEM_CONFIGURATION,
]

export const actions: ActionTree<AppState, RootState> = {
  async [AppAction.load] ({ commit, dispatch, rootGetters, getters }: ActionContext<AppState, RootState>): Promise<void> {
    commit(AppMutation.loading)
    try {
      const language: string = getters[AppGetter.selectedLanguage]
      const isEcocoachEmployee: string = getters[AppGetter.isEcocoachEmployee]
      await Promise.all([
        // api calls
        dispatch(`userAgreement/${UserAgreementAction.loadCurrentUserAgreements}`, null, { root: true}),
        dispatch(`userSettings/${UserSettingsAction.loadCurrentUserSettings}`, null, { root: true }),
        dispatch(`userSettings/${UserSettingsAction.loadMfaConfiguration}`, null, { root: true }),
        dispatch(`userAgreement/${UserAgreementAction.loadAcceptedUserAgreements}`, null, { root: true}),
        dispatch(`resource/${ResourceAction.loadResources}`, { language, categories: resourceCategories }, { root: true}),
        dispatch(`plcOperation/${PlcOperationAction.connectToControlStateHub}`, null, { root: true }),
        dispatch(`plcConfiguration/${PlcConfigurationAction.connectToConfigurationReleaseStateHub}`, null, { root: true }),
        dispatch(`project/${ProjectAction.loadProjects}`, null, { root: true }),
        dispatch(`plc/${PlcAction.loadPlcs}`, null, { root: true }),
        dispatch(`plc/${PlcAction.connectToPlcStateHub}`, null, { root: true }),
        dispatch(`authorization/${AuthorizationAction.loadPartners}`, null, {root : true }),
        dispatch(`authorization/${AuthorizationAction.loadProjects}`, null, { root: true }),
        dispatch(`systemConfiguration/${SystemConfigurationAction.loadSystemConfiguration}`, { language, isEcocoachEmployee }, { root: true }),
        dispatch(`consumption/${ConsumptionAction.loadProjects}`, null, { root: true }),
        dispatch(`alarm/${AlarmAction.connectToAlarmStateHub}`, true, { root: true }),
      ])
      await dispatch(AppAction.loadAppSettings)
    } catch (error) {
      commit('app/setLoadingFailure', undefined, {root:true})
      commit('wizard/setToastContent',  rootGetters['resource/dictionary']('reload.app'), {root:true})
      commit('wizard/showToast', undefined, {root:true})
      throw error
    }
    finally {
      commit(AppMutation.loaded)
    }
  },
  async [AppAction.loadAppSettings] ({ dispatch }): Promise<void> {
    await Promise.all([
      dispatch(`selectPlcUi/${SelectPlcUiAction.loadFilterSettings}`, null, { root: true }),
      dispatch(`manageAlarmsUi/${ManageAlarmsUiAction.loadFilterSettings}`, null, { root: true }),
      dispatch(`alarmLogUi/${AlarmLogUiAction.loadFilterSettings}`, null, { root: true }),
      dispatch(`auditLogUi/${AuditLogUiAction.loadFilterSettings}`, null, { root: true }),
    ])
  },
  async [AppAction.setNavigationDrawerMinimized] ({ commit, dispatch }, payload: boolean): Promise<void> {
    if(payload) {
      dispatch(`consumptionUi/${ConsumptionUiAction.setNavigationMinimized}`, true, {root:true})
    }
    await AppDataStorageService.set(NavigationDrawerMinimizedStorageKey, payload ? 'true' : '')
    commit(AppMutation.setNavigationDrawerMinimized, payload)
  },
  async [AppAction.acceptUserAgreement]({ commit, dispatch, rootState }, payload: string): Promise<void> {
    try {
      commit(AppMutation.setInteracted, true)
      const userAgreement = rootState.userAgreement.currentUserAgreements.find(doc => doc.documentType === payload)!
      await dispatch(`userAgreement/${UserAgreementAction.acceptUserAgreement}`, {
        documentType: userAgreement.documentType,
        documentVersion: userAgreement.documentVersion,
      }, { root: true })
    } finally {
      commit(AppMutation.setInteracted, false)
    }
  },
  async [AppAction.setEmailMfaEnabled]({ commit, dispatch }, payload: boolean): Promise<void> {
    try {
      commit(AppMutation.setInteracted, true)
      await dispatch(`userSettings/${UserSettingsAction.setEmailMfaEnabled}`, payload, { root: true })
    } finally {
      commit(AppMutation.setInteracted, false)
    }
  },
  async [AppAction.handleApiError] ({ getters, commit, rootState }:
    ActionContext<AppState, RootState>, payload: ApiErrorConverterResult): Promise<void> {
    if(getters.loadingApiSuccess && !rootState.wizard.toastIsVisible) { // initial toast cause is more important
      const message = [payload.message, ...payload.details].join('<br>')
      commit('wizard/setToastContent', message, {root:true})
      commit('wizard/showToast', undefined, {root:true})
    }
  },
  async [AppAction.handleHubReconnecting] ({ getters, commit, rootGetters }, hubId: string): Promise<void> {
    if(getters.loadingApiSuccess && hubId === controlStateHubId) {
      commit('wizard/setToastContent',  rootGetters['resource/dictionary']('error.no.connection'), {root:true})
      commit('wizard/showToast', undefined, {root:true})
    }
  },
  async [AppAction.handleHubReconnected] ({ commit, dispatch, rootGetters, state, rootState }, hubId: string): Promise<void> {
    if (hubId === controlStateHubId && state.selectedPlcId) {
      dispatch(`plcOperation/${PlcOperationAction.startNotifications}`, null, { root: true })
    } else if (hubId === configurationReleaseStateHubId && state.selectedPlcId) {
      await dispatch(`plcConfiguration/${PlcConfigurationAction.startConfigurationReleaseStateHubForPlc}`, state.selectedPlcId, { root:true })
    } else if (hubId === plcStateHubId) {
      const plcIds: string[] = rootGetters['app/writablePlcs'].map(plc => plc.id)
      dispatch(`plc/${PlcAction.startForPlcs}`, plcIds, { root:true })
    }
    if (rootState.wizard.toastIsVisible && rootState.wizard.toastContent === rootGetters['resource/dictionary']('error.no.connection')){
      commit('wizard/hideToast', undefined, {root:true})
    }
  },
  async [AppAction.selectLanguage]({ commit }: ActionContext<AppState, RootState>, language: string): Promise<void> {
    await AppDataStorageService.set('language', language)
    moment.locale(language)
    commit(AppMutation.selectLanguage, language)
  },
  async [AppAction.selectPlc] ({ commit, dispatch, state, rootState }: ActionContext<AppState, RootState>, plcId: string): Promise<void> {
    commit('wizard/setWaiting', true, {root:true})
    await dispatch(`plcConfiguration/${PlcConfigurationAction.loadReleases}`, { plcId }, {root: true})
    await dispatch(`plcConfiguration/${PlcConfigurationAction.startConfigurationReleaseStateHubForPlc}`, plcId, { root:true })
    if(state.selectedPlcId) {
      await dispatch(`plcConfiguration/${PlcConfigurationAction.stopConfigurationReleaseStateHubForPlc}`, state.selectedPlcId, { root:true })
    }    
    const projectId = rootState.plc.plcs.find(p => p.id === plcId)?.projectId ?? ''
    commit(AppMutation.setSelectedPlc, plcId)
    commit(AppMutation.setProjectSelection, projectId)
    commit('wizard/setWaiting', false, { root:true })
  },
  async [AppAction.loadActiveRelease] ({state, dispatch}) {
    const plcId = state.selectedPlcId
    const language = state.selectedLanguage
    await dispatch(`plcOperation/${PlcOperationAction.loadConfigurationByPlcId}`, { plcId, language }, { root:true })
  },
  async [AppAction.initiateAccountDeletion]({ commit }): Promise<void> {
    try {
      commit(AppMutation.setInteracted, true)
      await api.initiateAccountDeletion()
    } finally {
      commit(AppMutation.setInteracted, false)
    }
  },  
}