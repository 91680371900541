<template>
  <v-flex xs12 class="date-select">
    <v-menu
      v-model="$data._menu"
      :close-on-content-click="false"
      :nudge-right="40"
      lazy
      transition="scale-transition"
      offset-y
      full-width          
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="dateFormatted"
          :label="label"
          :hint="hint"
          :disabled="disabled"
          persistent-hint
          dark
          :readonly="!keyboard"
          prepend-icon="event"
          @blur="textChanged"
          v-on="on"
          :clearable="keyboard"
          @click:clear="textCleared"
          @keyup.enter="changeDatePicker"
        ></v-text-field>
      </template>
      <v-date-picker 
        v-model="date"
        :min="min"
        :max="max"
        no-title 
        dark
        :first-day-of-week="1"
        :locale="language"
        @input="changeDatePicker">
      </v-date-picker>
    </v-menu>
  </v-flex>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'DateSelect',
  props: {
      /** 
       * the date in format yyyy-mm-dd
       */
      value: {
          type: String,
          default: '',
      },
      /** 
       * text for label (above the input line)
       */
      label: {
          type: String,
          default: '',
      },
      /**
       * disabled
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** 
       * allow keyboard input in text field
       */
      keyboard: {
          type: Boolean,
          default: true,
      },
      /** 
       * text for hint (underneath the input line)
       */
      hint: {
          type: String,
          default: '',
      },
      menu: {
          type: Boolean,
          default: false,
      },
      language: {
        type: String,
        default: 'en-US',
      },
      /** 
       * Minimum selectable date in format YYYY-MM-DD
       */
      min: {
        type: String,
        default: undefined,
      },
      /** 
       * Maximum selectable date in format YYYY-MM-DD
       */
      max: {
        type: String,
        default: undefined,
      },
  },
  data() {
      return {
        date: this.value,
        dateFormatted: (this as any).formatDate(this.value),
        _menu: this.menu,
      }
  },
  methods: { 
    emitDateChanged(e) {
      this.$emit('changed', e)
    },
    textChanged(e) {
      const parsedDate = this.parseDate(this.dateFormatted)
      this.date = parsedDate
    }, 
    textCleared(e){
      this.date = ''
    },
    formatDate (date) {
      if (!date) { return null }
      const [year, month, day] = date.split('-')
      return `${Number(day).toString()}.${Number(month).toString()}.${year}`
    },
    parseDate (date) {
      try {
        const [day, month, year] = date.split('.')
        const dateString = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        const parsedDate = new Date(dateString).toString()
        if(parsedDate === 'Invalid Date') {
          return ''
        }
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      } catch(ex) {
        return ''
      }
    },
    changeDatePicker(){
      this.$data._menu = false
    },
  },
  watch: {
    date(val) {
      const formattedDate = this.formatDate(this.date)
      this.dateFormatted = (formattedDate ? formattedDate : '')
      this.emitDateChanged(this.date)
    },
    value(val) {
      this.date = this.value
    },
  },
})
</script>
<style lang="scss" scoped>

</style>

<docs>
A date picker (vuetify)
V1.1 
 - no option to pass a value from outside
 - keyboard input for date is supported
 - fix bugs when to close and when not to close calendar-menu



Usage example
```js
new Vue({
  methods: {
    log(date) {
      console.log('the selected date as string: ', date)
    }
  },
  template: `<date-select
    hint="someHint"
    label="someLabel"
    @changed="log"
     />`
})
```
</docs>