<template>
  <v-layout column wrap>
    <v-flex xs12>
      <v-layout row>
        <wizard-step-header
          :title="dictionary('wizard.parameterisation')" 
          :sub-title="plcName" 
        />
        <description-popover
              class="release-info"
              :header="'parameter.plc' | translate"
              :content="`${nameOfActiveRelease}<br>${descriptionOfActiveRelease}`"
              :title="'Information-' + 'releases.configuration' | translate"
          />
        <room-selector 
          slot="header" 
          :rooms="rooms"
          :selected-room-id="selectedRoomId" 
          @selected="onSelectRoom"
        />
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <v-layout row wrap>
        <v-flex xs8>
          <v-layout column wrap class="devices-content">
            <v-flex xs8>
              <v-layout row wrap>
                <v-flex xs3 v-for="device in devicesOfRoom" :key="device.id">
                  <div
                    class="device__card"
                    :class="{ 
                      selected: device.id === selectedDeviceId, 
                      confirmed: isConfirmedDevice(device.id), 
                      unconfirmed: !isConfirmedDevice(device.id)
                    }"
                    @click="selectDevice(device.id)"> 
                    <div class="preview-icons">
                      <div v-if="hasMessage(device.id)" class="status">
                        <v-tooltip bottom>
                          <v-icon slot="activator" color="white">message</v-icon> 
                          <span>{{ 'parameter.device.has.messages' | translate }}</span>
                        </v-tooltip>
                      </div>
                      <div v-if="hasOpenAlarm(device.id)" class="status">
                        <v-tooltip bottom>
                          <v-icon slot="activator" color="white">notifications_active</v-icon> 
                          <span>{{ 'parameter.device.has.alarms' | translate }}</span>
                        </v-tooltip>
                      </div>
                      <div v-if="isConfirmedDevice(device.id)" class="status confirmed">
                        <v-tooltip bottom>
                          <v-icon slot="activator">check</v-icon> 
                          <span>{{ 'parameter.device.confirmed' | translate }}</span>
                        </v-tooltip>
                      </div>
                      <div v-else class="status unconfirmed">
                        <v-tooltip bottom>
                          <v-icon slot="activator">close</v-icon> 
                          <span>{{ 'parameter.device.unconfirmed' | translate }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <DeviceWithEndpointIndicators :device="device" :allowChangeIcon="false"/>
                    <p class="name">{{ device.name }}</p>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <configurable-device 
            v-if="selectedDevice" 
            :device="selectedDevice"
          />   
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import RoomSelector from '@/components/ui/RoomSelector.vue'
import ConfigurableDevice from '@/components/views/ecoSetupTool/steps/configureDevices/ConfigurableDevice.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace} from 'vuex-class'
import DeviceWithEndpointIndicators from '../defineDevices/DeviceWithEndpointIndicators.vue'

import { DeviceModel, RoomModel } from '../../../../../../../eco-domain-store-modules/src/plcOperation/models'

import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'

const App = namespace('app')
const Resource = namespace('resource')
const ParametrizationUi = namespace('parametrizationUi')
const PlcOperation = namespace('plcOperation')
const AlarmDefinition = namespace('alarmDefinition')
const Alarm = namespace('alarm')

@Component({
  components: {
    RoomSelector,
    ConfigurableDevice,
    DeviceWithEndpointIndicators,
  },
})
export default class ConfigureDevices extends Vue {
  @App.Action public loadActiveRelease
  @App.Getter public selectedPlc: PlcModel
  @Resource.Getter public dictionary
  @ParametrizationUi.Getter public rooms: RoomModel[]
  @ParametrizationUi.Getter public selectedRoomId: string
  @ParametrizationUi.Getter public nameOfActiveRelease: string
  @ParametrizationUi.Getter public descriptionOfActiveRelease: string
  @ParametrizationUi.Getter public devicesOfRoom: DeviceModel[]
  @ParametrizationUi.Getter public selectedDeviceId: string
  @ParametrizationUi.Getter public isConfirmedDevice: (id: string) => boolean
  @ParametrizationUi.Getter public hasOpenAlarm: (id: string) => boolean
  @ParametrizationUi.Getter public hasMessage: (id: string) => boolean
  @ParametrizationUi.Getter public isInteracted: boolean
  @ParametrizationUi.Mutation public setSelectedRoomId: (id: string) => void
  @ParametrizationUi.Mutation public setSelectedDeviceId: (id: string) => void
  @ParametrizationUi.Action public startControlUpdatesForDevice: (id: string) => Promise<void>
  @ParametrizationUi.Action public stopControlUpdates: () => Promise<void>
  @PlcOperation.Action public loadConfirmedDevices: (plcId: string) => Promise<void>
  @AlarmDefinition.Action public loadAlarmDefinitions: (payload: { plcId: string }) => Promise<void>
  @Alarm.Action public startForPlcs: (plcIds: string[]) => Promise<void>

  public async mounted () {
    if (!this.selectedPlc) {
      return
    }
    try {
      await this.loadActiveRelease()
      if (!this.selectedRoomId || !this.rooms.find(room => room.id === this.selectedRoomId)) {
        this.setSelectedRoomId(this.rooms[0] && this.rooms[0].id || '')
      }
      if (this.selectedDeviceId && this.devicesOfRoom.some(d => d.id === this.selectedDeviceId)) {
        this.selectDevice(this.selectedDeviceId)
      } else {
        this.selectFirstDeviceOfRoom()
      }
      await this.loadConfirmedDevices(this.selectedPlc.id)
      await this.loadAlarmDefinitions({ plcId: this.selectedPlc.id })
      await this.startForPlcs([this.selectedPlc.id])
    } catch (exception) {
      this.$store.commit('wizard/setToastContent', this.dictionary('error.not.go.to.parameterisation'), {root: true})
      this.$store.commit('wizard/showToast', {root: true})
    }
  }

  public async beforeDestroy() {
    this.stopControlUpdates()
  }

  public get selectedDevice() {
    return this.devicesOfRoom.find(d => d.id === this.selectedDeviceId)
  }

  public get plcName() {
    return this.selectedPlc?.name ?? ''
  }

  public onSelectRoom(roomId: string) {
    this.setSelectedRoomId(roomId)
    this.selectFirstDeviceOfRoom()
  }

  public selectFirstDeviceOfRoom() {
    const firstDeviceOfRoom = this.devicesOfRoom[0]
    this.selectDevice(firstDeviceOfRoom && firstDeviceOfRoom.id || '')
  }

  public selectDevice(id: string) {
    this.setSelectedDeviceId(id)
    this.startControlUpdatesForDevice(id)
  }
}
</script>
<style lang="scss" scoped>
.devices-content{
  margin-bottom: 75px;
}
  .device__card {
    cursor: pointer;
    &.confirmed{
      border: 1px solid #008000;    
    } 
    &.unconfirmed{
      border: 1px solid #c2043e;
    }
    margin: 10px 10px 0 0;
    border-radius: 10px;
    min-height: 150px;
    max-height: 150px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #1a3856), color-stop(0.6, #080e19));
    .preview-icons {
      display: flex;
      width: 100%;
      min-height: 30px;
      padding: 5px;
      justify-content: flex-end;
      align-items: flex-start;
      .status {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-left: 10px;
        cursor: default;
        i {
          font-size: 18px;
          color: rgba(0,0,0,.5);
        }
        &.unconfirmed {
          background: rgb(194, 4, 62);
        }
        &.confirmed {
          background: rgb(0, 128, 0);    
        }
      }
    }
    &.selected {
      border: 1px solid #009ee3;
    }
    .ios__icon {
      display: flex;
      align-items: center;
      flex: 1;
    }
    p.name {
      color: #fff;
      margin-bottom: 5px;
      word-wrap: break-word;
      text-align: center;
      width: 90%;
      overflow: hidden;
    }
  }
  .release-info {
    flex: 0 0 20px;
    text-align: left;
    margin-top: 15px;
  }
</style>