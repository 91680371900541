import { EnergyStatusItemType } from '../common'

export interface EnergyStatusDevice {
  deviceId: string
  name: string
  iconResourceId: string
  items: EnergyStatusItem[]
}

export interface EnergyStatusItem {
  id: string
  type: EnergyStatusItemType
  unit: string
  colorGradient: string
  value: number | null
}

export interface MultipleEnergyStatusChanged {
  statuses: EnergyStatusChanged[]
}

export interface EnergyStatusChanged {
  id: string
  value: number
}

export interface EnergyStatusDeviceModel {
  id: string
  label: string
  icon: string
  powerGradient: string | null
  percentageGradient: string | null
  powerValue: number | null
  percentageValue: number | null
  powerDisplayValue: string
  percentageDisplayValue: string 
}

export interface ContributionPercentageModel {
  id: string
  gradient: string | null
  percentageValue: number | null
}

export const energyStatusHubId = 'energyStatusHub'
export const kioskHubId = 'kioskHub'
