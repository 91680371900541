<template>
  <div class="plc-alarms">
    <v-tooltip bottom>
      <icon-with-badge class="icon-button" slot="activator" :material-icon="icon" :badge="badge" :badge-color="color" @click="selected"/>      
      <span>{{plc.openCriticalAlarmsCount}} {{ 'alarm.opencount' | translate }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <div slot="activator" class="plc-alarm-history" @click="selected">
        <base-material-icon
          iconName="history"
        />
      </div>
      <span>{{ 'alarm.history' | translate }}</span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import IconWithBadge from '../../../../ui/IconWithBadge.vue'

import { PlcViewModel } from '../../../../../store/modules/selectPlcUi/models'

@Component({
  components: {
    IconWithBadge,
  },
})
export default class PlcTableAlarms extends Vue {
  @Prop() public plc: PlcViewModel

  @Emit('selected')
  public selected() {
    return this.plc.id
  }

  public get icon() {
    return this.plc.openCriticalAlarmsCount ? 'notifications_active' : 'notifications_off'
  }

  public get badge() {
    return this.plc.openCriticalAlarmsCount ? this.plc.openCriticalAlarmsCount : ''
  }

  public get color() {
    return this.plc.openCriticalAlarmsCount ? '#fa0f36' : 'green'
  }
}
</script>
<style lang="scss" scoped>
.plc-alarms {
  display: flex;
  align-items: center;
  .icon-button {
    cursor: pointer;
    margin-right: -10px
  }
  .plc-alarm-history {
    cursor: pointer;
  }
}
</style>
