import { ConsumptionUiGetter, ConsumptionUiState } from '@/store/modules/consumptionUi/types'
import { RootState } from '@/store/types'
import { ConsumptionReportAggregationInterval, EgonlineConfigurationModel, MinergieConfigurationModel, MinergieVisualizationInterval, OneClickTargetSystem } from '@ecocoach/domain-store-modules/src/consumption/models'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { matchSubstringCaseInsensitive } from '@ecocoach/domain-store-modules/src/utils'
import { DropdownOption } from '@ecocoach/shared-components/src'
import { GetterTree } from 'vuex'
import { AccountViewModel, MeterViewModel, UserViewModel } from './models'

const filterByProperty = <T>(item: T, propertySelector: (item: T) => string, filter: string): boolean => {
  if (!filter) {
    return true
  }
  return matchSubstringCaseInsensitive(propertySelector(item), filter)
}

const filterByProperties = <T>(item: T, propertySelectors: Array<(item: T) => string>, filter: string): boolean => {
  return propertySelectors.some(propertySelector => filterByProperty(item, propertySelector, filter))
}

export const getters: GetterTree<ConsumptionUiState, RootState> = {
  [ConsumptionUiGetter.isInteracted] ({ interacted }): boolean {
    return interacted
  },
  [ConsumptionUiGetter.selectedProjectId] (_, __, rootState): string {
    return rootState.consumption.projectId
  },
  [ConsumptionUiGetter.adminProjects] (_, __, rootState): DropdownOption[] {
    return rootState.consumption.projects
      .filter(p => p.currentUserAuthorization.includes('Edit'))
      .map(p => ({ id: p.id, label: p.name } as DropdownOption))
      .sort((a, b) => a.label.localeCompare(b.label))
  },
  [ConsumptionUiGetter.adminTargetSystems] (): DropdownOption[] {
    return [
      { id: OneClickTargetSystem.Ecocoach, label: OneClickTargetSystem.Ecocoach },
      { id: OneClickTargetSystem.Minergie, label: OneClickTargetSystem.Minergie },
      { id: OneClickTargetSystem.Egonline, label: OneClickTargetSystem.Egonline },
    ] as DropdownOption[]
  },
  [ConsumptionUiGetter.adminSelectedTargetSystem] (_, __, rootState): string {
    return rootState.consumption.targetSystem
  },
  [ConsumptionUiGetter.adminAccounts] ({ selectedAccountId, accountFilterString }, __, rootState): AccountViewModel[] {
    return rootState.consumption.accounts
      .filter(account => filterByProperties(account, [a => a.name, a => a.customId], accountFilterString))
      .map(account => {
        return {
          ...account,
          active: account.id === selectedAccountId,
        }
      })
      .sort((a, b) => a.name.localeCompare(b.name))
  },
  [ConsumptionUiGetter.adminSelectedAccountId] ({ selectedAccountId }): string {
    return selectedAccountId
  },
  [ConsumptionUiGetter.adminAccountFilterString] ({ accountFilterString }): string {
    return accountFilterString
  },
  [ConsumptionUiGetter.adminMeters] ({ selectedAccountId, meterFilterString }, __, rootState): MeterViewModel[] {
    return rootState.consumption.meters
      .filter(meter => filterByProperty(meter, m => m.name, meterFilterString))
      .map(meter => {
        const account = rootState.consumption.accounts.find(a => a.id === meter.consumptionAccountId)
        return {
          ...meter,
          selected: !!meter.consumptionAccountId,
          disabled: !!meter.consumptionAccountId && (meter.consumptionAccountId !== selectedAccountId) || !selectedAccountId,
          accountName: account?.name ?? '',
        }
      })
      .sort((a, b) => a.name.localeCompare(b.name))
  },
  [ConsumptionUiGetter.adminMeterFilterString] ({ meterFilterString }): string {
    return meterFilterString
  },
  [ConsumptionUiGetter.adminUsers] ({ selectedAccountId, userFilterString }, __, rootState): UserViewModel[] {
    return rootState.consumption.users
      .filter(user => filterByProperty(user, u => u.userIdentifier, userFilterString))
      .map(user => {
        const account = rootState.consumption.accounts.find(a => a.id === user.consumptionAccountId)
        return {
          ...user,
          selected: !!user.consumptionAccountId,
          disabled: !!user.consumptionAccountId && (user.consumptionAccountId !== selectedAccountId)  || !selectedAccountId,
          accountName: account?.name ?? '',
        }
      })
      .sort((a, b) => a.userIdentifier.localeCompare(b.userIdentifier))
  },
  [ConsumptionUiGetter.adminUserFilterString] ({ userFilterString }): string {
    return userFilterString
  },
  [ConsumptionUiGetter.adminMinergieInfo] (_, __, rootState): MinergieConfigurationModel {
    return rootState.consumption.minergieInfo ?? {
      minergieObjectId: '',
      isLinked: false,
      isVerified: false,
    }
  },
  [ConsumptionUiGetter.adminEgonlineConfiguration] (_, __, rootState): EgonlineConfigurationModel {
    return rootState.consumption.egonlineConfiguration ?? {
      egonlineProjectId: '',
      isLinked: false,
    }
  },
  [ConsumptionUiGetter.exportProjects] (_, __, rootState): DropdownOption[] {
    return rootState.consumption.projects
      .filter(p => p.currentUserAuthorization.includes('Export'))
      .map(p => ({ id: p.id, label: p.name } as DropdownOption))
      .sort((a, b) => a.label.localeCompare(b.label))
  },
  [ConsumptionUiGetter.exportAccounts] (_, __, rootState): DropdownOption[] {
    return  [{ id: '', label: '-' } as DropdownOption]
      .concat(rootState.consumption.accounts.map(p => ({ id: p.id, label: p.name } as DropdownOption)))
      .sort((a, b) => a.label.localeCompare(b.label))
  },
  [ConsumptionUiGetter.exportIntervals] (_, __, ___, rootGetters): DropdownOption[] {
    const stringResource = rootGetters[`resource/${ResourceGetter.dictionary}`]
    return [
      {
        id: ConsumptionReportAggregationInterval.FIFTEEN_MINUTES,
        label: stringResource('consumptionreport.interval.fifteenMinutes'),
      },{
        id: ConsumptionReportAggregationInterval.HOURLY,
        label: stringResource('consumptionreport.interval.hourly'),
      },{
        id: ConsumptionReportAggregationInterval.DAILY,
        label: stringResource('consumptionreport.interval.daily'),
      },{
        id: ConsumptionReportAggregationInterval.MONTHLY,
        label: stringResource('consumptionreport.interval.monthly'),
      },{
        id: ConsumptionReportAggregationInterval.YEARLY,
        label: stringResource('consumptionreport.interval.yearly'),
      },
    ]
  },
  [ConsumptionUiGetter.selectedReportAggregationInterval] ({ selectedReportAggregationInterval }): string {
    return selectedReportAggregationInterval
  }, 
  [ConsumptionUiGetter.selectedReportConsumptionAccountId] ({ selectedReportConsumptionAccountId }): string {
    return selectedReportConsumptionAccountId
  },
  [ConsumptionUiGetter.selectedReportFromDate] ({ selectedReportFromDate }): string {
    return selectedReportFromDate
  },  
  [ConsumptionUiGetter.selectedReportToDate] ({ selectedReportToDate }): string {
    return selectedReportToDate
  },
  [ConsumptionUiGetter.visualizationProjects] (_, __, rootState): DropdownOption[] {
    return rootState.consumption.projects
      .filter(p => p.targetSystems.includes(OneClickTargetSystem.Minergie))
      .map(p => ({ id: p.id, label: p.name } as DropdownOption))
      .sort((a, b) => a.label.localeCompare(b.label))
  },
  [ConsumptionUiGetter.visualizationAccounts] (_, __, rootState): DropdownOption[] {
    return  [{ id: '', label: '-' } as DropdownOption]
      .concat(rootState.consumption.accounts.map(p => ({ id: p.id, label: p.name } as DropdownOption)))
      .sort((a, b) => a.label.localeCompare(b.label))
  },
  [ConsumptionUiGetter.visualizationIntervals] (_, __, ___, rootGetters): DropdownOption[] {
    const stringResource = rootGetters[`resource/${ResourceGetter.dictionary}`]
    return [
      {
        id: MinergieVisualizationInterval.MONTHLY,
        label: stringResource('consumptionreport.interval.monthly'),
      },{
        id: MinergieVisualizationInterval.YEARLY,
        label: stringResource('consumptionreport.interval.yearly'),
      },
    ]
  },
  [ConsumptionUiGetter.selectedVisualizationInterval] ({ selectedVisualizationInterval }): string {
    return selectedVisualizationInterval
  }, 
  [ConsumptionUiGetter.selectedVisualizationConsumptionAccountId] ({ selectedVisualizationConsumptionAccountId }): string {
    return selectedVisualizationConsumptionAccountId
  },
  [ConsumptionUiGetter.hasOneClickExportProjects] (_, localGetters): boolean {
    return !!localGetters[ConsumptionUiGetter.exportProjects].length
  },
  [ConsumptionUiGetter.hasOneClickVisualizationProjects] (_, localGetters): boolean {
    return !!localGetters[ConsumptionUiGetter.visualizationProjects].length
  },
  [ConsumptionUiGetter.hasOneClickEditProjects] (_, localGetters): boolean {
    return !!localGetters[ConsumptionUiGetter.adminProjects].length
  },
  [ConsumptionUiGetter.showOneClickTopNavigation] (_, localGetters): boolean {
    return localGetters[ConsumptionUiGetter.hasOneClickEditProjects]
      || localGetters[ConsumptionUiGetter.hasOneClickExportProjects] 
      || localGetters[ConsumptionUiGetter.hasOneClickVisualizationProjects]
  },
  [ConsumptionUiGetter.showOneClickSubNavigation] (state, localGetters) {
    const numSubNavigationItems = [
      localGetters[ConsumptionUiGetter.hasOneClickEditProjects],
      localGetters[ConsumptionUiGetter.hasOneClickExportProjects],
      localGetters[ConsumptionUiGetter.hasOneClickVisualizationProjects],
    ].filter(result => result === true).length
    return !state.navigationMinimized && numSubNavigationItems > 1
  },
}
