<template>
  <v-tooltip bottom>
    <v-menu slot="activator" bottom left @click.native.stop attach>
      <v-btn slot="activator" icon class="grid-action-menu-button">
        <v-icon>more_vert</v-icon>
      </v-btn>
      <v-list class="grid-action-menu">
        <v-subheader class="grid-action-menu-header" v-if="header">{{ header }}</v-subheader>
        <v-divider v-if="header"></v-divider>      
        <slot/>
      </v-list>
    </v-menu>
    <span>{{`navigation.more` | translate}}</span>
  </v-tooltip>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class MoreMenu extends Vue {
  @Prop({ default: ''}) public header: string
}
</script>
<style lang="scss" scoped>
.grid-action-menu {
  background: linear-gradient(to bottom, #1a3856, #080e19);
  .grid-action-menu-header {
    cursor: default;
    white-space: nowrap;
  }
}
</style>
<style lang="css">
button.grid-action-menu-button.btn {
  width: 20px;
  height: 20px;
  margin: 0px;
}
</style>