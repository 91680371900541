<template>
  <v-layout column class="card">
    <!-- title -->
    <v-layout row align-center class="header" @click="onToggle">
      <v-flex shrink>
        <h2>{{header}}<v-chip v-if="chipText" small :color="chipColor">{{ chipText }}</v-chip></h2>
        
      </v-flex>
      <slot name="actions" v-if="isExpanded"></slot>
      <v-flex v-if="collapsible" grow class="header-spacer"/>
      <card-action v-if="collapsible" :icon="icon" :tooltip="tooltip" @click="onToggle"/>
    </v-layout>
    <div class="body" v-if="isExpanded">
      <slot></slot>
    </div>
  </v-layout>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import CardAction from './CardAction.vue'

const Resource = namespace('resource')

@Component({
  components: {
    CardAction,
  },
})
export default class Card extends Vue {
  @Prop() public header: string
  @Prop({default: false}) public collapsible: boolean
  @Prop({default: true }) public expanded: boolean
  @Prop() public chipText: string 
  @Prop() public chipColor: string
  @Resource.Getter public dictionary

  public isExpanded = true

  public mounted() {
    this.isExpanded = this.expanded
  }

  @Watch('expanded') 
  public openChanged(value: boolean) {
    this.isExpanded = value
  }

  public get icon() {
    return this.isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
  }

  public get tooltip() {
    return this.dictionary(this.isExpanded ? 'common.button.close' : 'common.button.open')
  }

  @Emit('expandedChanged') 
  public onToggle() {
    this.isExpanded = !this.isExpanded
    return this.isExpanded
  }
}
</script>

<style lang="scss" type="text/scss" scoped>
.card {
  margin: 10px;
  .header {
    padding: 0px 20px 0px 10px;
    min-height: 50px;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, #1a3856),
      color-stop(0.6, #080e19)
    );
    .header-spacer {
      min-width: 10px;
    }
  }
  .body {
    padding: 10px;
    background: #1a3856;
  }
}

</style>