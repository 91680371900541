export enum PlcType {
  UNDEFINED = 'Undefined',
  ECO_BASIC_CONTROL_LARGE = 'ecoBasicControlLarge',
  ECO_BASIC_CONTROL_MEDIUM = 'ecoBasicControlMedium',
  ECO_BASIC_CONTROL_SMALL = 'ecoBasicControlSmall',
  ECO_BASIC_CONTROL_LIGHT = 'ecoBasicControlLight',
}

export enum ArchitectureType {
  None = 'None',
  Classic = 'Classic',
  ObjectOriented = 'ObjectOriented'
}

export enum SourceLanguage {
  StructuredText = 'StructuredText',
  CSharp = 'CSharp',
}

export enum ControlTypeV2 {
  Action = 'Action',
  Actions = 'Actions',
  Toggle = 'Toggle',
  Toggles = 'Toggles',
  ConsumptionProcessToggle = 'ConsumptionProcessToggle',
  BooleanDisplay = 'BooleanDisplay',
  NumericDisplay = 'NumericDisplay',
  EnumDisplay = 'EnumDisplay',
  TimeDisplay = 'TimeDisplay',
  NumericInput = 'NumericInput',
  TextDisplay = 'TextDisplay',
  TextInput = 'TextInput',
  EnumInput = 'EnumInput',
  Links = 'Links',
  DeviceMessages = 'DeviceMessages',
  StateOfChargeConfiguration = 'StateOfChargeConfiguration',
  ArrayContainer = 'ArrayContainer',
}

export enum EnergyStatusItemType {
  ProducerPower = 'ProducerPower',
  ConsumerPower = 'ConsumerPower',
  BufferPower = 'BufferPower',
  BufferLevel = 'BufferLevel',
  GridPower = 'GridPower',
  HousePower = 'HousePower',
}

export enum AlarmOperator {
  Equal = '==',
  LessThan = '<',
  GreaterThan = '>',
}

export enum AlarmLevel {
  Critical = 'Critical',
  Information = 'Information',
}

export enum PlcOperationState {
  Disconnected = 'Disconnected',
  OnlinePartiallyReady = 'OnlinePartiallyReady',
  OnlineReady = 'OnlineReady',
  Offline = 'Offline',
  Updating = 'Updating',
}
