import { PlcOperationState } from '@ecocoach/domain-store-modules/src/common'
import { PlcOperationStateHistoryItemModel } from '@ecocoach/domain-store-modules/src/plcConnection/models'
import moment from 'moment'
import { PlcOperationStateHistoryItemViewModel, PlcStatus } from './models'

(moment as any).suppressDeprecationWarnings = true

export function toViewModel(model: PlcOperationStateHistoryItemModel) {
  return {
    ...model,
    status: toPlcStatus(model.plcOperationState),
    dateTimeFormatted: model.timeStamp ? moment.utc(model.timeStamp).local().format('DD.MM.YYYY - HH:mm:ss') : 'n/a',
  } as PlcOperationStateHistoryItemViewModel
}

export const toPlcStatus = (plcOperationState: PlcOperationState): PlcStatus => {
  switch (plcOperationState) {
  case PlcOperationState.OnlineReady:
    return PlcStatus.Online
  case PlcOperationState.OnlinePartiallyReady:
    return PlcStatus.PartiallyOnline
  case PlcOperationState.Offline:
  case PlcOperationState.Disconnected:
    return PlcStatus.Offline
  case PlcOperationState.Updating:
    return PlcStatus.Updating
  default:
    return PlcStatus.Unknown
  }
}
