import { MutationTree } from 'vuex'
import { ArchitectureType } from '../common'
import { remove, sortedBySortOrder, upsert } from '../utils'
import { 
  AutoMappingModel, 
  DeviceMappingModel,
  DeviceModel, 
  DeviceUpgradeInfoItem, 
  ReleaseModel, 
  RoomModel, 
  SolutionHardwareInstanceModel,
} from './models'
import { PlcConfigurationMutation, PlcConfigurationState } from './types'

export const mutations: MutationTree<PlcConfigurationState> = {
  [PlcConfigurationMutation.setReleaseTemplates](state, payload: ReleaseModel[]) {
    state.releaseTemplates = payload
  },
  [PlcConfigurationMutation.setReleases](state, payload: ReleaseModel[]) {
    state.releases = payload
  },
  [PlcConfigurationMutation.addRelease](state, payload: ReleaseModel) {
    upsert(state.releases, payload)
  },
  [PlcConfigurationMutation.updateRelease](state, payload: ReleaseModel) {
    upsert(state.releases, payload)
  },
  [PlcConfigurationMutation.removeRelease](state, payload: string) {
    remove(state.releases, payload)
  },
  [PlcConfigurationMutation.setLoadedRelease](state, payload: { plcId: string, releaseId: string, architectureType: ArchitectureType }) {
    state.plcId = payload.plcId
    state.releaseId = payload.releaseId
    state.architectureType = payload.architectureType
  },
  [PlcConfigurationMutation.setRooms](state, payload: RoomModel[]) {
    state.rooms = sortedBySortOrder(payload)
  },
  [PlcConfigurationMutation.addRoom](state, payload: RoomModel) {
    upsert(state.rooms, payload)
    state.rooms = sortedBySortOrder(state.rooms)
  },
  [PlcConfigurationMutation.updateRoom](state, payload: RoomModel) {
    upsert(state.rooms, payload)
    state.rooms = sortedBySortOrder(state.rooms)
  },
  [PlcConfigurationMutation.removeRoom](state, payload: string) {
    remove(state.rooms, payload)
  },
  [PlcConfigurationMutation.sortRooms](state, payload: string[]) {
    payload.forEach((id, index) => {
      state.rooms.find(room => room.id === id)!.sortOrder = index
    })
    state.rooms = sortedBySortOrder(state.rooms)
  },
  [PlcConfigurationMutation.setDevices](state, payload: DeviceModel[]) {
    state.devices = sortedBySortOrder(payload)
  },
  [PlcConfigurationMutation.addDevice](state, payload: DeviceModel) {
    upsert(state.devices, payload)
    state.devices = sortedBySortOrder(state.devices)
  },
  [PlcConfigurationMutation.updateDevice](state, payload: DeviceModel) {
    upsert(state.devices, payload)
    state.devices = sortedBySortOrder(state.devices)
  },
  [PlcConfigurationMutation.removeDevice](state, payload: string) {
    remove(state.devices, payload)
  },
  [PlcConfigurationMutation.sortDevices](state, payload: string[]) {
    payload.forEach((id, index) => {
      state.devices.find(device => device.id === id)!.sortOrder = index
    })
    state.devices = sortedBySortOrder(state.devices)
  },
  [PlcConfigurationMutation.setSolutionHardwareInstances](state, payload: SolutionHardwareInstanceModel[]) {
    state.solutionHardwareInstances = sortedBySortOrder(payload)
  },
  [PlcConfigurationMutation.addSolutionHardwareInstance](state, payload: SolutionHardwareInstanceModel) {
    upsert(state.solutionHardwareInstances, payload)
    state.solutionHardwareInstances = sortedBySortOrder(state.solutionHardwareInstances)
  },
  [PlcConfigurationMutation.updateSolutionHardwareInstance](state, payload: SolutionHardwareInstanceModel) {
    upsert(state.solutionHardwareInstances, payload)
    state.solutionHardwareInstances = sortedBySortOrder(state.solutionHardwareInstances)
  },
  [PlcConfigurationMutation.removeSolutionHardwareInstance](state, payload: string) {
    remove(state.solutionHardwareInstances, payload)
  },
  [PlcConfigurationMutation.sortSolutionHardwareInstances](state, payload: string[]) {
    payload.forEach((id, index) => {
      state.solutionHardwareInstances.find(instance => instance.id === id)!.sortOrder = index
    })
    state.solutionHardwareInstances = sortedBySortOrder(state.solutionHardwareInstances)
  },
  [PlcConfigurationMutation.setDeviceMappings](state, payload: DeviceMappingModel[]) {
    state.deviceMappings = payload
  },
  [PlcConfigurationMutation.addDeviceMapping](state, payload: DeviceMappingModel) {
    upsert(state.deviceMappings, payload)
  },
  [PlcConfigurationMutation.removeDeviceMapping](state, payload: string) {
    remove(state.deviceMappings, payload)
  },
  [PlcConfigurationMutation.setAutoMappings](state, payload: AutoMappingModel[]) {
    state.autoMappings = payload
  },
  [PlcConfigurationMutation.addAutoMapping](state, payload: AutoMappingModel) {
    upsert(state.autoMappings, payload)
  },
  [PlcConfigurationMutation.removeAutoMapping](state, payload: string) {
    remove(state.autoMappings, payload)
  },
  [PlcConfigurationMutation.setDeviceUpgradeInfos](state, payload: DeviceUpgradeInfoItem[]) {
    state.deviceUpgradeInfos = payload
  },
}
