<template>
  <div class="control-component-wrapper">
    <v-tooltip bottom v-if="!isClassicControlDefinition">
      <icon-button class="app-display-level"
        slot="activator" 
        type="materialIcon"
        :materialIconName="appVisibilityIcon"
        :height="20"
        :width="20"
        :enabled="false"
        :style="appVisibilityStyle"
      />
      <span>{{ appVisibilityTooltip | translate }}</span>
    </v-tooltip>
    <control-component-v-2
      :context="contextV2"
      :control="controlViewModelV2"
      :potential-descendent-controls="[]"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ControlComponentV2 from '../../../../../shared-components/src/components/deviceControls/ControlComponentV2.vue'

import { ControlDefinitionDefaultValueInput, ControlDefinitionViewModel, ControlDefinitionViewModelV2 } from '../../../store/modules/functionDefinitionEditorUi/models'

import { stateForControlDefinitionV2, stateForMappedControlDefinitionV2 } from './helpers'

import { ControlCommand, ControlContextV2, ControlViewModelV2 } from '../../../../../shared-components/src/components/deviceControls/v2/models'

import { mapToClassicDefaultValue, mapToControlViewModelV2 } from '../../../store/modules/functionDefinitionEditorUi/helpers'

import { DropdownOption } from '../../../../../shared-components/src'

import { AppDisplayLevels } from '../../../../../eco-domain-store-modules/src/plcOperation/models'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')
const Resource = namespace('resource')
const ParametrizationUi = namespace('parametrizationUi')

@Component({
  components: {
    ControlComponentV2,
  },
})
export default class PreviewControl extends Vue {
  @Prop() public controlDefinition: ControlDefinitionViewModel | ControlDefinitionViewModelV2
  @Prop({ default: false }) public readonly: boolean
  @FunctionDefinitionEditorUi.Getter public controlDefinitionDefaultValues: (id: string) => any
  @FunctionDefinitionEditorUi.Mutation public setControlDefinitionDefaultValue: (value: ControlDefinitionDefaultValueInput) => void
  @Resource.Getter public resolveStringResource: (key: string) => string
  @ParametrizationUi.Getter public appDisplayLevelOptions: DropdownOption[]

  public get controlViewModelV2(): ControlViewModelV2 {
    if (this.isClassicControlDefinition) {
      const controlDefinitionV1 = this.controlDefinition as ControlDefinitionViewModel
      const controlViewModel = mapToControlViewModelV2(controlDefinitionV1)
      controlViewModel.readonly = this.readonly
      controlViewModel.state = stateForMappedControlDefinitionV2(controlDefinitionV1, this.controlDefinitionDefaultValues(controlDefinitionV1.id))
      return controlViewModel
    } else {
      const controlDefinitionV2 = this.controlDefinition as ControlDefinitionViewModelV2
      return {
        id: controlDefinitionV2.id,
        type: controlDefinitionV2.type,
        attributes: controlDefinitionV2.attributes,
        state: stateForControlDefinitionV2(controlDefinitionV2, this.controlDefinitionDefaultValues(controlDefinitionV2.id)),
        pendingState: {},
        readonly: this.readonly,
      } as ControlViewModelV2
    }
  }

  public get contextV2(): ControlContextV2 {
    return {
      executeCommand: (controlId: string, command: ControlCommand) => {
        if (this.isClassicControlDefinition) {
          const defaultValue = mapToClassicDefaultValue(this.controlDefinition as ControlDefinitionViewModel, command)
          this.setControlDefinitionDefaultValue({
            controlDefinitionId: this.controlDefinition.id,
            command: 'defaultValue',
            defaultValue,
          } as ControlDefinitionDefaultValueInput)
        } else {
          this.setControlDefinitionDefaultValue({
            controlDefinitionId: this.controlDefinition.id,
            command: command.command,
            defaultValue: command.params,
          } as ControlDefinitionDefaultValueInput)        
        }
      },
      resolveStringResource: (resourceKey: string): string => {
        return this.resolveStringResource(resourceKey)
      },
    } as ControlContextV2
  }

  public get isClassicControlDefinition() {
    return !!(this.controlDefinition as ControlDefinitionViewModel).kind
  }

  public get appVisibilityIcon() {
    return (this.controlDefinition as ControlDefinitionViewModelV2)?.attributes.appDisplayLevel === AppDisplayLevels.NOT_DISPLAYED
      ? 'visibility_off'
      : 'visibility'
  }

  public get appVisibilityStyle() {
    switch ((this.controlDefinition as ControlDefinitionViewModelV2)?.attributes.appDisplayLevel) {
    case AppDisplayLevels.NOT_DISPLAYED:
    case AppDisplayLevels.DISPLAYED_ON_THIRD_LEVEL:
      return { opacity: 0.5 }
    default: 
      return { opacity: 1 }
    }
  }

  public get appVisibilityTooltip() {
    const currentLevelLabel = this.appDisplayLevelOptions
      .find(_ => _.id === (this.controlDefinition as ControlDefinitionViewModelV2)?.attributes.appDisplayLevel)?.label
    return `ecoMobileApp: ${currentLevelLabel}`
  }
}
</script>
<style lang='scss' scoped>
.control-component-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 7px 0px;
  .app-display-level {
    margin: -7px;
    margin-right: 0px;
  }
}
</style>