<template>
  <modal-dialog 
    :show="functionDefinition"
    header="Release notes" 
    button1-text='Yes'
    button2-text="Cancel"
    :button1-disabled="!!actionInProgress || !releaseNotesChanged"
    :button2-disabled="!!actionInProgress"
    @button1Pressed="onConfirmed"
    @button2Pressed="onClose"
  >
    <v-layout column>
      <v-flex class="fb-info">
        Function definition: {{name}}
        <br>
        Version: {{version}}
      </v-flex>
      <v-flex>
        <form-field text="Release notes">
          <text-input
            :value="releaseNotes"
            @input="releaseNotes = $event"
          />
        </form-field>
      </v-flex>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { SetFunctionBlockReleaseNotesInput } from '../../../../../eco-domain-store-modules/src/systemConfiguration/models'
import { FunctionBlockTreeItemViewModel } from '../../../store/modules/functionBlockUi/models'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')
const Resource = namespace('resource')

@Component({
  components: {
  },
})
export default class EditFunctionDefinitionReleaseNotesDialog extends Vue {
  @Prop() public functionDefinition: FunctionBlockTreeItemViewModel
  @FunctionDefinitionEditorUi.Getter public actionInProgress: string
  @FunctionDefinitionEditorUi.Action public upsertReleaseNotes: (payload: SetFunctionBlockReleaseNotesInput) => Promise<void>
  @Resource.Getter public resolveStringResource: (key: string) => string

  public releaseNotes = ''
  public initialReleaseNotes = ''

  public mounted() {
    this.releaseNotes = this.functionDefinition?.releaseNotes
    this.initialReleaseNotes = this.functionDefinition?.releaseNotes
  }

  public get name() {
    return this.functionDefinition?.name
  }

  public get version() {
    return this.functionDefinition?.version
  }

  public get releaseNotesChanged() {
    return this.releaseNotes !== this.initialReleaseNotes
  }

  public async onConfirmed() {
    await this.upsertReleaseNotes({
      id: this.functionDefinition.id,
      releaseNotes: this.releaseNotes,
    })
    this.onClose()
  }

  @Emit('closed') 
  public onClose () {
    return
  }
}
</script>

<style lang="scss" type="text/scss" scoped>
.fb-info {
  margin-bottom: 20px;
}
</style>