import { del, get, set, Store } from 'idb-keyval'

export interface IndexedDBWrapperOptions {
  appId: string
  environment: string
}

class IndexedDBWrapper {
  private store: Store

  public init(options: IndexedDBWrapperOptions) {
    this.store = new Store(`eco-appdata-${options.appId}-${options.environment}`)
  }

  public get = async (key: string): Promise<string|null> => {
    if (!this.store) {
      // tslint:disable-next-line:no-console
      console.error('IndexedDBWrapper:get error, not initialized')
      return null
    }

    try {
      const data = await get<string>(key, this.store)
      return data || null
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('IndexedDBWrapper:get error', key, error)
      return null
    }
  }

  public set = async (key: string, data: string) => {
    if (!this.store) {
      // tslint:disable-next-line:no-console
      console.error('IndexedDBWrapper:set error, not initialized')
      return
    }

    try {
      await set(key, data, this.store)
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('IndexedDBWrapper:set error', key, error)
    }
  }

  public remove = async (key: string) => {
    if (!this.store) {
      // tslint:disable-next-line:no-console
      console.error('IndexedDBWrapper:remove error, not initialized')      
      return
    }

    try {
      await del(key, this.store)
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('IndexedDBWrapper:del error', key, error)
    }
  }
}

export default new IndexedDBWrapper()
