import { AlarmsTableFilterSettings } from './models'

export enum AlarmLogUiAction {
  openAlarmLogForPlc = 'openAlarmLogForPlc',
  closeAlarmLog = 'closeAlarmLog',
  loadFilterSettings = 'loadFilterSettings',
  updateFilterSettings = 'updateFilterSettings',
  navigateToParametrization = 'navigateToParametrization',
}

export enum AlarmLogUiMutation {
  setInteracted = 'setInteracted',
  setSelectedPlcId = 'setSelectedPlcId',
}

export enum AlarmLogUiGetter {
  isInteracted = 'isInteracted',
  selectedPlcId = 'selectedPlcId',
  filterSettings = 'filterSettings',
  alarms = 'alarms',
  filteredAlarms = 'filteredAlarms',
  alarmStatusOptions = 'alarmStatusOptions',
}

export interface AlarmLogUiState {
  interacted: boolean
  selectedPlcId: string
  filterSettings: AlarmsTableFilterSettings
}
