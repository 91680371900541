<template>
  <div class="slots" :class="[
    mappingsForEndpoint.length > 0 ? 'bordered' :'',
  ]"
  >
    <manual-mapping-solution-hardware-unmapped-endpoint
      v-if="addMappingActionVisible"
      :endpoint="endpoint"
      :index="index"
      :is-currently-mapping="isCurrentlyMapping"
      :disabled="!addMappingActionEnabled"
      @click="onMapped"
    />
    <manual-mapping-solution-hardware-mapped-endpoint
      :endpoint="endpoint"
      :mappings="mappingsForEndpoint"
      :index="index"
      @click="onUnmapped"
    />
  </div>
</template>
<script lang="ts">
import ManualMappingSolutionHardwareMappedEndpoint from '@/components/views/ecoSetupTool/steps/mapHardware/ManualMappingSolutionHardwareMappedEndpoint.vue'
import ManualMappingSolutionHardwareUnmappedEndpoint from '@/components/views/ecoSetupTool/steps/mapHardware/ManualMappingSolutionHardwareUnmappedEndpoint.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { DeviceMappingModel, SolutionHardwareInstanceModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

import { SolutionHardwareDefinitionEndpointModel, SolutionHardwareDefinitionModel } from '../../../../../../../eco-domain-store-modules/src/systemConfiguration/models'

const MappingUi = namespace('mappingUi')

@Component ({
  components: {
    ManualMappingSolutionHardwareMappedEndpoint,
    ManualMappingSolutionHardwareUnmappedEndpoint,
  },
})
export default class ManualMappingSolutionHardwareEndpoint extends Vue {
  @Prop() public endpoint: SolutionHardwareDefinitionEndpointModel
  @Prop() public index: string
  @Prop() public solutionHardwareInstance: SolutionHardwareInstanceModel
  @Prop() public solutionHardwareDefinition: SolutionHardwareDefinitionModel
  @MappingUi.Getter public existingManualMappingsForSolutionHardwareEndpoint
  @MappingUi.Getter public isAutoMappingStarted
  @MappingUi.Getter public isManualMappingStarted
  @MappingUi.Getter public doesSolutionHardwareEndpointAcceptAdditionalManualMappings
  @MappingUi.Getter public isCurrentlyManualMappingSolutionHardwareEndpoint
  @MappingUi.Getter public isMatchingSolutionHardwareManualMappingEndpoint
  @MappingUi.Action public addSolutionHardwareToCurrentManualMapping
  @MappingUi.Action public removeManualMapping

  public get addMappingActionVisible(): boolean {
    return this.doesSolutionHardwareEndpointAcceptAdditionalManualMappings(
      this.solutionHardwareInstance.id,
      this.endpoint)
  }

  public get addMappingActionEnabled(): boolean {
    if (this.isAutoMappingStarted) {
      return false
    }
    if (!this.isManualMappingStarted || this.isCurrentlyMapping) {
      return true
    }
    return this.isMatchingSolutionHardwareManualMappingEndpoint(
      this.solutionHardwareInstance.id,
      this.endpoint)
  }
  
  public get isCurrentlyMapping(): boolean {
    return this.isCurrentlyManualMappingSolutionHardwareEndpoint(
      this.solutionHardwareInstance.id,
      this.endpoint.id)
  }

  public get mappingsForEndpoint(): DeviceMappingModel[] {
    return this.existingManualMappingsForSolutionHardwareEndpoint(
      this.solutionHardwareInstance.id,
      this.endpoint.id)
  }

  public onMapped() {
    this.addSolutionHardwareToCurrentManualMapping({
      type: this.endpoint.isOutput ? 'output' : 'input',
      id: this.solutionHardwareInstance.id,
      endpointId: this.endpoint.id,
    })
  }

  public onUnmapped(mapping: DeviceMappingModel) {
    this.removeManualMapping(mapping)
  }
}
</script>

<style lang="scss" scoped>
.slots {
  &.bordered {
      border-width: 1px;
      border-style: solid;
      border-color: white;
  }
  .slot {
    display:flex;
    .label {
      flex: 1;
    }
    .action {
      flex: 0;
    }
  }
}
</style>
