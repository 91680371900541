import { actions } from '@/store/modules/solutionHardwareUi/actions'
import { getters } from '@/store/modules/solutionHardwareUi/getters'
import { mutations } from '@/store/modules/solutionHardwareUi/mutations'
import { state } from '@/store/modules/solutionHardwareUi/state'
import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { SolutionHardwareUiAction, SolutionHardwareUiGetter, SolutionHardwareUiMutation, SolutionHardwareUiState } from './types'

const namespaced: boolean = true
export const solutionHardwareUi: Module<SolutionHardwareUiState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action: SolutionHardwareUiAction, 
  mutation: SolutionHardwareUiMutation,
  getter: SolutionHardwareUiGetter,
}

export const domain = 'solutionHardwareUi'

export const vuexModule = {
  [domain]: solutionHardwareUi,
}