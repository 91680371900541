<template>
  <modal-dialog :show="show"
    header="Default alarm definition"
    button1-text="Save"
    button2-text="Cancel"
    :button1-disabled="!canSave"
    @button1Pressed="onSave"
    @button2Pressed="onClose"
  >
    <v-layout column>
      <form-field text="Alarm definition">
        <string-resource-select
          :options="filteredSystemConfigurationResources('DefaultAlarmDefinitions_', '_Name', false)"
          :selected-value="nameResourceId"
          category="SystemConfiguration"
          prefix="DefaultAlarmDefinitions_"
          postfix="_Name"
          default-value=""
          @selected="nameResourceId = $event"
          @created="onNameResourceCreated"
        />
      </form-field>
      <form-field text="Endpoint path">
        <autocomplete-select
          :options="functionDefinitionDefaultAlarmDefinitionEndpointPathOptions"
          :selected-value="endpointPath"
          :disabled="isInteracted || !isCreating"
          @selected="endpointPath = $event"
        />
      </form-field>
      <checkbox-with-label
        :value="isNumeric"
        :text="'alarmdefinition.numeric' | translate"
        :disabled="!endpointPath  || !isCreating"
        @checked="onIsNumericChanged(!$event)"
      />
      <!-- numeric endpoint -->
      <div v-if="isNumeric" class="condition-numeric">
        <form-field text="Condition">
          <div class="condition-numeric-form">
            <autocomplete-select 
              :options="functionDefinitionDefaultAlarmDefinitionOperatorOptions"
              :selected-value="operator"
              :disabled="!endpointPath"
              @selected="operator = $event"
            />
            <text-input type="number" pattern="\d*"
              :value="value"
              :disabled="!endpointPath"
              @input="value = Number($event)"
            />
          </div>
        </form-field>
        <form-field text="Hysteresis">
          <text-input type="number" pattern="\d*"
            :value="hysteresis"
            :disabled="!endpointPath"
            @input="hysteresis = Number($event)"
          />
        </form-field>
        <form-field text="Unit">
          <text-input
            :value="unit"
            :disabled="!endpointPath || !isCreating"
            @input="unit = $event"
          />
        </form-field>
      </div>
      <!-- boolean endpoint -->
      <div v-else>
        <form-field text="Condition">
          <autocomplete-select 
            :options="['True', 'False']"
            :selected-value="value ? 'True' : 'False'"
            :disabled="!endpointPath"
            @selected="value = $event === 'True'"
          />
        </form-field>
      </div>      
      <form-field text="Alarm message">
        <string-resource-select
          :options="filteredSystemConfigurationResources('DefaultAlarmDefinitions_', '_Message', false)"
          :selected-value="messageResourceId"
          category="SystemConfiguration"
          prefix="DefaultAlarmDefinitions_"
          postfix="_Message"
          default-value=""
          @selected="messageResourceId = $event"
          @created="onMessageResourceCreated"
        />
      </form-field>
      <form-field text="Level">
        <autocomplete-select
          :options="functionDefinitionDefaultAlarmLevelOptions"
          :selected-value="level"
          @selected="level = $event"
        />
      </form-field>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import StringResourceSelect from './StringResourceSelect.vue'

import { AlarmLevel, AlarmOperator } from '../../../../../eco-domain-store-modules/src/common'

import { DefaultAlarmDefinition } from '../../../../../eco-domain-store-modules/src/systemConfiguration/models'

import { newGuid } from './../../../store/helpers'

import { DropdownOption } from '../../../../../shared-components/src'

import { CreateResourceInput } from '../../../../../eco-domain-store-modules/src/resource/models'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')

@Component({
  components: {
    StringResourceSelect,
  },
})
export default class EditDefaultAlarmDefinitionDialog extends Vue {
  @Prop() public show: boolean
  @Prop() public model: DefaultAlarmDefinition
  @FunctionDefinitionEditorUi.Getter public actionInProgress: string
  @FunctionDefinitionEditorUi.Getter public functionDefinitionDefaultAlarmDefinitionEndpointPathOptions: string[]
  @FunctionDefinitionEditorUi.Getter public functionDefinitionDefaultAlarmDefinitionOperatorOptions: string[]
  @FunctionDefinitionEditorUi.Getter public functionDefinitionDefaultAlarmLevelOptions: string[]
  @FunctionDefinitionEditorUi.Getter public filteredSystemConfigurationResources:
    (prefix: string, postfix: string, allowEmpty: boolean) => DropdownOption[]
  @FunctionDefinitionEditorUi.Mutation public upsertFunctionDefinitionDefaultAlarmDefinition: (payload: DefaultAlarmDefinition) => void
  @FunctionDefinitionEditorUi.Action public createResource: (payload: CreateResourceInput) => Promise<void>

  public id = ''
  public nameResourceId = ''
  public messageResourceId = ''
  public operator = AlarmOperator.Equal
  public value: number | boolean = true
  public unit = ''
  public hysteresis = 0
  public isNumeric = false
  public endpointPath = ''
  public level = AlarmLevel.Critical

  public get isCreating() {
    return !this.model
  }

  @Watch('show')
  public onShow() {
    if (this.model) { // edit
      this.id = this.model.id
      this.nameResourceId = this.model.nameResourceId
      this.messageResourceId = this.model.messageResourceId
      this.operator = this.model.operator
      this.value = this.model.value
      this.unit = this.model.unit
      this.hysteresis = this.model.hysteresis
      this.isNumeric = this.model.isNumeric
      this.endpointPath = this.model.endpointPath
      this.level = this.model.level ?? AlarmLevel.Critical
    } else { // create
      this.id = newGuid(),
      this.nameResourceId = ''
      this.messageResourceId = ''
      this.operator = AlarmOperator.Equal
      this.value = true
      this.unit = ''
      this.hysteresis = 0
      this.isNumeric = false
      this.endpointPath = ''
      this.level = AlarmLevel.Critical
    }
  }

  public async onIsNumericChanged(value: boolean) {
    this.isNumeric = value
    if (!value) {
      this.operator = AlarmOperator.Equal
      this.value = true
      this.unit = ''
      this.hysteresis = 0
    } else {
      this.value = 0
    }
  }

  public get canSave() {
    return this.endpointPath && this.nameResourceId && this.messageResourceId
  }

  public onSave() {
    this.upsertFunctionDefinitionDefaultAlarmDefinition({
      id: this.id,
      nameResourceId: this.nameResourceId,
      messageResourceId: this.messageResourceId,
      operator: this.operator,
      value: this.value,
      unit: this.unit,
      hysteresis: this.hysteresis,
      isNumeric: this.isNumeric,
      endpointPath: this.endpointPath,
      level: this.level,
    })
    this.onClose()
  }

  @Emit('closed')
  public onClose() {
    return
  }

  public onNameResourceCreated(input: CreateResourceInput) {
    this.createResource(input)
    this.nameResourceId = input.id
  }

  public onMessageResourceCreated(input: CreateResourceInput) {
    this.createResource(input)
    this.messageResourceId = input.id
  }

}
</script>
<style lang="scss" scoped>
.condition-numeric {
  display: flex;
  .condition-numeric-form {
    display: flex;
    .text-input {
      height: 32px;
    }
  }
}
.field {
  height: 70px;
  .text-input {
    padding-left: 10px;
    padding-right: 10px;
  }
  .label {
    padding-left: 10px;
  }
}
.checkboxWithLabel {
  margin-left: -5px;
}
</style>