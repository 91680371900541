import { ActionTree } from 'vuex'
import fileHelper from '../helpers/fileHelper'
import { StatusCode } from '../services/api.service'
import commandApi from './api/command'
import queryApi from './api/query'
import { AccountModel, ConsumptionReportInput, EgonlineConfigurationModel, LoadInput, MeterAssignmentModel, MeterModel, MinergieConfigurationModel, MinergieVisualizationDataInput, OneClickTargetSystem, UserAssignmentModel, UserModel } from './models'
import { ConsumptionAction, ConsumptionMutation, ConsumptionState } from './types'

export const actions: ActionTree<ConsumptionState, {}> = {
  async [ConsumptionAction.loadProjects]({ commit }): Promise<void> {
    const data = await queryApi.loadProjects()
    commit(ConsumptionMutation.setProjects, data)
  },  
  async [ConsumptionAction.loadProject]({ commit }, payload: LoadInput): Promise<void> {
    let accounts: AccountModel[] = []
    let meters: MeterModel[] = []
    let users: UserModel[] = []
    let minergieInfo: MinergieConfigurationModel | null = null
    let egonlineConfiguration: EgonlineConfigurationModel | null = null
    if (payload.targetSystem === OneClickTargetSystem.Ecocoach) {
      [accounts, meters, users] = await Promise.all([
        queryApi.loadAccountsEcocoach(payload.projectId),
        queryApi.loadMetersEcocoach(payload.projectId),
        queryApi.loadUsersEcocoach(payload.projectId),
      ])
    } else if (payload.targetSystem === OneClickTargetSystem.Minergie) {
      [minergieInfo, accounts, meters] = await Promise.all([
        queryApi.loadInfoMinergie(payload.projectId),
        queryApi.loadAccountsMinergie(payload.projectId),
        queryApi.loadMetersMinergie(payload.projectId),
      ])
    } else if (payload.targetSystem === OneClickTargetSystem.Egonline) {
      [egonlineConfiguration, accounts, meters, users] = await Promise.all([
        queryApi.loadConfigurationEgonline(payload.projectId),
        queryApi.loadAccountsEgonline(payload.projectId),
        queryApi.loadMetersEgonline(payload.projectId),
        queryApi.loadUsersEgonline(payload.projectId),
      ])
    }
    commit(ConsumptionMutation.setProjectId, payload.projectId)
    commit(ConsumptionMutation.setTargetSystem, payload.targetSystem)
    commit(ConsumptionMutation.setAccounts, accounts)
    commit(ConsumptionMutation.setMeters, meters)
    commit(ConsumptionMutation.setUsers, users)
    commit(ConsumptionMutation.setMinergieInfo, minergieInfo)
    commit(ConsumptionMutation.setEgonlineConfiguration, egonlineConfiguration)
  },
  async [ConsumptionAction.createAccount]({ state, commit }, payload: AccountModel): Promise<void> {
    if (state.targetSystem === OneClickTargetSystem.Ecocoach) {
      const { id } = await commandApi.createAccountEcocoach(state.projectId, payload)
      commit(ConsumptionMutation.upsertAccount, { ...payload, id })
    } else if (state.targetSystem === OneClickTargetSystem.Minergie) {
      const { id } = await commandApi.createAccountMinergie(state.projectId,payload)
      commit(ConsumptionMutation.upsertAccount, { ...payload, id })
    } else if (state.targetSystem === OneClickTargetSystem.Egonline) {
      const { id } = await commandApi.createAccountEgonline(state.projectId,payload)
      commit(ConsumptionMutation.upsertAccount, { ...payload, id })
    }
  },
  async [ConsumptionAction.updateAccount]({ state, commit }, payload: AccountModel): Promise<void> {
    if (state.targetSystem === OneClickTargetSystem.Ecocoach) {
      await commandApi.modifyAccountEcocoach(state.projectId, payload)
      commit(ConsumptionMutation.upsertAccount, payload)
    } else if (state.targetSystem === OneClickTargetSystem.Minergie) {
      await commandApi.modifyAccountMinergie(state.projectId, payload)
      commit(ConsumptionMutation.upsertAccount, payload)
    } else if (state.targetSystem === OneClickTargetSystem.Egonline) {
      await commandApi.modifyAccountEgonline(state.projectId, payload)
      commit(ConsumptionMutation.upsertAccount, payload)
    }
  },
  async [ConsumptionAction.deleteAccount]({ state, commit }, payload: string): Promise<void> {
    if (state.targetSystem === OneClickTargetSystem.Ecocoach) {
      await commandApi.deleteAccountEcocoach(state.projectId, payload)
      commit(ConsumptionMutation.removeAccount, payload)
    } else if (state.targetSystem === OneClickTargetSystem.Minergie) {
      await commandApi.deleteAccountMinergie(state.projectId, payload)
      commit(ConsumptionMutation.removeAccount, payload)
    } else if (state.targetSystem === OneClickTargetSystem.Egonline) {
      await commandApi.deleteAccountEgonline(state.projectId, payload)
      commit(ConsumptionMutation.removeAccount, payload)
    }
  },
  async [ConsumptionAction.assignMeter]({ state, commit }, payload: MeterAssignmentModel): Promise<void> {
    const meter = state.meters.find(m => m.meterId === payload.meterId)!
    if (state.targetSystem === OneClickTargetSystem.Ecocoach) {
      await commandApi.assignMeterEcocoach(state.projectId, payload)
      commit(ConsumptionMutation.upsertMeter, { ...meter, consumptionAccountId: payload.consumptionAccountId } as MeterModel)
    } else if (state.targetSystem === OneClickTargetSystem.Minergie) {
      await commandApi.assignMeterMinergie(state.projectId, payload)
      commit(ConsumptionMutation.upsertMeter, { ...meter, consumptionAccountId: payload.consumptionAccountId } as MeterModel)
    } else if (state.targetSystem === OneClickTargetSystem.Egonline) {
      await commandApi.assignMeterEgonline(state.projectId, payload)
      commit(ConsumptionMutation.upsertMeter, { ...meter, consumptionAccountId: payload.consumptionAccountId } as MeterModel)
    }
  },
  async [ConsumptionAction.unassignMeter]({ state, commit }, payload: MeterAssignmentModel): Promise<void> {
    const meter = state.meters.find(m => m.meterId === payload.meterId)!
    if (state.targetSystem === OneClickTargetSystem.Ecocoach) {
      await commandApi.unassignMeterEcocoach(state.projectId, payload)
      commit(ConsumptionMutation.upsertMeter, { ...meter, consumptionAccountId: '' } as MeterModel)
    } else if (state.targetSystem === OneClickTargetSystem.Minergie) {
      await commandApi.unassignMeterMinergie(state.projectId, payload)
      commit(ConsumptionMutation.upsertMeter, { ...meter, consumptionAccountId: '' } as MeterModel)
    } else if (state.targetSystem === OneClickTargetSystem.Egonline) {
      await commandApi.unassignMeterEgonline(state.projectId, payload)
      commit(ConsumptionMutation.upsertMeter, { ...meter, consumptionAccountId: '' } as MeterModel)
    }
  },
  async [ConsumptionAction.assignUser]({ state, commit }, payload: UserAssignmentModel): Promise<void> {
    const user = state.users.find(u => u.userIdentifier === payload.dynamicMeterUserIdentifier)!
    if (state.targetSystem === OneClickTargetSystem.Ecocoach) {
      await commandApi.assignUserEcocoach(state.projectId, payload)
      commit(ConsumptionMutation.upsertUser, { ...user, consumptionAccountId: payload.consumptionAccountId } as UserModel)
    }
    if (state.targetSystem === OneClickTargetSystem.Egonline) {
      await commandApi.assignEgonlineUser(state.projectId, payload)
      commit(ConsumptionMutation.upsertUser, { ...user, consumptionAccountId: payload.consumptionAccountId } as UserModel)
    }
  },
  async [ConsumptionAction.unassignUser]({ state, commit }, payload: UserAssignmentModel): Promise<void> {
    const user = state.users.find(u => u.userIdentifier === payload.dynamicMeterUserIdentifier)!
    if (state.targetSystem === OneClickTargetSystem.Ecocoach) {
      await commandApi.unassignUserEcocoach(state.projectId, payload)
      commit(ConsumptionMutation.upsertUser, { ...user, consumptionAccountId: '' } as UserModel)
    }
    if (state.targetSystem === OneClickTargetSystem.Egonline) {
      await commandApi.unassignEgonlineUser(state.projectId, payload)
      commit(ConsumptionMutation.upsertUser, {...user, consumptionAccountId: ''} as UserModel)
    }
  },
  async [ConsumptionAction.linkAccountMinergie]({ state, commit }, minergieObjectId: string): Promise<void> {
    const result = await commandApi.linkProjectMinergie(state.projectId, minergieObjectId)
    commit(ConsumptionMutation.setMinergieInfo, result)
  },
  async [ConsumptionAction.unlinkAccountMinergie]({ state, commit }): Promise<void> {
    const result = await commandApi.unlinkProjectMinergie(state.projectId)
    commit(ConsumptionMutation.setMinergieInfo, result)
  },
  async [ConsumptionAction.verifyAccountMinergie]({ state, commit }): Promise<void> {
    try {
      const result = await commandApi.verifyProjectMinergie(state.projectId)
      commit(ConsumptionMutation.setMinergieInfo, result)
    } catch (error) {
      if ((error as any).response?.status === StatusCode.BadRequest) {
        commit(ConsumptionMutation.setMinergieInfo, {
          ...state.minergieInfo,
          isVerified: false,
        } as MinergieConfigurationModel)
      }
      throw error
    }
  },
  async [ConsumptionAction.linkProjectEgonline]({ state, commit }, egonlineProjectId: string): Promise<void> {
    const result = await commandApi.linkProjectEgonline(state.projectId, egonlineProjectId)
    commit(ConsumptionMutation.setEgonlineConfiguration, result)
  },
  async [ConsumptionAction.unlinkProjectEgonline]({ state, commit }): Promise<void> {
    const result = await commandApi.unlinkProjectEgonline(state.projectId)
    commit(ConsumptionMutation.setEgonlineConfiguration, result)
  },
  async [ConsumptionAction.loadReport]({ state }, reportParams: ConsumptionReportInput): Promise<void> {
    const data = await queryApi.loadReport(reportParams)
    const projectName = state.projects.find(p => p.id === reportParams.projectId)?.name ?? 'unknownProject'
    let fileName = 'project-' + projectName + '-interval-' + reportParams.aggregationInterval
    if (reportParams.consumptionAccountId && reportParams.consumptionAccountId !== '') {
      const accountName = state.accounts.find(a => a.id === reportParams.consumptionAccountId)?.name ?? ''
      fileName += ('-account-' + accountName)
    }
    if (reportParams.dateFrom && reportParams.dateFrom !== '') {
      fileName += ('-dateFrom-' + reportParams.dateFrom)
    }
    if (reportParams.dateTo && reportParams.dateTo !== '') {
      fileName += ('-dateTo-' + reportParams.dateTo)
    }
    if (reportParams.resourceLanguage && reportParams.resourceLanguage !== '') {
      fileName += ('-language-' + reportParams.resourceLanguage)
    }
    fileName += '-export.csv'
    fileHelper.saveBlobToFile(data, fileName)
  },
  async [ConsumptionAction.loadMinergieChartData]({ commit }, input: MinergieVisualizationDataInput): Promise<void> {
    const data = await queryApi.loadChartDataMinergie(input)
    commit(ConsumptionMutation.setMinergieChartData, data)
  },
}
