<template>
  <div class="log-row">
    <div class="log-level" :style="{ backgroundColor: backgroundColor(source) }" :title="`${source.logLevel}`">{{ source.logLevel }}</div>
    <div class="log-source" :title="`${source.logSource}`">{{ logSourceDisplayName(source) }}</div>
    <div class="log-timestamp" :title="`${source.logTimestamp} (UTC)`">{{ timeStampDisplayName(source) }}</div>
    <div class="log-message" :title="`${source.logMessage}`">{{ source.logMessage }}</div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { formatLogTimeStamp } from '../../../../../store/modules/logToolUi/helpers'

import { LogEntryModel, LogLevel } from '../../../../../store/modules/logToolUi/models'

@Component({
  components: {
  },
})
export default class VirtualLogEntriesTableItem extends Vue {
  @Prop() public index: number
  @Prop() public source: LogEntryModel

  public backgroundColor(logEntry: LogEntryModel) {
    switch (logEntry.logLevel) {
    case LogLevel.Error: return 'red'
    case LogLevel.Warning: return 'yellow'
    case LogLevel.Information: return 'blue'
    default: return 'transparent'
    }
  }

  public logSourceDisplayName(logEntry: LogEntryModel) {
    return logEntry.logSource.split('Ecocoach.EcoCloudConnector.').pop()
  }

  public timeStampDisplayName(logEntry: LogEntryModel) {
    return formatLogTimeStamp(logEntry.logTimestamp)
  }
}
</script>
<style lang="scss" scoped>
.log-row {
  display: flex;
  border-bottom: 1px solid gray;
}
.log-level, .log-source, .log-timestamp, .log-message {
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;    
}
.log-level {
  min-width: 80px;
  max-width: 80px;
}
.log-source {
  min-width: 200px;
  max-width: 200px;
}
.log-timestamp {
  min-width: 180px;
  max-width: 180px;
}
.log-message {
  min-width: 60%;
  flex: 1;
}
</style>
