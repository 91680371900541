<template>
  <modal-dialog 
    :show="show"
    :header="headerText"
    :button1-text="confirmText"
    :button2-text="cancelText"
    :button1-disabled="disabled"
    :button2-disabled="disabled"
    @button1Pressed="onOk"
    @button2Pressed="onCancelled"
  >
    <div class="body-text">{{ bodyText }}</div>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

import ModalDialog from './ModalDialog.vue'

@Component({
  components: {
    ModalDialog,
  },
})
export default class ConfirmDialog extends Vue {
  @Prop() public show: boolean
  @Prop() public header: string
  @Prop() public text: string
  @Prop() public confirmButtonText: string
  @Prop() public cancelButtonText: string
  @Prop({ default: false }) public disabled: boolean

  @Emit('confirmed') 
  public onOk () {
    return true
  }

  @Emit('cancelled') 
  public onCancelled () {
    return true
  }

  public get headerText() {
    return this.header || 'Delete'
  }

  public get bodyText() {
    return this.text || 'Are you sure?'
  }

  public get confirmText() {
    return this.confirmButtonText || 'OK'
  }

  public get cancelText() {
    return this.cancelButtonText || 'Cancel'
  }
}
</script>
<style lang="scss" scoped>
.body-text {
  text-align: left;
}
</style>