<template>
  <modal-dialog v-if="selectedPlcId"
    :show="true"
    :header="'plcConnection.history' | translate"
    closing-x=true
    @xPressed="closePlcConnectionHistory"
    width=1000
    height=750
  >
    <v-layout column>
      <plc-connection-history-table/>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import PlcConnectionHistoryTable from './PlcConnectionHistoryTable.vue'

const PlcConnectionHistoryUi = namespace('plcConnectionHistoryUi')

@Component({
  components: {
    PlcConnectionHistoryTable,
  },
})
export default class PlcConnectionHistory extends Vue { 
  @PlcConnectionHistoryUi.Getter public selectedPlcId: boolean
  @PlcConnectionHistoryUi.Action public closePlcConnectionHistory: () => Promise<void>
}
</script>
<style lang="scss" scoped>

</style>