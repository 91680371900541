import { MappingUiState } from '@/store/modules/mappingUi/types'

export const state: MappingUiState = {
  currentManualMapping: {
    type: '',
    deviceId: '',
    deviceEndpointId: '',
    solutionHardwareInstanceId: '',
    solutionHardwareEndpointId: '',
  },
  currentAutoMapping: {
    interfaceType: '',
    deviceId: '',
    deviceSlotInternalName: '',
    solutionHardwareInstanceId: '',
    solutionHardwareSlotInternalName: '',
    instanceNumber: -1,
  },
  interacted: false,
}
