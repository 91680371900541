export interface WizardStep {
    name: string
    stepText: string
    entryCondition: string[]
    entryConditionFailedText: string
}

export interface WizardState {
    toastIsVisible: boolean
    toastContent: string
    steps: WizardStep[]
    currentStep: WizardStep
    waiting: boolean
    releaseDialogOpened: boolean
}

export enum WizardMutation {
    wentToStep = 'wentToStep',
    hideToast = 'hideToast',
    showToast = 'showToast',
    setToastContent ='setToastContent',
    setWaiting = 'setWaiting',
    openReleaseDialog = 'openReleaseDialog',
    closeReleaseDialog = 'closeReleaseDialog',
}

export enum WizardAction {
    goToStep = 'goToStep',
    startConfigurePlc = 'startConfigurePlc',
    startParameterization = 'startParameterization',
    startAlarmDefinitions = 'startAlarmDefinitions',
    continueWithReleaseInEdit = 'continueWithReleaseInEdit',
}

export enum WizardGetter {
    wizardSteps = 'wizardSteps',
    getActiveStep = 'getActiveStep',
    entryConditionMet = 'entryConditionMet',
    isWaiting = 'isWaiting',
    isReleaseDialogOpen = 'isReleaseDialogOpen',
    conditionNotInEdit = 'conditionNotInEdit',
    conditionStillInEdit = 'conditionStillInEdit',
}