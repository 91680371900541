<template>
  <modal-dialog :show="show"
    header="Energy status item"
    button1-text="Save"
    button2-text="Cancel"
    :button1-disabled="!canSave"
    @button1Pressed="onSave"
    @button2Pressed="onClose"
  >
    <v-layout column>
      <form-field text="Endpoint path">
        <autocomplete-select
          :options="functionDefinitionEnergyStatusItemEndpointPathOptions"
          :selected-value="endpointPath"
          @selected="endpointPath = $event"
        />
      </form-field>
      <form-field text="Unit">
        <autocomplete-select 
          :options="functionDefinitionEnergyStatusItemUnitOptions"
          :selected-value="unit"
          @selected="unit = $event"
        />
      </form-field>        
      <form-field text="Type">
        <autocomplete-select 
          :options="functionDefinitionEnergyStatusItemTypeOptions"
          :selected-value="type"
          @selected="type = $event"
        />
      </form-field>
      <form-field text="Subscribe/Poll">
        <autocomplete-select
          :options="['Subscribe', 'Poll']"
          :selected-value="toSubscribe ? 'Subscribe' : 'Poll'"
          @selected="toSubscribe = $event === 'Subscribe'"
        />
      </form-field>
      <form-field text="Polling interval (ms)" v-if="!toSubscribe">
        <text-input
          :value="pollInterval"
          @changed="pollInterval = $event"
        />
      </form-field>
      <form-field text="Color gradient (optional)">
        <color-gradient-picker
          :color-gradient="colorGradient"
          @selected="colorGradient = $event"
        />
      </form-field>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { EnergyStatusItemType } from '../../../../../eco-domain-store-modules/src/common'
import { EnergyStatusItem } from '../../../../../eco-domain-store-modules/src/systemConfiguration/models'

import { newGuid } from '../../../store/helpers'
import { DEFAULT_POLLING_INTERVAL_MS } from '../../../store/modules/functionDefinitionEditorUi/models'

import ColorGradientPicker from '@/components/ui/ColorGradientPicker.vue'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')

@Component({
  components: {
    ColorGradientPicker,
  },
})
export default class EditEnergyStatusItemDialog extends Vue {
  @Prop() public show: boolean
  @Prop() public model: EnergyStatusItem
  @FunctionDefinitionEditorUi.Getter public actionInProgress: string
  @FunctionDefinitionEditorUi.Getter public functionDefinitionEnergyStatusItemEndpointPathOptions: string[]
  @FunctionDefinitionEditorUi.Getter public functionDefinitionEnergyStatusItemUnitOptions: string[]
  @FunctionDefinitionEditorUi.Getter public functionDefinitionEnergyStatusItemTypeOptions: string[]
  @FunctionDefinitionEditorUi.Mutation public upsertFunctionDefinitionEnergyStatusItem: (payload: EnergyStatusItem) => void

  public id = ''
  public endpointPath = ''
  public unit = ''
  public type = ''
  public toSubscribe = false
  public pollInterval = DEFAULT_POLLING_INTERVAL_MS
  public colorGradient = ''

  @Watch('show')
  public async onShow() {
    if (this.model) { // edit
      this.id = this.model.id
      this.endpointPath = this.model.endpointPath
      this.unit = this.model.unit
      this.type = this.model.type
      this.toSubscribe = this.model.toSubscribe
      this.pollInterval = this.model.pollInterval
      this.colorGradient = this.model.colorGradient
    } else { // create
      this.id = newGuid(),
      this.endpointPath = ''
      this.unit = ''
      this.type = ''
      this.toSubscribe = false
      this.pollInterval = DEFAULT_POLLING_INTERVAL_MS
      this.colorGradient = ''
    }
  }

  public get canSave() {
    return this.endpointPath && this.unit && this.type
  }

  public onSave() {
    this.upsertFunctionDefinitionEnergyStatusItem({
      id: this.id,
      endpointPath: this.endpointPath,
      unit: this.unit,
      type: this.type as EnergyStatusItemType,
      toSubscribe: this.toSubscribe,
      pollInterval: this.pollInterval,
      colorGradient: this.colorGradient,
    })
    this.onClose()
  }

  @Emit('closed')
  public onClose() {
    return
  }
}
</script>
<style lang="scss" scoped>
.field {
  height: 70px;
  .text-input {
    padding-left: 10px;
    padding-right: 10px;
  }
  .label {
    padding-left: 10px;
  }
  .color-gradient-picker {
    padding: 10px;
  }
}
</style>