<template>
  <div class="dashboard">
    <wizard-step-header :title="'navigation.releaseNotes' | translate" />
    <!--- Web App --->
    <h2>{{'webapp.title' | translate}}</h2>
    <h3>{{'current.version' | translate}}: {{version}}</h3>
    <v-container grid-list-xs fluid class="release-notes-container">
      <v-layout row nowrap>
        <v-flex xs6 lg3>
          <v-card dark class="release-notes-header-card">
            <v-card-text pl-10>{{'information.version' | translate}}</v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs8 lg4>
          <v-card dark class="release-notes-header-card">
            <v-card-text pl-10>{{'navigation.releaseNotes' | translate}}</v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout
        row
        nowrap
        v-for="webAppReleaseNote in webAppReleaseNotes"
        :key="webAppReleaseNote.version"
      >
        <v-flex xs6 lg3 d-flex>
          <v-card dark class="release-notes-row-card">
            <v-card-text class="pl-10">{{webAppReleaseNote.version}}</v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs8 lg4>
          <v-card dark class="release-notes-row-card">
            <v-card-text class="pl-10" v-html="webAppReleaseNote.releaseNotes"></v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!--- ecoCloudConnector --->
    <h2>ecoCloudConnector</h2>
    <v-container grid-list-xs fluid class="release-notes-container">
      <v-layout row nowwrap>
        <v-flex xs6 lg3>
          <v-card dark class="release-notes-header-card">
            <v-card-text pl-10>{{'information.version' | translate}}</v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs8 lg4>
          <v-card dark class="release-notes-header-card">
            <v-card-text pl-10>{{'navigation.releaseNotes' | translate}}</v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row nowrap v-for="eccReleaseNote in eccReleaseNotes" :key="eccReleaseNote.version">
        <v-flex xs6 lg3 d-flex>
          <v-card dark class="release-notes-row-card">
            <v-card-text class="pl-10">{{eccReleaseNote.version}}</v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs8 lg4>
          <v-card dark class="release-notes-row-card">
            <v-card-text class="pl-10" v-html="eccReleaseNote.releaseNotes"></v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { ReleaseNotesViewModel } from '../../store/modules/app/models'

const App = namespace('app')

@Component
export default class ReleaseNotes extends Vue {
  @App.Getter public webAppReleaseNotes: ReleaseNotesViewModel[]
  @App.Getter public eccReleaseNotes: ReleaseNotesViewModel[]
  @App.Getter public version: string
}
</script>
<style lang="scss" scoped>
header {
  padding: 30px 0 0 25px;
  height: 100px;
}
h2 {
  color: #fff;
  font-size: 1.5em;
  font-weight: 100;
  text-transform: uppercase;
  font-family: Titillium Web, sans-serif;
  padding: 0 0 0 25px;
}
h3 {
  color: #fff;
  font-family: Titillium Web, sans-serif;
  font-weight: normal;
  padding: 0 0 0 25px;
}
.release-notes-container {
  padding-top: 5px;
  .release-notes-header-card {
    background-color: rgb(15, 58, 100);
  }
  .release-notes-row-card {
    background-color: rgb(32, 71, 110);
  }
}
</style>