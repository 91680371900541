import { PlcConnectionHistoryTableFilterSettings } from './models'

export enum PlcConnectionHistoryUiAction {
  openPlcConnectionHistoryForPlc = 'openPlcConnectionHistoryForPlc',
  closePlcConnectionHistory = 'closePlcConnectionHistory',
  loadFilterSettings = 'loadFilterSettings',
  updateFilterSettings = 'updateFilterSettings',
}

export enum PlcConnectionHistoryUiMutation {
  setInteracted = 'setInteracted',
  setSelectedPlcId = 'setSelectedPlcId',
}

export enum PlcConnectionHistoryUiGetter {
  isInteracted = 'isInteracted',
  selectedPlcId = 'selectedPlcId',
  filterSettings = 'filterSettings',
  plcOperationStateHistoryItems = 'plcOperationStateHistoryItems',
  filteredPlcOperationStateHistoryItems = 'filteredPlcOperationStateHistoryItems',
  statuses = 'statuses',
}

export interface PlcConnectionHistoryUiState {
  interacted: boolean
  selectedPlcId: string
  filterSettings: PlcConnectionHistoryTableFilterSettings
}
