<template>
  <div>
    <div class="alarm-value" v-if="!alarm.isOpen">
      {{ 'alarm.peakValue' | translate }}: {{ alarm.peakValueText }}
    </div>
    <div class="alarm-condition">
      {{ 'alarmdefinition.condition' | translate }}: {{ alarm.alarmConditionText }}
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { AlarmViewModel } from '../../../../../store/modules/alarmLogUi/models'

@Component({
  components: {
  },
})
export default class AlarmTableValue extends Vue {
  @Prop() public alarm: AlarmViewModel
}
</script>
<style lang="scss" scoped>
</style>
