import { SolutionHardwareUiState } from '@/store/modules/solutionHardwareUi/types'

export const state: SolutionHardwareUiState = {
  interacted: false,
  clampLines: {
    masterClampLine: [],
    slaveClampLines: [],
  },
  modules: [],
}
