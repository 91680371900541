export interface DeviceUiState {
  interacted: boolean
  modifiedDeviceNames: Map<string, string>
  modifiedDeviceIcons: Map<string, string>
}

export enum DeviceUiAction {
  createDevice = 'createDevice',
  saveDevice = 'saveDevice',
  deleteDevice = 'deleteDevice',
  sortDevices = 'sortDevices',
}

export enum DeviceUiMutation {
  setInteracted = 'setInteracted',
  setDeviceName = 'setDeviceName',
  setDeviceIcon = 'setDeviceIcon',
  clearChanges = 'clearChanges',
}

export enum DeviceUiGetter {
  isInteracted = 'isInteracted',
  devicesOfRoom = 'devicesOfRoom',
  isMappedDevice = 'isMappedDevice',
  architectureType = 'architectureType',
}
