import { MutationTree } from 'vuex'
import { ChartCollectionModel, ChartDataModel } from './models'
import { ChartMutation, ChartState } from './types'

export const mutations: MutationTree<ChartState> = {
  [ChartMutation.setChartCollections](state: ChartState, payload: ChartCollectionModel[]) {
    state.chartCollections = payload
  },
  [ChartMutation.setChartData](state: ChartState, payload: ChartDataModel) {
    state.chartData = payload
  },
}