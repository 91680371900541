export interface ProjectModel {
  id: string
  name: string
  currentUserAuthorization: string
}

export interface RoomModel {
  roomId: string
  projectId: string
  plcId: string
  name: string
  icon: string
  sortOrder: number
}

export interface AuthorizationsModelBase {
  id: string
  permissions: UserPermissions[]
}

// tslint:disable-next-line no-empty-interface
export type ProjectAuthorizationsModel = AuthorizationsModelBase

export interface PlcAuthorizationsModel extends AuthorizationsModelBase {
  id: string
  projectId: string
  name: string
}

export interface DeviceAuthorizationsModel extends AuthorizationsModelBase {
  id: string
  plcId: string
  projectId: string
  roomId: string
  name: string
  icon: string
  sortOrder: number
}

export interface AlarmDefinitionAuthorizationsModel extends AuthorizationsModelBase {
  id: string
  plcId: string
  projectId: string
  name: string
  nameResourceId: string
}

export interface UserPermissions {
  userIdentifier: string
  permissions: Permission[]
}

export enum Permission {
  READ = 'Read',
  EDIT = 'Edit',
  DELETE = 'Delete',
  EXECUTE = 'Execute',
  AUTHORIZE = 'Authorize',
  SYSTEM_NOTIFICATION = 'SystemNotification',
  SYSTEM_NOTIFICATION_EMAIL = 'SystemNotificationEmail',
  CUSTOM_NOTIFICATION = 'CustomNotification',
  CUSTOM_NOTIFICATION_EMAIL = 'CustomNotificationEmail',
}

export interface PartnerModel {
  id: string
  name: string
  ownerMail: string
}

export interface SetProjectAuthorizationPayload {
  projectId: string
  userIdentifier: string
  permissions: Permission[]
}

export interface SetConsumptionAuthorizationPayload {
  projectId: string
  userIdentifier: string
  permissions: Permission[]
}

export interface SetPlcAuthorizationPayload {
  plcId: string
  userIdentifier: string
  permissions: Permission[]
}

export interface SetDeviceAuthorizationsPayload {
  deviceIds: string[]
  userIdentifier: string
  permissions: Permission[]
}

export interface SetAlarmDefinitionAuthorizationsPayload {
  alarmDefinitionIds: string[]
  userIdentifier: string
  permissions: Permission[]
}
