<template>
  <modal-dialog v-if="selectedPlcId"
    :show="true"
    :header="'alarm.history' | translate"
    closing-x=true
    @xPressed="closeAlarmLog"
    width=1200
    height=750
  >
    <v-layout column>
      <alarm-table/>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import AlarmTable from './AlarmTable.vue'

const AlarmLogUi = namespace('alarmLogUi')

@Component({
  components: {
    AlarmTable,
  },
})
export default class AlarmLog extends Vue { 
  @AlarmLogUi.Getter public isInteracted: boolean
  @AlarmLogUi.Getter public selectedPlcId: boolean
  @AlarmLogUi.Action public closeAlarmLog: () => Promise<void>

}
</script>
<style lang="scss" scoped>

</style>