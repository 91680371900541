import { MutationTree } from 'vuex'
import { sortedByName, sortedBySortOrder } from '../utils'
import { upsertPermissions } from './helpers'
import { AlarmDefinitionAuthorizationsModel, DeviceAuthorizationsModel, PartnerModel, PlcAuthorizationsModel, ProjectAuthorizationsModel, ProjectModel, RoomModel, SetAlarmDefinitionAuthorizationsPayload, SetConsumptionAuthorizationPayload, SetDeviceAuthorizationsPayload, SetPlcAuthorizationPayload, SetProjectAuthorizationPayload } from './models'
import { AuthorizationMutation, AuthorizationState } from './types'

export const mutations: MutationTree<AuthorizationState> = {
  [AuthorizationMutation.setPartners](state, payload: PartnerModel[]) {
    state.partners = payload
  },
  [AuthorizationMutation.setProjects](state, payload: ProjectModel[]) {
    state.projects = sortedByName(payload)
  },
  [AuthorizationMutation.setConsumptionProjects](state, payload: ProjectModel[]) {
    state.consumptionProjects = sortedByName(payload)
  },  
  [AuthorizationMutation.setProjectAuthorizations](state, payload: ProjectAuthorizationsModel) {
    state.projectAuthorizations = payload
  },
  [AuthorizationMutation.setConsumptionAuthorizations](state, payload: ProjectAuthorizationsModel) {
    state.consumptionAuthorizations = payload
  },
  [AuthorizationMutation.setPlcAuthorizations](state, payload: PlcAuthorizationsModel[]) {
    state.plcAuthorizations = payload
  },
  [AuthorizationMutation.setDeviceAuthorizations](state, payload: DeviceAuthorizationsModel[]) {
    state.deviceAuthorizations = payload
  },
  [AuthorizationMutation.setAlarmDefinitionAuthorizations](state, payload: AlarmDefinitionAuthorizationsModel[]) {
    state.alarmDefinitionAuthorizations = payload
  },
  [AuthorizationMutation.setRooms](state, payload: RoomModel[]) {
    state.rooms = sortedBySortOrder(payload)
  },
  [AuthorizationMutation.upsertProjectAuthorization](state, payload: SetProjectAuthorizationPayload) {
    upsertPermissions([state.projectAuthorizations], payload.projectId, payload.userIdentifier, payload.permissions)
  },
  [AuthorizationMutation.upsertConsumptionAuthorization](state, payload: SetConsumptionAuthorizationPayload) {
    upsertPermissions([state.consumptionAuthorizations], payload.projectId, payload.userIdentifier, payload.permissions)
  },
  [AuthorizationMutation.upsertPlcAuthorization](state, payload: SetPlcAuthorizationPayload) {
    upsertPermissions(state.plcAuthorizations, payload.plcId, payload.userIdentifier, payload.permissions)
  },
  [AuthorizationMutation.upsertDeviceAuthorizations](state, payload: SetDeviceAuthorizationsPayload) {
    payload.deviceIds.forEach(deviceId => {
      upsertPermissions(state.deviceAuthorizations, deviceId, payload.userIdentifier, payload.permissions)
    })
  },
  [AuthorizationMutation.upsertAlarmDefinitionAuthorizations](state, payload: SetAlarmDefinitionAuthorizationsPayload) {
    payload.alarmDefinitionIds.forEach(alarmDefinitionId => {
      upsertPermissions(state.alarmDefinitionAuthorizations, alarmDefinitionId, payload.userIdentifier, payload.permissions)
    })
  },
  [AuthorizationMutation.setAppRegistrationNames](state, payload: Map<string, string>) {
    state.appRegistrationNameLookup = payload
  },
}
