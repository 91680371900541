<template>
  <v-flex shrink>
    <div class="action">
      <v-tooltip bottom>
          <v-btn slot="activator" icon ripple :disabled="disabled" @click.stop="emitClick">
              <v-icon class="actionIcon">{{icon}}</v-icon>
          </v-btn>
          <span>{{tooltip}}</span>
      </v-tooltip>
    </div>
  </v-flex>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class CardAction extends Vue {
  @Prop() public icon: string
  @Prop() public tooltip: string
  @Prop() public disabled: boolean
  @Emit('click') public emitClick() {
    return true
  }
}
</script>

<style lang="scss" type="text/scss" scoped>
  .action {
    max-width: 30px;
    min-width: 30px;
  }
</style>