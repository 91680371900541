import { ProjectModel } from './models'

export interface ProjectState {
  projects: ProjectModel[]
}

export enum ProjectAction {
  loadProjects = 'loadProjects',
  createProject = 'createProject',
  updateProject = 'updateProject',
  deleteProject = 'deleteProject',
  createPlc = 'createPlc',
  updatePlc = 'updatePlc',
  deletePlc = 'deletePlc',
}

export enum ProjectMutation {
  setProjects = 'setProjects',
  upsertProject = 'upsertProject',
  removeProject = 'removeProject',
  upsertPlc = 'upsertPlc',
  removePlc = 'removePlc',
}
