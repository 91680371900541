<template>
  <!-- show panel if there is no editing release (and a completed is displayed) -->
  <div v-if="conditionNotInEdit" class="info-area">
    <div class="info-text">
        {{ 'releases.not.edit' | translate}}
    </div>
    <div class="button-area">
        <base-text-button
            class="action-button"
            :enabled="!isWaiting"
            @buttonClicked="useReleaseForCopy">
            {{ 'releases.choose' | translate }}
        </base-text-button>
    </div>
  </div>
  <!-- show panel if there -->
  <div v-else-if="conditionStillInEdit && currentNewRelease && (currentNewRelease.id !== releaseId)" class="info-area">
    <div class="info-text">
        {{ 'releases.load.edit' | translate}}
    </div>
    <div class="button-area">
        <base-text-button
            class="action-button"
            :enabled="!isWaiting"
            @buttonClicked="continueWithReleaseInEdit($router)">
            {{ 'releases.load' | translate }}
        </base-text-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ArchitectureType } from '../../../../../../../eco-domain-store-modules/src/common'

const Wizard = namespace('wizard')
const App = namespace('app')
const PlcConfiguration = namespace('plcConfiguration')
const ReleaseUi = namespace('releaseUi')

@Component({
  components: {
  },
})
export default class ReleaseInformationPanel extends Vue {
  @ReleaseUi.Action public copyRelease: (releaseId: string) => Promise<void>
  @ReleaseUi.Action public upgradeRelease: (useInTestDefinitions: boolean) => Promise<void>
  @PlcConfiguration.Getter public releaseId
  @ReleaseUi.Getter public currentNewRelease
  @ReleaseUi.Getter public currentReleaseShouldBeUpgraded: boolean
  @ReleaseUi.Mutation public showReleaseUpgradeConfirmationDialog: () => void
  @Wizard.Action public continueWithReleaseInEdit
  @Wizard.Action public goToStep
  @Wizard.Getter public conditionNotInEdit
  @Wizard.Getter public conditionStillInEdit
  @Wizard.Getter public wizardSteps
  @Wizard.Getter public isWaiting
  @Wizard.Mutation public setWaiting
  @App.Getter public selectedLanguage
  @App.Getter public userIdentifier: string
  @App.Getter public isEcocoachEmployee: boolean

  public async useReleaseForCopy() {
    await this.copyRelease(this.releaseId)
    if (this.shouldShowReleaseUpgradeConfirmationDialog) {
      this.showReleaseUpgradeConfirmationDialog()
      return
    }
    if (!this.isEcocoachEmployee) {
      await this.upgradeReleaseSilentlyIfRequired()
    }
    const step = this.wizardSteps.find(s => s.name === 'defineRooms')
    this.goToStep({step, router: this.$router})
  }

  public get shouldShowReleaseUpgradeConfirmationDialog() {
    return this.currentNewRelease.architectureType === ArchitectureType.ObjectOriented
      ? this.isEcocoachEmployee
      : (this.currentReleaseShouldBeUpgraded || this.isEcocoachEmployee)
  }

  public async upgradeReleaseSilentlyIfRequired() {
    if (!this.isEcocoachEmployee && this.currentNewRelease.architectureType === ArchitectureType.ObjectOriented) {
      await this.upgradeRelease(false)
    }
  }
} 
</script>
<style lang="scss" scoped>
.info-area {
    flex: 1;
    height: 55px;
    padding: 2px;
    border: 1px solid white;
    display: flex;
    .info-text{
        flex: 1;
        text-align: center;
        line-height: 45px;
    }
    .action-button{
      border: 1px solid white;
    }
}

</style>