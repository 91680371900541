<template>
  <card :header="'Database logging'" :chip-text="chipText" :chip-color="chipColor" :collapsible="true" :expanded="expanded" @expandedChanged="expandedChanged">
    <template v-slot:actions>
      <card-action
        icon="delete"
        tooltip="Clear all logs"
        :disabled="isInteracted || isLoading || !dbLogEntries.length"
        @click="clearDbLogEntries"
      />
      <card-action
        icon="download"
        tooltip="Download all log data as CSV"
        :disabled="isInteracted || isLoading || !dbLogEntries.length"
        @click="downloadDbLogEntries"
      />
    </template>
    <log-config-editor :destination="logDestination"/>
    <form-field :text="'Load from database'">
      <div class="field-row">
        <autocomplete-select class="fetch-loglevel"
          :options="logLevelOptions"
          option-value="id"
          option-label="label"
          :selected-value="logLevelToFetch"
          :disabled="isInteracted || isLoading"
          @selected="logLevelToFetch = $event"
        />
        <text-input class="fetch-amount" type="number" pattern="\d*"
          :value="numEntriesToFetch"
          :disabled="isInteracted || isLoading"
          @input="numEntriesToFetch = Number($event)"
        />
        <base-text-button class="fetch-button" @buttonClicked="onRetrieveLogsFromDb">Load</base-text-button>
      </div>
    </form-field>
    <form-field :text="'Purge from database'">
      <div class="field-row">
        <autocomplete-select class="purge-loglevel"
          :options="logLevelOptions"
          option-value="id"
          option-label="label"
          :selected-value="logLevelToPurge"
          :disabled="isInteracted || isLoading"
          @selected="logLevelToPurge = $event"
        />
        <base-text-button class="purge-button" @buttonClicked="showPurgeConfirmationDialog = true">Purge</base-text-button>
      </div>
    </form-field>
    <div class="log-entries-table">
      <virtual-log-entries-table :log-entries="dbLogEntries"/>
    </div>
    <confirm-dialog
      :show="showPurgeConfirmationDialog"
      :header="'common.button.delete' | translate"
      :text="'common.dialog.confirm' | translate"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      :disabled="isInteracted"
      @confirmed="onPurgeConfirmed"
      @cancelled="showPurgeConfirmationDialog = false"
    />
  </card>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Card from '../../../../ui/Card.vue'
import CardAction from '../../../../ui/CardAction.vue'

import LogConfigEditor from './LogConfigEditor.vue'

import VirtualLogEntriesTable from './VirtualLogEntriesTable.vue'

import { DropdownOption } from '@ecocoach/shared-components/src'
import { logLevelChipColor } from '../../../../../store/modules/logToolUi/helpers'
import { GetLogsFromDbPayload, LogDestination, LogEntryModel, LogLevel } from '../../../../../store/modules/logToolUi/models'

const LogToolUi = namespace('logToolUi')

@Component({
  components: {
    Card,
    CardAction,
    LogConfigEditor,
    VirtualLogEntriesTable,
  },
})
export default class DbLogging extends Vue { 
  @Prop() public expanded: boolean
  @LogToolUi.Getter public isInteracted: boolean
  @LogToolUi.Getter public isLoading: boolean
  @LogToolUi.Getter public defaultLogLevelForDestination: (destination: LogDestination) => LogLevel
  @LogToolUi.Getter public logLevelOptions: DropdownOption[]
  @LogToolUi.Getter public dbLogEntries: LogEntryModel[]
  @LogToolUi.Mutation public clearDbLogEntries: () => void
  @LogToolUi.Action public retrieveLogsFromDb: (payload: GetLogsFromDbPayload) => Promise<void>
  @LogToolUi.Action public purgeLogsFromDb: (payload: LogLevel) => Promise<void>
  @LogToolUi.Action public downloadDbLogEntries: () => Promise<void>

  public logLevelToFetch = LogLevel.Error
  public numEntriesToFetch = 10 

  public logLevelToPurge = LogLevel.Information
  public showPurgeConfirmationDialog = false

  public get logDestination() {
    return LogDestination.Db
  }

  public get defaultLogLevel() {
    return this.defaultLogLevelForDestination(this.logDestination)
  }

  public get chipText() {
    return this.defaultLogLevel
  }

  public get chipColor() {
    return logLevelChipColor(this.defaultLogLevel)
  }

  public onRetrieveLogsFromDb() {
    this.retrieveLogsFromDb({
      logLevel: this.logLevelToFetch,
      numEntries: this.numEntriesToFetch,
    })
  }

  public onShowPurgeConfirmationDialog() {
    this.showPurgeConfirmationDialog = true
  }

  public async onPurgeConfirmed() {
    await this.purgeLogsFromDb(this.logLevelToPurge)
    this.showPurgeConfirmationDialog = false
  }

  @Emit('expandedChanged') 
  public expandedChanged(expanded: boolean) {
    return expanded
  }
}
</script>
<style lang="scss" scoped>
.field {
  height: 70px;
  .field-row {
    display: flex;
  }
}
.fetch-amount {
  margin-left: 10px;
  flex: 0;
  height: 32px;
  min-width: 150px;
  margin-right: 20px;
}
.log-entries-table {
  height: 500px;
}
.v-btn {
  border: 1px solid white;
  margin: 0px;
}

</style>