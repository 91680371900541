<template>
<main>
  <v-card dark class="room" width="350">
    <v-card-title primary-title>
      <h3>{{ 'rooms.edit' | translate }}: {{ getRoomInputProperty('name') }}</h3>
    </v-card-title>
    <v-container class="room-form-content">
      <v-layout column>
        <text-field 
          :value="getRoomInputProperty('name')"
          :placeholder="dictionary('rooms.name')"
          :changesHandler="onNameChanged"/>
        <icon-chooser-popover class="icon-input"
          :title="dictionary('icon.choose')"
          :icons="roomIcons"
          :selected="getRoomInputProperty('icon')"
          @iconSelected="onIconChanged"/>
      </v-layout>
    </v-container>
    <v-card-actions>
      <v-layout row align-center justify-space-around>
        <v-flex xs4>
          <panel-box-action
            :isDisabled="!isInputValid"
            :isLoading="isInteracted"
            :title="dictionary('common.button.save')"
            :handler="saveRoom"/>
        </v-flex>
        <v-flex xs4>
          <panel-box-action
            :title="dictionary('common.button.cancel')"
            :handler="cancelRoom"/>
        </v-flex>
        <v-flex xs4>
          <panel-box-action
            :title="dictionary('common.button.delete')"
            :handler="askForDeleteConfirmation"/>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-card>
  <confirm-dialog
    :show="showDeleteConfirmation"
    :header="'wizard.rooms.alert.delete.headline' | translate"
    :text="'wizard.rooms.alert.delete.content' | translate"
    :confirm-button-text="'common.button.delete' | translate"
    :cancel-button-text="'common.button.cancel' | translate"
    :disabled="isInteracted"
    @confirmed="onDeleteConfirmed"
    @cancelled="showDeleteConfirmation = false"
  />
</main>
</template>

<script lang="ts">
import IconChooserPopover from '@/components/ui/IconChooserPopover.vue'
import PanelBoxAction from '@/components/ui/PanelBoxAction.vue'
import TextField from '@/components/ui/TextField.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { ResourceCategory, ResourceModel } from '../../../../../../../eco-domain-store-modules/src/resource/models'

const Resource = namespace('resource')
const RoomUi = namespace('roomUi')

@Component({
  components: {
    PanelBoxAction,
    TextField,
    IconChooserPopover,
  },
})

export default class RoomFormEdit extends Vue {
  @Resource.Getter public resourcesOfCategory: (category: ResourceCategory) => ResourceModel[]
  @Resource.Getter public dictionary
  @RoomUi.Getter public isInteracted: boolean
  @RoomUi.Getter public getRoomInputProperty: (property: string) => any
  @RoomUi.Mutation public setRoomInputProperty: (payload: { property: string, value: string }) => void
  @RoomUi.Action public saveRoom: () => Promise<void>
  @RoomUi.Action public cancelRoom: () => Promise<void>
  @RoomUi.Action public deleteRoom: (id: string) => Promise<void>

  public showDeleteConfirmation: boolean = false

  public get roomIcons() {
    return this.resourcesOfCategory(ResourceCategory.ROOM_ICONS)
  }

  public get isInputValid() {
    return !!(this.getRoomInputProperty('name') || '').trim()
  }

  public onNameChanged(value: string) {
    this.setRoomInputProperty({
      property: 'name',
      value: value.trim(),
    })
  }

  public onIconChanged(value: string) {
    this.setRoomInputProperty({
      property: 'icon',
      value,
    })
  }

  public askForDeleteConfirmation() {
    this.showDeleteConfirmation = true
  }

  public onDeleteConfirmed() {
    const roomId = this.getRoomInputProperty('id')
    this.deleteRoom(roomId)
    this.showDeleteConfirmation = false
  }
}
</script>

<style lang="scss">
.icon-input {
  padding: 20px 0px;
}
</style>

<style lang="scss" scoped>
.v-card__title {
  background: linear-gradient(to bottom, #1a3856, #080e19);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #1a3856), color-stop(0.6, #080e19));
}
.v-card__title--primary {
  padding-top: 15px;
}
.v-card__actions {
  padding: 0;
  button {
    margin: 0;
  }
}
.room-form-content {
  background-image: radial-gradient(circle at center top, #1a3856, #080e19);
}
</style>