import { ActionTree } from 'vuex'
import api from './api'
import { NoteModel } from './models'
import { NoteAction, NoteMutation, NoteState} from './types'

export const actions: ActionTree<NoteState, {}> = {
  async [NoteAction.initializeNotesForPlc]({ commit }, plcId: string): Promise<void> {
    const data = await api.loadNotesForPlc(plcId)
    commit(NoteMutation.setPlcId, plcId)
    commit(NoteMutation.setNotesForPlc, data)
  },
  async [NoteAction.uninitializeNotesForPlc]({ commit }): Promise<void> {
    commit(NoteMutation.setPlcId, '')
    commit(NoteMutation.setNotesForPlc, [])
  },
  async [NoteAction.createNoteForPlc]({ commit, state }, payload: NoteModel): Promise<void> {
    const note = await api.createNoteForPlc(state.plcId, payload)
    commit(NoteMutation.upsertNoteForPlc, note)
  },
  async [NoteAction.updateNoteForPlc]({ commit, state }, payload: NoteModel): Promise<void> {
    const note = await api.updateNoteForPlc(state.plcId, payload)
    commit(NoteMutation.upsertNoteForPlc, note)
  },
  async [NoteAction.deleteNoteForPlc]({ commit, state }, payload: string): Promise<void> {
    await api.deleteNoteForPlc(state.plcId, payload)
    commit(NoteMutation.removeNoteForPlc, payload)
  },
}
