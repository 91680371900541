<template>
  <eco-labeled-control
    :context="context"
    :attributes="attributes"
  >
    <div class="value">
      <span>{{ currentValue }}</span>
    </div>
  </eco-labeled-control>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ControlContextV2, TextDisplayControlAttributes } from './models'
import EcoLabeledControl from './shared/EcoLabeledControl.vue'
  
@Component({
  components: {
    EcoLabeledControl,
  },
})
export default class EcoTextDisplayControl extends Vue {
  @Prop() public id: string
  @Prop() public context: ControlContextV2
  @Prop() public attributes: TextDisplayControlAttributes
  @Prop() public state: object

  public get currentValue() {
    return this.state[this.attributes.state]
  }
}
</script>

<style lang="scss" scoped>
  .value {
    display: flex;
    flex-direction: row;
    padding: 5px 5px;
    line-height: 1.2;
    border-radius: 5px;
    background: rgba(123, 139, 154, 0.1);
    align-self: center;
}
</style>

<style lang="css">
</style>
