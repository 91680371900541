import { LogToolUiState } from '@/store/modules/logToolUi/types'

export const state: LogToolUiState = {
  interacted: false,
  loading: false,
  selectedPlcId: '',
  endpointRecordingConfigurations: [],
  selectedDeviceId: '',
  endpoints: [],
  loggingConfigurations: [],
  endpointLogEntries: [],
  cloudLogEntries: [],
  dbLogEntries: [],
}
