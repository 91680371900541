import { actions } from '@/store/modules/functionDefinitionEditorUi/actions'
import { getters } from '@/store/modules/functionDefinitionEditorUi/getters'
import { mutations } from '@/store/modules/functionDefinitionEditorUi/mutations'
import { state } from '@/store/modules/functionDefinitionEditorUi/state'
import { FunctionDefinitionEditorUiAction, FunctionDefinitionEditorUiGetter, FunctionDefinitionEditorUiMutation, FunctionDefinitionEditorUiState } from '@/store/modules/functionDefinitionEditorUi/types'
import { RootState } from  '@/store/types'
import { Module } from 'vuex'

const namespaced: boolean = true
export const functionDefinitionEditorUi: Module<FunctionDefinitionEditorUiState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export const types = {
  action: FunctionDefinitionEditorUiAction,
  mutation: FunctionDefinitionEditorUiMutation,
  getter: FunctionDefinitionEditorUiGetter,
}

export const domain = 'functionDefinitionEditorUi'

export const vuexModule = {
  [domain]: functionDefinitionEditorUi,
}