<template>
  <option-selector
    :title="'releases.new.oo.title' | translate"
    :description="'releases.new.oo.guide' | translate"
    :active="active"
    activeImage="UseNewChecked.svg"
    inactiveImage="UseNew.svg"
    @selected="onSelected"
  >
    <base-text-button
      v-if="active"
      :enabled="!isInteracted"
      @buttonClicked="startNewRelease"
    >
      {{'releases.keepgoing' | translate}}
    </base-text-button>
    <base-text-button v-if="active && isEcocoachEmployee"
      :enabled="!isInteracted"
      @buttonClicked="openFileDialog"
    >
      {{'releases.import' | translate}}
    </base-text-button>

    <input 
      :id="'file-upload-create'"
      type="file" 
      ref="input"
      accept=".json"
      @change="startImportRelease($event.target.files[0])"
    >
  </option-selector>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import OptionSelector from './OptionSelector.vue'

import { ArchitectureType } from '../../../../../../../eco-domain-store-modules/src/common'

const Wizard = namespace('wizard')
const App = namespace('app')
const ReleaseUi = namespace('releaseUi')

@Component({
  components: {
    OptionSelector,
  },
})
export default class NewOptionObjectOriented extends Vue {
  @App.Getter public userIdentifier: string
  @App.Getter public isEcocoachEmployee: boolean
  @Wizard.Action public goToStep
  @Wizard.Getter public wizardSteps
  @Wizard.Mutation public closeReleaseDialog
  @ReleaseUi.Action public createRelease: (architectureType: ArchitectureType) => Promise<void>
  @ReleaseUi.Action public importRelease: (file: File) => Promise<void>
  @ReleaseUi.Mutation public setInteracted
  @ReleaseUi.Getter public isInteracted
  @Prop() public active: boolean

  @Emit('selected')
  public onSelected() {
    return
  }

  public async startNewRelease() {
    await this.createRelease(ArchitectureType.ObjectOriented)
    this.goToRooms()
  }
  
  public async startImportRelease(file: File) {
    await this.importRelease(file)
    this.goToRooms()
  }

  public openFileDialog() {
    const input:any = this.$refs.input
    input.value = null
    input.click()
  }

  public goToRooms() {
    this.closeReleaseDialog()
    const step = this.wizardSteps.find(s => s.name === 'defineRooms')
    this.goToStep({ step, router: this.$router })
  }
}
</script>
<style lang="scss" scoped>
.v-btn {
  border: solid 1px white;
}
</style>