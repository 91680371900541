<template>
  <eco-labeled-control
    :context="context"
    :attributes="attributes"
  >
    <base-material-icon v-if="currentValue === true"
      :iconName="iconTrue"
      :height="25"
    />
    <base-material-icon v-else-if="currentValue === false"
      :iconName="iconFalse"
      :height="25"
    />
    <base-material-icon v-else
      iconName="help"
      :height="25"
    />
  </eco-labeled-control>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { BooleanDisplayControlAttributes, ControlContextV2 } from './models'
import EcoLabeledControl from './shared/EcoLabeledControl.vue'
  
@Component({
  components: {
    EcoLabeledControl,
  },
})
export default class EcoBooleanDisplayControl extends Vue {
  @Prop() public id: string
  @Prop() public context: ControlContextV2
  @Prop() public attributes: BooleanDisplayControlAttributes
  @Prop() public state: object

  public get iconTrue() {
    return this.attributes.iconTrue ?? 'power'
  }

  public get iconFalse() {
    return this.attributes.iconFalse ?? 'power_off'
  }

  public get currentValue() {
    return this.state[this.attributes.state]
  }
}
</script>

<style lang="scss" scoped>
</style>

<style lang="css">
</style>
