<template>
  <v-layout row align-center justify-end class="value">
    <v-flex grow>
    </v-flex>
    <v-flex shrink>
      <input ref="input" type="number" pattern="\d*" 
        :class="{
          'pending': pendingValue !== undefined,
          'readonly': readonly,
        }"
        v-model="internalValue"
        :min="min"
        :max="max"
        :step="step"
        :readonly="readonly"
        @change="setFocus"
        @blur="onValueChange"
        @keyup.enter="onEnter"
      >
    </v-flex>
    <v-flex shrink class="unit">
      <span>{{ unit }}</span>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class EcoNumberInput extends Vue {
  @Prop() public currentValue: number
  @Prop() public pendingValue: number
  @Prop() public min: number
  @Prop() public max: number
  @Prop() public step: number
  @Prop() public unit: string
  @Prop() public readonly: boolean

  public internalValue: number | null = null

  public mounted() {
    this.internalValue = this.currentValue
  }

  public onValueChange() {
    if (this.internalValue !== null) {
      // round to nearest step with step precision
      if (this.step) {
        const decimals = Math.max(0, Math.ceil(-Math.log10(this.step))) // e.g. step=1 -> 0, step=0.1 -> 1, step=0.01 -> 2
        this.internalValue = parseFloat((Math.round(this.internalValue / this.step) * this.step).toFixed(decimals))
      }

      // clamp to valid range
      if (this.internalValue < this.min) {
        this.internalValue = this.min
      }
      if (this.internalValue > this.max) {
        this.internalValue = this.max
      }
      this.onChange(this.internalValue)
    }
  }

  @Emit('changed')
  public onChange(value: number) {
    return (Number)(value)
  }

  @Watch('currentValue')
  public currentValueChanged() {
    this.updateInternalValue()
  }

  @Watch('pendingValue')
  public pendingValueChanged() {
    this.updateInternalValue()
  }

  public updateInternalValue() {
    this.internalValue = this.pendingValue !== undefined ? this.pendingValue : this.currentValue
  }

  // lose focus and trigger blur event on enter
  public onEnter() {
    (this.$refs.input as any).blur()
  }

  // keep focus after stepUp/Down clicks
  public setFocus() {
    (this.$refs.input as any).focus()
  }
}
</script>

<style lang="scss" scoped>
.value {
  input {
    text-align: right;
    outline: none;
    border-color: $fore-color-primary;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    min-width: 50px;
    @supports (-ms-ime-align:auto) {
      padding-right: 10px; /* edge has no up-down picker */
    }
    &.pending {
      border-color: $fore-color-disabled;
    }
    &.readonly {
      color: $fore-color-disabled;
      border-color: $fore-color-disabled;
    }
  }
  input::-webkit-inner-spin-button { margin-left: 5px; } 
  .unit{
    padding-left: 5px;
  }
}
</style>
