<template>
  <div class="account-list">
    <div class="account-head">
      <div class="account-title">
        {{ 'oneclick.account.name' | translate }}
      </div>
      <v-tooltip bottom>
        <icon-button slot="activator"
          type="materialIcon"
          materialIconName="edit"
          :height="25"
          :width="25"
          :enabled="!!adminSelectedAccountId && !isInteracted"
          @buttonClicked="showEditAccountDialog = true"
        />
        <span>{{ 'common.button.edit' | translate }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <icon-button slot="activator"
          type="materialIcon"
          materialIconName="delete_outline"
          :height="25"
          :width="25"
          :enabled="!!adminSelectedAccountId && !isInteracted"
          @buttonClicked="showDeleteAccountDialog = true"
        />
        <span>{{ 'common.button.delete' | translate }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <icon-button slot="activator"
          type="materialIcon"
          materialIconName="add"
          :height="25"
          :width="25"
          :enabled="!isInteracted"
          @buttonClicked="showAddAccountDialog = true"        
        />
        <span>{{ 'common.button.create' | translate }}</span>
      </v-tooltip>
    </div>
    <div class="name-filter">
      <text-input
        :value="adminAccountFilterString"
        :placeholder="'oneclick.filter.name' | translate"
        @input="setAccountFilterString"
      />
    </div>
    <div :class="adminAccounts.length > 0 ? 'accounts-content' : ''">
      <div 
        v-for="account in adminAccounts"
        :key="account.id"
        class="accounts" >
        <div class="account-item" 
          :class="account.active ? 'selected' : ''"
          @click="setSelectedAccountId(account.id)">
          <div class="account-name" >
            {{ 'oneclick.account.name' | translate }} {{ account.name }} 
          </div>
          <div class="account-id">
            {{ 'oneclick.account.custom' | translate }} {{ account.customId }}
          </div>
        </div>
      </div>
    </div>
    <add-account-dialog
      :show="showAddAccountDialog"
      @confirmed="onCreateAccount"
      @cancelled="showAddAccountDialog = false"
    />
    <edit-account-dialog
      :show="showEditAccountDialog"
      :account="adminAccounts.find(a => a.id === adminSelectedAccountId)"
      @confirmed="onSaveAccount"
      @cancelled="showEditAccountDialog = false"
    />
    <confirm-dialog 
      :show="showDeleteAccountDialog"
      :header="'oneclick.account.deletion' | translate"
      :text="'oneclick.account.deletion.warning' | translate"
      :cancel-button-text="'common.button.cancel' | translate"
      confirm-button-text="Ok"
      @confirmed="onDeleteAccount"
      @cancelled="showDeleteAccountDialog = false"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AddAccountDialog from './AddAccountDialog.vue'
import EditAccountDialog from './EditAccountDialog.vue'

import { AccountModel } from '../../../../../../eco-domain-store-modules/src/consumption/models'
import { AccountViewModel } from '../../../../store/modules/consumptionUi/models'

const Resource = namespace('resource')
const ConsumptionUi = namespace ('consumptionUi')

@Component({
  components: {
    AddAccountDialog,
    EditAccountDialog,
  },
})
export default class AccountList extends Vue {
  @ConsumptionUi.Getter public isInteracted: boolean
  @ConsumptionUi.Getter public adminAccounts: AccountViewModel[]
  @ConsumptionUi.Getter public adminSelectedAccountId: string
  @ConsumptionUi.Getter public adminAccountFilterString: string
  @ConsumptionUi.Mutation public setSelectedAccountId: (accountId: string) => void
  @ConsumptionUi.Mutation public setAccountFilterString: (filter: string) => void
  @ConsumptionUi.Action public createAccount: (account: AccountModel) => Promise<void>
  @ConsumptionUi.Action public updateAccount: (account: AccountModel) => Promise<void>
  @ConsumptionUi.Action public deleteAccount: () => Promise<void>
  @Resource.Getter public dictionary

  public showAddAccountDialog = false
  public showEditAccountDialog = false
  public showDeleteAccountDialog = false

  public onCreateAccount(account: AccountModel) {
    this.createAccount(account)
    this.showAddAccountDialog = false
  }

  public onSaveAccount(account: AccountModel) {
    this.updateAccount(account)
    this.showEditAccountDialog = false
  }

  public async onDeleteAccount() {
    await this.deleteAccount()
    this.showDeleteAccountDialog = false
  }
}
</script>

<style lang="scss" scoped>
.account-list {
  width: 100%;
  text-align: left;
  .accounts {
    justify-items: center;
    text-align: left;
    align-items: center;
    cursor: pointer;
    .account-item {
      min-height: 45px;
      padding: 10px;
      display: flex;
      flex: 1;
      flex-direction: row;
      border-bottom: 1px solid white;
      .account-name {
        justify-content: flex-start;
        padding-right: 8px;
        flex: 1 1 50%;
      }
      .account-id {
        justify-content: flex-end;
        flex: 1 1 50%;
      }
    }
    .account-item:hover {
      background: grey;
    }
  }
}
.accounts-content {
  margin-top: 5px;
  padding: 2px;
}
.account-head{
  display: flex;
  flex: 1;
  flex-direction: row;
  .account-title {
    font-size: 24px;
    line-height: 48px;
  }
}
.selected {
  background: #c2043e;
}
.v-btn {
  margin-right: 0px;
}
</style>
