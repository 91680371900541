<template>
  <v-card dark class="room" width="350">
    <v-card-title primary-title>
      <h3>{{ 'rooms.create' | translate }}</h3>
    </v-card-title>
    <v-container class="room-form-content">
      <v-layout column>
        <text-field 
          :value="getRoomInputProperty('name')"
          :placeholder="dictionary('rooms.name')"
          :changesHandler="onNameChanged"/>
        <icon-chooser-popover class="icon-input"
          :title="dictionary('icon.choose')"
          :icons="roomIcons"
          :selected="getRoomInputProperty('icon')"
          @iconSelected="onIconChanged"/>
      </v-layout>
    </v-container>
    <v-card-actions>
      <panel-box-action
        :isLoading="isInteracted"
        :isDisabled="!isInputValid"
        :title="'+ ' + dictionary('rooms.create')"
        :handler="createRoom"/>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import IconChooserPopover from '@/components/ui/IconChooserPopover.vue'
import PanelBoxAction from '@/components/ui/PanelBoxAction.vue'
import TextField from '@/components/ui/TextField.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ResourceCategory, ResourceModel } from '../../../../../../../eco-domain-store-modules/src/resource/models'

const Resource = namespace('resource')
const RoomUi = namespace('roomUi')

@Component({
  components: {
    PanelBoxAction,
    TextField,
    IconChooserPopover,
  },
})
export default class RoomFormCreate extends Vue {
  @Resource.Getter public dictionary
  @Resource.Getter public resourcesOfCategory: (category: ResourceCategory) => ResourceModel[]
  @RoomUi.Getter public isInteracted: boolean
  @RoomUi.Getter public getRoomInputProperty: (property: string) => any
  @RoomUi.Mutation public setRoomInputProperty: (payload: { property: string, value: string }) => void
  @RoomUi.Action public createRoom: () => Promise<void>

  public get roomIcons() {
    return this.resourcesOfCategory(ResourceCategory.ROOM_ICONS)
  }

  public get isInputValid() {
    return !!this.getRoomInputProperty('name').trim()
  }

  public onNameChanged(value: string) {
    this.setRoomInputProperty({
      property: 'name',
      value: value.trim(),
    })
  }

  public onIconChanged(value: string) {
    this.setRoomInputProperty({
      property: 'icon',
      value,
    })
  }
}
</script>

<style lang="scss" scoped>
.icon-input {
  padding: 20px 0px;
}
.v-card__title {
  background: linear-gradient(to bottom, #1a3856, #080e19);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #1a3856), color-stop(0.6, #080e19));
}
.v-card__title--primary {
  padding-top: 15px;
}
.v-card__actions {
  padding: 0;
  button {
    margin: 0;
  }
}
.room-form-content {
  background-image: radial-gradient(circle at center top, #1a3856, #080e19);
}
</style>