import { AuditLogEntryModel } from './models'

export interface AuditLogState {
  auditLogEntries: AuditLogEntryModel[]
}

export enum AuditLogAction {
  loadAuditLogEntries = 'loadAuditLogEntries',
}

export enum AuditLogMutation {
  setAuditLogEntries = 'setAuditLogEntries',
}
