import { MutationTree } from 'vuex'
import { UserDetails } from './models'
import { AuthenticationMutation, AuthenticationState } from './types'

export const mutations: MutationTree<AuthenticationState> = {
  [AuthenticationMutation.setLoggedInUser](state, userDetails: UserDetails | null) {
    state.loggedInUser = userDetails || {
      userIdentifier: '',
      firstName: '',
      lastName: '',
    } as UserDetails
  },
}
