<template>
  <modal-dialog 
    :show="show"
    :width="320"
    button1-text="OK"
    @button1Pressed="onOk"
  >
    {{ 'releases.editing.warning' | translate }}
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class IncompleteReleaseWarningDialog extends Vue {
  @Prop() public show: boolean

  @Emit('closed') 
  public onOk () {
    return
  }
}
</script>
