import { MutationTree } from 'vuex'
import { ParametrizationUiMutation, ParametrizationUiState } from './types'

export const mutations: MutationTree<ParametrizationUiState> = {
  [ParametrizationUiMutation.setSelectedRoomId](state, payload: string) {
    state.selectedRoomId = payload
  },
  [ParametrizationUiMutation.setSelectedDeviceId](state, payload: string) {
    state.selectedDeviceId = payload
  },
  [ParametrizationUiMutation.setInteracted](state, payload: boolean) {
    state.interacted = payload
  },
}
