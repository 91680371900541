import { ComponentFirmwareReleaseModel, ControlModelV2, DeviceModel, PendingControlCommand, PlcModel, ProjectModel, RoomModel, SceneModel } from './models'

export interface PlcOperationState {
    plcId: string
    projectId: string
    projects: ProjectModel[]
    plcs: PlcModel[]
    rooms: RoomModel[]
    roomsLookup: Map<string, RoomModel>
    roomsForPlcLookup: Map<string, RoomModel[]>
    devices: DeviceModel[]
    devicesLookup: Map<string, DeviceModel>
    devicesForRoomLookup: Map<string, DeviceModel[]>
    controls: ControlModelV2[]
    controlsLookup: Map<string, ControlModelV2>
    controlsForDeviceLookup: Map<string, ControlModelV2[]>
    controlsFilter: (control: ControlModelV2) => boolean
    scenes: SceneModel[]
    favorites: string[]
    pendingControlCommands: PendingControlCommand[]
    confirmedDeviceIds: string[]
    controlUpdateTimeout: boolean
    controlUpdateFailed: boolean
    activePlcConfigurationReleaseId: string
    componentFirmwareReleases: ComponentFirmwareReleaseModel[]
}

export enum PlcOperationAction {
    loadProjects = 'loadProjects',

    loadConfigurationByPlcId = 'loadConfigurationByPlcId',
    loadConfigurationByProjectId = 'loadConfigurationByProjectId',

    loadScenes = 'loadScenes',
    createScene = 'createScene',
    updateScene = 'updateScene',
    deleteScene = 'deleteScene',
    activateScene = 'activateScene',
    createSceneSchedule = 'createSceneSchedule',
    updateSceneSchedule = 'updateSceneSchedule',
    deleteSceneSchedule = 'deleteSceneSchedule',

    loadFavorites = 'loadFavorites',
    createFavorite = 'createFavorite',
    deleteFavorite = 'deleteFavorite',

    connectToControlStateHub = 'connectToControlStateHub',
    disconnectFromControlStateHub = 'disconnectFromControlStateHub',
    setControlsFilter = 'setControlsFilter',
    startNotifications = 'startNotifications',
    stopNotifications = 'stopNotifications',
    stateUpdatesReceived = 'stateUpdatesReceived',

    registerControlCommand = 'registerControlCommand',
    unregisterControlCommand = 'unregisterControlCommand',
    triggerControlUpdateTimeout = 'triggerControlUpdateTimeout',
    triggerControlUpdateFailed = 'triggerControlUpdateFailed',
    createArrayEntry = 'createArrayEntry',
    deleteArrayEntry = 'deleteArrayEntry',
    setDisplayName = 'setDisplayName',
    setControlAppDisplayLevel = 'setControlAppDisplayLevel',
    setControlColorGradient = 'setControlColorGradient',
    executeControlCommand = 'executeControlCommand',

    setDeviceIgnoreInCharts = 'setDeviceIgnoreInCharts',
    setDeviceConsumptionValidationEnabled = 'setDeviceConsumptionValidationEnabled',
    setConsumptionProcessOwnerLockEnabled = 'setConsumptionProcessOwnerLockEnabled',
    setMeasuringPointColor = 'setMeasuringPointColor',
    setEnergyStatusItemColor = 'setEnergyStatusItemColor',
    connectToDeviceConfirmationStateHub = 'connectToDeviceConfirmationStateHub',
    loadConfirmedDevices = 'loadConfirmedDevices',
    confirmDevice = 'confirmDevice',
    unconfirmDevice = 'unconfirmDevice',

    loadComponentFirmwareReleases = 'loadComponentFirmwareReleases',
    initializePlc = 'initializePlc',
    migratePlcToEcoCloudConnector = 'migratePlcToEcoCloudConnector',
    restartPlc = 'restartPlc',
    restartEcoCloudConnector = 'restartEcoCloudConnector',
    updateToLatestEcoCloudConnector = 'updateToLatestEcoCloudConnector',
    updateToEcoCloudConnectorVersion = 'updateToEcoCloudConnectorVersion',
    deployComponentFirmware = 'deployComponentFirmware',
    resetPlcConfigurationRelease = 'resetPlcConfigurationRelease',

    connectToPlcStateHub = 'connectToPlcStateHub',
    disconnectFromPlcStateHub = 'disconnectFromPlcStateHub',
    startPlcStateStateHubForProject = 'startPlcStateStateHubForProject',
    stopPlcStateHubForProject = 'stopPlcStateHubForProject',
}

export enum PlcOperationMutation {
    setPlcId = 'setPlcId',
    setProjectId = 'setProjectId',

    // id can be found in plcConfiguration
    setActivePlcConfigurationReleaseId = 'setActivePlcConfigurationReleaseId',

    setProjects = 'setProjects',
    setPlcs = 'setPlcs',
    setRooms = 'setRooms',
    setControls = 'setControls',
    setControlsFilter = 'setControlsFilter',
    setControlState = 'setControlState',
    setControlUpdateTimeout = 'setControlUpdateTimeout',
    setControlUpdateFailed = 'setControlUpdateFailed',
    setControlDisplayName = 'setControlDisplayName',
    setControlAppDisplayLevel = 'setControlAppDisplayLevel',
    setControlColorGradient = 'setControlColorGradient',

    upsertPendingControlCommand = 'upsertPendingControlCommand',
    removePendingControlCommand = 'removePendingControlCommand',

    setScenes = 'setScenes',
    upsertScene = 'upsertScene',
    removeScene = 'removeScene',

    setFavorites = 'setFavorites',
    addFavorite = 'addFavorite',
    removeFavorite = 'removeFavorite',

    setDevices = 'setDevices',

    setComponentFirmwareReleases = 'setComponentFirmwareReleases',

    setDeviceIgnoreInCharts = 'setDeviceIgnoreInCharts',
    setDeviceConsumptionValidationEnabled = 'setDeviceConsumptionValidationEnabled',
    setConsumptionProcessOwnerLockEnabled = 'setConsumptionProcessOwnerLockEnabled',
    setMeasuringPointColor = 'setMeasuringPointColor',
    setEnergyStatusItemColor = 'setEnergyStatusItemColor',
    setConfirmedDevices = 'setConfirmedDevices',
    addConfirmedDevice = 'addConfirmedDevice',
    removeConfirmedDevice = 'removeConfirmedDevice',
}

export enum PlcOperationGetter {
    devices = 'devices',
    devicesOfRoom = 'devicesOfRoom',
    rooms = 'rooms',
    roomsOfPlc = 'roomsOfPlc',
    activePlcConfigurationReleaseId = 'activePlcConfigurationReleaseId',

    // controls
    controls = 'controls',
    filteredControls = 'filteredControls',
    hasControlUpdateFailed = 'hasControlUpdateFailed',
    hasControlUpdateTimeout = 'hasControlUpdateTimeout',
    pendingControlCommand = 'pendingControlCommand',

    // scenes
    scenes = 'scenes',
    sceneById = 'sceneById',
    schedulesOfScene = 'schedulesOfScene',

    // favorites
    favorites = 'favorites',

    // device confirmation
    confirmedDeviceIds = 'confirmedDeviceIds',
}
