<template>
  <div v-if="isEcocoachEmployee">
    <div>MainThreadState: {{ plc.mainThreadState }}</div>
    <div v-for="state in plc.threadHealthStates" :key="state.threadName">
      <div>
        <v-icon :color="color(state)" size="25px" :title="toolTip(state)">{{ icon(state) }}</v-icon>
        {{ state.threadName }}
      </div>
    </div>
    <div v-if="canCopyToClipboard">
      <base-text-button
        @buttonClicked="copyToClipboard">
        {{ 'common.button.copydetailstoclipboard' | translate }}
      </base-text-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { PlcViewModel } from '../../../../../store/modules/selectPlcUi/models'

import { ThreadHealthState } from '@ecocoach/domain-store-modules/src/plc/models'

const App = namespace('app')
const Resource = namespace('resource')

@Component({
  components: {
  },
})
export default class PlcTableStatusDetails extends Vue {
  @Prop() public plc: PlcViewModel
  @App.Getter public isEcocoachEmployee: boolean
  @Resource.Getter public dictionary

  public icon(state: ThreadHealthState) {
    if(state.isInitialized && state.isStarted && !state.totalNumberOfExecutionsWithException) {
      return 'check_circle'
    }
    if(state.isInitialized && state.isStarted) {
      return 'offline_pin'
    }
    return 'error'  
  }

  public color(state: ThreadHealthState){
    if(state.isInitialized && state.isStarted && !state.totalNumberOfExecutionsWithException) {
      return 'green'
    }
    if(state.isInitialized && state.isStarted) {
      return 'orange'
    }
    return 'red'
  }

  public toolTip(state: ThreadHealthState) {
    return `${state.totalNumberOfExecutionsWithException}: ${state.lastException}`
  }

  public get canCopyToClipboard() {
    return this.plc.threadHealthStates.some(h => this.color(h) !== 'green')
  }

  public copyToClipboard() {
    const json = JSON.stringify({
      plcType: this.plc.plcType,
      ecoCloudConnectorVersion: this.plc.ecoCloudConnectorVersion,
      mainThreadState: this.plc.mainThreadState,
      threadHealthStates: this.plc.threadHealthStates,
    }, null, 2)
    this.$copyText(json)
  }
}
</script>
<style lang="scss" scoped>
.v-btn {
  border: 1px solid white;
}
</style>