import { GetterTree } from 'vuex'
import { 
  AutoMappingModel, 
  DeviceMappingModel, 
  DeviceModel, 
  ReleaseModel, 
  RoomModel, 
  SolutionHardwareInstanceModel,
} from './models'
import { PlcConfigurationGetter, PlcConfigurationState } from './types'

export const getters: GetterTree<PlcConfigurationState, {}> = {
  [PlcConfigurationGetter.releaseTemplates]({ releaseTemplates }): ReleaseModel[] {
    return releaseTemplates
  },
  [PlcConfigurationGetter.releases]({ releases }): ReleaseModel[] {
    return releases
  },
  [PlcConfigurationGetter.rooms]({ rooms }): RoomModel[] {
    return rooms
  },
  [PlcConfigurationGetter.devices]({ devices }): DeviceModel[] {
    return devices
  },
  [PlcConfigurationGetter.solutionHardwareInstances]({ solutionHardwareInstances }): SolutionHardwareInstanceModel[] {
    return solutionHardwareInstances
  },
  [PlcConfigurationGetter.deviceMappings]({ deviceMappings }): DeviceMappingModel[] {
    return deviceMappings
  },
  [PlcConfigurationGetter.autoMappings]({ autoMappings }): AutoMappingModel[] {
    return autoMappings
  },
  [PlcConfigurationGetter.releaseId]({releaseId}): string{
    return releaseId
  },
}
