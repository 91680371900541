export enum FunctionBlockUiAction {
}

export enum FunctionBlockUiMutation {
}

export enum FunctionBlockUiGetter {
    functionBlocksSearchResult = 'functionBlocksSearchResult',
    amountOfHardwareMappableInputEndpointsById = 'amountOfHardwareMappableInputEndpointsById',
    amountOfHardwareMappableOutputEndpointsById = 'amountOfHardwareMappableOutputEndpointsById',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FunctionBlockUiState {
}
