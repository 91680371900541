import { MutationTree } from 'vuex'
import { remove , upsert, upsertWithId } from '../utils'
import { AccountModel, EgonlineConfigurationModel, MeterModel, MinergieConfigurationModel, MinergieVisualizationData, OneClickTargetSystem, ProjectModel, UserModel } from './models'
import { ConsumptionMutation, ConsumptionState } from './types'

export const mutations: MutationTree<ConsumptionState> = {
  [ConsumptionMutation.setProjects](state: ConsumptionState, payload: ProjectModel[]) {
    state.projects = payload
  },
  [ConsumptionMutation.setProjectId](state: ConsumptionState, payload: string) {
    state.projectId = payload
  },
  [ConsumptionMutation.setTargetSystem](state: ConsumptionState, payload: OneClickTargetSystem) {
    state.targetSystem = payload
  },
  [ConsumptionMutation.setAccounts](state: ConsumptionState, payload: AccountModel[]) {
    state.accounts = payload
  },
  [ConsumptionMutation.setMeters](state: ConsumptionState, payload: MeterModel[]) {
    state.meters = payload
  },
  [ConsumptionMutation.setUsers](state: ConsumptionState, payload: UserModel[]) {
    state.users = payload
  },
  [ConsumptionMutation.upsertAccount](state: ConsumptionState, payload: AccountModel) {
    upsert(state.accounts, payload)
  },
  [ConsumptionMutation.removeAccount](state: ConsumptionState, payload: string) {
    remove(state.accounts, payload)
  },
  [ConsumptionMutation.upsertMeter](state: ConsumptionState, payload: MeterModel) {
    upsertWithId(state.meters, payload, m => m.meterId)
  },
  [ConsumptionMutation.upsertUser](state: ConsumptionState, payload: UserModel) {
    upsertWithId(state.users, payload, u => u.userIdentifier)
  },
  [ConsumptionMutation.setMinergieInfo](state: ConsumptionState, payload: MinergieConfigurationModel | null) {
    state.minergieInfo = payload
  },
  [ConsumptionMutation.setMinergieChartData](state: ConsumptionState, payload: MinergieVisualizationData[]) {
    state.minergieChartData = payload
  },
  [ConsumptionMutation.setEgonlineConfiguration](state: ConsumptionState, payload: EgonlineConfigurationModel | null) {
    state.egonlineConfiguration = payload
  },
}
