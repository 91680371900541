<template>
  <v-app dark>
    <accept-user-agreements-dialog v-if="showUserAgreementsDialog" :show="showUserAgreementsDialog" @closed="onUserAgreementsDialogClosed()" />
    <configure-mfa-dialog v-if="showMfaConfigurationDialog" :show="showMfaConfigurationDialog" @closed="showMfaConfigurationDialog = false" />
    <side-navigation v-if="succeededHydrating" />
    <v-content class="radiant">
      <div v-if="environmentBadge" class="environment-badge">{{ environmentBadge }}</div>
      <v-container class="app-container-padding" fluid :fill-height="isHydrating">
        <v-alert type="error"
                 transition="scale-transition"
                 class="errorToast"
                 :value="snackbar">
          <div class="toast-content">
            <div class="toast-text" v-html="snackbar ? toastContent : ''" />
            <v-btn class="toast-close" flat icon @click.stop="hideToast">
              <v-icon class="transient">close</v-icon>
            </v-btn>
          </div>
        </v-alert>
        <v-layout align-center justify-center v-if="isHydrating">
          <v-progress-circular v-if="isHydrating"
                               class="app-loading-circle"
                               :indeterminate="true"
                               :size="175"
                               :width="2">
            <p class="app-loading-text">LOADING</p>
          </v-progress-circular>
        </v-layout>
        <router-view v-else />
      </v-container>
    </v-content>
  </v-app>
</template>

<script lang="ts">
import PanelBoxAction from '@/components/ui/PanelBoxAction.vue'
import SideNavigation from '@/components/ui/SideNavigation.vue'
import AcceptUserAgreementsDialog from './views/AcceptUserAgreementsDialog.vue'
import ConfigureMfaDialog from './views/ConfigureMfaDialog.vue'

import EnvironmentService from '@ecocoach/domain-store-modules/src/services/environment.service'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { NavigationDrawerMinimizedStorageKey, UserAgreementViewModel } from '../store/modules/app/models'

import AppDataStorageService from '../../../eco-domain-store-modules/src/services/appdatastorage.service'

import store from '../store'

const { Getter, Action } = namespace('app')
const Wizard = namespace('wizard')

@Component({
  components: {
    AcceptUserAgreementsDialog,
    ConfigureMfaDialog,
    SideNavigation,
    PanelBoxAction,
  },
})
export default class App extends Vue {
  @Getter public isHydrating
  @Getter public succeededHydrating
  @Getter public userAgreements: UserAgreementViewModel[]
  @Getter public isEmailMfaEnabled: boolean|null
  @Getter public languages: Array < { id: string, label: string } >
  @Getter public loadingApiSuccess: boolean
  @Getter public environmentBadge: string
  @Action public load: () => Promise<void>
  @Action public selectLanguage: (language: string) => Promise<void>
  @Action public setNavigationDrawerMinimized: (minimized: boolean) => Promise<void>
  @Wizard.Mutation public hideToast
  @Wizard.State(state => state.toastIsVisible) public snackbar
  @Wizard.State(state => state.toastContent) public toastContent

  public showUserAgreementsDialog = false
  public showMfaConfigurationDialog = false

  public async mounted() {

    store.subscribe(({ type }) => {
      if (type === 'app/loaded' && this.hasUnacceptedUserAgreements && this.loadingApiSuccess) {
        this.showUserAgreementsDialog = true
      }
      this.showMfaDialog(type)
    })
    await this.initialize()
  }

  private showMfaDialog(type:string) {
    if (type === 'app/loaded' && !this.isMfaConfigured && this.loadingApiSuccess && !this.showUserAgreementsDialog) {
      this.showMfaConfigurationDialog = true
    }
  }

  private get hasUnacceptedUserAgreements(): boolean {
    return this.userAgreements.some((_) => !_.accepted)
  }

  private onUserAgreementsDialogClosed(): void {
    this.showUserAgreementsDialog = false
    this.showMfaDialog('app/loaded')
  }

  private get isMfaConfigured(): boolean{
    return this.isEmailMfaEnabled != null
  }

  private async initialize() {
    const storedLanguage = await AppDataStorageService.get('language')
    this.selectLanguage(EnvironmentService.determineLanguage(this.languages.map(lang => lang.id), storedLanguage))
    const navigationMinmized = await AppDataStorageService.get(NavigationDrawerMinimizedStorageKey)
    this.setNavigationDrawerMinimized(!!navigationMinmized)
    await this.load()
  }
}
</script>

<style lang="scss">
body, .application, .application--wrap {
  font-family: 'Titillium Web', sans-serif !important;
}
body {
  background: radial-gradient(#1a3856, #080e19) !important;
  
  .errorToast {
    z-index: 999;
    max-width: 800px;
    background: linear-gradient(to right, #fa0f36, #78022d);
    bottom: 80px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    .toast-content {
      display: flex;
      .toast-text {
        max-width: 700px;
        padding-top: 12px;
      }
      .toast-close {
        width: 36px;
      }
    }
    .transient {
      color: rgba(0,0,0,.5);
    }
  }
}
a:link, a:visited, a:hover {
  text-decoration: none;
  color: white;
}
p.error-dialog-text {
  text-align: center;
  margin: 50px 25px 40px 25px;
}
p.app-loading-text {
  color: #fff;
  margin: 0;
}
.app-loading-circle {
  color: #fa0f36 !important;
}
.radiant {
  background: radial-gradient(#1a3856, #080e19);
}
.environment-badge {
  background-color: yellow;
  box-shadow: 0 0 3px 2px rgba(0,0,0,0.8);
  position: absolute;
  height: 200px;
  right: -100px;
  top: -100px;
  width: 200px;
  transform: rotate(45deg);
  color: black;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

// default table style for tables in in function block description html tooltips
// description resources may contain html with e.g. <table class="fb-description-table">...</table>
// styles can be overwritten in the resource itself with inline style tags like <td style="text-align: left">
table.fb-description-table {
  border-collapse: collapse;
  // border: 1px solid white;
  th, td {
    border: 1px solid white;
    padding: 5px;
    text-align: center;
  }
}

/* width */
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background: #020407;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 0px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #1a3856;
    border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #1a3856;
}

.container.fluid{
  height: 100%;
}
.layout.column.wrap {
  display: block !important;
  height: 100%;
}
.app-container-padding {
  padding: 15px;
}
@media screen and (max-height: 1031px) {
  .content--wrap {
    max-height: 610px;
  }
}
@media screen and (min-height: 1032px) {
  .content--wrap {
    max-height: 1000px;
  }
}

</style>
<style lang="css">
html, body, main, #app, .application--wrap .content--wrap {
  height: 100% !important;
  min-height: 100% !important;
}

</style>
