import { LogToolUiGetter, LogToolUiState } from '@/store/modules/logToolUi/types'
import { RootState } from '@/store/types'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { DropdownOption } from '@ecocoach/shared-components/src'
import moment from 'moment'
import { GetterTree } from 'vuex'
import { formatTimeStamp, parseDeviceIdFromEndpoint } from './helpers'
import { EndpointRecordingViewModel, LogDestination, LogEntryModel, LoggerConfigurationModel, LogLevel } from './models'

const oneThousand = 1000

export const getters: GetterTree<LogToolUiState, RootState> = {
  [LogToolUiGetter.isInteracted] ({ interacted }) {
    return interacted
  },
  [LogToolUiGetter.isLoading] ({ loading }) {
    return loading
  },
  [LogToolUiGetter.selectedPlcId] ({ selectedPlcId }) {
    return selectedPlcId
  },
  [LogToolUiGetter.endpointRecordingConfigurations] ({ endpointRecordingConfigurations }, _, rootState): EndpointRecordingViewModel[] {
    return endpointRecordingConfigurations.map(recording => {
      const deviceId = parseDeviceIdFromEndpoint(recording.qualifiedEndpointPath)
      return {
        ...recording,
        deviceName: rootState.plcOperation.devicesLookup.get(deviceId)?.name ?? 'n/a',
        endpointDisplayName: recording.qualifiedEndpointPath.split('.', 3).reverse()[0],
        interval: `${recording.intervalInMilliseconds / oneThousand} sec`,
        started: formatTimeStamp(recording.startDateTimeUtc),
        ended: formatTimeStamp(recording.endDateTimeUtc),
        stillRunning: moment.utc(recording.endDateTimeUtc).isAfter(moment.utc()),
      } as EndpointRecordingViewModel
    })
      .sort((a, b) => a.qualifiedEndpointPath.localeCompare(b.qualifiedEndpointPath))
  },
  [LogToolUiGetter.deviceOptions] (_, __, rootState): DropdownOption[] {
    return rootState.plcOperation.devices.map(device => {
      const room = rootState.plcOperation.roomsLookup.get(device.roomId)
      return {
        id: device.id,
        label: `${device?.name} - ${room?.name}`,
      }
    })
  },
  [LogToolUiGetter.controlOptions] (state, __, rootState, rootGetters): DropdownOption[] {
    const resolveStringResource = rootGetters[`resource/${ResourceGetter.resolveStringResource}`]
    const controls = rootState.plcOperation.controlsForDeviceLookup.get(state.selectedDeviceId) ?? []
    return controls.map(control => {
      return {
        id: control.id,
        label: resolveStringResource(control.attributes.label),
      }
    })
  },
  [LogToolUiGetter.endpointOptions] (state): DropdownOption[] {
    return state.endpoints.map(endpoint => {
      return {
        id: endpoint.internalName,
        label: `${endpoint.internalName} (${endpoint.dataType})`,
      }
    })
  },
  [LogToolUiGetter.selectedDeviceId] ({ selectedDeviceId }) {
    return selectedDeviceId
  },
  [LogToolUiGetter.defaultLogLevelForDestination] ({ loggingConfigurations }) {
    return (destination: LogDestination): LogLevel => {
      return loggingConfigurations.find(c => c.logDestination === destination && c.logSource === '')?.logLevel ?? LogLevel.None
    }
  },
  [LogToolUiGetter.loggingConfigurationsForDestination] ({ loggingConfigurations }) {
    return (destination: LogDestination): LoggerConfigurationModel[] => {
      return loggingConfigurations.filter(c => c.logDestination === destination && !!c.logSource && c.isActive !== false)
        .sort((a, b) => a.logSource.localeCompare(b.logSource))
    }
  },
  [LogToolUiGetter.logLevelOptions] (): DropdownOption[] {
    return Object.values(LogLevel).map(logLevel => {
      return {
        id: logLevel,
        label: logLevel,
      }
    })
  },
  [LogToolUiGetter.logSourceOptions] ({ loggingConfigurations }): DropdownOption[] {
    return Array.from(new Set(loggingConfigurations.map(c => c.logSource)))
      .filter(logSource => !!logSource)
      .sort((a, b) => a.localeCompare(b))
      .map(logSource => {
        return {
          id: logSource,
          label: logSource,
        }
      })
  },
  [LogToolUiGetter.endpointLogEntries] ({ endpointLogEntries }): LogEntryModel[] {
    return endpointLogEntries
  },
  [LogToolUiGetter.cloudLogEntries] ({ cloudLogEntries }): LogEntryModel[] {
    return cloudLogEntries
  },
  [LogToolUiGetter.dbLogEntries] ({ dbLogEntries }): LogEntryModel[] {
    return dbLogEntries
  },
}
