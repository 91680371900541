<template>
  <v-layout column wrap align-center class="consumption-visualization">
    <wizard-step-header 
      :title="'navigation.ecoOneClick.visualization' | translate" 
      :sub-title="'oneclick.visualization.subtitle'  | translate"
    />
    <v-flex xs12 class="visualization-options">
      <v-layout class="selection" justify-center row wrap>
        <v-flex  xs12 md5><v-card-text :class="{'small': !$vuetify.breakpoint.mdAndUp}">{{ 'consumption.project' | translate}}</v-card-text></v-flex>
        <v-flex  xs12 md7>
           <autocomplete-select
            :options="visualizationProjects"
            option-value="id"
            option-label="label"
            :selected-value="selectedProjectId"
            @selected="selectVisualizationProject"
            class="auto-select"
          />
        </v-flex>
      </v-layout>
      <v-layout class="selection" justify-center row wrap>
        <v-flex  xs12 md5><v-card-text :class="{'small': !$vuetify.breakpoint.mdAndUp}">{{ 'consumption.interval' | translate }}</v-card-text></v-flex>
        <v-flex  xs12 md7>
          <base-single-select
            :options="visualizationIntervals"
            option-value="id"
            option-label="label"
            :selected-value="selectedVisualizationInterval"
            @selected="selectVisualizationInterval"
            class="single-select"
          />
        </v-flex>
      </v-layout>
      <v-layout class="selection" justify-center row wrap>
        <v-flex  xs12 md5><v-card-text :class="{'small': !$vuetify.breakpoint.mdAndUp}">{{ 'consumption.consumptionaccount' | translate }}</v-card-text></v-flex>
        <v-flex  xs12 md7>
           <autocomplete-select 
            :options="visualizationAccounts"
            option-value="id"
            option-label="label"
            :selected-value="selectedVisualizationConsumptionAccountId"
            @selected="selectVisualizationConsumptionAccountId"
            class="auto-select"
          />
        </v-flex>
      </v-layout>
    </v-flex>    
    <v-flex xs12 class="visualization-options">
      <keep-alive>
        <div id="chart-container" />
      </keep-alive>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { MinergieVisualizationInterval } from '../../../../../../eco-domain-store-modules/src/consumption/models'
import { DropdownOption } from '../../../../../../shared-components/src'

const ConsumptionUi = namespace ('consumptionUi')

@Component({
  components: {

  },
})
export default class ConsumptionVizualization extends Vue {
  @ConsumptionUi.Getter public isInteracted: boolean
  @ConsumptionUi.Getter public visualizationProjects: DropdownOption[]
  @ConsumptionUi.Getter public selectedProjectId: string
  @ConsumptionUi.Getter public visualizationAccounts: DropdownOption[]
  @ConsumptionUi.Getter public visualizationIntervals: DropdownOption[]
  @ConsumptionUi.Getter public selectedVisualizationInterval: MinergieVisualizationInterval
  @ConsumptionUi.Getter public selectedVisualizationConsumptionAccountId: string
  @ConsumptionUi.Action public initializeVisualization: () => Promise<void>
  @ConsumptionUi.Action public selectVisualizationProject: (projectId: string) => Promise<void>
  @ConsumptionUi.Action public selectVisualizationInterval: (interval: MinergieVisualizationInterval) => Promise<void>
  @ConsumptionUi.Action public selectVisualizationConsumptionAccountId: (id: string) => Promise<void>

  public async mounted() {
    await this.initializeVisualization()
  }
}
</script>
<style lang="scss" scoped>
header {
  padding: 30px 0 0 25px;
  height: 100px;
}
.consumption-visualization {
  padding-top: 10px;
  .auto-select {
    margin-top: 10px;
    padding-top: 0px;
  }
  .single-select {
    padding-right: 10px;
    margin-bottom: 0px;
  }
  .visualization-options {
    padding-top: 10px;
    padding-left: 15px;
  }
}
.v-btn {
  border: 1px solid white;
}
</style>