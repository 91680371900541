import { AccountModel, EgonlineConfigurationModel, MeterModel, MinergieConfigurationModel, MinergieVisualizationData, OneClickTargetSystem, ProjectModel, UserModel } from './models'

export interface ConsumptionState {
  projects: ProjectModel[]
  projectId: string
  targetSystem: OneClickTargetSystem
  accounts: AccountModel[]
  meters: MeterModel[]
  users: UserModel[]
  minergieInfo: MinergieConfigurationModel | null
  minergieChartData: MinergieVisualizationData[]
  egonlineConfiguration: EgonlineConfigurationModel | null
}

export enum ConsumptionAction {
  loadProjects = 'loadProjects',
  loadProject = 'loadProject',
  createAccount = 'createAccount',
  updateAccount = 'updateAccount',
  deleteAccount = 'deleteAccount',
  assignMeter = 'assignMeter',
  unassignMeter = 'unassignMeter',
  assignUser = 'assignUser',
  unassignUser = 'unassignUser',
 
  linkAccountMinergie = 'linkAccountMinergie',
  unlinkAccountMinergie = 'unlinkAccountMinergie',
  verifyAccountMinergie = 'verifyAccountMinergie',

  linkProjectEgonline = 'linkProjectEgonline',
  unlinkProjectEgonline = 'unlinkProjectEgonline',

  loadReport = 'loadReport',

  loadMinergieChartData = 'loadMinergieChartData'
}

export enum ConsumptionMutation {
  setProjects = 'setProjects',
  setProjectId = 'setProjectId',
  setTargetSystem = 'setTargetSystem',
  setAccounts = 'setAccounts',
  setMeters = 'setMeters',
  setUsers = 'setUsers',
  upsertAccount = 'upsertAccount',
  removeAccount = 'removeAccount',
  upsertMeter = 'upsertMeter',
  upsertUser = 'upsertUser',
  setMinergieInfo = 'setMinergieInfo',
  setMinergieChartData = 'setMinergieChartData',
  setEgonlineConfiguration = 'setEgonlineConfiguration',
}
