import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'
import { AlarmAction, AlarmMutation, AlarmState } from './types'

import { Module } from 'vuex'

const namespaced: boolean = true
export const alarm: Module<AlarmState,any> = {
  namespaced,
  state,
  actions,
  mutations,
}

export const types = {
  action:  AlarmAction,
  mutation: AlarmMutation,
}

export const domain = 'alarm'

export const vuexModule = {
  [domain]: alarm,
}