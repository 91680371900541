import { WizardAction, WizardGetter, WizardMutation, WizardState } from '@/store/modules/wizard/types'
import { RootState } from '@/store/types'
import { PlcConfigurationAction } from '@ecocoach/domain-store-modules/src/plcConfiguration/types'

import { ActionContext, ActionTree } from 'vuex'
import { AppAction, AppGetter } from '../app/types'

export const actions: ActionTree<WizardState, RootState> = {
  async [WizardAction.goToStep]({ commit, getters, rootGetters }: 
            ActionContext<WizardState, RootState>, {step, router}): Promise<void> {
    if(getters[WizardGetter.entryConditionMet](step) === true) {
      // warning when user wants to parameterize but is editing a release
      if(getters.conditionStillInEdit && step.name === 'configureDevices') {                    
        commit(WizardMutation.setToastContent, rootGetters['resource/dictionary']('releases.still.edit'))
        commit(WizardMutation.showToast)
      }
      router.push({name: step.name})
      commit(WizardMutation.wentToStep, step)
    } else {
      commit(WizardMutation.setToastContent, rootGetters['resource/dictionary'](step.entryConditionFailedText))
      commit(WizardMutation.showToast)
    }
  },
  async [WizardAction.startConfigurePlc] ({state, commit, rootGetters, getters, dispatch}) {
    commit('releaseUi/setReleasesLoaded', true, {root:true})
    if(state.currentStep.name === 'selectPlc' 
            && getters[WizardGetter.entryConditionMet](state.steps[1]) === true){
      await dispatch('app/loadActiveRelease', undefined, {root:true}) // is needed for green ticking the correct release
      commit(WizardMutation.openReleaseDialog)            
    } else {
      commit(WizardMutation.setToastContent, rootGetters['resource/dictionary'](state.steps[1].entryConditionFailedText))
      commit(WizardMutation.showToast)
    }
  },
  async [WizardAction.startParameterization] ({getters, rootState, rootGetters, dispatch, commit}, router) {
    commit('setWaiting', true)
    if(rootGetters[`app/${AppGetter.hasSelectedPlc}`]) {
      await dispatch(`app/${AppAction.loadActiveRelease}`, undefined, { root: true })
      if(rootState.plcOperation.activePlcConfigurationReleaseId) {
        await dispatch(`plcConfiguration/${PlcConfigurationAction.loadReleaseDetails}`, 
          { 
            releaseId: rootState.plcOperation.activePlcConfigurationReleaseId, 
            language: rootGetters[`app/${AppGetter.selectedLanguage}`],
          }, { root:true })
        const step = getters.wizardSteps.find(s => s.name === 'configureDevices')
        dispatch('goToStep', {step, router})
      } else {
        commit('setToastContent', rootGetters['resource/dictionary']('error.not.go.to.parameterisation'))
        commit('showToast', undefined)
      }
    } else  {
      commit('setToastContent', rootGetters['resource/dictionary']('error.no.sps.selected'))
      commit('showToast', undefined)
    }
    commit('setWaiting', false)
  },
  async [WizardAction.startAlarmDefinitions] ({getters, rootState, rootGetters, dispatch, commit}, router) {
    commit(WizardMutation.setWaiting, true)
    if(rootGetters[`app/${AppGetter.hasSelectedPlc}`]) {
      await dispatch(`app/${AppAction.loadActiveRelease}`, undefined, { root: true })
      if(rootState.plcOperation.activePlcConfigurationReleaseId) {
        await dispatch(`plcConfiguration/${PlcConfigurationAction.loadReleaseDetails}`, 
          { 
            releaseId: rootState.plcOperation.activePlcConfigurationReleaseId, 
            language: rootGetters[`app/${AppGetter.selectedLanguage}`],
          }, { root:true })
        const step = getters.wizardSteps.find(s => s.name === 'manageAlarms')
        dispatch('goToStep', {step, router})
      } else {
        commit('setToastContent', rootGetters['resource/dictionary']('error.not.go.to.alarms'))
        commit('showToast', undefined)
      }
    } else  {
      commit('setToastContent', rootGetters['resource/dictionary']('error.no.sps.selected'))
      commit('showToast', undefined)
    }
    commit('setWaiting', false)
  },
  async [WizardAction.continueWithReleaseInEdit] ({getters, rootGetters, dispatch, commit}, router) {
    commit('setWaiting', true)
    await dispatch('plcConfiguration/loadReleaseDetails', {
      releaseId:  rootGetters['releaseUi/currentNewRelease'].id, 
      language: rootGetters['app/selectedLanguage'],
    }, {root:true})
    const step = getters.wizardSteps.find(s => s.name === 'defineRooms')
    dispatch('goToStep', {step, router})
    commit('setWaiting', false)
  },
}
