<template>
    <div class="navigation-buttons">
        <modal-dialog
            :show="isReleaseDialogOpen"
            :header="'releases.configurations' | translate"
            closing-x=true
            @xPressed="closeReleaseDialog"
            width=1200
            height=750
        >
            <manage-releases class="releases-modal"/>
        </modal-dialog>
        <div v-if="getActiveStep.name !== 'configureDevices'" class="button-container">
            <base-text-button
                :enabled="!isWaiting"
                @buttonClicked="startConfigurePlc">
                {{ 'select.plc.config' | translate }}
            </base-text-button>
            <base-text-button
                :enabled="!isWaiting"
                @buttonClicked="startParameterization($router)">
                {{ 'parameter.plc' | translate }}
            </base-text-button>
            <base-text-button
                :enabled="!isWaiting"
                @buttonClicked="startAlarmDefinitions($router)">
                {{ 'common.button.manage.alarms' | translate }}
            </base-text-button>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import ManageReleases from './steps/manageRelease/index.vue'

const Wizard = namespace('wizard')

@Component({
  components: {
    ManageReleases,
  },
})
export default class NavigationButtons extends Vue {
    @Wizard.Action public startConfigurePlc
    @Wizard.Action public startParameterization
    @Wizard.Action public startAlarmDefinitions
    @Wizard.Mutation public closeReleaseDialog
    @Wizard.Getter public wizardSteps
    @Wizard.Getter public getActiveStep
    @Wizard.Getter public isReleaseDialogOpen
    @Wizard.Getter public isWaiting
}
</script>

<style lang="scss" scoped>
.navigation-buttons {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin-right: 20px;
    button {
        border: 1px white solid;
    }
}
.releases-modal{
    width: 1000px;
}
.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100%!important;
}
</style>

