import { actions } from '@/store/modules/selectPlcUi/actions'
import { getters } from '@/store/modules/selectPlcUi/getters'
import { mutations } from '@/store/modules/selectPlcUi/mutations'
import { state } from '@/store/modules/selectPlcUi/state'
import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { SelectPlcUiAction, SelectPlcUiGetter, SelectPlcUiMutation, SelectPlcUiState } from './types'

const namespaced: boolean = true
export const selectPlcUi: Module<SelectPlcUiState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action: SelectPlcUiAction, 
  mutation: SelectPlcUiGetter,
  getter: SelectPlcUiMutation,
}

export const domain = 'selectPlcUi'

export const vuexModule = {
  [domain]: selectPlcUi,
}
