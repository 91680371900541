import { ChartCollectionModel, ChartDataModel } from './models'

export interface ChartState {
  chartCollections: ChartCollectionModel[]
  chartData: ChartDataModel | null
}

export enum ChartAction {
  loadChartCollections = 'loadChartCollections',
  loadChartData = 'loadChartData',
  loadReport = 'loadReport',
}

export enum ChartMutation {
  setChartCollections = 'setChartCollections',
  setChartData = 'setChartData',
}
