import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { state } from './state'
import { UserSettingsAction, UserSettingsGetter, UserSettingsMutation, UserSettingsState } from './types'

import { Module } from 'vuex'

const namespaced: boolean = true
export const userSettings: Module<UserSettingsState, any> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export const types = {
  action: UserSettingsAction, 
  mutation: UserSettingsMutation,
  getter: UserSettingsGetter,
}

export const domain = 'userSettings'

export const vuexModule = {
  [domain]: userSettings,
}