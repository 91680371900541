import { ActionTree } from 'vuex'
import ApiService from '../services/api.service'
import HubService from '../services/hub.service'
import { deepCompare } from '../utils'
import queryApi from './api/query'
import { alarmStateHubId, CustomPlcAlarmModel, LoadCustomPlcAlarmsPayload, MessageAlarmModel, ServiceAlarmModel } from './models'
import { AlarmAction,  AlarmMutation, AlarmState } from './types'

export const actions: ActionTree<AlarmState, {}> = {
  async [AlarmAction.loadCustomPlcAlarmsForPlc] ({ commit }, payload: LoadCustomPlcAlarmsPayload) {
    const data = await queryApi.loadAlarmsHistoryForPlc(payload.projectId, payload.plcId)
    commit(AlarmMutation.loadedCustomPlcAlarms, data)
  },
  async [AlarmAction.loadCustomPlcAlarmsForProject] ({ commit, state }, payload: LoadCustomPlcAlarmsPayload) {
    const data = await queryApi.loadAlarmsHistoryForProject(payload.projectId, payload.itemLimit, payload.created)
    if (payload.created) {
      commit(AlarmMutation.loadedCustomPlcAlarms, state.customPlcAlarms.concat(data))
    } else {
      commit(AlarmMutation.loadedCustomPlcAlarms, data)
    }
  },
  async [AlarmAction.serviceAlarmReceived] ({ commit, state }, payload: ServiceAlarmModel) {
    const existingAlarm = state.serviceAlarms.find(alarm => alarm.alarmId === payload.alarmId)
    if (deepCompare(existingAlarm, payload)) { // don't update when unchanged
      return
    }
    commit(AlarmMutation.upsertServiceAlarm, payload)
    commit(AlarmMutation.setServiceAlarmTrigger, payload.active)
  },
  async [AlarmAction.messageAlarmReceived] ({ commit, state }, payload: MessageAlarmModel) {
    const existingAlarm = state.messageAlarms.find(alarm => alarm.alarmId === payload.alarmId)
    if (deepCompare(existingAlarm, payload)) { // don't update when unchanged
      return
    }
    commit(AlarmMutation.upsertMessageAlarm, payload)
    commit(AlarmMutation.setMessageAlarmTrigger, payload.active)
  },
  async [AlarmAction.customPlcAlarmReceived] ({commit, state}, payload: CustomPlcAlarmModel) {
    const existingAlarm = state.customPlcAlarms.find(alarm => alarm.alarmInstanceId === payload.alarmInstanceId)
    if (deepCompare(existingAlarm, payload)) { // don't update when unchanged
      return
    }
    commit(AlarmMutation.upsertCustomPlcAlarm, payload)
    commit(AlarmMutation.setCustomPlcAlarmTrigger, payload.isOpen)
  },
  async [AlarmAction.connectToAlarmStateHub] ({ dispatch }, forPlc: boolean) {
    const customAlarmCallbackMethod = forPlc ? 'customAlarmChangedForPlc' : 'customAlarmChanged'
    await HubService.connect({
      hubId: alarmStateHubId,
      hubUrl: `${ApiService.backendEnvironmentConfiguration().alarmStateHub}`,
      methodCallbacks: [
        {
          method: 'serviceAlarmChanged',
          callback: (payload: ServiceAlarmModel) => {
            dispatch(AlarmAction.serviceAlarmReceived, payload)
          },
        }, 
        {
          method: 'customMessageAlarmChanged',
          callback: (payload: MessageAlarmModel) => {
            dispatch(AlarmAction.messageAlarmReceived, payload)
          },
        },
        {
          method: customAlarmCallbackMethod,
          callback: (payload: CustomPlcAlarmModel) => {
            dispatch(AlarmAction.customPlcAlarmReceived, payload)
          },
        },
      ],
    })
  },
  async [AlarmAction.disconnectFromAlarmStateHub] () {
    await HubService.disconnect(alarmStateHubId)
  },
  async [AlarmAction.startForProject](_, payload: string) {
    HubService.invoke({
      hubId: alarmStateHubId,
      method: 'StartForProject',
      args: [payload],
    })
  },
  async [AlarmAction.startForPlcs](_, payload: string[]) {
    HubService.invoke({
      hubId: alarmStateHubId,
      method: 'StartForPlcs',
      args: [payload],
    })
  },
}