import { ActionTree } from 'vuex'
import ApiService from '../services/api.service'
import HubService from '../services/hub.service'
import api from './api'
import { energyStatusHubId, EnergyStatusItem, kioskHubId, MultipleEnergyStatusChanged } from './models'
import { EnergyStatusAction, EnergyStatusMutation, EnergyStatusState } from './types'

export const actions: ActionTree<EnergyStatusState, {}> = {
  async [EnergyStatusAction.loadEnergyStatusData]({ commit, state }, payload: { projectId: string, language: string }): Promise<void> {
    const { data, projectName } = state.isKioskMode
      ? await api.loadKioskEnergyData(payload.projectId, payload.language)
      : await api.loadEnergyData(payload.projectId, payload.language)
    commit(EnergyStatusMutation.setProjectId, payload.projectId)
    commit(EnergyStatusMutation.setProjectName, projectName)
    commit(EnergyStatusMutation.setEnergyStatusData, data)
  },
  async [EnergyStatusAction.connectHub]({ dispatch, state }): Promise<void> {
    const hubId = state.isKioskMode
      ? kioskHubId
      : energyStatusHubId
    const hubUrl = state.isKioskMode 
      ? `${ApiService.backendEnvironmentConfiguration().kioskHub}`
      : `${ApiService.backendEnvironmentConfiguration().energyStatusHub}`
    await HubService.connect({
      hubId,
      hubUrl,
      methodCallbacks: [
        {
          method: 'MultipleEnergyStatusChanged',
          callback: (payload: MultipleEnergyStatusChanged) => {
            dispatch(EnergyStatusAction.energyStatusChangesReceived, payload)
          },
        },
      ],
    })
  },
  async [EnergyStatusAction.disconnectHub]({ state }): Promise<void> {
    const hubId = state.isKioskMode
      ? kioskHubId
      : energyStatusHubId
    await HubService.disconnect(hubId)
  },
  async [EnergyStatusAction.setEnergyStatusItemsFilter]({ commit, dispatch }, filter: (item: EnergyStatusItem) => boolean): Promise<void> {
    commit(EnergyStatusMutation.setEnergyStatusItemsFilter, filter)
    dispatch(EnergyStatusAction.startNotifications)
  },
  async [EnergyStatusAction.startNotifications]({ state }): Promise<void> {
    const energyStatusItemIds = state.energyStatusDevices.map(d => d.items).reduce((a, b) => a.concat(b), [])
      .filter(state.energyStatusItemsFilter)
      .map(item => item.id)
    HubService.invoke({
      hubId: state.isKioskMode ? kioskHubId : energyStatusHubId,
      method: 'StartNotifications',
      args: [energyStatusItemIds],
    })
  },
  async [EnergyStatusAction.stopNotifications]({ state }): Promise<void> {
    HubService.invoke({
      hubId: state.isKioskMode ? kioskHubId : energyStatusHubId,
      method: 'StartNotifications',
      args: [[]],
    })
  },
  async [EnergyStatusAction.energyStatusChangesReceived]({ commit }, payload: MultipleEnergyStatusChanged): Promise<void> {
    payload.statuses.forEach(message => commit(EnergyStatusMutation.setEnergyStatusValue, message))
  },
}
