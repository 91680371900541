import { ManageAlarmsUiGetter, ManageAlarmsUiState } from '@/store/modules/manageAlarmsUi/types'
import { RootState } from '@/store/types'
import { AlarmDefinitionModel } from '@ecocoach/domain-store-modules/src/alarmDefinition/models'
import { AlarmLevel } from '@ecocoach/domain-store-modules/src/common'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { matchSubstringCaseInsensitive } from '@ecocoach/domain-store-modules/src/utils'
import { DropdownOption } from '@ecocoach/shared-components/src'
import { GetterTree } from 'vuex'
import { operatorOptionsForAlarmDefinitionOption, toViewModel, valueOptionsForAlarmDefinitionOption } from './helpers'
import { AlarmDefinitionEditDialogViewModel, AlarmDefinitionTableFilterSettings, AlarmDefinitionViewModel } from './models'

export const getters: GetterTree<ManageAlarmsUiState, RootState> = {
  [ManageAlarmsUiGetter.isInteracted] ({ interacted }) {
    return interacted
  },
  [ManageAlarmsUiGetter.selectedAlarmDefinitionId] ({ selectedAlarmDefinitionId }) {
    return selectedAlarmDefinitionId
  },
  [ManageAlarmsUiGetter.filterSettings] ({ filterSettings }): AlarmDefinitionTableFilterSettings {
    return filterSettings
  },
  [ManageAlarmsUiGetter.levelOptions] (_, __, ___, rootGetters): DropdownOption[] {
    return [
      { id: AlarmLevel.Critical, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('alarm.level.critical') },
      { id: AlarmLevel.Information, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('alarm.level.information') },
    ]
  },
  [ManageAlarmsUiGetter.alarmDefinitions] (_, __, rootState, rootGetters): AlarmDefinitionViewModel[] {
    return rootState.alarmDefinition.alarmDefinitions
      .filter(a => a.plcId === rootState.app.selectedPlcId)
      .map(a => toViewModel(a, rootGetters[`resource/${ResourceGetter.resolveStringResource}`]))
  },
  [ManageAlarmsUiGetter.filteredAlarmDefinitions] ({ filterSettings }) {
    return (alarmDefinitions: AlarmDefinitionViewModel[]): AlarmDefinitionViewModel[] => {
      let filteredAlarmDefinitions = [...alarmDefinitions]
      if (filterSettings.levels.length) {
        filteredAlarmDefinitions = filteredAlarmDefinitions.filter(item => filterSettings.levels.includes(item.level))
      }
      if (filterSettings.name) {
        filteredAlarmDefinitions = filteredAlarmDefinitions.filter(a => matchSubstringCaseInsensitive(a.displayName, filterSettings.name))
      }
      if (filterSettings.controlName) {
        filteredAlarmDefinitions = filteredAlarmDefinitions
          .filter(a => matchSubstringCaseInsensitive(a.option.controlName, filterSettings.controlName))
      }
      if (filterSettings.deviceName) {
        filteredAlarmDefinitions = filteredAlarmDefinitions
          .filter(a => matchSubstringCaseInsensitive(a.option.deviceName, filterSettings.deviceName))
      }
      if (filterSettings.roomName) {
        filteredAlarmDefinitions = filteredAlarmDefinitions
          .filter(a => matchSubstringCaseInsensitive(a.option.roomName, filterSettings.roomName))
      }
      if (filterSettings.message) {
        filteredAlarmDefinitions = filteredAlarmDefinitions
          .filter(a => matchSubstringCaseInsensitive(a.displayMessage, filterSettings.message))
      }
      return filteredAlarmDefinitions
    }
  },
  [ManageAlarmsUiGetter.dialog] (state, __, rootState, rootGetters): AlarmDefinitionEditDialogViewModel {
    const resolveStringResource = rootGetters[`resource/${ResourceGetter.resolveStringResource}`]
    const controls = [{ id: '', label: '-' } as DropdownOption]
      .concat(rootState.alarmDefinition.alarmDefinitionOptions.map(c => {
        return {
          id: c.controlId,
          label: `${resolveStringResource(c.controlName)} - ${c.deviceName} - ${c.roomName}`,
        } as DropdownOption
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    const alarmDefinitionModel = state.editingAlarmDefinition || {} as AlarmDefinitionModel
    return {
      ...alarmDefinitionModel,
      show: !!state.editingAlarmDefinition,
      isCreating: alarmDefinitionModel.id === '',
      controls,
      operators: operatorOptionsForAlarmDefinitionOption(alarmDefinitionModel.option?.isNumeric, resolveStringResource),
      values: valueOptionsForAlarmDefinitionOption(alarmDefinitionModel.option?.isNumeric, resolveStringResource),
      level: alarmDefinitionModel.level ?? AlarmLevel.Critical,
    } as AlarmDefinitionEditDialogViewModel
  },
}
