import { NoteModel } from './models'

export interface NoteState {
  plcId: string
  notesForPlc: NoteModel[]
}

export enum NoteAction {
  initializeNotesForPlc = 'initializeNotesForPlc',
  uninitializeNotesForPlc = 'uninitializeNotesForPlc',
  createNoteForPlc = 'createNoteForPlc',
  updateNoteForPlc = 'updateNoteForPlc',
  deleteNoteForPlc = 'deleteNoteForPlc',
}

export enum NoteMutation {
  setPlcId = 'setPlcId',
  setNotesForPlc = 'setNotesForPlc',
  upsertNoteForPlc = 'upsertNoteForPlc',
  removeNoteForPlc = 'removeNoteForPlc',
}
