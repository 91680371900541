<template>
  <v-flex xs12 class="time-select">
    <v-menu
      ref="menu"
      v-model="menu2"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time"
      lazy
      transition="scale-transition"
      offset-y
      full-width          
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="timeFormatted"
          :label="label"
          :hint="hint"
          :disabled="disabled"
          persistent-hint
          dark
          :readonly="!keyboard"
          prepend-icon="access_time"
          @blur="textChanged"
          v-on="on"
          :clearable="keyboard"
          @click:clear="textCleared"
          @keyup.enter="changeTimePicker"
        ></v-text-field>
      </template>
      <v-time-picker 
        v-if="menu2"
        v-model="time"
        :min="min"
        :max="max"
        no-title 
        dark
        format="24hr"
        @click:minute="$refs.menu.save(time)"
        >
      </v-time-picker>
    </v-menu>
  </v-flex>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'TimeSelect',
  props: {
      /** 
       * the time in format hh:mm
       */
      value: {
          type: String,
          default: '',
      },
      /** 
       * text for label (above the input line)
       */
      label: {
          type: String,
          default: '',
      },
      /**
       * disabled
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** 
       * allow keyboard input in text field
       */
      keyboard: {
          type: Boolean,
          default: true,
      },
      /** 
       * text for hint (underneath the input line)
       */
      hint: {
          type: String,
          default: '',
      },
      menu: {
          type: Boolean,
          default: false,
      },
      /** 
       * Minimum selectable time in format HH:mm
       */
      min: {
        type: String,
        default: undefined,
      },
      /** 
       * Maximum selectable date in format HH:mm
       */
      max: {
        type: String,
        default: undefined,
      },
  },
  data() {
      return {
        time: this.value,
        timeFormatted: this.value,
        menu2: false,
      }
  },
  methods: { 
    emitTimeChanged(e) {
      this.$emit('changed', e)
    },
    textChanged(e) {
      const parsedTime = this.parseTime(this.timeFormatted)
      this.time = parsedTime
    }, 
    textCleared(e){
      this.time = ''
    },
    formatTime(time) {
      if (!time) {
        return '00:00'
      }
      const [hour, minute] = time.split(':')
      return `${hour}:${minute}`
    },
    parseTime(time) {
      try {
        const [hour, minute] = time.split(':')
        const parsedTime = new Date()
        parsedTime.setHours(hour)
        parsedTime.setMinutes(minute)

        if(parsedTime.toString() === 'Invalid time') {
          return ''
        }
        return `${parsedTime.getHours().toString().padStart(2, '0')}:${parsedTime.getMinutes().toString().padStart(2, '0')}`
      } catch(ex) {
        return ''
      }
    },
    changeTimePicker(){
      this.menu2 = false
    },
  },
  watch: {
    time(val) {
      this.timeFormatted = (val ? val : '')
      this.emitTimeChanged(this.time)
    },
    value(val) {
      this.time = this.value
    },
  },
})
</script>
<style lang="scss" scoped>

</style>

<docs>
A time picker (vuetify)


Usage example
```js
new Vue({
  methods: {
    log(time) {
      console.log('the selected time as string: ', time)
    }
  },
  template: `<time-select
    hint="someHint"
    label="someLabel"
    @changed="log"
  />`
})
```
</docs>