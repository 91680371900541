import { newGuid } from '@/store/helpers'
import { FunctionDefinitionEditorUiAction, FunctionDefinitionEditorUiGetter, FunctionDefinitionEditorUiMutation, FunctionDefinitionEditorUiState } from '@/store/modules/functionDefinitionEditorUi/types'
import { RootState } from '@/store/types'
import { ArchitectureType, ControlTypeV2, SourceLanguage } from '@ecocoach/domain-store-modules/src/common'
import fileHelper from '@ecocoach/domain-store-modules/src/helpers/fileHelper'
import { PlcConfigurationAction } from '@ecocoach/domain-store-modules/src/plcConfiguration/types'
import { ControlKinds } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { CreateResourceInput, ResourceCategory } from '@ecocoach/domain-store-modules/src/resource/models'
import { ResourceAction } from '@ecocoach/domain-store-modules/src/resource/types'
import AppDataStorageService from '@ecocoach/domain-store-modules/src/services/appdatastorage.service'
import commandApi from '@ecocoach/domain-store-modules/src/systemConfiguration/api/command'
import queryApi from '@ecocoach/domain-store-modules/src/systemConfiguration/api/query'
import { ParseFunctionBlockDefinitionXmlOutputV2, SetFunctionBlockObsoleteInput, SetFunctionBlockReleaseNotesInput, TwinCatLibraryDependency, UploadNewDefinitionsInput, UploadNewDefinitionsInputV2 } from '@ecocoach/domain-store-modules/src/systemConfiguration/models'
import { SystemConfigurationAction, SystemConfigurationMutation } from '@ecocoach/domain-store-modules/src/systemConfiguration/types'
import { deepCompare, deepCopy, toLowerCaseKeys } from '@ecocoach/domain-store-modules/src/utils'
import { AxiosError } from 'axios'
import { ActionTree } from 'vuex'
import { endpointPropertiesForKindAndType, releaseNotesResourceId, toControlDefinitionForApi, toControlDefinitions } from './helpers'
import { ArchitectureTypeStorageKey, ControlDefinitionViewModel, ControlDefinitionViewModelV2, DEFAULT_POLLING_INTERVAL_MS, FunctionDefinitionViewModel, PreserveProjectFilesInUranusStorageKey, SourceLanguageStorageKey } from './models'

const DefaultCyleTimeInMs = 25

function validateControlDefinition(controlDefinition: ControlDefinitionViewModel) : string[] {
  const errors: string[] = []
  if (!controlDefinition.id) {
    errors.push('Id not set')
  }
  if (!controlDefinition.kind) {
    errors.push('Kind not set')
  }
  if (!controlDefinition.type) {
    errors.push('Type not set')
  }
  const missingEndpoints = endpointPropertiesForKindAndType(controlDefinition, 'validation')
    .filter(endpointProperty => !controlDefinition[endpointProperty])
  missingEndpoints.forEach(endpointProperty => errors.push(`'No valid endpoint value for '${endpointProperty}`))

  return errors
}

function validateControlDefinitionV2(controlDefinition: ControlDefinitionViewModelV2) : string[] {
  const errors: string[] = []
  if (controlDefinition.type === ControlTypeV2.ConsumptionProcessToggle) {
    if (controlDefinition.commands.consumptionProcessToggleCommand.endpoint !== controlDefinition.states.value.endpoint) {
      errors.push('Command and state endpoint of ConsumptionProcessToggle must be identical')
    }
  }
  if (controlDefinition.type === ControlTypeV2.DeviceMessages) {
    if (!controlDefinition.attributes.messagesEnum) {
      errors.push('messagesEnum cannot be empty')
    }
  }
  return errors
}

function validateFunctionDefinition(functionDefinition: FunctionDefinitionViewModel, 
  controlDefinitions?: Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2>)
   : string[] {

  const warnings: string[] = []

  // validate endpoints
  if (functionDefinition.endpoints.length === 0) {
    warnings.push(`No endpoints`) 
  }

  // validate control definitions
  if (controlDefinitions) {
    const knownIds = controlDefinitions.map(cd => cd.id)
    const unknownControlDefintionReferences = functionDefinition.controlDefinitionMappings
      .filter(mapping => !knownIds.includes(mapping.controlDefinitionId))
      .map(mapping => mapping.controlDefinitionId)
    if (unknownControlDefintionReferences.length !== 0) {
      warnings.push(`Unknown control definition ids removed: ${unknownControlDefintionReferences.join(';')}`)
    }
  }
  return warnings
}

function mergeFunctionDefinition(functionDefinition: FunctionDefinitionViewModel, 
  controlDefinitions: Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2>) {
  // merge endpoint resources from function definition with endpoints from xml
  const hardwareMappableEndpoints = functionDefinition.endpoints.filter(endpoint => 
    endpoint.isMappableHardwareInput || endpoint.isMappableHardwareOutput)
  functionDefinition.endpointResources = hardwareMappableEndpoints.map(endpoint => {
    return (functionDefinition.endpointResources || []).find(resource => resource.endpointPath === endpoint.internalName) || {
      endpointPath: endpoint.internalName,
      nameResourceId: '',
      descriptionResourceId: '',
    }
  })

  // merge control definitions from function definition with matching ones from api
  const matchingIds = controlDefinitions.map(cd => cd.id)
  functionDefinition.controlDefinitionMappings = functionDefinition.controlDefinitionMappings
    .filter(mapping => matchingIds.includes(mapping.controlDefinitionId))

  // sort control definitions according usage in function definition
  const sortedUsedControlDefinitions = functionDefinition.controlDefinitionMappings
    .map(mapping => controlDefinitions.find(cd => cd.id === mapping.controlDefinitionId)!)
  const sortedUnusedControlDefinitions = controlDefinitions
    .filter(cd => !sortedUsedControlDefinitions.find(c => c.id === cd.id))
    .sort((a, b) => a.type.localeCompare(b.type))

  controlDefinitions.splice(0, controlDefinitions.length, ...[...sortedUsedControlDefinitions, ...sortedUnusedControlDefinitions])
}

function toControlDefinitionsV2(data: ParseFunctionBlockDefinitionXmlOutputV2): ControlDefinitionViewModelV2[] {
  return data.matchingControlDefinitions.map(cd => {
    return {
      ...cd,
      obsolete: false,
      isExisting: true,
    }
  })
}

export const actions: ActionTree<FunctionDefinitionEditorUiState, RootState> = {
  async [FunctionDefinitionEditorUiAction.initialize] ({ commit, dispatch, rootState }): Promise<void> {
    try {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, 'initializing')
      const architectureType = await AppDataStorageService.get(ArchitectureTypeStorageKey) || ArchitectureType.Classic
      commit(FunctionDefinitionEditorUiMutation.setArchitectureType, architectureType)
      const sourceLanguage = await AppDataStorageService.get(SourceLanguageStorageKey) || SourceLanguage.StructuredText
      commit(FunctionDefinitionEditorUiMutation.setSourceLanguage, sourceLanguage)
      const preserveProjectFilesInUranus = await AppDataStorageService.getBoolean(PreserveProjectFilesInUranusStorageKey) || false
      commit(FunctionDefinitionEditorUiMutation.setPreserveProjectFilesInUranus, preserveProjectFilesInUranus)
      await Promise.all([
        dispatch(`systemConfiguration/${SystemConfigurationAction.loadSystemConfiguration}`, { 
          language: rootState.app.selectedLanguage, 
          isEcocoachEmployee: true,
        }, { root: true }),
        dispatch(`systemConfiguration/${SystemConfigurationAction.loadGlobalVariables}`, null, { root: true }),
        dispatch(`systemConfiguration/${SystemConfigurationAction.loadPackageInfo}`, null, { root: true }),
        dispatch(`resource/${ResourceAction.loadResources}`, { 
          language: rootState.app.selectedLanguage, 
          categories: [ResourceCategory.FUNCTION_BLOCK_DESCRIPTION_STRINGS],
        }, { root:true }),
      ])
    } finally {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
    }
  },
  async [FunctionDefinitionEditorUiAction.showError] ({ commit }, error: string | AxiosError): Promise<void> {
    let message = 'An error occurred'
    if (typeof error === 'string') {
      message = error
    }
    if (typeof error === 'object' && error.response && error.response.data && error.response.data.message) {
      message = error.response.data.message
    }
    commit('wizard/setToastContent', message, {root:true})
    commit('wizard/showToast', undefined, {root:true})
  },
  async [FunctionDefinitionEditorUiAction.toggleArchitectureType] ({ state, commit }): Promise<void> {
    const architectureType = state.architectureType === ArchitectureType.ObjectOriented ? ArchitectureType.Classic : ArchitectureType.ObjectOriented
    const sourceLanguage = architectureType === ArchitectureType.Classic ? SourceLanguage.StructuredText : state.sourceLanguage
    await AppDataStorageService.set(ArchitectureTypeStorageKey, architectureType)
    commit(FunctionDefinitionEditorUiMutation.setArchitectureType, architectureType)
    commit(FunctionDefinitionEditorUiMutation.setSourceLanguage, sourceLanguage)
    commit(FunctionDefinitionEditorUiMutation.setFunctionDefinitionProperty, { property: 'id', value: '' })
    commit(FunctionDefinitionEditorUiMutation.setControlDefinitions, [])
    commit(FunctionDefinitionEditorUiMutation.setControlDefinitionsOriginal, [])
  },
  async [FunctionDefinitionEditorUiAction.toggleSourceLanguage] ({ state, commit }): Promise<void> {
    const sourceLanguage = state.sourceLanguage === SourceLanguage.CSharp ? SourceLanguage.StructuredText : SourceLanguage.CSharp
    await AppDataStorageService.set(SourceLanguageStorageKey, sourceLanguage)
    commit(FunctionDefinitionEditorUiMutation.setSourceLanguage, sourceLanguage)
    commit(FunctionDefinitionEditorUiMutation.setFunctionDefinitionProperty, { property: 'id', value: '' })
    commit(FunctionDefinitionEditorUiMutation.setControlDefinitions, [])
    commit(FunctionDefinitionEditorUiMutation.setControlDefinitionsOriginal, [])
  },
  async [FunctionDefinitionEditorUiAction.togglePreserveProjectFilesInUranus] ({ state, commit }): Promise<void> {
    const preserveProjectFilesInUranus = !state.preserveProjectFilesInUranus
    await AppDataStorageService.setBoolean(PreserveProjectFilesInUranusStorageKey, preserveProjectFilesInUranus)
    commit(FunctionDefinitionEditorUiMutation.setPreserveProjectFilesInUranus, preserveProjectFilesInUranus)
  },
  async [FunctionDefinitionEditorUiAction.createResource] ({ commit, dispatch }, payload: CreateResourceInput): Promise<void> {
    try {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, 'createResource')
      await dispatch(`resource/${ResourceAction.createResource}`, payload, { root: true })
    } finally {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
    }
  },
  async [FunctionDefinitionEditorUiAction.downloadRelease]({ commit, dispatch }, releaseId: string): Promise<void> {
    try {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, 'downloadRelease')
      await dispatch(`plcConfiguration/${PlcConfigurationAction.downloadRelease}`, releaseId, { root: true })
    } finally {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
    }
  },
  async [FunctionDefinitionEditorUiAction.createControlDefinition] ({ commit, dispatch, state }): Promise<void> {
    if (state.architectureType === ArchitectureType.ObjectOriented) {
      commit(FunctionDefinitionEditorUiMutation.setControlDefinitionInputV2, {
        ...state.controlDefinitionInputV2,
        id: newGuid(),
      } as ControlDefinitionViewModelV2)
      dispatch(FunctionDefinitionEditorUiAction.initializeControlDefinitionTypeV2, state.controlDefinitionInputV2.type)
    } else { // ArchitectureType.Classic
      commit(FunctionDefinitionEditorUiMutation.setControlDefinitionInputProperty, { property: 'id', value: newGuid() })  
      commit(FunctionDefinitionEditorUiMutation.setControlDefinitionInputProperty, { property: 'isExisting', value: false })
      commit(FunctionDefinitionEditorUiMutation.setControlDefinitionInputProperty, { property: 'obsolete', value: false })
    }
  },
  async [FunctionDefinitionEditorUiAction.editControlDefinition] ({ commit, state }, id: string): Promise<void> {
    const controlDefinition = deepCopy(state.controlDefinitions.find(_ => _.id === id))
    if (state.architectureType === ArchitectureType.ObjectOriented) {
      commit(FunctionDefinitionEditorUiMutation.setControlDefinitionInputV2, controlDefinition)
    } else { // ArchitectureType.Classic
      commit(FunctionDefinitionEditorUiMutation.setControlDefinitionInput, controlDefinition)
    }
  },
  async [FunctionDefinitionEditorUiAction.storeControlDefinition] ({ dispatch, state }): Promise<void> {
    const controlDefinition = deepCopy(state.architectureType === ArchitectureType.ObjectOriented
      ? state.controlDefinitionInputV2 : state.controlDefinitionInput)
    if (state.architectureType === ArchitectureType.ObjectOriented) {
      const controlDefinitionV2 = controlDefinition as ControlDefinitionViewModelV2
      const validationErrors = validateControlDefinitionV2(controlDefinitionV2)
      if (validationErrors.length > 0) {
        const error = `Validation error: ${validationErrors.join(',')}`
        dispatch(FunctionDefinitionEditorUiAction.showError, error)
        throw error
      }
      if (controlDefinitionV2.type === ControlTypeV2.NumericInput) {
        if (!controlDefinitionV2.commands.beginSetValueCommand?.endpoint) {
          delete controlDefinitionV2.commands.beginSetValueCommand
          controlDefinitionV2.attributes.beginCommand = ''
        }
        if (!controlDefinitionV2.commands.endSetValueCommand?.endpoint) {
          delete controlDefinitionV2.commands.endSetValueCommand
          controlDefinitionV2.attributes.endCommand = ''
        }
      }
    } else { // ArchitectureType.Classic
      const validationErrors = validateControlDefinition(controlDefinition as ControlDefinitionViewModel)
      if (validationErrors.length > 0) {
        const error = `Validation error: ${validationErrors.join(',')}`
        dispatch(FunctionDefinitionEditorUiAction.showError, error)
        throw error
      }
    }
    const index = state.controlDefinitions.findIndex(cd => cd.id === controlDefinition.id)
    if (index >= 0) {
      state.controlDefinitions.splice(index, 1, controlDefinition)
    } else { // insert new controls at start of list
      state.controlDefinitions.unshift(controlDefinition)
    }
  },
  async [FunctionDefinitionEditorUiAction.initializeControlDefinitionTypeV2] ({ commit, state, getters }, type: ControlTypeV2): Promise<void> {
    const defaultControlDefinition: ControlDefinitionViewModelV2 = getters[FunctionDefinitionEditorUiGetter.controlDefinitionDefaults](type)
    defaultControlDefinition.id = state.controlDefinitionInputV2.id

    // populate attributes from existing state
    const existingAttributes = state.controlDefinitionInputV2.attributes
    const sealedAttributes = ['appearance', 'command', 'beginCommand', 'endCommand', 'state', 'options', 'action', 'actions', 'toggle', 'toggles']
    Object.keys(defaultControlDefinition.attributes).filter(key => !sealedAttributes.includes(key)).forEach(key => {
      defaultControlDefinition.attributes[key] = 
        existingAttributes[key] ?? defaultControlDefinition.attributes[key]
    })
    commit(FunctionDefinitionEditorUiMutation.setControlDefinitionInputV2, deepCopy(defaultControlDefinition))
  },
  async [FunctionDefinitionEditorUiAction.setControlDefinitionTargetInstance] ({ commit }, targetInstanceEndpointPath: string): Promise<void> {
    commit(FunctionDefinitionEditorUiMutation.setControlDefinitionAttribute, {
      key: 'sourceInterfaceId',
      value: '',
    })
    commit(FunctionDefinitionEditorUiMutation.setControlDefinitionCommandProperty, { 
      command: 'linksCommand', 
      property: 'endpoint', 
      value: targetInstanceEndpointPath, 
    })
    commit(FunctionDefinitionEditorUiMutation.setControlDefinitionStateProperty, { 
      state: 'value', 
      property: 'endpoint', 
      value: targetInstanceEndpointPath, 
    })
  },
  async [FunctionDefinitionEditorUiAction.setControlDefinitionVisibilityCondition] ({ commit }, payload: boolean): Promise<void> {
    if (payload) {
      commit(FunctionDefinitionEditorUiMutation.setControlDefinitionAttribute, {
        key: 'visibilityCondition',
        value: { 
          state: 'visibility',
          value: null,
        },
      })
      commit(FunctionDefinitionEditorUiMutation.addControlDefinitionState, {
        state: 'visibility',
        endpoint: '',
        toSubscribe: true,
        pollInterval: DEFAULT_POLLING_INTERVAL_MS,
      })
    } else {
      commit(FunctionDefinitionEditorUiMutation.removeControlDefinitionAttribute, 'visibilityCondition')
      commit(FunctionDefinitionEditorUiMutation.removeControlDefinitionState, 'visibility')
    }
  },
  async [FunctionDefinitionEditorUiAction.setControlDefinitionVisibilityConditionValue] ({ commit }, payload: boolean | number): Promise<void> {
    commit(FunctionDefinitionEditorUiMutation.setControlDefinitionAttribute, {
      key: 'visibilityCondition',
      value: { 
        state: 'visibility',
        value: payload,
      },
    })
  },
  async [FunctionDefinitionEditorUiAction.createFunctionDefinitionFromExisting]  ({ commit, dispatch, state }, id: string)
  : Promise<void> {
    try {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, 'newversion')
      const architectureType = state.architectureType
      const existingFunctionBlockDefinition = architectureType === ArchitectureType.ObjectOriented
        ? (state.sourceLanguage === SourceLanguage.CSharp
          ? await queryApi.cSharpFunctionBlockDefinition(id)
          : await queryApi.functionBlockDefinition(id))
        : await queryApi.functionDefinition(id)
      const parseOutput = architectureType === ArchitectureType.ObjectOriented
        ? (state.sourceLanguage === SourceLanguage.CSharp
          ? await commandApi.getFunctionBlockPackageDefinition(existingFunctionBlockDefinition.functionBlockPackageInfoId!)
          : await commandApi.parseFunctionBlockDefinitionXml(existingFunctionBlockDefinition.xml!))
        : await commandApi.parseFunctionBlockXml(existingFunctionBlockDefinition.xml!)

      const controlDefinitions = architectureType === ArchitectureType.ObjectOriented
        ? toControlDefinitionsV2(parseOutput)
        : toControlDefinitions(parseOutput)
  
      const functionDefinition: FunctionDefinitionViewModel = {
        id: newGuid(),
        internalName: parseOutput.internalName,
        xml: existingFunctionBlockDefinition.xml,
        functionBlockPackageInfoId: existingFunctionBlockDefinition.functionBlockPackageInfoId,
        version: existingFunctionBlockDefinition.version || '',
        nameResourceId: existingFunctionBlockDefinition.nameResourceId || '',
        descriptionResourceId: existingFunctionBlockDefinition.descriptionResourceId || '',
        iconResourceId: existingFunctionBlockDefinition.iconResourceId || '',
        functionBlockDefinitionCategoryId: existingFunctionBlockDefinition.functionBlockDefinitionCategoryId || '',
        twinCatLibraryDependency: existingFunctionBlockDefinition.twinCatLibraryDependency || TwinCatLibraryDependency.NONE,
        cycleTimeInMilliseconds: existingFunctionBlockDefinition.cycleTimeInMilliseconds ?? DefaultCyleTimeInMs,
        messagesEnumInternalName: parseOutput.messagesEnumInternalName,
        messagesEnumValues: parseOutput.messagesEnumValues,
        controlDefinitionMappings: existingFunctionBlockDefinition.controlDefinitionMappings || [],
        endpoints: parseOutput.endpoints || [],
        autoMappingSlots: existingFunctionBlockDefinition.autoMappingSlots || [],
        endpointResources: existingFunctionBlockDefinition.endpointResources || [],
        instanceResources: parseOutput.sourceInstances?.map(i => ({
          resourceId: existingFunctionBlockDefinition.instanceResources.find(r => r.endpointPath === i.endpointPath)?.resourceId ?? '',
          endpointPath: i.endpointPath,
        })) ??[],
        predecessorFunctionBlockIds: [],
        targetInstances: parseOutput.targetInstances || [],
        measuringPoints: existingFunctionBlockDefinition.measuringPoints || [],
        energyStatusItems: existingFunctionBlockDefinition.energyStatusItems || [],
        defaultAlarmDefinitions: existingFunctionBlockDefinition.defaultAlarmDefinitions || [],
        architectureType: existingFunctionBlockDefinition.architectureType,
        sourceLanguage: existingFunctionBlockDefinition.sourceLanguage,
        licenseTags: existingFunctionBlockDefinition.licenseTags,
      }
      const validationWarnings = validateFunctionDefinition(functionDefinition, controlDefinitions)
  
      mergeFunctionDefinition(functionDefinition, controlDefinitions)
  
      commit(FunctionDefinitionEditorUiMutation.setFunctionDefinition, functionDefinition)
      commit(FunctionDefinitionEditorUiMutation.setControlDefinitions, controlDefinitions)
      commit(FunctionDefinitionEditorUiMutation.setControlDefinitionsOriginal, deepCopy(controlDefinitions))
  
      if (validationWarnings.length > 0) {
        const error = `Warning: ${validationWarnings.join(',')}`
        dispatch(FunctionDefinitionEditorUiAction.showError, error)
      }
    } finally {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
    }
  },
  async [FunctionDefinitionEditorUiAction.createFunctionDefinitionFromXmlFile] ({ commit, dispatch, state }, file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, 'newversion')
      const reader = new FileReader()
      reader.onload = async (e: any) => {
        try {
          const architectureType = state.architectureType
          const xml: string = e.target.result
          // .replSace('\\\\', '\\').replace('"', '\"')
          const parseOutput = state.architectureType === ArchitectureType.ObjectOriented
            ? await commandApi.parseFunctionBlockDefinitionXml(xml)
            : await commandApi.parseFunctionBlockXml(xml)
            
          const controlDefinitions = architectureType === ArchitectureType.ObjectOriented
            ? toControlDefinitionsV2(parseOutput)
            : toControlDefinitions(parseOutput)
      
          const functionDefinition: FunctionDefinitionViewModel = {
            id: newGuid(),
            internalName: parseOutput.internalName,
            xml,
            version: '',
            nameResourceId: `FunctionDefinitions_${parseOutput.internalName}_Name`,
            descriptionResourceId: `FunctionDefinitions_${parseOutput.internalName}_Description`,
            iconResourceId: '',
            functionBlockDefinitionCategoryId: '',
            twinCatLibraryDependency: TwinCatLibraryDependency.NONE,
            cycleTimeInMilliseconds: DefaultCyleTimeInMs,
            messagesEnumInternalName: parseOutput.messagesEnumInternalName,
            messagesEnumValues: parseOutput.messagesEnumValues,
            controlDefinitionMappings: [],
            endpoints: parseOutput.endpoints || [],
            autoMappingSlots: [],
            endpointResources: [],
            instanceResources: parseOutput.sourceInstances?.map(i => ({
              resourceId: '',
              endpointPath: i.endpointPath,
            })) ?? [], 
            predecessorFunctionBlockIds: [],
            targetInstances: parseOutput.targetInstances,
            measuringPoints: [],
            energyStatusItems: [],
            defaultAlarmDefinitions: [],
            architectureType: state.architectureType,
            sourceLanguage: SourceLanguage.StructuredText,
            licenseTags: [],
          }

          const validationWarnings = validateFunctionDefinition(functionDefinition, controlDefinitions)

          mergeFunctionDefinition(functionDefinition, controlDefinitions)

          commit(FunctionDefinitionEditorUiMutation.setFunctionDefinition, functionDefinition)
          commit(FunctionDefinitionEditorUiMutation.setControlDefinitions, controlDefinitions)
          commit(FunctionDefinitionEditorUiMutation.setControlDefinitionsOriginal, deepCopy(controlDefinitions))

          if (validationWarnings.length > 0) {
            const error = `Warning: ${validationWarnings.join(',')}`
            dispatch(FunctionDefinitionEditorUiAction.showError, error)
          }
        } finally {
          commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
          resolve()
        }
      }
      reader.onerror = (error) => {
        commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
        dispatch(FunctionDefinitionEditorUiAction.showError, 'Reading file failed')
        reject(error)
      }
      reader.readAsText(file)
    })
  },
  async [FunctionDefinitionEditorUiAction.createFunctionDefinitionFromPackage] ({ commit, dispatch }, functionBlockPackageInfoId: string)
  : Promise<void> {
    try {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, 'newversion')
      const parseOutput = await commandApi.getFunctionBlockPackageDefinition(functionBlockPackageInfoId)
      const controlDefinitions = toControlDefinitionsV2(parseOutput)
      const functionDefinition: FunctionDefinitionViewModel = {
        id: newGuid(),
        internalName: parseOutput.internalName,
        functionBlockPackageInfoId,
        version: '',
        nameResourceId: `FunctionDefinitions_${parseOutput.internalName}_Name`,
        descriptionResourceId: `FunctionDefinitions_${parseOutput.internalName}_Description`,
        iconResourceId: '',
        functionBlockDefinitionCategoryId: '',
        twinCatLibraryDependency: TwinCatLibraryDependency.NONE,
        cycleTimeInMilliseconds: DefaultCyleTimeInMs,
        messagesEnumInternalName: parseOutput.messagesEnumInternalName,
        messagesEnumValues: parseOutput.messagesEnumValues,
        controlDefinitionMappings: [],
        endpoints: parseOutput.endpoints || [],
        autoMappingSlots: [],
        endpointResources: [],
        instanceResources: parseOutput.sourceInstances?.map(i => ({
          resourceId: '',
          endpointPath: i.endpointPath,
        })) ?? [], 
        predecessorFunctionBlockIds: [],
        targetInstances: parseOutput.targetInstances,
        measuringPoints: [],
        energyStatusItems: [],
        defaultAlarmDefinitions: [],
        architectureType: ArchitectureType.ObjectOriented,
        sourceLanguage: SourceLanguage.CSharp,
        licenseTags: [],
      }

      const validationWarnings = validateFunctionDefinition(functionDefinition, controlDefinitions)

      mergeFunctionDefinition(functionDefinition, controlDefinitions)

      commit(FunctionDefinitionEditorUiMutation.setFunctionDefinition, functionDefinition)
      commit(FunctionDefinitionEditorUiMutation.setControlDefinitions, controlDefinitions)
      commit(FunctionDefinitionEditorUiMutation.setControlDefinitionsOriginal, deepCopy(controlDefinitions))

      if (validationWarnings.length > 0) {
        const error = `Warning: ${validationWarnings.join(',')}`
        dispatch(FunctionDefinitionEditorUiAction.showError, error)
      }
    } finally {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
    }
  },
  async [FunctionDefinitionEditorUiAction.updateFunctionDefinitionFromXmlFile] ({ commit, dispatch, state }, file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, 'newversion')
      const reader = new FileReader()
      reader.onload = async (e: any) => {
        try {
          const architectureType = state.architectureType
          const existingFunctionBlockDefinition = state.functionDefinition
          const xml = e.target.result
          const parseOutput = state.architectureType === ArchitectureType.ObjectOriented
            ? await commandApi.parseFunctionBlockDefinitionXml(xml)
            : await commandApi.parseFunctionBlockXml(xml)
  
          const controlDefinitions = architectureType === ArchitectureType.ObjectOriented
            ? toControlDefinitionsV2(parseOutput)
            : toControlDefinitions(parseOutput)
      
          const functionDefinition: FunctionDefinitionViewModel = {
            id: existingFunctionBlockDefinition.id,
            internalName: parseOutput.internalName,
            xml,
            version: existingFunctionBlockDefinition.version,
            nameResourceId: existingFunctionBlockDefinition.nameResourceId,
            descriptionResourceId: existingFunctionBlockDefinition.descriptionResourceId,
            iconResourceId: existingFunctionBlockDefinition.iconResourceId,
            functionBlockDefinitionCategoryId: existingFunctionBlockDefinition.functionBlockDefinitionCategoryId,
            twinCatLibraryDependency: existingFunctionBlockDefinition.twinCatLibraryDependency,
            cycleTimeInMilliseconds: existingFunctionBlockDefinition.cycleTimeInMilliseconds,
            messagesEnumInternalName: parseOutput.messagesEnumInternalName,
            messagesEnumValues: parseOutput.messagesEnumValues,
            controlDefinitionMappings: existingFunctionBlockDefinition.controlDefinitionMappings,
            endpoints: parseOutput.endpoints,
            autoMappingSlots: existingFunctionBlockDefinition.autoMappingSlots,
            endpointResources: existingFunctionBlockDefinition.endpointResources,
            instanceResources: parseOutput.sourceInstances.map(i => ({
              resourceId: existingFunctionBlockDefinition.instanceResources.find(r => r.endpointPath === i.endpointPath)?.resourceId ?? '',
              endpointPath: i.endpointPath,
            })),
            predecessorFunctionBlockIds: existingFunctionBlockDefinition.predecessorFunctionBlockIds,
            targetInstances: parseOutput.targetInstances,
            measuringPoints: existingFunctionBlockDefinition.measuringPoints,
            energyStatusItems: existingFunctionBlockDefinition.energyStatusItems,
            defaultAlarmDefinitions: existingFunctionBlockDefinition.defaultAlarmDefinitions,
            architectureType: existingFunctionBlockDefinition.architectureType,
            sourceLanguage: existingFunctionBlockDefinition.sourceLanguage,
            licenseTags: existingFunctionBlockDefinition.licenseTags,
          }
  
          const validationWarnings = validateFunctionDefinition(functionDefinition, controlDefinitions)
              
          mergeFunctionDefinition(functionDefinition, controlDefinitions)
  
          commit(FunctionDefinitionEditorUiMutation.setFunctionDefinition, functionDefinition)
          commit(FunctionDefinitionEditorUiMutation.setControlDefinitions, controlDefinitions)
          commit(FunctionDefinitionEditorUiMutation.setControlDefinitionsOriginal, deepCopy(controlDefinitions))
          
          if (validationWarnings.length > 0) {
            const error = `Warning: ${validationWarnings.join(',')}`
            dispatch(FunctionDefinitionEditorUiAction.showError, error)
          }
        } finally {
          commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
          resolve()
        }
      }
      reader.onerror = (error) => {
        commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
        dispatch(FunctionDefinitionEditorUiAction.showError, 'Reading file failed')
        reject(error)
      }
      reader.readAsText(file)
    })
  },
  async [FunctionDefinitionEditorUiAction.updateFunctionDefinitionFromPackage] ({ commit, dispatch, state }, functionBlockPackageInfoId: string)
  : Promise<void> {
    try{
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, 'newversion')
      const existingFunctionBlockDefinition = state.functionDefinition
      const parseOutput = await commandApi.getFunctionBlockPackageDefinition(functionBlockPackageInfoId)
      const controlDefinitions = toControlDefinitionsV2(parseOutput)
      const functionDefinition: FunctionDefinitionViewModel = {
        id: existingFunctionBlockDefinition.id,
        internalName: parseOutput.internalName,
        functionBlockPackageInfoId,
        version: existingFunctionBlockDefinition.version,
        nameResourceId: existingFunctionBlockDefinition.nameResourceId,
        descriptionResourceId: existingFunctionBlockDefinition.descriptionResourceId,
        iconResourceId: existingFunctionBlockDefinition.iconResourceId,
        functionBlockDefinitionCategoryId: existingFunctionBlockDefinition.functionBlockDefinitionCategoryId,
        twinCatLibraryDependency: existingFunctionBlockDefinition.twinCatLibraryDependency,
        cycleTimeInMilliseconds: existingFunctionBlockDefinition.cycleTimeInMilliseconds,
        messagesEnumInternalName: parseOutput.messagesEnumInternalName,
        messagesEnumValues: parseOutput.messagesEnumValues,
        controlDefinitionMappings: existingFunctionBlockDefinition.controlDefinitionMappings,
        endpoints: parseOutput.endpoints,
        autoMappingSlots: existingFunctionBlockDefinition.autoMappingSlots,
        endpointResources: existingFunctionBlockDefinition.endpointResources,
        instanceResources: parseOutput.sourceInstances?.map(i => ({
          resourceId: existingFunctionBlockDefinition.instanceResources.find(r => r.endpointPath === i.endpointPath)?.resourceId ?? '',
          endpointPath: i.endpointPath,
        })) ??[],
        predecessorFunctionBlockIds: existingFunctionBlockDefinition.predecessorFunctionBlockIds,
        targetInstances: parseOutput.targetInstances,
        measuringPoints: existingFunctionBlockDefinition.measuringPoints,
        energyStatusItems: existingFunctionBlockDefinition.energyStatusItems,
        defaultAlarmDefinitions: existingFunctionBlockDefinition.defaultAlarmDefinitions,
        architectureType: existingFunctionBlockDefinition.architectureType,
        sourceLanguage: existingFunctionBlockDefinition.sourceLanguage,
        licenseTags: existingFunctionBlockDefinition.licenseTags,
      }

      const validationWarnings = validateFunctionDefinition(functionDefinition, controlDefinitions)
          
      mergeFunctionDefinition(functionDefinition, controlDefinitions)

      commit(FunctionDefinitionEditorUiMutation.setFunctionDefinition, functionDefinition)
      commit(FunctionDefinitionEditorUiMutation.setControlDefinitions, controlDefinitions)
      commit(FunctionDefinitionEditorUiMutation.setControlDefinitionsOriginal, deepCopy(controlDefinitions))
      
      if (validationWarnings.length > 0) {
        const error = `Warning: ${validationWarnings.join(',')}`
        dispatch(FunctionDefinitionEditorUiAction.showError, error)
      }
    } finally {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
    }
  },
  async [FunctionDefinitionEditorUiAction.downloadFunctionBlockXml] ({ rootState, commit }, id: string): Promise<void> {
    try {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, 'downloadXml')
      const functionBlock = rootState.systemConfiguration.functionBlocks.find(_ => _.id === id)!
      const functionBlockDefinition = functionBlock.architectureType === ArchitectureType.ObjectOriented
        ? await queryApi.functionBlockDefinition(id)
        : await queryApi.functionDefinition(id)
      fileHelper.saveBlobToFile(functionBlockDefinition.xml, `${functionBlock.internalName}.xml`)
    } finally {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
    }
  },
  async [FunctionDefinitionEditorUiAction.uploadNewDefinitions] ({ state, commit, dispatch, getters, rootState }, dryRun: boolean): Promise<void> {
    const originalControlDefinitionIds = state.controlDefinitionsOriginal.map(cd => cd.id)
    const usedControlDefinitionIds = state.functionDefinition.controlDefinitionMappings.map(mapping => mapping.controlDefinitionId)
    const newUsedControlDefinitionIds = usedControlDefinitionIds.filter(id => !originalControlDefinitionIds.includes(id))
    const existingUsedControlDefinitionIds = usedControlDefinitionIds.filter(id => originalControlDefinitionIds.includes(id))

    const ofKind = (kind: ControlKinds, controlDefinitions: Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2>)
     : ControlDefinitionViewModel[] => {
      return controlDefinitions.filter(cd => (cd as ControlDefinitionViewModel).kind === kind).map(cd => cd as ControlDefinitionViewModel)
    }

    const newControlDefinitionsOfKind = (kind: ControlKinds): ControlDefinitionViewModel[] => {
      return ofKind(kind, state.controlDefinitions.filter(cd => newUsedControlDefinitionIds.includes(cd.id)))
    }

    const modifiedControlDefinitionsOfKind = (kind: ControlKinds): any[] => {
      const modifications: any[] = []
      const usedControlDefinitions = ofKind(kind, existingUsedControlDefinitionIds.map(id => state.controlDefinitions.find(cd => cd.id === id)!))
      usedControlDefinitions.forEach(usedControlDefinition => {
        const originalControlDefinition = state.controlDefinitionsOriginal.find(cd => cd.id === usedControlDefinition.id)!

        const diffObject = {}
        getters.controlDefinitionProperties(usedControlDefinition.kind, usedControlDefinition.type, false).forEach(key => {
          if (!deepCompare(usedControlDefinition[key], originalControlDefinition[key])) {
            diffObject[key] = usedControlDefinition[key]
          }
        })
        if (Object.keys(diffObject).filter(key => key !== 'obsolete').length > 0) {
          // tslint:disable-next-line:no-string-literal
          diffObject['controlDefinitionId'] = usedControlDefinition.id
          modifications.push(diffObject)
        }
      })
      return modifications
    }

    const input = {
      validationBuildOnly: dryRun,
      preserveProjectFilesInUranus: state.preserveProjectFilesInUranus,
      functionDefinitions: [{
        id: state.functionDefinition.id,
        functionBlockXml: state.functionDefinition.xml,
        version: state.functionDefinition.version,
        iconResourceId: state.functionDefinition.iconResourceId,
        nameResourceId: state.functionDefinition.nameResourceId,
        descriptionResourceId: state.functionDefinition.descriptionResourceId,
        releaseNotesResourceId: releaseNotesResourceId(state.functionDefinition.internalName, state.functionDefinition.version),
        categoryId: state.functionDefinition.functionBlockDefinitionCategoryId,
        twinCatLibraryDependency: state.functionDefinition.twinCatLibraryDependency,
        controlDefinitionIds: state.functionDefinition.controlDefinitionMappings.map(mapping => mapping.controlDefinitionId),
        autoMappingSlots: state.functionDefinition.autoMappingSlots,
        endpointResources: state.functionDefinition.endpointResources,
        defaultValues: state.functionDefinition.controlDefinitionMappings.filter(mapping => !!mapping.defaultValues?.defaultValue)
          .map(mapping => {
            return {
              controlDefinitionId: mapping.controlDefinitionId,
              defaultValue: mapping.defaultValues.defaultValue,
            }
          }),
        predecessorFunctionBlockIds: state.functionDefinition.predecessorFunctionBlockIds,
      }],
      buttonControlDefinitions: newControlDefinitionsOfKind(ControlKinds.BUTTON_CONTROL).map(cd => 
        toControlDefinitionForApi(cd, state.functionDefinition, getters.controlDefinitionProperties)),
      colorPickerControlDefinitions: newControlDefinitionsOfKind(ControlKinds.COLOR_PICKER_CONTROL).map(cd => 
        toControlDefinitionForApi(cd, state.functionDefinition, getters.controlDefinitionProperties)),
      monitoringControlDefinitions: newControlDefinitionsOfKind(ControlKinds.MONITORING_CONTROL).map(cd => 
        toControlDefinitionForApi(cd, state.functionDefinition, getters.controlDefinitionProperties)),
      parameterControlDefinitions: newControlDefinitionsOfKind(ControlKinds.PARAMETER_CONTROL).map(cd => 
        toControlDefinitionForApi(cd, state.functionDefinition, getters.controlDefinitionProperties)),
      switchControlDefinitions: newControlDefinitionsOfKind(ControlKinds.SWITCH_CONTROL).map(cd => 
        toControlDefinitionForApi(cd, state.functionDefinition, getters.controlDefinitionProperties)),
      textControlDefinitions: newControlDefinitionsOfKind(ControlKinds.TEXT_CONTROL).map(cd => 
        toControlDefinitionForApi(cd, state.functionDefinition, getters.controlDefinitionProperties)),

      modifiedButtonControlDefinitions: modifiedControlDefinitionsOfKind(ControlKinds.BUTTON_CONTROL),
      modifiedColorPickerControlDefinitions: modifiedControlDefinitionsOfKind(ControlKinds.COLOR_PICKER_CONTROL),
      modifiedMonitoringControlDefinitions: modifiedControlDefinitionsOfKind(ControlKinds.MONITORING_CONTROL),
      modifiedParameterControlDefinitions: modifiedControlDefinitionsOfKind(ControlKinds.PARAMETER_CONTROL),
      modifiedSwitchControlDefinitions: modifiedControlDefinitionsOfKind(ControlKinds.SWITCH_CONTROL),
      modifiedTextControlDefinitions: modifiedControlDefinitionsOfKind(ControlKinds.TEXT_CONTROL),
    } as UploadNewDefinitionsInput

    try {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, dryRun ? 'dryrun' : 'uploading')
      const output = await commandApi.uploadNewDefinitions(input, rootState.app.selectedLanguage)
      state.uploadResults.unshift({
        dryRun,
        success: true,
        uploadInput: input,
        uploadOutput: output,
      })
      if (!dryRun) {
        // set control defintions obsolete
        const alreadyObsoleteControlDefinitions = state.controlDefinitionsOriginal.filter(cd => cd.obsolete).map(cd => cd.id)
        const setObsoleteControlDefinitionCalls = state.controlDefinitions
          .filter(cd => cd.obsolete && !alreadyObsoleteControlDefinitions.includes(cd.id))
          .map(cd => commandApi.setControlDefinitionObsolete(cd.id))
        await Promise.all(setObsoleteControlDefinitionCalls)

        // reset state (reload needed to sync everything with backend)
        commit(FunctionDefinitionEditorUiMutation.setFunctionDefinitionProperty, { property: 'id', value: '' })
        commit(FunctionDefinitionEditorUiMutation.setControlDefinitions, [])
        commit(FunctionDefinitionEditorUiMutation.setControlDefinitionsOriginal, [])

        // add newly created function blocks to store
        const functionBlocks = rootState.systemConfiguration.functionBlocks.concat(output.functionDefinitionOutputs.map(i => i.functionBlockOutput))
        commit(`systemConfiguration/${SystemConfigurationMutation.setFunctionBlocks}`, functionBlocks, { root: true })

        // set function blocks obsolete
        output.functionDefinitionOutputs
          .map(fd => fd.setTestStateOutput && fd.setTestStateOutput.obsoleteIds || [])
          .reduce((a, b) => a.concat(b), [])
          .forEach(id => commit(`systemConfiguration/${SystemConfigurationMutation.setFunctionBlockObsolete}`,
            { id } as SetFunctionBlockObsoleteInput, { root: true }))
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log((e as any).response.data)

      state.uploadResults.unshift({
        dryRun,
        success: false,
        uploadInput: input,
        uploadOutput: (e as any).response.data,
      })

      const messages = toLowerCaseKeys((e as any).response.data)?.processingErrors?.map(e => e.exception.message) ?? []
      const error = `Errors: ${messages.join(',')}`
      dispatch(FunctionDefinitionEditorUiAction.showError, error)
    } finally {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
    }
  },
  async [FunctionDefinitionEditorUiAction.uploadNewDefinitionsV2] ({ state, commit, dispatch, rootState }, dryRun: boolean): Promise<void> {
    const usedControlDefinitionIds = state.functionDefinition.controlDefinitionMappings.map(mapping => mapping.controlDefinitionId)
    const usedControlDefinitions = state.controlDefinitions.filter(cd => usedControlDefinitionIds.includes(cd.id))
    const upsertedControlDefinitions = usedControlDefinitions
      .filter(cd => !deepCompare(cd, state.controlDefinitionsOriginal.find(_ => _.id === cd.id)))
    const alreadyObsoleteControlDefinitions = state.controlDefinitionsOriginal.filter(cd => cd.obsolete).map(cd => cd.id)
    const newObsoleteControlDefinitions = state.controlDefinitions
      .filter(cd => cd.obsolete && !alreadyObsoleteControlDefinitions.includes(cd.id))
      .map(cd => cd.id)

    const input = {
      validationBuildOnly: dryRun,
      preserveProjectFilesInUranus: state.preserveProjectFilesInUranus,
      definitions: [{
        xml: state.functionDefinition.xml,
        version: state.functionDefinition.version,
        functionBlockDefinitionCategoryId: state.functionDefinition.functionBlockDefinitionCategoryId,
        nameResourceId: state.functionDefinition.nameResourceId,
        descriptionResourceId: state.functionDefinition.descriptionResourceId,
        iconResourceId: state.functionDefinition.iconResourceId,
        releaseNotesResourceId: releaseNotesResourceId(state.functionDefinition.internalName, state.functionDefinition.version),
        controlDefinitionMappings: state.functionDefinition.controlDefinitionMappings,
        cycleTimeInMilliseconds: state.functionDefinition.cycleTimeInMilliseconds,
        autoMappingSlots: state.functionDefinition.autoMappingSlots,
        predecessorFunctionBlockIds: state.functionDefinition.predecessorFunctionBlockIds,
        endpointResources: state.functionDefinition.endpointResources,
        instanceResources: state.functionDefinition.instanceResources,
        measuringPoints: state.functionDefinition.measuringPoints,
        energyStatusItems: state.functionDefinition.energyStatusItems,
        defaultAlarmDefinitions: state.functionDefinition.defaultAlarmDefinitions,
        licenseTags: state.functionDefinition.licenseTags,
      }],
      controlDefinitionsToUpsert: upsertedControlDefinitions,
      controlDefinitionsToDelete: [],
      controlDefinitionsToSetObsolete: newObsoleteControlDefinitions,
    } as UploadNewDefinitionsInputV2

    try {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, dryRun ? 'dryrun' : 'uploading')
      const output = await commandApi.uploadDefinitions(input, rootState.app.selectedLanguage)
      state.uploadResults.unshift({
        dryRun,
        success: true,
        uploadInput: input,
        uploadOutput: output,
      })
      if (!dryRun) {
        // reset state (reload needed to sync everything with backend)
        commit(FunctionDefinitionEditorUiMutation.setFunctionDefinitionProperty, { property: 'id', value: '' })
        commit(FunctionDefinitionEditorUiMutation.setControlDefinitions, [])
        commit(FunctionDefinitionEditorUiMutation.setControlDefinitionsOriginal, [])

        // add newly created function blocks to store
        const functionBlocks = rootState.systemConfiguration.functionBlocks.concat(output.functionBlockDefinitions)
        commit(`systemConfiguration/${SystemConfigurationMutation.setFunctionBlocks}`, functionBlocks, { root: true })

        // set function blocks obsolete
        output.obsoleteFunctionBlockDefinitionIds?.forEach(id => 
          commit(`systemConfiguration/${SystemConfigurationMutation.setFunctionBlockObsolete}`, { id }, { root: true }))
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log((e as any).response.data)

      state.uploadResults.unshift({
        dryRun,
        success: false,
        uploadInput: input,
        uploadOutput: (e as any).response.data,
      })

      const message = toLowerCaseKeys((e as any).response.data).message
      const messages = [...(message ? [message] : []), ...toLowerCaseKeys((e as any).response.data).processingErrors?.map(e => e.exception.message) ?? []]
      const error = `Errors: ${messages.join(',')}`
      dispatch(FunctionDefinitionEditorUiAction.showError, error)
    } finally {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
    }
  },
  async [FunctionDefinitionEditorUiAction.uploadCSharpDefinitions] ({ state, commit, dispatch, rootState }, dryRun: boolean): Promise<void> {
    const usedControlDefinitionIds = state.functionDefinition.controlDefinitionMappings.map(mapping => mapping.controlDefinitionId)
    const usedControlDefinitions = state.controlDefinitions.filter(cd => usedControlDefinitionIds.includes(cd.id))
    const upsertedControlDefinitions = usedControlDefinitions
      .filter(cd => !deepCompare(cd, state.controlDefinitionsOriginal.find(_ => _.id === cd.id)))
    const alreadyObsoleteControlDefinitions = state.controlDefinitionsOriginal.filter(cd => cd.obsolete).map(cd => cd.id)
    const newObsoleteControlDefinitions = state.controlDefinitions
      .filter(cd => cd.obsolete && !alreadyObsoleteControlDefinitions.includes(cd.id))
      .map(cd => cd.id)

    const input = {
      validationBuildOnly: dryRun,
      preserveProjectFilesInUranus: state.preserveProjectFilesInUranus,
      definitions: [{
        functionBlockPackageInfoId: state.functionDefinition.functionBlockPackageInfoId,
        version: state.functionDefinition.version,
        functionBlockDefinitionCategoryId: state.functionDefinition.functionBlockDefinitionCategoryId,
        nameResourceId: state.functionDefinition.nameResourceId,
        descriptionResourceId: state.functionDefinition.descriptionResourceId,
        iconResourceId: state.functionDefinition.iconResourceId,
        releaseNotesResourceId: releaseNotesResourceId(state.functionDefinition.internalName, state.functionDefinition.version),
        controlDefinitionMappings: state.functionDefinition.controlDefinitionMappings,
        cycleTimeInMilliseconds: state.functionDefinition.cycleTimeInMilliseconds,
        autoMappingSlots: state.functionDefinition.autoMappingSlots,
        predecessorFunctionBlockIds: state.functionDefinition.predecessorFunctionBlockIds,
        endpointResources: state.functionDefinition.endpointResources,
        instanceResources: state.functionDefinition.instanceResources,
        measuringPoints: state.functionDefinition.measuringPoints,
        energyStatusItems: state.functionDefinition.energyStatusItems,
        defaultAlarmDefinitions: state.functionDefinition.defaultAlarmDefinitions,
        licenseTags: state.functionDefinition.licenseTags,
      }],
      controlDefinitionsToUpsert: upsertedControlDefinitions,
      controlDefinitionsToDelete: [],
      controlDefinitionsToSetObsolete: newObsoleteControlDefinitions,
    } as UploadNewDefinitionsInputV2

    try {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, dryRun ? 'dryrun' : 'uploading')
      const output = await commandApi.uploadCSharpDefinitions(input, rootState.app.selectedLanguage)
      state.uploadResults.unshift({
        dryRun,
        success: true,
        uploadInput: input,
        uploadOutput: output,
      })
      if (!dryRun) {
        // reset state (reload needed to sync everything with backend)
        commit(FunctionDefinitionEditorUiMutation.setFunctionDefinitionProperty, { property: 'id', value: '' })
        commit(FunctionDefinitionEditorUiMutation.setControlDefinitions, [])
        commit(FunctionDefinitionEditorUiMutation.setControlDefinitionsOriginal, [])

        // add newly created function blocks to store
        const functionBlocks = rootState.systemConfiguration.functionBlocks.concat(output.functionBlockDefinitions)
        commit(`systemConfiguration/${SystemConfigurationMutation.setFunctionBlocks}`, functionBlocks, { root: true })

        // set function blocks obsolete
        output.obsoleteFunctionBlockDefinitionIds?.forEach(id => 
          commit(`systemConfiguration/${SystemConfigurationMutation.setFunctionBlockObsolete}`, { id }, { root: true }))
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log((e as any).response.data)

      state.uploadResults.unshift({
        dryRun,
        success: false,
        uploadInput: input,
        uploadOutput: (e as any).response.data,
      })

      const message = toLowerCaseKeys((e as any).response.data).message
      const messages = [...(message ? [message] : []), ...toLowerCaseKeys((e as any).response.data).processingErrors?.map(e => e.exception.message) ?? []]
      const error = `Errors: ${messages.join(',')}`
      dispatch(FunctionDefinitionEditorUiAction.showError, error)
    } finally {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
    }
  },
  async [FunctionDefinitionEditorUiAction.setFunctionDefinitionActive] ({ commit, dispatch }, id: string): Promise<void> {
    try {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, 'setActive')
      await dispatch(`systemConfiguration/${SystemConfigurationAction.setFunctionBlockActive}`, id, { root: true })
    } finally {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
    }
  },
  async [FunctionDefinitionEditorUiAction.setFunctionDefinitionObsolete] ({ commit, dispatch },
    payload: SetFunctionBlockObsoleteInput): Promise<void> {
    try {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, 'setObsolete')
      await dispatch(`systemConfiguration/${SystemConfigurationAction.setFunctionBlockObsolete}`, payload, { root: true })
    } finally {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
    }
  },
  async [FunctionDefinitionEditorUiAction.upsertReleaseNotes] ({ commit, dispatch, state, rootState },
    payload: SetFunctionBlockReleaseNotesInput): Promise<void> {
    try {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, 'saveReleaseNotes')
      const functionBlock = rootState.systemConfiguration.functionBlocks.find(fb => fb.id === payload.id)!
      const category = state.architectureType === ArchitectureType.ObjectOriented 
        ? ResourceCategory.SYSTEM_CONFIGURATION 
        : ResourceCategory.FUNCTION_BLOCK_DESCRIPTION_STRINGS
      await dispatch(`resource/${ResourceAction.createResource}`, {
        id: functionBlock.releaseNotesResourceId,
        category,
        defaultValue: payload.releaseNotes,
      } as CreateResourceInput, { root: true })
      commit(`systemConfiguration/${SystemConfigurationMutation.setFunctionBlockReleaseNotes}`, payload, { root: true })
    } finally {
      commit(FunctionDefinitionEditorUiMutation.setActionInProgress, '')
    }
  },
}
