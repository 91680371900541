import moment from 'moment'
import { LogLevel } from './models'

export function formatTimeStamp(dateTimeUtc: string) {
  return moment.utc(dateTimeUtc).local().format('DD.MM.YYYY - HH:mm:ss')
}

export function formatLogTimeStamp(dateTimeUtc: string) {
  return moment.utc(dateTimeUtc).local().format('DD.MM.YYYY - HH:mm:ss.SSS')
}

export function getLocalDateFromUtcDateTime(dateTimeUtc: string) {
  return moment.utc(dateTimeUtc).local().format('YYYY-MM-DD')
}

export function getLocalTimeFromUtcDateTime(dateTimeUtc: string) {
  return moment.utc(dateTimeUtc).local().format('HH:mm')
}

export function updateUtcDateTimeWithLocalDate(dateTimeUtc: string, newLocalDate: string) {
  const [year, month, day] = newLocalDate.split('-')
  return moment.utc(dateTimeUtc).local()
    .year(Number(year))
    .month(Number(month) - 1) // moment.js months are 0-based
    .date(Number(day))
    .utc().toISOString()
}

export function updateUtcDateTimeWithLocalTime(dateTimeUtc: string, newLocalTime: string) {
  const [hour, minute] = newLocalTime.split(':')
  return moment.utc(dateTimeUtc).local()
    .hour(Number(hour))
    .minute(Number(minute))
    .utc().toISOString()
}

export function todayLocalDate() {
  return moment().format('YYYY-MM-DD')
}

export function logLevelChipColor(logLevel: LogLevel) {
  switch (logLevel) {
  case LogLevel.None: return 'gray'
  case LogLevel.Error: return 'red'
  case LogLevel.Warning: return 'orange'
  case LogLevel.Information: return 'blue'
  case LogLevel.Debug: return 'gray'
  }
}

export function parseDeviceIdFromEndpoint(endpoint: string) {
  const deviceIdWithoutDashes = endpoint.split('device_')[1]?.split('.')[0]
  return deviceIdWithoutDashes ? addDashesToGuid(deviceIdWithoutDashes) : ''
}

export function addDashesToGuid(guid: string) {
  // tslint:disable-next-line:no-magic-numbers
  return guid.substr(0,8)+'-'+guid.substr(8, 4)+'-'+guid.substr(12,4)+'-'+guid.substr(16,4)+'-'+guid.substr(20)
}
