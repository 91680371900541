import { UserDetails } from './models'

export interface AuthenticationState {
  loggedInUser: UserDetails,
}

export enum AuthenticationAction {
  initialize = 'initialize',
  handleAppLoad = 'handleAppLoad',
  handleUnauthorized = 'handleUnauthorized',
  login = 'login',
  logout = 'logout',
  editProfile = 'editProfile',
  refreshToken = 'refreshToken',
}

export enum AuthenticationMutation {
  setLoggedInUser = 'setLoggedInUser',
  resetLoggedInUser = 'resetLoggedInUser',
}

export enum AuthenticationGetter {
  loggedIn = 'loggedIn',
  loggedInUser = 'loggedInUser',
}