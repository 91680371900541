import { actions } from '@/store/modules/roomUi/actions'
import { getters } from '@/store/modules/roomUi/getters'
import { mutations } from '@/store/modules/roomUi/mutations'
import { state } from '@/store/modules/roomUi/state'
import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { RoomUiAction, RoomUiGetter, RoomUiMutation, RoomUiState } from './types'

const namespaced: boolean = true
export const roomUi: Module<RoomUiState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action: RoomUiAction, 
  mutation: RoomUiMutation,
  getter: RoomUiGetter,
}

export const domain = 'roomUi'

export const vuexModule = {
  [domain]: roomUi,
}