import { CustomPlcAlarmModel } from '@ecocoach/domain-store-modules/src/alarm/models'

export interface AlarmViewModel extends CustomPlcAlarmModel {
  opened: string
  closed: string
  deviceId: string
  deviceName: string
  peakValueText: string
  alarmConditionText: string
  displayName: string
  displayMessage: string
}

export interface AlarmsTableFilterSettings {
  states: boolean[]
  deviceName: string
  name: string
  message: string
  openedDate: string
  closedDate: string
}

export const AlarmTableFilterSettingsStorageKey = 'alarmsFilterSettings'
