import { ArchitectureType } from '../common'
import { PlcConfigurationState } from './types'

export const state: PlcConfigurationState = {
  releaseTemplates: [],
  plcId: '',
  releaseId: '',
  architectureType: ArchitectureType.Classic,
  releases: [],
  rooms: [],
  devices: [],
  solutionHardwareInstances: [],
  deviceMappings: [],
  autoMappings: [],
  deviceUpgradeInfos: [],
}