<template>
  <modal-dialog :show="show"
    header="Measuring point"
    button1-text="Save"
    button2-text="Cancel"
    :button1-disabled="!canSave"
    @button1Pressed="onSave"
    @button2Pressed="onClose"
  >
    <v-layout column>
      <form-field text="Name (optional)">
        <string-resource-select
          :options="filteredSystemConfigurationResources('MeasuringPoint_', '_Name', true)"
          :selected-value="resourceId"
          category="SystemConfiguration"
          prefix="MeasuringPoint_"
          postfix="_Name"
          default-value=""
          @selected="resourceId = $event"
          @created="onMeasuringPointResourceCreated"
        />
      </form-field>
      <form-field text="Endpoint path">
        <autocomplete-select
          :options="functionDefinitionMeasuringPointEndpointPathOptions"
          :selected-value="endpointPath"
          @selected="endpointPath = $event"
        />
      </form-field>
      <form-field text="Unit">
        <autocomplete-select 
          :options="functionDefinitionMeasuringPointUnitOptions"
          :selected-value="unit"
          @selected="unit = $event"
        />
      </form-field>
      <form-field text="Read interval (s)">
        <text-input
          :value="readIntervalInSeconds"
          @changed="readIntervalInSeconds = $event"
        />
      </form-field>
      <checkbox-with-label
        :value="allowedForBilling"
        text="Allowed for billing"
        @checked="allowedForBilling = !$event"
      />
      <form-field text="Tags">
        <autocomplete-select 
          :multiple="true"
          :options="functionDefinitionMeasuringPointTagOptions"
          option-value="id"
          option-label="label"
          :selected-value="tags"
          placeholdertext="Select tags"
          @selected="tags = $event"
        />
      </form-field>
      <form-field text="Color gradient (optional)">
        <color-gradient-picker
          :color-gradient="colorGradient"
          @selected="colorGradient = $event"
        />
      </form-field>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import StringResourceSelect from './StringResourceSelect.vue'

import { CreateResourceInput } from '../../../../../eco-domain-store-modules/src/resource/models'
import { MeasuringPoint, MeasuringPointTag } from '../../../../../eco-domain-store-modules/src/systemConfiguration/models'
import { DEFAULT_MEASURING_POINT_READ_INTERVAL_S } from '../../../store/modules/functionDefinitionEditorUi/models'

import { newGuid } from './../../../store/helpers'

import { DropdownOption } from '../../../../../shared-components/src'

import ColorGradientPicker from '@/components/ui/ColorGradientPicker.vue'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')

@Component({
  components: {
    ColorGradientPicker,
    StringResourceSelect,
  },
})
export default class EditMeasuringPointDialog extends Vue {
  @Prop() public show: boolean
  @Prop() public model: MeasuringPoint
  @FunctionDefinitionEditorUi.Getter public functionDefinitionMeasuringPointEndpointPathOptions: string[]
  @FunctionDefinitionEditorUi.Getter public functionDefinitionMeasuringPointUnitOptions: string[]
  @FunctionDefinitionEditorUi.Getter public functionDefinitionMeasuringPointTagOptions: DropdownOption[]
  @FunctionDefinitionEditorUi.Getter public filteredSystemConfigurationResources:
    (prefix: string, postfix: string, allowEmpty: boolean) => DropdownOption[]
  @FunctionDefinitionEditorUi.Mutation public upsertFunctionDefinitionMeasuringPoint: (payload: MeasuringPoint) => void
  @FunctionDefinitionEditorUi.Action public createResource: (payload: CreateResourceInput) => Promise<void>

  public id = ''
  public endpointPath = ''
  public unit = ''
  public readIntervalInSeconds = DEFAULT_MEASURING_POINT_READ_INTERVAL_S
  public allowedForBilling = false
  public tags: string[] = []
  public resourceId = ''
  public colorGradient = ''

  @Watch('show')
  public async onShow() {
    if (this.model) { // edit
      this.id = this.model.id
      this.endpointPath = this.model.endpointPath
      this.unit = this.model.unit
      this.readIntervalInSeconds = this.model.readIntervalInSeconds
      this.allowedForBilling = this.model.allowedForBilling
      this.tags = this.model.tags
      this.resourceId = this.model.resourceId
      this.colorGradient = this.model.colorGradient
    } else { // create
      this.id = newGuid(),
      this.endpointPath = ''
      this.unit = ''
      this.readIntervalInSeconds = DEFAULT_MEASURING_POINT_READ_INTERVAL_S
      this.allowedForBilling = false
      this.tags = []
      this.resourceId = ''
      this.colorGradient = ''
    }
  }
  
  public get canSave() {
    return this.endpointPath && this.unit && this.readIntervalInSeconds && this.tags.length
  }

  public onSave() {
    this.upsertFunctionDefinitionMeasuringPoint({
      id: this.id,
      endpointPath: this.endpointPath,
      unit: this.unit,
      readIntervalInSeconds: this.readIntervalInSeconds,
      allowedForBilling: this.allowedForBilling,
      tags: this.tags as MeasuringPointTag[],
      resourceId: this.resourceId,
      colorGradient: this.colorGradient,
    })
    this.onClose()
  }

  @Emit('closed')
  public onClose() {
    return
  }

  public onMeasuringPointResourceCreated(input: CreateResourceInput) {
    this.createResource(input)
    this.resourceId = input.id
  }
}
</script>
<style lang="scss" scoped>
.field {
  height: 70px;
  .text-input {
    padding-left: 10px;
    padding-right: 10px;
  }
  .label {
    padding-left: 10px;
  }
  .color-gradient-picker {
    padding: 10px;
  }
}
</style>