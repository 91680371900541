import { ArchitectureType, SourceLanguage } from '@ecocoach/domain-store-modules/src/common'
import { ControlDefinitionViewModel, ControlDefinitionViewModelV2, FunctionDefinitionViewModel, UploadDefinitionResult } from './models'

export enum FunctionDefinitionEditorUiAction {
    // general
    initialize = 'initialize',
    showError = 'showError',
    toggleArchitectureType = 'toggleArchitectureType',
    toggleSourceLanguage = 'toggleSourceLanguage',
    togglePreserveProjectFilesInUranus = 'togglePreserveProjectFilesInUranus',
    createResource = 'createResource',
    downloadRelease = 'downloadRelease',

    // control definitions
    createControlDefinition = 'createControlDefinition',
    editControlDefinition = 'editControlDefinition',
    storeControlDefinition = 'storeControlDefinition',
    initializeControlDefinitionTypeV2 = 'initializeControlDefinitionTypeV2',
    setControlDefinitionTargetInstance = 'setControlDefinitionTargetInstance',
    setControlDefinitionVisibilityCondition = 'setControlDefinitionVisibilityCondition',
    setControlDefinitionVisibilityConditionValue = 'setControlDefinitionVisibilityConditionValue',

    // function definition
    createFunctionDefinitionFromExisting = 'createFunctionDefinitionFromExisting',
    createFunctionDefinitionFromXmlFile = 'createFunctionDefinitionFromXmlFile',
    createFunctionDefinitionFromPackage = 'createFunctionDefinitionFromPackage',
    updateFunctionDefinitionFromXmlFile = 'updateFunctionDefinitionFromXmlFile',
    updateFunctionDefinitionFromPackage = 'updateFunctionDefinitionFromPackage',
    downloadFunctionBlockXml = 'downloadFunctionBlockXml',

    // upload
    uploadNewDefinitions = 'uploadNewDefinitions',
    uploadNewDefinitionsV2 = 'uploadNewDefinitionsV2',
    uploadCSharpDefinitions = 'uploadCSharpDefinitions',

    // tree
    setFunctionDefinitionActive = 'setFunctionDefinitionActive',
    setFunctionDefinitionObsolete = 'setFunctionDefinitionObsolete',
    upsertReleaseNotes = 'upsertReleaseNotes',
}

export enum FunctionDefinitionEditorUiMutation {
    // general
    setActionInProgress = 'setActionInProgress',
    setArchitectureType = 'setArchitectureType',
    setSourceLanguage = 'setSourceLanguage',
    setPreserveProjectFilesInUranus = 'setPreserveProjectFilesInUranus',

    // control definitions
    setControlDefinitions = 'setControlDefinitions',
    setControlDefinitionsOriginal = 'setControlDefinitionsOriginal',

    setControlDefinitionInput = 'setControlDefinitionInput',
    setControlDefinitionInputProperty = 'setControlDefinitionInputProperty',

    setControlDefinitionInputV2 = 'setControlDefinitionInputV2',
    setControlDefinitionObsolete = 'setControlDefinitionObsolete',
    setControlDefinitionAttribute = 'setControlDefinitionAttribute',
    removeControlDefinitionAttribute = 'removeControlDefinitionAttribute',
    setControlDefinitionStateProperty = 'setControlDefinitionStateProperty',
    setControlDefinitionCommandProperty = 'setControlDefinitionCommandProperty',
    addControlDefinitionState = 'addControlDefinitionState',
    removeControlDefinitionState = 'removeControlDefinitionState',

    // function definition
    setFunctionDefinition = 'setFunctionDefinition',
    setFunctionDefinitionProperty = 'setFunctionDefinitionProperty',

    // instances
    setFunctionDefinitionInstanceResourceProperty = 'setFunctionDefinitionInstanceResourceProperty',

    // measuring points
    upsertFunctionDefinitionMeasuringPoint = 'upsertFunctionDefinitionMeasuringPoint',
    removeFunctionDefinitionMeasuringPoint = 'removeFunctionDefinitionMeasuringPoint',

    // energy status items
    upsertFunctionDefinitionEnergyStatusItem = 'upsertFunctionDefinitionEnergyStatusItem',
    removeFunctionDefinitionEnergyStatusItem = 'removeFunctionDefinitionEnergyStatusItem',

    // default alarm definitions
    upsertFunctionDefinitionDefaultAlarmDefinition = 'upsertFunctionDefinitionDefaultAlarmDefinition',
    removeFunctionDefinitionDefaultAlarmDefinition = 'removeFunctionDefinitionDefaultAlarmDefinition',

    // endpoints
    setFunctionDefinitionEndpointResourceProperty = 'setFunctionDefinitionEndpointResourceProperty',
    
    // linking control definitions
    setLinkedControlDefinitionIds = 'setLinkedControlDefinitionIds',
    linkControlDefinition = 'linkControlDefinition',
    unlinkControlDefinition = 'unlinkControlDefinition',

    // default values
    setControlDefinitionDefaultValue = 'setControlDefinitionDefaultValue',
}

export enum FunctionDefinitionEditorUiGetter {
    // general
    actionInProgress = 'actionInProgress',
    uploadResults = 'uploadResults',
    architectureType = 'architectureType',
    sourceLanguage = 'sourceLanguage',
    preserveProjectFilesInUranus = 'preserveProjectFilesInUranus',
    
    // control definitions
    controlDefinitions = 'controlDefinitions',
    controlDefinitionInput = 'controlDefinitionInput',

    controlDefinitionDefaults = 'controlDefinitionDefaults',
    controlDefinitionTypeOptionsV2 = 'controlDefinitionTypeOptionsV2',
    controlDefinitionAppearanceOptions = 'controlDefinitionAppearanceOptions',
    controlDefinitionTargetInstanceOptions = 'controlDefinitionTargetInstanceOptions',
    controlDefinitionSelectedTargetInstanceId = 'controlDefinitionSelectedTargetInstanceId',
    controlDefinitionSourceInterfaceOptions = 'controlDefinitionSourceInterfaceOptions',
    controlDefinitionMessagesEnumOptions = 'controlDefinitionMessagesEnumOptions',
    controlDefinitionTimeTypeOptions = 'controlDefinitionTimeTypeOptions',
    controlDefinitionCommandEndpointOptions = 'controlDefinitionCommandEndpointOptions',
    controlDefinitionStateEndpointOptions = 'controlDefinitionStateEndpointOptions',
    controlDefinitionControlTagOptions = 'controlDefinitionControlTagOptions',
    controlDefinitionLinkTypeOptions = 'controlDefinitionLinkTypeOptions',

    controlDefinitionProperties = 'controlDefinitionProperties',
    controlDefinitionEndpointProperties = 'controlDefinitionEndpointProperties',
    controlDefinitionKindOptions = 'controlDefinitionKindOptions',
    controlDefinitionTypeOptions = 'controlDefinitionTypeOptions',
    controlDefinitionAppDisplayLevelOptions = 'controlDefinitionAppDisplayLevelOptions',
    controlDefinitionAppDisplayLevelOptionsV2 = 'controlDefinitionAppDisplayLevelOptionsV2',
    controlDefinitionConfigurationToolOptions = 'controlDefinitionConfigurationToolOptions',
    controlDefinitionEnergyDisplayLevelOptions = 'controlDefinitionEnergyDisplayLevelOptions',
    controlDefinitionUnitOptions = 'controlDefinitionUnitOptions',
    controlDefinitionNameResources = 'controlDefinitionNameResources',
    controlDefinitionDescriptionResources = 'controlDefinitionDescriptionResources',
    controlDefinitionDropDownValueNameResources = 'controlDefinitionDropDownValueNameResources',
    controlDefinitionMinValueLabelResources = 'controlDefinitionMinValueLabelResources',
    controlDefinitionMaxValueLabelResources = 'controlDefinitionMaxValueLabelResources',
    controlDefinitionInputsAndOutputs = 'controlDefinitionInputsAndOutputs',
    controlDefinitionInputsAndOutputsForEndpoint = 'controlDefinitionInputsAndOutputsForEndpoint',
    controlDefinitionUsedInputsAndOutputs = 'controlDefinitionUsedInputsAndOutputs',
    controlDefinitionSoftwareMappingCategories = 'controlDefinitionSoftwareMappingCategories',

    // function definition
    functionDefinition = 'functionDefinition',
    functionDefinitionInputs = 'functionDefinitionInputs',
    functionDefinitionOutputs = 'functionDefinitionOutputs',
    functionDefinitionCategoryOptions = 'functionDefinitionCategoryOptions',
    functionDefinitionTwinCatLibraryDependencyOptions = 'functionDefinitionTwinCatLibraryDependencyOptions',
    functionDefinitionCycleTimeOptions = 'functionDefinitionCycleTimeOptions',
    functionDefinitionNameResources = 'functionDefinitionNameResources',
    functionDefinitionDescriptionResources = 'functionDefinitionDescriptionResources',
    functionDefinitionIconResources = 'functionDefinitionIconResources',
    functionDefinitionLicenseTagOptions = 'functionDefinitionLicenseTagOptions',

    // measuring points
    functionDefinitionMeasuringPointEndpointPathOptions = 'functionDefinitionMeasuringPointEndpointPathOptions',
    functionDefinitionMeasuringPointUnitOptions = 'functionDefinitionMeasuringPointUnitOptions',
    functionDefinitionMeasuringPointTagOptions = 'functionDefinitionMeasuringPointTagOptions',

    // energy status items
    functionDefinitionEnergyStatusItemEndpointPathOptions = 'functionDefinitionEnergyStatusItemEndpointPathOptions',
    functionDefinitionEnergyStatusItemUnitOptions = 'functionDefinitionEnergyStatusItemUnitOptions',
    functionDefinitionEnergyStatusItemTypeOptions = 'functionDefinitionEnergyStatusItemTypeOptions',

    // default alarm definitions
    functionDefinitionDefaultAlarmDefinitionEndpointPathOptions = 'functionDefinitionDefaultAlarmDefinitionEndpointPathOptions',
    functionDefinitionDefaultAlarmDefinitionOperatorOptions = 'functionDefinitionDefaultAlarmDefinitionOperatorOptions',
    functionDefinitionDefaultAlarmLevelOptions = 'functionDefinitionDefaultAlarmLevelOptions',

    // endpoints
    hardwareMappableInputResources = 'hardwareMappableInputResources',
    hardwareMappableOutputResources = 'hardwareMappableOutputResources',
    hardwareMappableEndpointNameResources = 'hardwareMappableEndpointNameResources',
    hardwareMappableEndpointDescriptionResources = 'hardwareMappableEndpointDescriptionResources',

    // preview
    setupToolStatusControlDefinitions = 'setupToolStatusControlDefinitions',
    setupToolParameterControlDefinitions = 'setupToolParameterControlDefinitions',
    setupToolFunctionTestControlDefinitions = 'setupToolFunctionTestControlDefinitions',

    // default values
    controlDefinitionDefaultValues = 'controlDefinitionDefaultValues',

    // tree
    successorFunctionBlocksOptions = 'successorFunctionBlocksOptions',
    predecessorFunctionBlocksOptions = 'predecessorFunctionBlocksOptions',

    // oo-resouce options
    filteredSystemConfigurationResources = 'filteredSystemConfigurationResources',

    // csharp
    functionDefinitionPackageInternalNames = 'functionDefinitionPackageInternalNames',
    functionDefinitionPackageOptions = 'functionDefinitionPackageOptions',
}

export interface FunctionDefinitionEditorUiState {
    architectureType: ArchitectureType
    sourceLanguage: SourceLanguage
    preserveProjectFilesInUranus: boolean
    controlDefinitions: Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2>
    controlDefinitionsOriginal: Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2>
    controlDefinitionInput: ControlDefinitionViewModel
    controlDefinitionInputV2: ControlDefinitionViewModelV2
    functionDefinition: FunctionDefinitionViewModel
    actionInProgress: '' | 'initializing' | 'newversion' | 'dryrun' | 'uploading'
    uploadResults: UploadDefinitionResult[]
}