import { FunctionDefinitionEditorUiGetter, FunctionDefinitionEditorUiState  } from '@/store/modules/functionDefinitionEditorUi/types'
import { RootState } from '@/store/types'
import { AlarmLevel, AlarmOperator, ArchitectureType, ControlTypeV2, EnergyStatusItemType, SourceLanguage } from '@ecocoach/domain-store-modules/src/common'
import { AppDisplayLevels, ConfigurationToolCategories, ControlKinds, ControlTypes, EnergyDisplayLevels } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { ResourceModel } from '@ecocoach/domain-store-modules/src/resource/models'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { ControlTag, EndpointType, EndpointV2, FunctionBlockState, GlobalVariableModel, LicenseTag, LinkType, MeasuringPointTag, TwinCatLibraryDependency } from '@ecocoach/domain-store-modules/src/systemConfiguration/models'
import { groupedBy } from '@ecocoach/domain-store-modules/src/utils'
import { ActionControlAppearance, ActionControlAttributes, ActionsControlAppearance, ActionsControlAttributes, BaseControlAttributes, BooleanDisplayControlAppearance, BooleanDisplayControlAttributes, ConsumptionProcessToggleControlAppearance, ConsumptionProcessToggleControlAttributes, DeviceMessagesControlAppearance, DeviceMessagesControlAttributes, EnumDisplayControlAppearance, EnumDisplayControlAttributes, EnumInputControlAppearance, EnumInputControlAttributes, LinksControlAppearance, LinksControlAttributes, NumericDisplayControlAppearance, NumericDisplayControlAttributes, NumericInputControlAppearance, NumericInputControlAttributes, StateOfChargeConfigurationControlAppearance, StateOfChargeConfigurationControlAttributes, TextDisplayControlAppearance, TextDisplayControlAttributes, TextInputControlAppearance, TextInputControlAttributes, TimeDisplayControlAppearance, TimeDisplayControlAttributes, TimeDisplayControlTimeType, ToggleControlAppearance, ToggleControlAttributes, TogglesControlAppearance, TogglesControlAttributes } from '@ecocoach/shared-components/src/components/deviceControls/v2/models'
import { DropdownOption } from '@ecocoach/shared-components/src/models'
import { compare, validate } from 'compare-versions'
import { GetterTree } from 'vuex'
import { endpointPropertiesForKindAndType, isConfigurationToolCategory, isInvokableEndpointType, isReadableEndpointType, isWritableEndpointType } from './helpers'
import { ControlCommandType, ControlDefinitionViewModel, ControlDefinitionViewModelV2, DEFAULT_POLLING_INTERVAL_MS, EndpointPropertyName, FunctionDefinitionViewModel, IconDropdownOption, UploadDefinitionResult } from './models'

const readableEndpointTypes = new Set([EndpointType.Input, EndpointType.Output, EndpointType.Property, EndpointType.LocalVariable])
const isReadableEndpoint = (endpoint: EndpointV2) => readableEndpointTypes.has(endpoint.endpointType) && !endpoint.isWriteOnlyProperty
const booleanEndpointDataTypes = new Set(['BOOL', 'Boolean'])
const stringEndpointDataTypes = new Set(['string', 'wstring', 'derived', 'String'])
const nonNumericEndpointDataTypes = new Set(...Array.from(stringEndpointDataTypes.keys()), Array.from(booleanEndpointDataTypes.keys()), 'TIME', 'ARRAY', 'POINTER')

export const getters: GetterTree<FunctionDefinitionEditorUiState, RootState> = {
  [FunctionDefinitionEditorUiGetter.actionInProgress] (state): '' | 'initializing' | 'newversion' | 'dryrun' | 'uploading' {
    return state.actionInProgress
  },
  [FunctionDefinitionEditorUiGetter.uploadResults] (state): UploadDefinitionResult[] {
    return state.uploadResults
  },
  [FunctionDefinitionEditorUiGetter.architectureType] (state: FunctionDefinitionEditorUiState): ArchitectureType {
    return state.architectureType
  },
  [FunctionDefinitionEditorUiGetter.sourceLanguage] (state: FunctionDefinitionEditorUiState): SourceLanguage {
    return state.sourceLanguage
  },
  [FunctionDefinitionEditorUiGetter.preserveProjectFilesInUranus] (state: FunctionDefinitionEditorUiState): boolean {
    return state.preserveProjectFilesInUranus
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitions] (state: FunctionDefinitionEditorUiState)
  : Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2> {
    return state.controlDefinitions
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionInput] (state: FunctionDefinitionEditorUiState)
   : ControlDefinitionViewModel | ControlDefinitionViewModelV2 {
    return state.architectureType === ArchitectureType.ObjectOriented
      ? state.controlDefinitionInputV2
      : state.controlDefinitionInput
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionDefaults] () {
    return (type: ControlTypeV2): ControlDefinitionViewModelV2 => {
      const controlDefinition = {
        id: '',
        type,
        attributes: {
          label: '',
          tooltip: '',
          appDisplayLevel: AppDisplayLevels.NOT_DISPLAYED,
          configurationToolCategory: ConfigurationToolCategories.PARAMETER,
          ecocoachOnly: false,
          tags: [],
        } as BaseControlAttributes,
        commands: {},
        states: {},
        obsolete: false,
        isExisting: false,
      } as ControlDefinitionViewModelV2

      const command = {
        endpoint: '',
        cloudPersistent: false,
        templateAttached: false,
        auditable: false,
        auditResourceId: '',
      }

      const subscribedState = {
        endpoint: '',
        toSubscribe: true,
        pollInterval: DEFAULT_POLLING_INTERVAL_MS,
      }

      const polledState = {
        endpoint: '',
        toSubscribe: false,
        pollInterval: DEFAULT_POLLING_INTERVAL_MS,
      }

      switch (type) {
      case ControlTypeV2.Action: {
        controlDefinition.attributes = {
          ...controlDefinition.attributes,
          appearance: ActionControlAppearance.TextButton,
          action: {
            command: 'actionCommand',
            text: '',
          },
          confirmationText: '',
        } as ActionControlAttributes
        controlDefinition.commands = {
          actionCommand: {
            ...command,
            type: ControlCommandType.InvokeMethodEndpoint,
          },
        }
        break
      }
      case ControlTypeV2.Actions: {
        controlDefinition.attributes = {
          ...controlDefinition.attributes,
          appearance: ActionsControlAppearance.IconButton,
          actions: [{
            command: 'firstActionCommand',
            icon: '',
          },{
            command: 'secondActionCommand',
            icon: '',
          }],
        } as ActionsControlAttributes
        controlDefinition.commands = {
          firstActionCommand: {
            ...command,
            type: ControlCommandType.InvokeMethodEndpoint,
          },
          secondActionCommand: {
            ...command,
            type: ControlCommandType.InvokeMethodEndpoint,
          },
        }
        break
      }
      case ControlTypeV2.Toggle: {
        controlDefinition.attributes = {
          ...controlDefinition.attributes,
          appearance: ToggleControlAppearance.Switch,
          toggle: {
            command: 'toggleCommand',
            state: 'value',
          },
        } as ToggleControlAttributes
        controlDefinition.commands = {
          toggleCommand: {
            ...command,
            type: ControlCommandType.SetBoolEndpoint,
          },
        }
        controlDefinition.states = {
          value: {
            ...subscribedState,
          },
        }
        break
      }
      case ControlTypeV2.Toggles: {
        controlDefinition.attributes = {
          ...controlDefinition.attributes,
          appearance: TogglesControlAppearance.IconButtons,
          toggles: [{
            command: 'firstToggleCommand',
            state: 'firstValue',
            icon: '',
          },{
            command: 'secondToggleCommand',
            state: 'secondValue',
            icon: '',
          }],
        } as TogglesControlAttributes
        controlDefinition.commands = {
          firstToggleCommand: {
            ...command,
            type: ControlCommandType.SetBoolEndpoint,
          },
          secondToggleCommand: {
            ...command,
            type: ControlCommandType.SetBoolEndpoint,
          },
        }
        controlDefinition.states = {
          firstValue: {
            ...subscribedState,
          },
          secondValue: {
            ...subscribedState,
          },
        }
        break
      }
      case ControlTypeV2.ConsumptionProcessToggle: {
        controlDefinition.attributes = {
          ...controlDefinition.attributes,
          appearance: ConsumptionProcessToggleControlAppearance.Switch,
          toggle: {
            command: 'consumptionProcessToggleCommand',
            state: 'value',
          },
        } as ConsumptionProcessToggleControlAttributes
        controlDefinition.commands = {
          consumptionProcessToggleCommand: {
            ...command,
            type: ControlCommandType.SetConsumptionProcess,
          },
        }
        controlDefinition.states = {
          value: {
            ...subscribedState,
          },
        }
        break
      }   
      case ControlTypeV2.BooleanDisplay: {
        controlDefinition.attributes = {
          ...controlDefinition.attributes,
          appearance: BooleanDisplayControlAppearance.Icons,
          iconTrue: '',
          iconFalse: '',
          state: 'value',
        } as BooleanDisplayControlAttributes
        controlDefinition.states = {
          value: {
            ...polledState,
          },
        }
        break
      }        
      case ControlTypeV2.NumericDisplay: {
        controlDefinition.attributes = {
          ...controlDefinition.attributes,
          appearance: NumericDisplayControlAppearance.Label,
          unit: '',
          decimals: 1,
          invalidValue: 1e300,
          invalidDisplayValue: 'n/a',
          state: 'value',
        } as NumericDisplayControlAttributes
        controlDefinition.states = {
          value: {
            ...polledState,
          },
        }
        break
      }        
      case ControlTypeV2.EnumDisplay: {
        controlDefinition.attributes = {
          ...controlDefinition.attributes,
          appearance: EnumDisplayControlAppearance.Label,
          options: [] as DropdownOption[],
          state: 'value',
        } as EnumDisplayControlAttributes
        controlDefinition.states = {
          value: {
            ...polledState,
          },
        }
        break
      } 
      case ControlTypeV2.TimeDisplay: {
        controlDefinition.attributes = {
          ...controlDefinition.attributes,
          appearance: TimeDisplayControlAppearance.DDMMYYYYHHmmss,
          timeType: TimeDisplayControlTimeType.UnixSeconds,
          state: 'value',
        } as TimeDisplayControlAttributes
        controlDefinition.states = {
          value: {
            ...polledState,
          },
        }
        break
      } 
      case ControlTypeV2.NumericInput: {
        controlDefinition.attributes = {
          ...controlDefinition.attributes,
          appearance: NumericInputControlAppearance.Input,
          minValue: 0,
          maxValue: 100,
          interval: 1,
          minValueLabel: '',
          maxValueLabel: '',
          unit: '',
          gradient: '',
          command: 'setValueCommand',
          beginCommand: 'beginSetValueCommand',
          endCommand: 'endSetValueCommand',
          state: 'value',
        } as NumericInputControlAttributes
        controlDefinition.commands = {
          setValueCommand: {
            ...command,
            type: ControlCommandType.SetNumericEndpoint,
          },
          beginSetValueCommand: {
            ...command,
            type: ControlCommandType.SetBoolEndpoint,
          },
          endSetValueCommand: {
            ...command,
            type: ControlCommandType.SetBoolEndpoint,
          },
        }
        controlDefinition.states = {
          value: {
            ...polledState,
          },
        }
        break
      }
      case ControlTypeV2.TextDisplay: {
        controlDefinition.attributes = {
          ...controlDefinition.attributes,
          appearance: TextDisplayControlAppearance.Label,
          state: 'value',
        } as TextDisplayControlAttributes
        controlDefinition.states = {
          value: {
            ...polledState,
          },
        }
        break
      }
      case ControlTypeV2.TextInput: {
        controlDefinition.attributes = {
          ...controlDefinition.attributes,
          appearance: TextInputControlAppearance.Input,
          maxLength: 80,
          command: 'setTextCommand',
          state: 'value',
        } as TextInputControlAttributes
        controlDefinition.commands = {
          setTextCommand: {
            ...command,
            type: ControlCommandType.SetStringEndpoint,
          },
        }
        controlDefinition.states = {
          value: {
            ...polledState,
          },
        }
        break
      }
      case ControlTypeV2.EnumInput: {
        controlDefinition.attributes = {
          ...controlDefinition.attributes,
          appearance: EnumInputControlAppearance.Dropdown,
          options: [] as DropdownOption[],
          command: 'setEnumCommand',
          state: 'value',
        } as EnumInputControlAttributes
        controlDefinition.commands = {
          setEnumCommand: {
            ...command,
            type: ControlCommandType.SetNumericEndpoint,
          },
        }
        controlDefinition.states = {
          value: {
            ...polledState,
          },
        }
        break
      }
      case ControlTypeV2.Links: {
        controlDefinition.attributes = {
          ...controlDefinition.attributes,
          appearance: LinksControlAppearance.Dropdown,
          sourceInterfaceId: '',
          linkType: '',
          maxNumSelections: 1,
          options: [] as DropdownOption[],
          command: 'linksCommand',
          state: 'value',
        } as LinksControlAttributes
        controlDefinition.commands = {
          linksCommand: {
            ...command,
            type: ControlCommandType.SetLinkSources,
            cloudPersistent: true,
          },
        }
        controlDefinition.states = {
          value: {
            ...subscribedState,
          },
        }
        break
      }
      case ControlTypeV2.StateOfChargeConfiguration: {
        controlDefinition.attributes = {
          ...controlDefinition.attributes,
          appearance: StateOfChargeConfigurationControlAppearance.Input,
          selfConsumptionCapacity: {
            state: 'selfConsumptionCapacity',
            label: '',
          },
          peakShavingCapacity: {
            command: 'peakShavingCapacityCommand',
            state: 'peakShavingCapacity',
            label: '',
          },
          loadManagementCapacity: {
            command: 'loadManagementCapacityCommand',
            state: 'loadManagementCapacity',
            label: '',
          },
          offGridCapacity: {
            command: 'offGridCapacityCommand',
            state: 'offGridCapacity',
            label: '',
          },
        } as StateOfChargeConfigurationControlAttributes
        controlDefinition.commands = {
          peakShavingCapacityCommand: {
            ...command,
            type: ControlCommandType.SetNumericEndpoint,
            cloudPersistent: true,
          },
          loadManagementCapacityCommand: {
            ...command,
            type: ControlCommandType.SetNumericEndpoint,
            cloudPersistent: true,
          },
          offGridCapacityCommand: {
            ...command,
            type: ControlCommandType.SetNumericEndpoint,
            cloudPersistent: true,
          },
        }
        controlDefinition.states = {
          selfConsumptionCapacity: {
            ...subscribedState,
          },
          peakShavingCapacity: {
            ...subscribedState,
          },
          loadManagementCapacity: {
            ...subscribedState,
          },
          offGridCapacity: {
            ...subscribedState,
          },
        }
        break
      }
      case ControlTypeV2.DeviceMessages: {
        controlDefinition.attributes = {
          ...controlDefinition.attributes,
          appearance: DeviceMessagesControlAppearance.Icons,
          messagesEnum: '',
          state: 'value',
        } as DeviceMessagesControlAttributes
        controlDefinition.states = {
          value: {
            ...subscribedState,
          },
        }
        break
      }
      }
      return controlDefinition
    }  
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionTypeOptionsV2] () : DropdownOption[] {
    return Object.values(ControlTypeV2)
      .filter(value => value !== ControlTypeV2.ArrayContainer) // not supported to create, only mapped from old architecture
      .sort()
      .map(value => ({ id: value, label: value }))
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionAppearanceOptions] () {
    return (type: ControlTypeV2): DropdownOption[] => {
      switch (type) {
      case ControlTypeV2.Action: return Object.values(ActionControlAppearance).map(value => ({ id: value, label: value }))
      case ControlTypeV2.Actions: return Object.values(ActionsControlAppearance).map(value => ({ id: value, label: value }))
      case ControlTypeV2.Toggle: return Object.values(ToggleControlAppearance).map(value => ({ id: value, label: value }))
      case ControlTypeV2.Toggles: return Object.values(TogglesControlAppearance).map(value => ({ id: value, label: value }))
      case ControlTypeV2.BooleanDisplay: return Object.values(BooleanDisplayControlAppearance).map(value => ({ id: value, label: value }))
      case ControlTypeV2.NumericDisplay: return Object.values(NumericDisplayControlAppearance).map(value => ({ id: value, label: value }))
      case ControlTypeV2.EnumDisplay: return Object.values(EnumDisplayControlAppearance).map(value => ({ id: value, label: value }))
      case ControlTypeV2.TimeDisplay: return Object.values(TimeDisplayControlAppearance).map(value => ({ id: value, label: value }))
      case ControlTypeV2.NumericInput: return Object.values(NumericInputControlAppearance).map(value => ({ id: value, label: value }))
      case ControlTypeV2.TextDisplay: return Object.values(TextDisplayControlAppearance).map(value => ({ id: value, label: value }))
      case ControlTypeV2.TextInput: return Object.values(TextInputControlAppearance).map(value => ({ id: value, label: value }))
      case ControlTypeV2.EnumInput: return Object.values(EnumInputControlAppearance).map(value => ({ id: value, label: value }))
      case ControlTypeV2.Links: return Object.values(LinksControlAppearance).map(value => ({ id: value, label: value }))
      case ControlTypeV2.StateOfChargeConfiguration: return Object.values(StateOfChargeConfigurationControlAppearance)
        .map(value => ({ id: value, label: value }))
      case ControlTypeV2.DeviceMessages: return Object.values(DeviceMessagesControlAppearance).map(value => ({ id: value, label: value }))
      case ControlTypeV2.ConsumptionProcessToggle: return Object.values(ConsumptionProcessToggleControlAppearance).map(v => ({ id: v, label: v }))
      default: return []
      }
    }  
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionTargetInstanceOptions] (state) : DropdownOption[] {
    return state.functionDefinition.targetInstances.map(targetInstance => {
      return {
        id: targetInstance.endpointPath, 
        label: targetInstance.endpointPath || 'Self',
      }
    }).sort((a, b) => a.id.localeCompare(b.id))
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionSelectedTargetInstanceId] (state) : string {
    return state.controlDefinitionInputV2.commands.linksCommand?.endpoint ?? ''
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionSourceInterfaceOptions] (state, localGetters) : DropdownOption[] {
    const selectedTargetInstanceId = localGetters[FunctionDefinitionEditorUiGetter.controlDefinitionSelectedTargetInstanceId]
    return state.functionDefinition.targetInstances
      .find(t => t.endpointPath === selectedTargetInstanceId)?.sourceInterfaces
      .map(sourceInterface => {
        return {
          id: sourceInterface.sourceInterfaceId, 
          label: sourceInterface.internalName,
        }
      }).sort((a, b) => a.id.localeCompare(b.id)) ?? []
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionMessagesEnumOptions] (state) : DropdownOption[] {
    const messagesEnum = state.functionDefinition.messagesEnumInternalName
    return messagesEnum ? [
      { id: messagesEnum, label: messagesEnum },
    ] : []
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionTimeTypeOptions] () : DropdownOption[] {
    return [
      { id: TimeDisplayControlTimeType.UnixSeconds, label: 'Unix (seconds)' },
      { id: TimeDisplayControlTimeType.UnixMilliseconds, label: 'Unix (milliseconds)' },
    ]
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionCommandEndpointOptions] (state) {
    return (controlType: ControlTypeV2, commandType: ControlCommandType): string[] => {
      const isMatchingEndpoint = (e: EndpointV2) => {
        const writableEndpointTypes = [EndpointType.Input, EndpointType.Property, EndpointType.LocalVariable]
        const isWritableEndpoint = writableEndpointTypes.includes(e.endpointType) && !e.isReadOnlyProperty
        switch (controlType) {
        case ControlTypeV2.Action:
        case ControlTypeV2.Actions:
          return e.endpointType === EndpointType.Method
        case ControlTypeV2.Toggle:
        case ControlTypeV2.Toggles:
        case ControlTypeV2.ConsumptionProcessToggle:
          return isWritableEndpoint && booleanEndpointDataTypes.has(e.dataType)
        case ControlTypeV2.NumericInput:
        case ControlTypeV2.StateOfChargeConfiguration:
          return commandType === ControlCommandType.SetNumericEndpoint
            ? (isWritableEndpoint && !nonNumericEndpointDataTypes.has(e.dataType))
            :  (isWritableEndpoint && booleanEndpointDataTypes.has(e.dataType))
        case ControlTypeV2.EnumInput:
          return isWritableEndpoint && e.isEnum
        case ControlTypeV2.TextInput:
          return isWritableEndpoint && stringEndpointDataTypes.has(e.dataType)
        default: return false
        }
      }

      return controlType === ControlTypeV2.Links
        ? [''].concat(state.functionDefinition.targetInstances.map(t => t.endpointPath).sort((a, b) => a.localeCompare(b)))
        : [''].concat(state.functionDefinition.endpoints.filter(isMatchingEndpoint).map(e => e.internalName).sort((a, b) => a.localeCompare(b)))
    }
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionStateEndpointOptions] (state) {
    return (controlType: ControlTypeV2, stateName: string) : string[] => {
      if (stateName === 'visibility') {
        return state.functionDefinition.endpoints.filter(isReadableEndpoint).map(e => e.internalName).sort((a, b) => a.localeCompare(b))
      }
      const isMatchingEndpoint = (e: EndpointV2) => {
        switch (controlType) {
        case ControlTypeV2.Toggle:
        case ControlTypeV2.Toggles:
        case ControlTypeV2.BooleanDisplay:
        case ControlTypeV2.ConsumptionProcessToggle:
          return isReadableEndpoint(e) && booleanEndpointDataTypes.has(e.dataType)
        case ControlTypeV2.NumericDisplay:
        case ControlTypeV2.NumericInput:
        case ControlTypeV2.TimeDisplay:
        case ControlTypeV2.StateOfChargeConfiguration:
          return isReadableEndpoint(e) && !nonNumericEndpointDataTypes.has(e.dataType)
        case ControlTypeV2.EnumInput:
        case ControlTypeV2.EnumDisplay:
          return isReadableEndpoint(e)  && e.isEnum
        case ControlTypeV2.TextDisplay:
        case ControlTypeV2.TextInput:
          return isReadableEndpoint(e) && stringEndpointDataTypes.has(e.dataType)
        default: return false
        }
      }

      return controlType === ControlTypeV2.Links
        ? state.functionDefinition.targetInstances.map(t => t.endpointPath).sort((a, b) => a.localeCompare(b))
        : state.functionDefinition.endpoints.filter(isMatchingEndpoint).map(e => e.internalName).sort((a, b) => a.localeCompare(b))
    }
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionControlTagOptions] (): DropdownOption[] {
    return Object.values(ControlTag).map(value => ({ id: value, label: value }))
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionLinkTypeOptions] (): DropdownOption[] {
    return [{ id: '', label: '' }, ...Object.values(LinkType).map(value => ({ id: value, label: value }))]
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionProperties] () {
    return (kind: ControlKinds, type: ControlTypes, isForUi=true): string[] => {
      const commonProperties = [
        'id',
        'type',
        'obsolete',
        'appDisplayLevel',
        'configurationToolCategory',
        'nameResourceId',
        'descriptionResourceId',
      ]
      const endpointSubscriptionProperties = [
        'toSubscribe',
        'pollInterval',
      ]
      switch (kind) {
      case ControlKinds.BUTTON_CONTROL: return commonProperties
      case ControlKinds.COLOR_PICKER_CONTROL: return [...commonProperties, ...endpointSubscriptionProperties]
      case ControlKinds.MONITORING_CONTROL: 
      {
        const hasUnit = 
            type === ControlTypes.UIValue || 
            type === ControlTypes.UICircle || 
            type === ControlTypes.UIDottedStateBar || 
            type === ControlTypes.UISolidStateBar ||
            type === ControlTypes.UIMultiValue
 
        const hasIsMeterDataAggregationEnabled = type === ControlTypes.UIValue
        const hasIsDynamicMeterControl = type === ControlTypes.UIValue

        return [
          ...commonProperties,
          ...endpointSubscriptionProperties,
          'energyDisplayLevel',
          'energyDisplaySummary',
          'colorGradient',
          ...((hasUnit || !isForUi) ? ['unit'] : []),
          ...(hasIsMeterDataAggregationEnabled ? ['isMeterDataAggregationEnabled'] : []),
          ...(hasIsDynamicMeterControl ? ['isDynamicMeterControl'] : []),
        ]
      }
      case ControlKinds.PARAMETER_CONTROL: 
      {
        const hasMinMax = type === ControlTypes.UISlider || type === ControlTypes.UIValuePicker 

        const hasInterval = type === ControlTypes.UISlider || type === ControlTypes.UIValuePicker || type === ControlTypes.UIDropdownValueList

        const hasUnit = type === ControlTypes.UISlider || type === ControlTypes.UIValuePicker
          
        const hasSoftwareMappingCategory = type === ControlTypes.UISoftwareMappingSource || type === ControlTypes.UISoftwareMappingTarget

        const hasDropdownValueList = type === ControlTypes.UIDropdownValueList

        return [
          ...commonProperties,
          ...endpointSubscriptionProperties,
          ...(hasMinMax ? ['minValue', 'maxValue'] : []),
          ...(hasInterval ? ['interval'] : []),
          'colorGradient',
          ...(hasUnit ? ['unit'] : []),
          ...(hasSoftwareMappingCategory ? ['softwareMappingGlobalVariableId'] : []),
          ...(hasDropdownValueList ? ['dropdownValueList'] : []),
        ]
      }
      case ControlKinds.SWITCH_CONTROL:
      {
        const hasIsMeterActivationControl = type === ControlTypes.UISwitch
        return [
          ...commonProperties,
          ...endpointSubscriptionProperties,
          ...(hasIsMeterActivationControl ? ['isMeterActivationControl'] : []),
        ]
      }
      case ControlKinds.TEXT_CONTROL: return [...commonProperties, ...endpointSubscriptionProperties]
      default: return[]
      }
    }  
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionEndpointProperties] () {
    return (kind: ControlKinds, type: ControlTypes): EndpointPropertyName[] => {
      return endpointPropertiesForKindAndType({ kind, type })
    }
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionKindOptions] (_, __, ___, rootGetters): IconDropdownOption[] {
    return [
      { 
        id: ControlKinds.BUTTON_CONTROL,
        label: 'Button control', 
        icon: rootGetters['resource/svgIcon']('eco.pushbutton1.white'),
      },
      {
        id: ControlKinds.COLOR_PICKER_CONTROL,
        label: 'Color picker control',
        icon: rootGetters['resource/svgIcon']('eco.test.white'),
      },
      { 
        id: ControlKinds.MONITORING_CONTROL,
        label: 'Monitoring control',
        icon: rootGetters['resource/svgIcon']('eco.meter.white'),
      },
      { 
        id: ControlKinds.PARAMETER_CONTROL,
        label: 'Parameter control',
        icon: rootGetters['resource/svgIcon']('eco.ems.white'),
      },
      { 
        id: ControlKinds.SWITCH_CONTROL,
        label: 'Switch control',
        icon: rootGetters['resource/svgIcon']('eco.power-off.white'),
      },
      { 
        id: ControlKinds.TEXT_CONTROL,
        label: 'Text control',
        icon: rootGetters['resource/svgIcon']('eco.information.white'),
      },
    ]
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionTypeOptions] () {
    // would be better to get this stuff from backend
    return (kind: ControlKinds): ControlTypes[] => {
      switch (kind) {
      case ControlKinds.BUTTON_CONTROL: return [
        ControlTypes.UIButton,
        ControlTypes.UIUpDownButton,
        ControlTypes.UIEnoceanDimmer,
        ControlTypes.UIPlusMinusButton,
      ]
      case ControlKinds.COLOR_PICKER_CONTROL: return [
        ControlTypes.UIHSL,
      ]
      case ControlKinds.MONITORING_CONTROL: return [
        ControlTypes.UIDottedStateBar,
        ControlTypes.UISolidStateBar,
        ControlTypes.UICircle,
        ControlTypes.UIValue,
        ControlTypes.UIMonitoringLock,
        ControlTypes.UIAlarm,
        ControlTypes.UIOnOff,
        ControlTypes.UIErrorMessage,
      ]
      case ControlKinds.PARAMETER_CONTROL: return [
        ControlTypes.UISlider,
        ControlTypes.UIValuePicker,
        ControlTypes.UISoftwareMappingSource,
        ControlTypes.UISoftwareMappingTarget,
        ControlTypes.UIDropdownValueList,
      ]
      case ControlKinds.SWITCH_CONTROL: return [
        ControlTypes.UISwitch,
        ControlTypes.UILock,
        ControlTypes.UISwitchButton,
        ControlTypes.UICheckbox,
        ControlTypes.UIUpDownSwitch,
      ]
      case ControlKinds.TEXT_CONTROL: return [
        ControlTypes.UIStringPicker,
      ]
      default: return[]
      }
    }
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionAppDisplayLevelOptions] (): AppDisplayLevels[] {
    return Object.values(AppDisplayLevels)
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionAppDisplayLevelOptionsV2] (): DropdownOption[] {
    return [
      { id: AppDisplayLevels.NOT_DISPLAYED, label: 'Hidden' },
      { id: AppDisplayLevels.DISPLAYED_ON_FIRST_LEVEL, label: 'Displayed on all levels' },
      { id: AppDisplayLevels.DISPLAYED_ON_THIRD_LEVEL, label: 'Displayed on device level only' },
    ]
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionConfigurationToolOptions] (): ConfigurationToolCategories[] {
    return Object.values(ConfigurationToolCategories)
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionEnergyDisplayLevelOptions] (): EnergyDisplayLevels[] {
    return Object.values(EnergyDisplayLevels)
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionUnitOptions] (): string[] {
    return ['', '%','°C', 'K', 'kLux', 'kW', 'KW/h', 'kWh', 'kVA', 'kvar', 'l', 'Lux', 'm/s', 'm³', 'm³/h', 'ppm', 's', 'ms', 'V', 'W', 'Ws', 'A', 'mA', 'min', 'h', 'Hz', 'Hz/s', 'VA']
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionNameResources] (_, __, rootState: RootState): DropdownOption[] {
    const nameFilterPredicate = (resource: ResourceModel) => {
      return resource.id.startsWith('ControlDefinitions_') && resource.id.includes('_Name')
    } 
    return rootState.resource.fbStrings.filter(nameFilterPredicate).map(resource => ({
      id: resource.id,
      label: `${resource.value} (${resource.id})`,
    }))
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionDescriptionResources] (
    state: FunctionDefinitionEditorUiState, __, rootState: RootState): DropdownOption[]  {
    const descriptionFilterPredicate = (resource: ResourceModel) => {
      return resource.id.startsWith('ControlDefinitions_') && resource.id.includes('_Description')
    }
    return rootState.resource.fbStrings.filter(descriptionFilterPredicate).map(resource => ({
      id: resource.id,
      label: `${resource.value} (${resource.id})`,
    }))
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionDropDownValueNameResources] (_, __, rootState: RootState): DropdownOption[] {
    const nameFilterPredicate = (resource: ResourceModel) => {
      return resource.id.startsWith('DropdownValueDefinitions_') && resource.id.includes('_Name')
    } 
    return rootState.resource.fbStrings.filter(nameFilterPredicate).map(resource => ({
      id: resource.id,
      label: `${resource.value} (${resource.id})`,
    }))
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionMinValueLabelResources] (_, __, rootState: RootState): DropdownOption[] {
    const nameFilterPredicate = (resource: ResourceModel) => {
      return resource.id.startsWith('ControlDefinitions_') && resource.id.includes('_MinimumValueLabel')
    } 
    return [{ id: '', label: '' }]
      .concat(rootState.resource.appStrings.filter(nameFilterPredicate).map(resource => ({
        id: resource.id,
        label: `${resource.value} (${resource.id})`,
      })))
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionMaxValueLabelResources] (_, __, rootState: RootState): DropdownOption[] {
    const nameFilterPredicate = (resource: ResourceModel) => {
      return resource.id.startsWith('ControlDefinitions_') && resource.id.includes('_MaximumValueLabel')
    } 
    return [{ id: '', label: '' }]
      .concat(rootState.resource.appStrings.filter(nameFilterPredicate).map(resource => ({
        id: resource.id,
        label: `${resource.value} (${resource.id})`,
      })))
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionInputsAndOutputs] (state: FunctionDefinitionEditorUiState) {
    const endpointTypes = [EndpointType.Input, EndpointType.Output, EndpointType.Method]
    return state.functionDefinition.endpoints.filter(endpoint => endpointTypes.includes(endpoint.endpointType))
      .map(endpoint => endpoint.internalName)
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionInputsAndOutputsForEndpoint] (state: FunctionDefinitionEditorUiState) {
    return (endpoint: EndpointPropertyName): string[] => {
      const writableEndpointType = isWritableEndpointType(endpoint)
      const readableEndpointType = isReadableEndpointType(endpoint)
      const invokableEndpointType = isInvokableEndpointType(endpoint)

      const isMatchingEndpoint = (e: EndpointV2) => {
        const isInput = e.endpointType === EndpointType.Input
        const isOutput = e.endpointType === EndpointType.Output
        const isProperty = e.endpointType === EndpointType.Property
        const isMethod = e.endpointType === EndpointType.Method

        if (writableEndpointType && (isInput || (isProperty && !e.isReadOnlyProperty))) {
          return true
        }
        if (readableEndpointType && (isOutput || isInput || (isProperty && !e.isWriteOnlyProperty))) {
          return true
        }
        if (invokableEndpointType && isMethod) {
          return true
        }
        return false
      }

      return state.functionDefinition.endpoints.filter(isMatchingEndpoint).map(e => e.internalName)
    }
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionUsedInputsAndOutputs] (state: FunctionDefinitionEditorUiState, localGetters: any): string[] {
    const allInputsAndOutputs: string[] = localGetters[FunctionDefinitionEditorUiGetter.controlDefinitionInputsAndOutputs]
    const endPointsOfSelectedControlDefinitions = state.controlDefinitions
      .filter(cd => state.functionDefinition.controlDefinitionMappings.map(mapping => mapping.controlDefinitionId).includes(cd.id))
      .map(cd => endpointPropertiesForKindAndType(cd as ControlDefinitionViewModel).map(endpointProperty => cd[endpointProperty] as string))
      .reduce((a, b) => a.concat(b), [])
    return allInputsAndOutputs.filter(inputOrOutput => endPointsOfSelectedControlDefinitions.includes(inputOrOutput))
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionSoftwareMappingCategories] (_, __, rootState: RootState) : GlobalVariableModel[] {
    return rootState.systemConfiguration.globalVariables
      .filter(gv => gv.isSoftwareMappingCategory)
      .sort((a, b) => a.name.localeCompare(b.name))
  },
  [FunctionDefinitionEditorUiGetter.functionDefinition] (state: FunctionDefinitionEditorUiState): FunctionDefinitionViewModel {
    return state.functionDefinition
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionInputs] (state: FunctionDefinitionEditorUiState): string[] {
    return state.functionDefinition.endpoints.filter(endpoint => endpoint.endpointType === EndpointType.Input).map(endpoint => endpoint.internalName)
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionOutputs] (state: FunctionDefinitionEditorUiState): string[] {
    return state.functionDefinition.endpoints.filter(endpoint => endpoint.endpointType === EndpointType.Output).map(endpoint => endpoint.internalName)
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionCategoryOptions] (_, __, rootState: RootState): DropdownOption[] {
    const functionBlockCategories = rootState.systemConfiguration.functionBlockCategories
    return functionBlockCategories.map(category => ({
      id: category.id,
      label: `${category.name} (${category.id})`,
    }))
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionTwinCatLibraryDependencyOptions] (): DropdownOption[] {
    return [
      { id: TwinCatLibraryDependency.NONE , label: 'None' },
      { id: TwinCatLibraryDependency.FTP , label: 'FTP' },
      { id: TwinCatLibraryDependency.MOD_BUS_RTU , label: 'Mod Bus RTU' },
      { id: TwinCatLibraryDependency.MOD_BUS_TCP , label: 'Mod Bus TCP' },
      { id: TwinCatLibraryDependency.SERIAL , label: 'Serial' },
      { id: TwinCatLibraryDependency.TCP_IP , label: 'TCP/IP' },
      { id: TwinCatLibraryDependency.ETHERCAT , label: 'EtherCat' },
    ]
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionCycleTimeOptions] (): DropdownOption[] {
    return [
      { id: 5 , label: '5 ms (RTU Master task)' },
      { id: 10 , label: '10 ms (fast task)' },
      { id: 25 , label: '25 ms (slow task)' },
    ]
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionNameResources] (_, __, rootState: RootState): DropdownOption[] {
    const nameFilterPredicate = (resource: ResourceModel) => {
      return resource.id.startsWith('FunctionDefinitions_') && resource.id.endsWith('_Name')
    } 
    return rootState.resource.fbStrings.filter(nameFilterPredicate).map(resource => ({
      id: resource.id,
      label: `${resource.value} (${resource.id})`,
    })).sort((a, b) => a.label.localeCompare(b.label))
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionDescriptionResources] (_, __, rootState: RootState): DropdownOption[] {
    const descriptionFilterPredicate = (resource: ResourceModel) => {
      return resource.id.startsWith('FunctionDefinitions_') && resource.id.endsWith('_Description')
    } 
    return rootState.resource.fbStrings.filter(descriptionFilterPredicate).map(resource => ({
      id: resource.id,
      label: `${resource.value} (${resource.id})`,
    })).sort((a, b) => a.label.localeCompare(b.label))
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionIconResources] (_, __, rootState: RootState, rootGetters): IconDropdownOption[] {
    return rootState.resource.deviceIcons.getAll().map(resource => ({
      id: resource.id,
      label: resource.id,
      icon: rootGetters['resource/svgIcon'](resource.id),
    })).sort((a, b) => a.label.localeCompare(b.label))
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionLicenseTagOptions] (): DropdownOption[] {
    return Object.values(LicenseTag).map(value => ({ id: value, label: value }))
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionMeasuringPointEndpointPathOptions] (state): string[] {
    return state.functionDefinition.endpoints.filter(isReadableEndpoint).map(e => e.internalName).sort((a, b) => a.localeCompare(b))
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionMeasuringPointUnitOptions] (): string[] {
    return ['', 'kWh', '%', 'm³', '°C']
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionMeasuringPointTagOptions] (): DropdownOption[] {
    return [
      { id: MeasuringPointTag.ConsumptionMeter, label: `${MeasuringPointTag.ConsumptionMeter} (ecoOneClick)` },
      { id: MeasuringPointTag.DynamicConsumptionMeter, label: `${MeasuringPointTag.DynamicConsumptionMeter} (ecoOneClick)` },
      { id: MeasuringPointTag.EnergyConsumed, label: `${MeasuringPointTag.EnergyConsumed} (energy consumer chart)` },
      { id: MeasuringPointTag.EnergyProduced, label: `${MeasuringPointTag.EnergyProduced} (energy producer chart)` },
      { id: MeasuringPointTag.EnergyExportedGrid, label: `${MeasuringPointTag.EnergyExportedGrid} (N+)` },
      { id: MeasuringPointTag.EnergyImportedGrid, label: `${MeasuringPointTag.EnergyImportedGrid} (N-)` },
      { id: MeasuringPointTag.EnergyChargedBuffer, label: `${MeasuringPointTag.EnergyChargedBuffer} (B+)` },
      { id: MeasuringPointTag.EnergyDischargedBuffer, label: `${MeasuringPointTag.EnergyDischargedBuffer} (B-)` },
      { id: MeasuringPointTag.EnergyProducersToConsumers, label: `${MeasuringPointTag.EnergyProducersToConsumers} (EMS P1)` },
      { id: MeasuringPointTag.EnergyProducersToGrid, label: `${MeasuringPointTag.EnergyProducersToGrid} (EMS P2)` },
      { id: MeasuringPointTag.EnergyProducersToBuffers, label: `${MeasuringPointTag.EnergyProducersToBuffers} (EMS P3)` },
      { id: MeasuringPointTag.EnergyGridToConsumers, label: `${MeasuringPointTag.EnergyGridToConsumers} (EMS N1)` },
      { id: MeasuringPointTag.EnergyGridToBuffers, label: `${MeasuringPointTag.EnergyGridToBuffers} (EMS N2)` },
      { id: MeasuringPointTag.EnergyBuffersToConsumers, label: `${MeasuringPointTag.EnergyBuffersToConsumers} (EMS B1)` },
      { id: MeasuringPointTag.EnergyBuffersToGrid, label: `${MeasuringPointTag.EnergyBuffersToGrid} (EMS B2)` },
      { id: MeasuringPointTag.HeatImported, label: `${MeasuringPointTag.HeatImported} (heat chart)` },
      { id: MeasuringPointTag.ColdWaterImported, label: `${MeasuringPointTag.ColdWaterImported} (cold water chart)` },
      { id: MeasuringPointTag.HotWaterImported, label: `${MeasuringPointTag.HotWaterImported} (warm water chart)` },
      { id: MeasuringPointTag.BufferLevel, label: `${MeasuringPointTag.BufferLevel} (SoC)` },
      { id: MeasuringPointTag.Temperature, label: `${MeasuringPointTag.Temperature} (temperature chart)` },
    ]
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionEnergyStatusItemEndpointPathOptions] (state): string[] {
    return state.functionDefinition.endpoints.filter(isReadableEndpoint).map(e => e.internalName).sort((a, b) => a.localeCompare(b))
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionEnergyStatusItemUnitOptions] (): string[] {
    return ['', 'kW', '%']
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionEnergyStatusItemTypeOptions] (): string[] {
    return ['', ...Object.values(EnergyStatusItemType).sort()]
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionDefaultAlarmDefinitionEndpointPathOptions] (state): string[] {
    return state.functionDefinition.endpoints.filter(isReadableEndpoint).map(e => e.internalName).sort((a, b) => a.localeCompare(b))
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionDefaultAlarmDefinitionOperatorOptions] (): string[] {
    return Object.values(AlarmOperator)
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionDefaultAlarmLevelOptions] (): string[] {
    return Object.values(AlarmLevel)
  },
  [FunctionDefinitionEditorUiGetter.hardwareMappableInputResources] (state: FunctionDefinitionEditorUiState)
    : Array< { endpointPath: string, nameResourceId: string, descriptionResourceId: string } > {
    const allHarwareMappableInputs = state.functionDefinition.endpoints
      .filter(endpoint => endpoint.isMappableHardwareInput)
      .map(endpoint => endpoint.internalName)
    const result = state.functionDefinition.endpointResources
      .filter(resource => allHarwareMappableInputs.includes(resource.endpointPath))
    return result
  },
  [FunctionDefinitionEditorUiGetter.hardwareMappableOutputResources] (state: FunctionDefinitionEditorUiState)
    : Array< { endpointPath: string, nameResourceId: string, descriptionResourceId: string } > {
    const allHarwareMappableOutputs = state.functionDefinition.endpoints
      .filter(endpoint => endpoint.isMappableHardwareOutput)
      .map(endpoint => endpoint.internalName)
    return state.functionDefinition.endpointResources.filter(resource => allHarwareMappableOutputs.includes(resource.endpointPath))
  },
  [FunctionDefinitionEditorUiGetter.hardwareMappableEndpointNameResources] (_, __, rootState: RootState): DropdownOption[] {
    const nameFilterPredicate = (resource: ResourceModel) => {
      return resource.id.startsWith('HardwareMappableEndpointDefinitions_') && resource.id.endsWith('_Name')
    } 
    return rootState.resource.fbStrings.filter(nameFilterPredicate).map(resource => ({
      id: resource.id,
      label: `${resource.value} (${resource.id})`,
    })).sort((a, b) => a.label.localeCompare(b.label))
  },
  [FunctionDefinitionEditorUiGetter.hardwareMappableEndpointDescriptionResources] (_, __, rootState: RootState): DropdownOption[] {
    const descriptionFilterPredicate = (resource: ResourceModel) => {
      return resource.id.startsWith('HardwareMappableEndpointDefinitions_') && resource.id.endsWith('_Description')
    } 
    return rootState.resource.fbStrings.filter(descriptionFilterPredicate).map(resource => ({
      id: resource.id,
      label: `${resource.value} (${resource.id})`,
    })).sort((a, b) => a.label.localeCompare(b.label))
  },
  [FunctionDefinitionEditorUiGetter.setupToolStatusControlDefinitions] (state: FunctionDefinitionEditorUiState) 
    : Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2> {
    const controlDefinitionIds = state.functionDefinition.controlDefinitionMappings.map(mapping => mapping.controlDefinitionId)
    return state.controlDefinitions.filter(controlDefinition => 
      controlDefinitionIds.includes(controlDefinition.id) && 
      isConfigurationToolCategory(controlDefinition, ConfigurationToolCategories.STATE) && 
      controlDefinition.type !== ControlTypes.UISoftwareMappingSource)
  },
  [FunctionDefinitionEditorUiGetter.setupToolParameterControlDefinitions] (state: FunctionDefinitionEditorUiState)
    : Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2> {
    const controlDefinitionIds = state.functionDefinition.controlDefinitionMappings.map(mapping => mapping.controlDefinitionId)
    return state.controlDefinitions.filter(controlDefinition => 
      controlDefinitionIds.includes(controlDefinition.id) && 
      isConfigurationToolCategory(controlDefinition, ConfigurationToolCategories.PARAMETER) && 
      controlDefinition.type !== ControlTypes.UISoftwareMappingSource)
  },
  [FunctionDefinitionEditorUiGetter.setupToolFunctionTestControlDefinitions] (state: FunctionDefinitionEditorUiState)
    : Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2> {
    const controlDefinitionIds = state.functionDefinition.controlDefinitionMappings.map(mapping => mapping.controlDefinitionId)
    return state.controlDefinitions.filter(controlDefinition => 
      controlDefinitionIds.includes(controlDefinition.id) && 
      isConfigurationToolCategory(controlDefinition, ConfigurationToolCategories.FUNCTION_TEST) && 
      controlDefinition.type !== ControlTypes.UISoftwareMappingSource)
  },
  [FunctionDefinitionEditorUiGetter.controlDefinitionDefaultValues] (state: FunctionDefinitionEditorUiState) {
    return (id: string): { [command: string]: any } => {
      return state.functionDefinition.controlDefinitionMappings.find(mapping => mapping.controlDefinitionId === id)
        ?.defaultValues ?? {}
    }
  },
  [FunctionDefinitionEditorUiGetter.successorFunctionBlocksOptions] (state, _, rootState, rootGetters): DropdownOption[] {
    return [{ id: '', label: '-' }]
      .concat(rootState.systemConfiguration.functionBlocks
        .filter(fb => 
          fb.architectureType === state.architectureType &&
          fb.sourceLanguage === state.sourceLanguage && 
          fb.state === FunctionBlockState.ACTIVE)
        .map(fb => {
          const name = fb.name || rootGetters[`resource/${ResourceGetter.dictionary}`](fb.nameResourceId)
          return {
            id: fb.id,
            label: `${name} (${fb.internalName}, Version: ${fb.version})`,
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label)))
  },
  [FunctionDefinitionEditorUiGetter.predecessorFunctionBlocksOptions] (state, _, rootState, rootGetters): DropdownOption[] {
    return rootState.systemConfiguration.functionBlocks
      .filter(fb => 
        fb.architectureType === state.architectureType &&
        fb.sourceLanguage === state.sourceLanguage && 
        (fb.state === FunctionBlockState.ACTIVE || fb.state === FunctionBlockState.OBSOLETE))
      .map(fb => {
        const name = fb.name || rootGetters[`resource/${ResourceGetter.dictionary}`](fb.nameResourceId)
        return {
          id: fb.id,
          label: `${name} (${fb.internalName}, Version: ${fb.version})`,
        }
      })        
      .sort((a, b) => a.label.localeCompare(b.label))
  },
  [FunctionDefinitionEditorUiGetter.filteredSystemConfigurationResources] (_, __, rootState: RootState) {
    return (prefix: string, postfix: string, allowEmpty: false): DropdownOption[] => {
      const filterPredicate = (resource: ResourceModel) => {
        return resource.id.startsWith(prefix) && resource.id.includes(postfix)
      }
      return (allowEmpty ? [{ id: '', label: '' }] : [])
        .concat(rootState.resource.systemConfigurationStrings.filter(filterPredicate).map(resource => ({
          id: resource.id,
          label: `${resource.value} (${resource.id})`,
        })).sort((a, b) => a.label.localeCompare(b.label)))
    }
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionPackageInternalNames] (_, __, rootState): DropdownOption[] {
    return Array.from(groupedBy(rootState.systemConfiguration.packageInfo, p => p.internalName).keys()).map(internalName => {
      return {
        id: internalName,
        label: internalName,
      }
    }).sort((a, b) => a.label.localeCompare(b.label))
  },
  [FunctionDefinitionEditorUiGetter.functionDefinitionPackageOptions] (_, __, rootState) {
    return (internalName: string): DropdownOption[] => {
      return rootState.systemConfiguration.packageInfo
        .filter(p => p.internalName === internalName && validate(p.packageVersion))
        .sort((a, b) => compare(a.packageVersion, b.packageVersion, '>') ? -1 : 1)
        .map(p => ({ id: p.id, label: p.packageVersion}))
    }
  },
}
