class LocalStorageWrapper {
  public get = async (key: string): Promise<string|null> => {
    if (!localStorage) {
      // tslint:disable-next-line:no-console
      console.error('LocalStorageWrapper:get error, localStorage lot available')
      return null
    }
  
    try {
      const data = localStorage.getItem(key)
      return data || null
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('LocalStorageWrapper:get error', key, error)
      return null
    }
  }

  public set = async (key: string, data: string) => {
    if (!localStorage) {
      // tslint:disable-next-line:no-console
      console.error('LocalStorageWrapper:set error, localStorage lot available')
      return
    }

    try {
      return localStorage.setItem(key, data)
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('LocalStorageWrapper:set error', key, error)
    }
  }

  public remove = async (key: string) => {
    if (!localStorage) {
      // tslint:disable-next-line:no-console
      console.error('LocalStorageWrapper:remove error, localStorage lot available')
      return
    }

    try {
      localStorage.removeItem(key)
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('LocalStorageWrapper:del error', key, error)
    }
  }
}

export default new LocalStorageWrapper()
