<template>
  <div class="autoMappingItem">
    <div class="box" :class="isMapped ? 'active' : ''" :title="slotLabel">
      <div class="label">{{slotLabel}}</div>
      <div class="moreinfo">
        <description-popover
          class="popUpButton"
          :header="slotLabel"
          :content="slotDescription"
        />
      </div>
    </div>
    <div class="action">
      <mapping-action-button
        :is-mapped="isMapped"
        :is-currently-mapping="isCurrentlyMapping"
        :disabled="disabled"
        @click="$emit('click')"
      />
    </div>
  </div>
</template>
<script lang="ts">
import MappingActionButton from '@/components/views/ecoSetupTool/steps/mapHardware/MappingActionButton.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { AutoMappingModel } from '../../../../../../../eco-domain-store-modules/src/plcConfiguration/models'

import { AutoMappingSlotModel } from '../../../../../../../eco-domain-store-modules/src/systemConfiguration/models'

const MappingUi = namespace('mappingUi')

@Component ({
  components: {
    MappingActionButton,
  },
})
export default class AutoMappingItem extends Vue {
  @Prop() public autoMappingSlot: AutoMappingSlotModel
  @Prop({ default: null }) public autoMapping: AutoMappingModel
  @Prop({ default: false}) public isMapped: boolean
  @Prop({ default: false}) public isCurrentlyMapping: boolean
  @Prop({ default: false}) public disabled: boolean
  @MappingUi.Getter public autoMappingSlotDescription: (slot: AutoMappingSlotModel, mapping: AutoMappingModel) => string
  @MappingUi.Getter public autoMappingSlotName: (slot: AutoMappingSlotModel, mapping: AutoMappingModel) => string

  public get slotLabel(): string {
    return this.autoMappingSlotName(this.autoMappingSlot, this.autoMapping)
  }

  public get slotDescription(): string {
    return this.autoMappingSlotDescription(this.autoMappingSlot, this.autoMapping)
  }
}
</script>

<style lang="scss" scoped>
.autoMappingItem {
  display:flex;
  align-items: center;
  height: 39px;
  background-color: #0b1621;
  .box {
    flex: 1;
    flex-basis: auto;
    margin: 4px;
    padding: 0 10px;
    height: 32px;
    background-color: #0b2035;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.active {
      background-color: #58aa31;
    }
    .label {
      flex: 1;
      text-align: left;
    }
  }
}

</style>