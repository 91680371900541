import EnvironmentService from './environment.service'
import IndexedDB from './storage/indexeddb.wrapper'
import IosKeyChain from './storage/ioskeychain.wrapper'
import LocalStorageWrapper from './storage/localstorage.wrapper'

export interface AppDataStorageServiceOptions {
  appId: string
  environment: string
}

class AppDataStorageService {
  private appId: string
  private environment: string

  public init(options: AppDataStorageServiceOptions) {
    this.appId = options.appId
    this.environment = options.environment
    if (!EnvironmentService.isRunningOnIos) {
      IndexedDB.init(options)
    }
  }

  public async get(key: string): Promise<string> {
    // fallback for reading values written to localstorage previously (ios)
    return await this.read(this.makeKey(key)) || await LocalStorageWrapper.get(this.makeKey(key)) || ''
  }

  public async getBoolean(key: string): Promise<boolean | null> {
    const stringValue = await this.get(key)
    if (stringValue === 'true') {
      return true
    }
    if (stringValue === 'false') {
      return false
    }
    return null
  }

  public async getObject<T>(key: string): Promise<T | undefined> {
    const json = await this.get(key)
    if (json) {
      try {
        return JSON.parse(json)
      } catch (error) {
        return undefined
      }
    }
    return undefined
  }

  public async set(key: string, value: string): Promise<void> {
    await this.write(this.makeKey(key), value)
  }

  public async setBoolean(key: string, value: boolean): Promise<void> {
    this.set(key, value ? 'true' : 'false')
  }

  public async setObject<T>(key: string, object: T): Promise<void> {
    this.set(key, JSON.stringify(object))
  }

  public async delete(key: string): Promise<void> {
    await this.remove(this.makeKey(key))
    // clean up also values written to localstorage previously
    LocalStorageWrapper.remove(this.makeKey(key))
  }

  // can be called bofre initialization e.g. to persist the backend environment which is needed for initialization
  public async getForAppId(appId: string, key: string): Promise<string> {
    if (EnvironmentService.isRunningOnIos) {
      return await IosKeyChain.get(this.makeKeyForAppId(appId, key)) || ''
    } else {
      return await LocalStorageWrapper.get(this.makeKeyForAppId(appId, key)) || ''
    }
  }

  // can be called bofre initialization e.g. to persist the backend environment which is needed for initialization
  public async setForAppId(appId: string, key: string, value: string): Promise<void> {
    if (EnvironmentService.isRunningOnIos) {
      await IosKeyChain.set(this.makeKeyForAppId(appId, key), value)
    } else {
      await LocalStorageWrapper.set(this.makeKeyForAppId(appId, key), value)
    }
  }

  private makeKey(key: string) {
    return `eco:${this.appId}:${this.environment}:${key}`
  }

  private makeKeyForAppId(appId: string, key: string) {
    return `eco:${appId}:${key}`
  }

  private read = async (key: string): Promise<string|null> => {
    if (EnvironmentService.isRunningOnIos) {
      return await IosKeyChain.get(key)
    } else {
      return await IndexedDB.get(key)
    }
  }

  private write = async (key: string, data: string) => {
    if (EnvironmentService.isRunningOnIos) {
      await IosKeyChain.set(key, data)
    } else {
      await IndexedDB.set(key, data)
    }
  }

  private remove = async (key: string) => {
    if (EnvironmentService.isRunningOnIos) {
      await IosKeyChain.remove(key)
    } else {
      await IndexedDB.remove(key)
    }
  }
}

export default new AppDataStorageService()
