<template>
	<v-layout :class="{
    'labeledControl': true,
    'column': direction!== 'row',
    'row': direction === 'row',
    'align-center': alignCenter,
    'align-left': alignLeft,
    'align-start': alignStart,
  }">
    <v-flex :class="{'labelContainer': true}">
      <label-component
        :text="label"
        :description-title="label"
        :description-content="tooltip">
      </label-component>
      <div>
        <slot name="hint"></slot>
      </div>
    </v-flex>
    <v-flex shrink :class="{'left-padding': (direction === 'row')}">
      <slot></slot>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

import LabelComponent from '../../../patterns/LabelComponent.vue'

import { BaseControlAttributes, ControlContextV2 } from '../models'

@Component({
  components: {
    LabelComponent,
  },
})
export default class EcoLabeledControl extends Vue {
  @Prop() public context: ControlContextV2
  @Prop() public attributes: BaseControlAttributes
  @Prop({ default: 'row' }) public direction: string
  @Prop({ default: '' }) public align: string

  public get label() {
    return this.context.resolveStringResource(this.attributes.label)
  }

  public get tooltip() {
    return this.context.resolveStringResource(this.attributes.tooltip)
  }

  public get alignCenter() {
    return this.align ? this.align === 'center' : this.direction === 'row'
  }

  public get alignLeft() {
    return this.align ? this.align === 'left' : this.direction !== 'row'
  }

  public get alignStart() {
    return this.align === 'start'
  }
}
</script>

<style lang="scss" scoped>
.labeledControl {
  color: $fore-color-primary;
  font-family: $font-family-primary;
  text-align: left;
  .left-padding {
    padding-left: 15px;
  }
  .labelContainer {
    display: flex;
    align-items: center;
  }
}
</style>

<style lang="css">
</style>
