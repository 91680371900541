if (!document.getElementById('app')) {
  throw new Error('unsupported browser, app div was removed')
}

import Vue from 'vue'
import App from './components/App.vue'
import router from './router'
import store from './store'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import SharedComponents from '@ecocoach/shared-components/src'

import VueClipboard from 'vue-clipboard2'

import { AppIdWebApp } from '@ecocoach/domain-store-modules/src/alarm/types'
import { AuthenticationAction } from '@ecocoach/domain-store-modules/src/authentication/types'
import { ApiErrorConverter } from '@ecocoach/domain-store-modules/src/helpers/apiErrorConverter'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import ApiService from '@ecocoach/domain-store-modules/src/services/api.service'
import AppDataStorageService from '@ecocoach/domain-store-modules/src/services/appdatastorage.service'
import CacheService from '@ecocoach/domain-store-modules/src/services/cache.service'
import HubService from '@ecocoach/domain-store-modules/src/services/hub.service'
import { authenticationOptions, backendEnvironment } from './environment'
import { AppAction, AppGetter } from './store/modules/app/types'

Vue.use(Vuetify)
Vue.filter('translate', value => store.getters['resource/dictionary'](value))
Vue.filter('resolveIcon', (value) => store.getters[`resource/${ResourceGetter.svgIcon}`](value))
Vue.use(SharedComponents)
Vue.use(VueClipboard)

AppDataStorageService.init({ appId: AppIdWebApp, environment: backendEnvironment()})

const apiErrorConverter = new ApiErrorConverter({
  resolveStringResource: store.getters[`resource/${ResourceGetter.dictionary}`],
})

initialize(window.location.href)

async function initialize(url?: string) {
  ApiService.init({ appId: AppIdWebApp, environment: backendEnvironment() })
  ApiService.registerErrorHandler(errorContext => store.dispatch(`app/${AppAction.handleApiError}`, apiErrorConverter.convert(errorContext)))
  HubService.registerReconnectingHandler(({ hubId }) => store.dispatch(`app/${AppAction.handleHubReconnecting}`, hubId))
  HubService.registerReconnectedHandler(({ hubId }) => store.dispatch(`app/${AppAction.handleHubReconnected}`, hubId))

  await store.dispatch(`authentication/${AuthenticationAction.initialize}`, authenticationOptions(backendEnvironment()))
  await store.dispatch(`authentication/${AuthenticationAction.handleAppLoad}`, url)

  CacheService.init({ userIdentifier: store.getters[`app/${AppGetter.userIdentifier}`], environment: backendEnvironment(), version: 1})

  new Vue({
    store: (store as any),
    router,
    render: (h) => h(App),
  }).$mount('#app')
}
