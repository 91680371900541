import { RootState } from '@/store/types'
import { AccountModel, ConsumptionReportInput, LoadInput, MeterAssignmentModel, MinergieVisualizationDataInput, MinergieVisualizationInterval, OneClickTargetSystem, UserAssignmentModel } from '@ecocoach/domain-store-modules/src/consumption/models'
import { ConsumptionAction } from '@ecocoach/domain-store-modules/src/consumption/types'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import AppDataStorageService from '@ecocoach/domain-store-modules/src/services/appdatastorage.service'
import Highcharts from 'highcharts'
import { ActionContext, ActionTree } from 'vuex'
import { AppAction, AppGetter } from '../app/types'
import { ChartOptions, makeChartConfig } from './chartHelper'
import { OneClickAdminProjectIdStorageKey, OneClickAdminTargetSystemStorageKey, OneClickExportProjectIdStorageKey, OneClickVisualizationProjectIdStorageKey } from './models'
import { ConsumptionUiAction, ConsumptionUiGetter, ConsumptionUiMutation, ConsumptionUiState } from './types'

export const actions: ActionTree<ConsumptionUiState, RootState> = {
  async [ConsumptionUiAction.initializeAdmin] ({ commit, dispatch, getters }) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      const lastSelectedProjectId = await AppDataStorageService.get(OneClickAdminProjectIdStorageKey)
      const projectToSelect = getters[ConsumptionUiGetter.adminProjects].find(p => p.id === lastSelectedProjectId)
        || getters[ConsumptionUiGetter.adminProjects][0]
      const projectId = projectToSelect?.id ?? ''
      const storedTargetSystem = await AppDataStorageService.get(OneClickAdminTargetSystemStorageKey) as OneClickTargetSystem
      const targetSystem = storedTargetSystem || OneClickTargetSystem.Ecocoach
      if (projectId) {
        await dispatch(`consumption/${ConsumptionAction.loadProject}`, {
          projectId,
          targetSystem,
        } as LoadInput, { root: true })
      }
      commit(ConsumptionUiMutation.setSelectedAccountId, '')
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.selectAdminProject] ({ commit, dispatch, rootState }, projectId: string) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.loadProject}`, {
        projectId,
        targetSystem: rootState.consumption.targetSystem,
      } as LoadInput, { root: true })
      commit(ConsumptionUiMutation.setSelectedAccountId, '')
      await AppDataStorageService.set(OneClickAdminProjectIdStorageKey, projectId)
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.selectAdminTargetSystem] ({ commit, dispatch, rootState }, targetSystem: OneClickTargetSystem) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.loadProject}`, {
        projectId: rootState.consumption.projectId,
        targetSystem,
      } as LoadInput, { root: true })
      commit(ConsumptionUiMutation.setSelectedAccountId, '')
      await AppDataStorageService.set(OneClickAdminTargetSystemStorageKey, targetSystem)
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.createAccount] ({ commit, dispatch }, account: AccountModel) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.createAccount}`, account, { root: true })
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.updateAccount] ({ commit, dispatch }, account: AccountModel) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.updateAccount}`, account, { root: true })
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.deleteAccount] ({ commit, dispatch, state }) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.deleteAccount}`, state.selectedAccountId, { root: true })
      commit(ConsumptionUiMutation.setSelectedAccountId, '')
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.assignMeter] ({ commit, dispatch, state }, meterId: string) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.assignMeter}`, {
        meterId,
        consumptionAccountId: state.selectedAccountId,
      } as MeterAssignmentModel, { root: true })
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.unassignMeter] ({ commit, dispatch, state }, meterId: string) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.unassignMeter}`, {
        meterId,
        consumptionAccountId: state.selectedAccountId,
      } as MeterAssignmentModel, { root: true })
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.assignUser] ({ commit, dispatch, state }, dynamicMeterUserIdentifier: string) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.assignUser}`, {
        dynamicMeterUserIdentifier,
        consumptionAccountId: state.selectedAccountId,
      } as UserAssignmentModel, { root: true })
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.unassignUser] ({ commit, dispatch, state }, dynamicMeterUserIdentifier: string) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.unassignUser}`, {
        dynamicMeterUserIdentifier,
        consumptionAccountId: state.selectedAccountId,
      } as UserAssignmentModel, { root: true })
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.linkAccountMinergie] ({ commit, dispatch }, minergieObjectId: string) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.linkAccountMinergie}`, minergieObjectId, { root: true })
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.unlinkAccountMinergie] ({ commit, dispatch }) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.unlinkAccountMinergie}`, null, { root: true })
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.verifyAccountMinergie] ({ commit, dispatch }) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.verifyAccountMinergie}`, null, { root: true })
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.linkProjectEgonline] ({ commit, dispatch }, egonlineProjectId: string) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.linkProjectEgonline}`, egonlineProjectId, { root: true })
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.unlinkProjectEgonline] ({ commit, dispatch }) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.unlinkProjectEgonline}`, null, { root: true })
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.initializeExport] ({ commit, dispatch, getters }) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      const lastSelectedProjectId = await AppDataStorageService.get(OneClickExportProjectIdStorageKey)
      const projectToSelect = getters[ConsumptionUiGetter.exportProjects].find(p => p.id === lastSelectedProjectId)
        || getters[ConsumptionUiGetter.exportProjects][0]
      const projectId = projectToSelect?.id ?? ''
      if (projectId) {
        await dispatch(ConsumptionUiAction.selectExportProject, projectId)
      }
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.selectExportProject] ({ commit, dispatch }, projectId: string) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.loadProject}`, {
        projectId,
        targetSystem: OneClickTargetSystem.Ecocoach,
      } as LoadInput, { root: true })
      await AppDataStorageService.set(OneClickExportProjectIdStorageKey, projectId)
      await dispatch(ConsumptionUiAction.resetExportForm)
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.resetExportForm] ({commit}: ActionContext<ConsumptionUiState, RootState>) {
    commit(ConsumptionUiMutation.setSelectedReportConsumptionAccountId, '') // show '-'
    commit(ConsumptionUiMutation.setSelectedReportFromDate, '')
    commit(ConsumptionUiMutation.setSelectedReportToDate,  '')
  }, 
  async [ConsumptionUiAction.downloadReport] ({ commit, dispatch, state, rootState }) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.loadReport}`, {
        projectId: rootState.consumption.projectId,
        aggregationInterval: state.selectedReportAggregationInterval,
        consumptionAccountId: state.selectedReportConsumptionAccountId,
        dateFrom: state.selectedReportFromDate,
        dateTo: state.selectedReportToDate,
        resourceLanguage: rootState.app.selectedLanguage,
      } as ConsumptionReportInput, { root: true })
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.initializeVisualization] ({ commit, dispatch, getters }) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      const lastSelectedProjectId = await AppDataStorageService.get(OneClickVisualizationProjectIdStorageKey)
      const projectToSelect = getters[ConsumptionUiGetter.visualizationProjects].find(p => p.id === lastSelectedProjectId)
        || getters[ConsumptionUiGetter.visualizationProjects][0]
      const projectId = projectToSelect?.id ?? ''
      if (projectId) {
        await dispatch(ConsumptionUiAction.selectVisualizationProject, projectId)
      }
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.selectVisualizationProject] ({ commit, dispatch, getters }, projectId: string) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      await dispatch(`consumption/${ConsumptionAction.loadProject}`, {
        projectId,
        targetSystem: OneClickTargetSystem.Minergie,
      } as LoadInput, { root: true })
      await AppDataStorageService.set(OneClickVisualizationProjectIdStorageKey, projectId)
      const accountId = getters[ConsumptionUiGetter.visualizationAccounts].filter(_ => _.id)[0]?.id ?? ''
      if (accountId) {
        dispatch(ConsumptionUiAction.selectVisualizationConsumptionAccountId, accountId)
      }
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.selectVisualizationInterval] ({ commit, dispatch }, interval: MinergieVisualizationInterval) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      commit(ConsumptionUiMutation.setSelectedVisualizationInterval, interval)
      await dispatch(ConsumptionUiAction.loadVisualizationData)
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.selectVisualizationConsumptionAccountId] ({ commit, dispatch }, accountId: string) {
    try {
      commit(ConsumptionUiMutation.setInteracted, true)
      commit(ConsumptionUiMutation.setSelectedVisualizationConsumptionAccountId, accountId)
      await dispatch(ConsumptionUiAction.loadVisualizationData)
    } finally {
      commit(ConsumptionUiMutation.setInteracted, false)
    }
  },
  async [ConsumptionUiAction.loadVisualizationData] ({ dispatch, state, rootState }) {
    await dispatch(`consumption/${ConsumptionAction.loadMinergieChartData}`, {
      projectId: rootState.consumption.projectId,
      accountId: state.selectedVisualizationConsumptionAccountId,
      interval: state.selectedVisualizationInterval,
      language: rootState.app.selectedLanguage,
    } as MinergieVisualizationDataInput, { root: true })
    await dispatch(ConsumptionUiAction.configureChart)
  },
  async [ConsumptionUiAction.configureChart]({ getters, rootState, rootGetters }): Promise<void> {
    const options: ChartOptions = {
      projectName: getters[ConsumptionUiGetter.visualizationProjects]
        .find(p => p.id === rootState.consumption.projectId)?.label,
      accountName: getters[ConsumptionUiGetter.visualizationAccounts]
        .find(a => a.id === getters[ConsumptionUiGetter.selectedVisualizationConsumptionAccountId])?.label,
      averageSeriesName: rootGetters[`resource/${ResourceGetter.dictionary}`]('oneclick.chart.average'),
      yearsSeriesName: rootGetters[`resource/${ResourceGetter.dictionary}`]('oneclick.chart.years'),
    }
    const chartConfig = makeChartConfig(rootState.consumption.minergieChartData[0], options)
    const chart = Highcharts.chart('chart-container', chartConfig)
    chart.redraw()
  },
  async [ConsumptionUiAction.topNavigationClicked] ({commit, rootGetters, getters, dispatch, state }, router) {
    const seeExport =  getters.hasOneClickExportProjects
    const seeEdit = getters.hasOneClickEditProjects
    if(seeExport && seeEdit) {
      if(rootGetters[`app/${AppGetter.isNavigationDrawerMinimized}`]) {
        dispatch(`app/${AppAction.setNavigationDrawerMinimized}`, false, {root:true})
        commit(ConsumptionUiMutation.setNavigationMinimized, false)
      } else {
        commit(ConsumptionUiMutation.setNavigationMinimized, !state.navigationMinimized)
      }
    } else {
      if(seeExport) {
        router.push({name: 'ecoOneClick-export'})
      } else if (seeEdit) {
        router.push({name:'ecoOneClick-administration'})
      }
    }
  },
  async [ConsumptionUiAction.setNavigationMinimized] ({commit}: ActionContext<ConsumptionUiState, RootState>, minimized: boolean) {
    commit(ConsumptionUiMutation.setNavigationMinimized, minimized)
  },
}