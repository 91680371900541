declare let Keychain: any

class IosKeyChainWrapper {
  public get = async (key: string): Promise<string|null> => {
    if (!Keychain) {
      // tslint:disable-next-line:no-console
      console.error('IosKeyChainWrapper:get error, not initialized')
      return null
    }

    return new Promise<string|null>((resolve, reject) => {
      Keychain.get(
        value => {
          return resolve(value)
        },
        error => { 
          // tslint:disable-next-line:no-console
          console.error('IosKeyChainWrapper:get error', key, error)
          reject(error)
        },
        key)
    })
  }

  public set = async (key: string, data: string) => {
    if (!Keychain) {
      // tslint:disable-next-line:no-console
      console.error('IosKeyChainWrapper:set error, not initialized')
      return
    }

    return new Promise<void>((resolve, reject) => {
      Keychain.set(
        () => {
          return resolve()
        },
        error => { 
          // tslint:disable-next-line:no-console
          console.error('IosKeyChainWrapper:set error', key, data, error)
          reject(error)
        },
        key,
        data)
    })
  }

  public remove = async (key: string) => {
    if (!Keychain) {
      // tslint:disable-next-line:no-console
      console.error('IosKeyChainWrapper:remove error, not initialized')
      return
    }

    return new Promise<void>((resolve, reject) => {
      Keychain.remove(
        () => {
          return resolve()
        },
        error => { 
          // tslint:disable-next-line:no-console
          console.error('IosKeyChainWrapper:remove error', key, error)
          reject(error)
        },
        key)
    })
  }
}

export default new IosKeyChainWrapper()
