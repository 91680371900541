<template>
  <div class="masterClampLine">
    <div v-if="isValidPlcType">
      <img class="plc" 
        :src="image(plc.plcType)"
        :alt="plc.plcType"
        :data-eco-image-resource-id="plc.plcType"
      />
    </div>
    <div v-else>
      {{ dictionary('error.plc.type.undefined') }}
    </div>
    <draggable v-model="clamps"
      class="masterClamps"
      group="clamps"
      animation="200"
      @end="sortSolutionHardwareInstances"
    >
      <clamp 
        v-for="clamp in clamps"
        :key="clamp.id"
        :clamp="clamp"
      />
    </draggable>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Draggable from 'vuedraggable'

import Clamp from './Clamp.vue'

import { SolutionHardwareClampLines, SolutionHardwareInstanceViewModel } from '../../../../../store/modules/solutionHardwareUi/models'

import { PlcType } from '@ecocoach/domain-store-modules/src/common'
import { PlcModel } from '@ecocoach/domain-store-modules/src/plc/models'

const SolutionHardwareUi = namespace('solutionHardwareUi')
const Resource = namespace('resource')

@Component({
  components: {
    Clamp,
    Draggable,
  },
})
export default class MasterClampLine extends Vue {
  @Prop() public clampLines: SolutionHardwareClampLines
  @Prop() public plc: PlcModel
  @SolutionHardwareUi.Action public sortSolutionHardwareInstances: () => Promise<void>
  @Resource.Getter public image
  @Resource.Getter public dictionary

  public get isValidPlcType() {
    return this.plc && this.plc.plcType !== PlcType.UNDEFINED
  }

  public get clamps(): SolutionHardwareInstanceViewModel[] {
    return this.clampLines.masterClampLine
  }

  public set clamps(value: SolutionHardwareInstanceViewModel[]) {
    this.clampLines.masterClampLine = value
  }
}
</script>

<style lang="scss" scoped>
.masterClampLine {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 381px;
}
.masterClamps {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
  min-width: 50px;
  min-height: 315px;
}
img.plc {
  height: 315px;
  color: white;
}
</style>