import { FunctionDefinitionEditorUiMutation, FunctionDefinitionEditorUiState } from '@/store/modules/functionDefinitionEditorUi/types'
import { ArchitectureType, SourceLanguage } from '@ecocoach/domain-store-modules/src/common'
import { deepCopy, remove, upsert } from '@ecocoach/domain-store-modules/src/utils'
import { MutationTree } from 'vuex'
import { DefaultAlarmDefinition, EnergyStatusItem, MeasuringPoint } from '../../../../../eco-domain-store-modules/src/systemConfiguration/models'
import { ControlDefinitionDefaultValueInput, ControlDefinitionViewModel, ControlDefinitionViewModelV2, FunctionDefinitionViewModel } from './models'

export const mutations: MutationTree<FunctionDefinitionEditorUiState> = {
  [FunctionDefinitionEditorUiMutation.setActionInProgress](state, value: '' | 'initializing' | 'newversion' | 'dryrun' | 'uploading') {
    state.actionInProgress = value
  },
  [FunctionDefinitionEditorUiMutation.setArchitectureType](state, value: ArchitectureType) {
    state.architectureType = value
  },
  [FunctionDefinitionEditorUiMutation.setSourceLanguage](state, value: SourceLanguage) {
    state.sourceLanguage = value
  },
  [FunctionDefinitionEditorUiMutation.setPreserveProjectFilesInUranus](state, value: boolean) {
    state.preserveProjectFilesInUranus = value
  },
  [FunctionDefinitionEditorUiMutation.setControlDefinitions](state: FunctionDefinitionEditorUiState,
    value: Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2>) {
    state.controlDefinitions = value
  },
  [FunctionDefinitionEditorUiMutation.setControlDefinitionsOriginal](state: FunctionDefinitionEditorUiState,
    value: Array<ControlDefinitionViewModel | ControlDefinitionViewModelV2>) {
    state.controlDefinitionsOriginal = value
  },
  [FunctionDefinitionEditorUiMutation.setControlDefinitionInput](state: FunctionDefinitionEditorUiState, payload: ControlDefinitionViewModel) {
    state.controlDefinitionInput = payload
  },
  [FunctionDefinitionEditorUiMutation.setControlDefinitionInputProperty](
    state: FunctionDefinitionEditorUiState, payload: { property: string, value: any }) {
    state.controlDefinitionInput[payload.property] = deepCopy(payload.value)
  },
  [FunctionDefinitionEditorUiMutation.setControlDefinitionInputV2](state: FunctionDefinitionEditorUiState, payload: ControlDefinitionViewModelV2) {
    state.controlDefinitionInputV2 = payload
  },
  [FunctionDefinitionEditorUiMutation.setControlDefinitionObsolete](state, payload: boolean) {
    state.controlDefinitionInputV2.obsolete = payload
  },
  [FunctionDefinitionEditorUiMutation.setControlDefinitionAttribute](
    state: FunctionDefinitionEditorUiState, payload: { key: string, value: any }) {
    state.controlDefinitionInputV2.attributes = {
      ...state.controlDefinitionInputV2.attributes,
      [payload.key]: deepCopy(payload.value),
    }
  },
  [FunctionDefinitionEditorUiMutation.removeControlDefinitionAttribute](state, payload: string) {
    const attributes = { ...state.controlDefinitionInputV2.attributes }
    delete attributes[payload]
    state.controlDefinitionInputV2.attributes = attributes
  },
  [FunctionDefinitionEditorUiMutation.setControlDefinitionStateProperty](
    state: FunctionDefinitionEditorUiState, payload: { state: string, property: string, value: any }) {
    state.controlDefinitionInputV2.states[payload.state] = {
      ...state.controlDefinitionInputV2.states[payload.state],
      [payload.property]: payload.value,
    }
  },
  [FunctionDefinitionEditorUiMutation.setControlDefinitionCommandProperty](
    state: FunctionDefinitionEditorUiState, payload: { command: string, property: string, value: any }) {
    state.controlDefinitionInputV2.commands[payload.command] = {
      ...state.controlDefinitionInputV2.commands[payload.command],
      [payload.property]: payload.value,
    }
  },
  [FunctionDefinitionEditorUiMutation.addControlDefinitionState](state, 
    payload: { state: string, endpoint: string, toSubscribe: boolean, pollInterval: number }) {
    state.controlDefinitionInputV2.states = {
      ...state.controlDefinitionInputV2.states,
      [payload.state]: {
        endpoint: payload.endpoint,
        toSubscribe: payload.toSubscribe,
        pollInterval: payload.pollInterval,
      },
    }
  },
  [FunctionDefinitionEditorUiMutation.removeControlDefinitionState](state, payload: string) {
    const states = { ...state.controlDefinitionInputV2.states }
    delete states[payload]
    state.controlDefinitionInputV2.states = states
  },
  [FunctionDefinitionEditorUiMutation.setFunctionDefinition](state: FunctionDefinitionEditorUiState, value: FunctionDefinitionViewModel) {
    state.functionDefinition = value
  },
  [FunctionDefinitionEditorUiMutation.setFunctionDefinitionProperty](
    state: FunctionDefinitionEditorUiState, payload: { property: string, value: any }) {
    state.functionDefinition[payload.property] = payload.value
  },
  [FunctionDefinitionEditorUiMutation.setFunctionDefinitionInstanceResourceProperty](state: FunctionDefinitionEditorUiState,
    payload: { id: string, value: string }) {
    state.functionDefinition.instanceResources.find(e => e.endpointPath === payload.id)!.resourceId = payload.value
  },
  [FunctionDefinitionEditorUiMutation.upsertFunctionDefinitionMeasuringPoint](state: FunctionDefinitionEditorUiState, payload: MeasuringPoint) {
    upsert(state.functionDefinition.measuringPoints, payload)
  },
  [FunctionDefinitionEditorUiMutation.removeFunctionDefinitionMeasuringPoint](state: FunctionDefinitionEditorUiState, payload: string) {
    remove(state.functionDefinition.measuringPoints, payload)
  },
  [FunctionDefinitionEditorUiMutation.upsertFunctionDefinitionEnergyStatusItem](state: FunctionDefinitionEditorUiState, payload: EnergyStatusItem) {
    upsert(state.functionDefinition.energyStatusItems, payload)
  },
  [FunctionDefinitionEditorUiMutation.removeFunctionDefinitionEnergyStatusItem](state: FunctionDefinitionEditorUiState, payload: string) {
    remove(state.functionDefinition.energyStatusItems, payload)
  },  
  [FunctionDefinitionEditorUiMutation.upsertFunctionDefinitionDefaultAlarmDefinition](state, payload: DefaultAlarmDefinition) {
    upsert(state.functionDefinition.defaultAlarmDefinitions, payload)
  },
  [FunctionDefinitionEditorUiMutation.removeFunctionDefinitionDefaultAlarmDefinition](state, payload: string) {
    remove(state.functionDefinition.defaultAlarmDefinitions, payload)
  },
  [FunctionDefinitionEditorUiMutation.setFunctionDefinitionEndpointResourceProperty](state: FunctionDefinitionEditorUiState,
    payload: { id: string, property: string, value: string }) {
    state.functionDefinition.endpointResources.find(e => e.endpointPath === payload.id)![payload.property] = payload.value
  },
  [FunctionDefinitionEditorUiMutation.setLinkedControlDefinitionIds](state: FunctionDefinitionEditorUiState, value: string[]) {
    state.functionDefinition.controlDefinitionMappings = value.map(id => {
      const existingMapping = state.functionDefinition.controlDefinitionMappings.find(mapping => mapping.controlDefinitionId === id)
      return existingMapping || {
        controlDefinitionId: id,
        defaultValues: {},
      }
    })
  },
  [FunctionDefinitionEditorUiMutation.linkControlDefinition](state: FunctionDefinitionEditorUiState, value: string) {
    if (!state.functionDefinition.controlDefinitionMappings.some(mapping => mapping.controlDefinitionId === value)) {
      state.functionDefinition.controlDefinitionMappings.push({
        controlDefinitionId: value,
        defaultValues: {},
      })
    }
  },
  [FunctionDefinitionEditorUiMutation.unlinkControlDefinition](state: FunctionDefinitionEditorUiState, value: string) {
    state.functionDefinition.controlDefinitionMappings = state.functionDefinition.controlDefinitionMappings
      .filter(mapping => mapping.controlDefinitionId !== value)
  },
  [FunctionDefinitionEditorUiMutation.setControlDefinitionDefaultValue](state: FunctionDefinitionEditorUiState,
    value: ControlDefinitionDefaultValueInput) {
    const controlDefinitionMapping = state.functionDefinition.controlDefinitionMappings
      .find(mapping => mapping.controlDefinitionId === value.controlDefinitionId)!
    controlDefinitionMapping.defaultValues = {
      ...controlDefinitionMapping.defaultValues,
      [value.command]: value.defaultValue,
    }
  },
}
