<template>
  <div class="table-filter-textsearch">
    <string-filter-component
      :value="value"
      :placeholder="placeholder"
      @changed="onChanged"
    />
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class TableFilterTextSearch extends Vue {
  @Prop() public value: string
  @Prop() public placeholder: string

  @Emit('changed')
  public onChanged(input: string) {
    return input
  }
}
</script>
<style lang="scss" scoped>
</style>
<style lang="css">
.table-filter-textsearch .string-filter-component{
  margin: 0px;
  padding: 0px;
  height: 30px;
  min-height: 30px;
}
.table-filter-textsearch .string-filter-component .reset-string-button .disabled {
  display: none;
}
.table-filter-textsearch .string-filter-component input{
  border: none;
  padding-left: 0px;
}
</style>