import { GetterTree } from 'vuex'
import { UserDetails } from './models'
import { AuthenticationGetter, AuthenticationState } from './types'

export const getters: GetterTree<AuthenticationState, {}> = {
  [AuthenticationGetter.loggedIn]: (state): boolean => {
    return !!state.loggedInUser.userIdentifier
  },
  [AuthenticationGetter.loggedInUser]: (state): UserDetails => {
    return state.loggedInUser 
  },
}
