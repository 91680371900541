<template>
  <div class="command-detail">
    <div class="command-name">
      <div>{{ `Command: ${name}` }}</div>
      <base-material-icon v-if="cloudPersistent" iconName="backup" :height="20" />
      <base-material-icon v-if="templateAttached" iconName="content_paste_go" :height="20" />
      <base-material-icon v-if="auditable" iconName="security" :height="20" />
    </div>    
    <form-field text="Endpoint">
      <autocomplete-select
        :options="controlDefinitionCommandEndpointOptions(controlDefinitionInput.type, type)"
        :selected-value="endpoint"
        :disabled="endpointsDisabled"
        @selected="setCommandProperty(name, 'endpoint', $event)"
      />
    </form-field>
    <form-field text="Cloud persistent" v-if="!cloudPersistentHidden">
      <autocomplete-select
        :options="['Yes', 'No']"
        :selected-value="cloudPersistent ? 'Yes' : 'No'"
        @selected="setCommandProperty(name, 'cloudPersistent', $event === 'Yes')"
      />
    </form-field>
    <form-field text="Template attached" v-if="cloudPersistent && !templateAttachedHidden">
      <autocomplete-select
        :options="['Yes', 'No']"
        :selected-value="templateAttached ? 'Yes' : 'No'"
        @selected="setCommandProperty(name, 'templateAttached', $event === 'Yes')"
      />
    </form-field>
    <form-field text="Auditable">
      <autocomplete-select
        :options="['Yes', 'No']"
        :selected-value="auditable ? 'Yes' : 'No'"
        @selected="setCommandProperty(name, 'auditable', $event === 'Yes')"
      />
    </form-field>
    <form-field text="Audit log entry" v-if="auditable">
      <string-resource-select
        :options="filteredSystemConfigurationResources('ControlDefinitions_', '_AuditLog', true)"
        :selected-value="auditResourceId"
        category="SystemConfiguration"
        prefix="ControlDefinitions_"
        postfix="_AuditLog"
        default-value=""
        @selected="setCommandProperty(name, 'auditResourceId', $event)"
        @created="onAuditableResourceCreated"
      />
    </form-field>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import StringResourceSelect from './StringResourceSelect.vue'

import { CreateResourceInput } from '../../../../../eco-domain-store-modules/src/resource/models'
import { ControlTypeV2, DropdownOption } from '../../../../../shared-components/src'
import { ControlCommandType, ControlDefinitionViewModelV2 } from '../../../store/modules/functionDefinitionEditorUi/models'

const FunctionDefinitionEditorUi = namespace('functionDefinitionEditorUi')

@Component({
  components: {
    StringResourceSelect,
  },
})
export default class CommandDetail extends Vue {
  @Prop() public name: string
  @Prop() public type: ControlCommandType
  @Prop() public endpoint: string
  @Prop() public cloudPersistent: boolean
  @Prop() public templateAttached: boolean
  @Prop() public auditable: boolean
  @Prop() public auditResourceId: string
  @FunctionDefinitionEditorUi.Getter public controlDefinitionInput: ControlDefinitionViewModelV2
  @FunctionDefinitionEditorUi.Getter public controlDefinitionCommandEndpointOptions: (control: ControlTypeV2, command: ControlCommandType) => string[]
  @FunctionDefinitionEditorUi.Getter public filteredSystemConfigurationResources:
    (prefix: string, postfix: string, allowEmpty: boolean) => DropdownOption[]
  @FunctionDefinitionEditorUi.Mutation public setControlDefinitionCommandProperty: (p: { command: string, property: string, value: any }) => void
  @FunctionDefinitionEditorUi.Action public createResource: (payload: CreateResourceInput) => Promise<void>

  public get endpointsDisabled() {
    return this.controlDefinitionInput.isExisting || this.controlDefinitionInput.type === ControlTypeV2.Links
  }

  public get cloudPersistentHidden() {
    return this.type === ControlCommandType.InvokeMethodEndpoint ||
           this.type === ControlCommandType.SetLinkSources ||
           this.name === 'beginSetValueCommand' ||
           this.name === 'endSetValueCommand'
  }

  public get templateAttachedHidden() {
    return this.type === ControlCommandType.InvokeMethodEndpoint ||
           this.name === 'beginSetValueCommand' ||
           this.name === 'endSetValueCommand'
  }

  public setCommandProperty(command: string, property: string, value: any) {
    this.setControlDefinitionCommandProperty({ command, property, value })
    if (property === 'cloudPersistent') {
      this.setControlDefinitionCommandProperty({ command, property: 'templateAttached', value })
    }
  }

  public onAuditableResourceCreated(input: CreateResourceInput) {
    this.createResource(input)
    this.setCommandProperty(this.name, 'auditResourceId', input.id)
  }
}
</script>

<style lang="scss" scoped>
  .command-detail {
    margin-bottom: 10px;
    border-left: 1px solid gray;
    .command-name {
      display: flex;
      gap: 5px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
</style>

<style lang="css">
</style>