<template>
      <div class="baseProgressBar">
        <div class="progressBar">
            <div
                :style="backgroundGradient"
                class="progressFill"/>
            <div
                :style="{background: gradientColors[1]}"
                class="progressBarBackground"/>
        </div>
        <div class="progress-cont-val">
            <span class="progressValueOutput"> {{ displayValue }} </span>
            <span class="inactive"> {{ '%' }} </span>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
    name: 'BaseProgressBar',
    props: {
        /**
         * the colors for the background gradient
         * as string array
         */
        gradientColors: {
            type: Array,
            default: () => ['red', 'darkred'],
        },
        /**
         * the current value
         */
        value: {
            type: Number,
            default: 0,
        },
        /**
         * gradient background style
         */
        isDotted: {
            type: Boolean,
            default: false,
        },
        /**
         * decimals for the value label
         */
        decimals: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        displayValue() {
            return this.value?.toFixed(this.decimals) ?? ''
        },
        backgroundGradient () {
            const filled = `-webkit-linear-gradient(right, ${ this.gradientColors[1] } 0%, ${ this.gradientColors[0] }  100%)`
            const dotted = `-webkit-repeating-linear-gradient(right, ${ this.gradientColors[0] }, 
                    ${ this.gradientColors[0] } 4px, transparent 4px, transparent 8px`
            return {
                width: `${this.value}%`,
                background: this.isDotted ? dotted : filled,
            }
        },
    },
})
</script>
<style lang="scss" scoped>
.baseProgressBar {
		display: flex;
		flex-direction: row;
        width: 100%;
	}
	.progressBar {
        flex-grow: 1;
        padding-right: 10px;
		height: 4px;
		border-radius: 2px;
        align-self: center;
		.progressFill {
			height: 4px;
			border-radius: 2px;
		}
		.progressBarBackground {
			opacity: 0.25;
			height: 4px;
			margin-top: -4px;
			border-radius: 2px;
		}
	}
	.progress-cont-val {
		max-width: 10%;
		border-radius: 5px;
		border: none;
		background-color: rgba(123, 139, 154, 0.1);
		text-align: end;
		padding: 5px;
	}
	.progressValueOutput {
		color: #fff;
		text-align: center;
	}
	.inactive {
		color: #7b8b9a;
	}
</style>

<docs>
A simple progress bar

The default progress bar with a value
```jsx
<base-progress-bar 
    :value="40" />
```

Custom gradient colors
```jsx
<base-progress-bar 
    :gradientColors="['blue','green']"
    :value="90" />
```

Custom gradient colors and dotted style
```jsx
<base-progress-bar 
    :gradientColors="['blue','green']"
    :is-dotted="true"
    :value="40" />
```
</docs>
