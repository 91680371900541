export interface ProjectManagementUiState {
  interacted: boolean
}

export enum ProjectManagementUiAction {
  createProject = 'createProject',
  updateProject = 'updateProject',
  deleteProject = 'deleteProject',
  createPlc = 'createPlc',
  updatePlc = 'updatePlc',
  deletePlc = 'deletePlc',
  setPlcType = 'setPlcType',
}

export enum ProjectManagementUiMutation {
  setInteracted = 'setInteracted',
}

export enum ProjectManagementUiGetter {
  isInteracted = 'isInteracted',
  projectsWithPlcs = 'projectsWithPlcs',
  plcTypeOptions = 'plcTypeOptions',
}