<template>
  <eco-labeled-control
    :context="context"
    :attributes="attributes"
  >
    <div class="buttons">
      <icon-button v-for="action in actions" :key="action.command" 
        type="materialIcon"
        :materialIconName="action.icon"
        :enabled="!readonly"
        @buttonClicked="onExecute(action.command)"
        :height="25"
      />
    </div>
  </eco-labeled-control>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import IconButton from '../../patterns/IconButton.vue'
import { ActionsControlAttributes, ControlContextV2 } from './models'
import EcoLabeledControl from './shared/EcoLabeledControl.vue'

@Component({
  components: {
    IconButton,
    EcoLabeledControl,
  },
})
export default class EcoActionsControl extends Vue {
  @Prop() public id: string
  @Prop() public context: ControlContextV2
  @Prop() public attributes: ActionsControlAttributes
  @Prop() public readonly: boolean

  public onExecute(command: string) {
    this.context.executeCommand(this.id, {
      command,
      params: null,
    })
  }

  public get actions() {
    return this.attributes.actions
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-right: -8px;
  display: flex;
  .v-btn {
    border-width: 1px;
    border-color: white;
    border-style: solid;
  }
}
</style>

<style lang="css">
</style>
