<template>
    <div class="dashboard">
      <wizard-step-header 
        :title="'navigation.account' | translate" 
        :sub-title="userIdentifier"
      />
        <v-container left class="account">
            <v-layout row wrap align-center class="move-to-left">
                <v-flex  xs12 md4 lg4>
                    <c-vard-text class="language-title">{{'account.language' | translate}}</c-vard-text>
                </v-flex>
                <v-flex  xs12 md4 lg4>
                        <base-single-select
                            class="select"
                            :options="languages"
                            :option-value="'id'"
                            :option-label="'label'"
                            :selectedValue="selectedLanguage"
                            @selected="onSelectLanguage"
                        />
                </v-flex>
            </v-layout>
            <v-layout row wrap align-center class="move-to-left">
              <v-flex xs12 md4 lg4>
                <c-vard-text class="language-title">{{'mfa.checkbox.email' | translate}}</c-vard-text>
              </v-flex>
              <v-flex xs12 md4 lg4>
                  <checkbox-with-label class="field"
                                       :value="isEmailMfaEnabled"
                                       :disabled="isInteracted"
                                       @checked="onMfaToggled" />
              </v-flex>
            </v-layout>
            <v-layout row wrap align-center class="move-to-left">
              <v-flex xs12 md4 lg4>
                <c-vard-text class="language-title">{{'settings.account.delete.info' | translate}}</c-vard-text>
              </v-flex>
              <v-flex xs12 md4 lg4>
                <v-flex xs12>
                  <base-text-button @buttonClicked="showDeleteAccountConfirmationDialog = true">{{'settings.account.delete' | translate}}</base-text-button>
                </v-flex>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
                <v-flex xs12><base-text-button
                        @buttonClicked="logout"
                    >{{'settings.account.logout' | translate}}</base-text-button></v-flex>
            </v-layout>
        </v-container>
        <confirm-dialog
          :show="showDeleteAccountConfirmationDialog"
          :header="'settings.account.delete' | translate"
          :text="'settings.account.delete.confirm' | translate"
          :confirm-button-text="'OK'"
          :cancel-button-text="'common.button.cancel' | translate"
          :disabled="isInteracted"
          @confirmed="onInitiateAccountDeletion"
          @cancelled="showDeleteAccountConfirmationDialog = false"
        />
    </div>
</template>
<script lang="ts">
import { Component, Vue } from  'vue-property-decorator'
import { namespace } from 'vuex-class'

const App = namespace('app')
const Authentication = namespace('authentication')

@Component
export default class Account extends Vue {
    @App.Action public selectLanguage: (language: string) => void
    @App.Getter public languages: Array<{id:string, label:string}>
    @App.Getter public selectedLanguage: string
    @App.Getter public userIdentifier: string
    @Authentication.Action public logout: () => Promise<void>
    @App.Getter public isEmailMfaEnabled: boolean
    @App.Getter public isInteracted: boolean
    @App.Action public setEmailMfaEnabled: (payload: boolean) => Promise<void>
    @App.Action public initiateAccountDeletion: () => Promise<void>

    public showDeleteAccountConfirmationDialog = false

    public onMfaToggled(value: boolean){
      this.setEmailMfaEnabled(!value)
    }

    public onSelectLanguage(value: string) {
      if (value !== this.selectedLanguage) {
        this.selectLanguage(value)
        window.location.reload()
      }
    }

    public async onInitiateAccountDeletion() {
      await this.initiateAccountDeletion()
      this.showDeleteAccountConfirmationDialog = false
      await this.logout()
    }
}
</script>
<style lang="scss" scoped>
header {
    padding: 30px 0 0 25px;
    height: 100px;
}
.dashboard {
    color: #fff;
    padding-top: 10px;
}
.supportText {
    p {
        color: white;
    }
    display: flex;
    flex-direction: column;
}
.language-title{
    font-size: 16px;
    margin-bottom: 15px;
}
.move-to-left{
    padding-left: 7px;
}
.v-btn {
    border: 1px solid white;
}
</style>

