<template>
  <text-input
    :value="value"
    @changed="onChanged"
  >
    <base-material-icon :icon-name="value" class="icon-preview"/>
  </text-input>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class MaterialIconInput extends Vue {
  @Prop() public value: string

  @Emit('changed')
  public onChanged(value: string) {
    return value
  }
}
</script>

<style lang="scss" scoped>
.icon-preview {
  margin-left: 10px;
}
</style>
