<template>
    <v-flex xs12 row wrap align-center class="text-input">
        <input
          :type="type"
          class="base-text-box"
          v-model="currentString"
          :disabled="disabled"
          :placeholder="placeholder"
          @input="emitInputChanged"
          @change="emitStringChanged"
          :class="{ disabled: disabled, pending: pending }"
          :autocomplete="optimizeForJson ? 'off' : 'on'" 
          :spellcheck="optimizeForJson ? 'false' : 'default'" 
        >
        <slot/>
        <div class="reset-string-button">
            <icon-button 
                @buttonClicked="resetCurrentString"
                type="materialIcon"
                materialIconName="clear"
                :height="25"
                :width="25"
                :enabled="!disabled && currentString !== ''"
            />
        </div>
    </v-flex>
</template>
<script lang="ts">
import Vue from 'vue'

import IconButton from './IconButton.vue'

export default Vue.extend({
  name: 'TextInput',
  components: {
    IconButton,
  },
  props: {
    /** 
     * value of the input to be set and updated from outside
     */
    value: {
      type: String,
      default: '',
    },
    /** 
     * disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** 
     * pending
     */
    pending: {
      type: Boolean,
      default: false,
    },
    /** 
     * optional placeholder text, displayed in case nothing is input
     */
    placeholder: {
      type: String,
      default: '',
    },
    /** 
     * disables autocomplete autocorrect autocapitalize and spellcheck 
     * for better performance of big inputs like json
     */
    optimizeForJson: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    pattern: {
      type: String,
      default: '',
    },
  },
  watch: {
    value(newVal, oldVal) {
      this.currentString = newVal
    },
  },
  data() {
    return {
      currentString: this.value,
    }
  },
  methods: {
    emitInputChanged() {
      /**
       * input event, fired on every change
       *
       * @event input
       * @type {string}
       */
      this.$emit('input', this.currentString)
    },
    emitStringChanged() {
      /**
       * changed event, fired when losing focus, pressing enter or esc
       *
       * @event changed
       * @type {string}
       */
      this.$emit('changed', this.currentString)
    },
    /**
     * resets the textbox
     */
    resetCurrentString() {
      if (this.currentString !== '') {
        this.currentString = ''
        this.emitInputChanged()
        this.emitStringChanged()
      }
    },
  },
})
</script>
<style lang="scss">
.text-input {
    margin: $margin-default;
    display: flex;
    flex-direction: row;
    margin: 0px;
    .base-text-box {
        flex-grow: 1;
    }
    .reset-string-button {
        width: 30px;
    }
    input {
        font-family: $font-family-primary;
        height: 100%;
        width: 100%;
        border-width: $default-border-width;
        border-style: solid;
        border-color: $fore-color-primary;
        padding: $default-padding;
        color: $fore-color-primary;
        background: transparent;
        outline: none;
        &.disabled {
          color: $fore-color-disabled;
        }
        &.pending {
          border-color: $fore-color-disabled;
        }
    }
    .v-btn {
      margin: 0px;
    }
    ::placeholder {
      color: $fore-color-disabled;
    }
}
</style>

<docs>
A text input control

Usage example
```js
new Vue({
  methods: {
    onChanged(text) {
      console.log(`changed: ${text}`)
    },
    onInput(text) {
      console.log(`input: ${text}`)
    }
  },
  template: `<text-input 
    placeholder="Type something..."
    @input="onInput"
    @changed="onChanged"
  />`
})
```

Initial value
```jsx
<text-input value="foo" />
```

Json optimized
```jsx
<text-input value="{ 'key': 'value'}" :optimize-for-json="true" />
```

Disabled
```jsx
<text-input value="this is read-only" :disabled="true" />
```
</docs>