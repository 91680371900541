import { ActionTree } from 'vuex'
import ApiService from '../services/api.service'
import AuthenticationService, { AuthenticationServiceOptions } from '../services/authentication.service'
import { TokenService } from '../services/tokenstorage.service'
import { AuthenticationOptions } from './models'
import { AuthenticationAction, AuthenticationGetter, AuthenticationMutation, AuthenticationState } from './types'

const REFRESH_TOKEN_TIMER_INTERVAL = 60 * 60 * 1000 // 1h
let refreshTokenTimerHandle: any | null = null

export const actions: ActionTree<AuthenticationState, {}> = {
  async [AuthenticationAction.initialize]({ dispatch, getters }, payload: AuthenticationOptions) {
    // configures services and initializes them with stored state
    AuthenticationService.init({
      clientId: payload.clientId,
      b2cTenant: payload.b2cTenant,
      b2cDefaultPolicy: 'B2C_1_default',
      b2cRecoveryPolicy: 'B2C_1_recovery',
      b2cEditPolicy: 'B2C_1_edit',
      scopes: ['offline_access', 'openid', `https://${payload.b2cTenant}.onmicrosoft.com/apis/user_impersonation`],
      redirectUrl: payload.redirectUrl,
      redirectCallback: payload.redirectCallback,
      useRefreshToken: payload.useRefreshToken,
      tokenEndpointProxyUrl: payload.tokenEndpointProxyUrl,
    } as AuthenticationServiceOptions)
    ApiService.registerUnauthorizedHandler(() => {
      if (getters[AuthenticationGetter.loggedIn]) {
        dispatch(AuthenticationAction.handleUnauthorized)
      }
    })
    await dispatch(AuthenticationAction.login)

    // periodically refresh token in background
    if (refreshTokenTimerHandle) {
      clearInterval(refreshTokenTimerHandle)
    }
    refreshTokenTimerHandle = setInterval(() => dispatch(AuthenticationAction.refreshToken), REFRESH_TOKEN_TIMER_INTERVAL)
  },
  async [AuthenticationAction.handleAppLoad]({ dispatch }, url: string) {
    // parses the url for code or errors after redirect from b2c login page
    await AuthenticationService.handleRedirectUrl(url)
    await dispatch(AuthenticationAction.login)
  },
  async [AuthenticationAction.handleUnauthorized]({ commit }) {
    commit(AuthenticationMutation.setLoggedInUser, null)
    ApiService.removeHeader()
    await AuthenticationService.clear()
    AuthenticationService.loginRedirect()
  },
  async [AuthenticationAction.login]({ commit }) {
    const token = await TokenService.getToken()
    if (token) {
      ApiService.setHeader(token)
      const userDetails = await TokenService.parseToken()
      commit(AuthenticationMutation.setLoggedInUser, userDetails)
    }
  },
  async [AuthenticationAction.logout]({ commit }) {
    commit(AuthenticationMutation.setLoggedInUser, null)
    ApiService.removeHeader()
    await AuthenticationService.logout()
  },
  async [AuthenticationAction.editProfile]({ commit }) {
    commit(AuthenticationMutation.setLoggedInUser, null)
    ApiService.removeHeader()
    await AuthenticationService.editProfile()
  },
  async [AuthenticationAction.refreshToken]({ dispatch }) {
    await AuthenticationService.refreshToken()
    await dispatch(AuthenticationAction.login)
  },
}
