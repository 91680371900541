import { Module } from 'vuex'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { state } from './state'
import { EnergyStatusAction, EnergyStatusGetter, EnergyStatusMutation, EnergyStatusState } from './types'

const namespaced: boolean = true
export const energyStatus: Module<EnergyStatusState,any> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action:  EnergyStatusAction,
  mutation: EnergyStatusMutation,
  getter: EnergyStatusGetter,
}

export const domain = 'energyStatus'

export const vuexModule = {
  [domain]: energyStatus,
}