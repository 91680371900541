import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { state } from './state'
import { PlcConfigurationAction, PlcConfigurationGetter, PlcConfigurationMutation, PlcConfigurationState } from './types'

import { Module } from 'vuex'

const namespaced: boolean = true
export const plcConfiguration: Module<PlcConfigurationState, any> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}

export const types = {
  action: PlcConfigurationAction, 
  mutation: PlcConfigurationMutation,
  getter: PlcConfigurationGetter,
}

export const domain = 'plcConfiguration'

export const vuexModule = {
  [domain]: plcConfiguration,
}