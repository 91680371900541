import { AuthorizationState } from './types'

export const state: AuthorizationState = {
  partners: [],
  projects: [],
  consumptionProjects: [],
  projectAuthorizations: {
    id: '',
    permissions: [],
  },
  consumptionAuthorizations: {
    id: '',
    permissions: [],
  },
  plcAuthorizations: [],
  deviceAuthorizations: [],
  alarmDefinitionAuthorizations: [],
  rooms: [],
  appRegistrationNameLookup: new Map<string, string>(),
}
