<template>
  <card :header="'Cloud logging'" :chip-text="chipText" :chip-color="chipColor" :collapsible="true" :expanded="expanded" @expandedChanged="expandedChanged">
    <template v-slot:actions>
      <card-action
        icon="play_arrow"
        tooltip="Start displaying logs"
        :disabled="isInteracted || isLoading"
        @click="startReceivingLogs"
      />
      <card-action
        icon="pause"
        tooltip="Stop displaying logs"
        :disabled="isInteracted || isLoading"
        @click="stopReceivingLogs"
      />
      <card-action
        icon="delete"
        tooltip="Clear all logs"
        :disabled="isInteracted || isLoading || !cloudLogEntries.length"
        @click="clearCloudLogEntries"
      />
      <card-action
        icon="download"
        tooltip="Download all log data as CSV"
        :disabled="isInteracted || isLoading || !cloudLogEntries.length"
        @click="downloadCloudLogEntries"
      />
    </template>
    <log-config-editor :destination="logDestination"/>
    <div class="log-entries-table">
      <virtual-log-entries-table :log-entries="cloudLogEntries"/>
    </div>
  </card>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Card from '../../../../ui/Card.vue'
import CardAction from '../../../../ui/CardAction.vue'

import LogConfigEditor from './LogConfigEditor.vue'

import VirtualLogEntriesTable from './VirtualLogEntriesTable.vue'

import { LogDestination, LogEntryModel, LogLevel } from '../../../../../store/modules/logToolUi/models'

import { logLevelChipColor } from '../../../../../store/modules/logToolUi/helpers'

const LogToolUi = namespace('logToolUi')

@Component({
  components: {
    Card,
    CardAction,
    LogConfigEditor,
    VirtualLogEntriesTable,
  },
})
export default class CloudLoggingConfiguration extends Vue { 
  @Prop() public expanded: boolean
  @LogToolUi.Getter public isInteracted: boolean
  @LogToolUi.Getter public isLoading: boolean
  @LogToolUi.Getter public defaultLogLevelForDestination: (destination: LogDestination) => LogLevel
  @LogToolUi.Getter public cloudLogEntries: LogEntryModel[]
  @LogToolUi.Mutation public clearCloudLogEntries: () => void
  @LogToolUi.Action public startReceivingLogs: () => Promise<void>
  @LogToolUi.Action public stopReceivingLogs: () => Promise<void>
  @LogToolUi.Action public downloadCloudLogEntries: () => Promise<void>

  public get logDestination() {
    return LogDestination.Cloud
  }

  public get defaultLogLevel() {
    return this.defaultLogLevelForDestination(this.logDestination)
  }

  public get chipText() {
    return this.defaultLogLevel
  }

  public get chipColor() {
    return logLevelChipColor(this.defaultLogLevel)
  }

  @Emit('expandedChanged') 
  public expandedChanged(expanded: boolean) {
    return expanded
  }
}
</script>
<style lang="scss" scoped>
.field {
  height: 70px;
  .field-row {
    display: flex;
  }
}
.log-entries-table {
  height: 500px;
}
</style>