import { newGuid } from '@/store/helpers'
import { FunctionDefinitionEditorUiState } from '@/store/modules/functionDefinitionEditorUi/types'
import { ArchitectureType, SourceLanguage } from '@ecocoach/domain-store-modules/src/common'
import { AppDisplayLevels, ConfigurationToolCategories, ControlKinds, ControlTypes, EnergyDisplayLevels } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { TwinCatLibraryDependency } from '@ecocoach/domain-store-modules/src/systemConfiguration/models'
import { ActionControlAppearance, ControlTypeV2 } from '@ecocoach/shared-components/src'
import { DEFAULT_POLLING_INTERVAL_MS, EndpointIsMethodPropertyName, EndpointPropertyName } from './models'

export const state: FunctionDefinitionEditorUiState = {
  architectureType: ArchitectureType.ObjectOriented,
  sourceLanguage: SourceLanguage.StructuredText,
  preserveProjectFilesInUranus: false,
  controlDefinitions: [],
  controlDefinitionsOriginal: [],
  controlDefinitionInput: {
    id: newGuid(),
    kind: ControlKinds.MONITORING_CONTROL,
    type: ControlTypes.UIValue,
    obsolete: false,
    appDisplayLevel: AppDisplayLevels.NOT_DISPLAYED,
    configurationToolCategory: ConfigurationToolCategories.STATE,
    energyDisplayLevel: EnergyDisplayLevels.NOT_DISPLAYED,
    energyDisplaySummary: false,
    minValue: 0,
    maxValue: 0,
    interval: 0,
    colorGradient: '',
    unit: '',
    nameResourceId: '',
    descriptionResourceId: '',
    softwareMappingGlobalVariableId: '',
    dropdownValueList: [],
    isMeterDataAggregationEnabled: false,
    isDynamicMeterControl: false,
    isMeterActivationControl: false,
    toSubscribe: false,
    pollInterval: DEFAULT_POLLING_INTERVAL_MS,
    [EndpointPropertyName.ActivateEndpoint]: '',
    [EndpointPropertyName.AdoptColorEndpoint]: '',
    [EndpointPropertyName.Button1Endpoint]: '',
    [EndpointPropertyName.Button2Endpoint]: '',
    [EndpointPropertyName.ReadEndpoint]: '',
    [EndpointPropertyName.RgbArrayReadEndpoint]: '',
    [EndpointPropertyName.RgbArrayWriteEndpoint]: '',
    [EndpointPropertyName.SwitchOffEndpoint]: '',
    [EndpointPropertyName.SwitchOffEndpoint2]: '',
    [EndpointPropertyName.SwitchOnEndpoint]: '',
    [EndpointPropertyName.SwitchOnEndpoint2]: '',
    [EndpointPropertyName.SwitchOutputEndpoint]: '',
    [EndpointPropertyName.SwitchOutputEndpoint2]: '',
    [EndpointPropertyName.WriteEndpoint]: '',
    [EndpointIsMethodPropertyName.ActivateEndpointIsMethod]: undefined,
    [EndpointIsMethodPropertyName.Button1IsMethod]: undefined,
    [EndpointIsMethodPropertyName.Button2IsMethod]: undefined,
    [EndpointIsMethodPropertyName.OffEndpointIsMethod]: undefined,
    [EndpointIsMethodPropertyName.OffEndpoint2IsMethod]: undefined,
    [EndpointIsMethodPropertyName.OnEndpointIsMethod]: undefined,
    [EndpointIsMethodPropertyName.OnEndpoint2IsMethod]: undefined,  
    isExisting: false, 
  },
  controlDefinitionInputV2: {
    id: newGuid(),
    type: ControlTypeV2.Action,
    attributes: {
      label: '',
      tooltip: '',
      appearance: ActionControlAppearance.TextButton,
      action: {
        command: 'actionCommand',
        text: '',
      },
      confirmationText: '',
    },
    commands: {},
    states: {},
    obsolete: false,
    isExisting: false,
  },
  functionDefinition: {
    id: '',
    internalName: '',
    xml: '',
    version: '',
    nameResourceId: '',
    descriptionResourceId: '',
    iconResourceId: '',
    functionBlockDefinitionCategoryId: '',
    cycleTimeInMilliseconds: 25,
    messagesEnumInternalName: '',
    messagesEnumValues: [],
    twinCatLibraryDependency: TwinCatLibraryDependency.NONE,
    controlDefinitionMappings: [],
    endpoints: [],
    autoMappingSlots: [],
    endpointResources: [],
    instanceResources: [],
    predecessorFunctionBlockIds: [],
    targetInstances: [],
    measuringPoints: [],
    energyStatusItems: [],
    defaultAlarmDefinitions: [],
    architectureType: ArchitectureType.Classic,
    sourceLanguage: SourceLanguage.StructuredText,
    licenseTags: [],
  },
  actionInProgress: '',
  uploadResults: [],
}
