<template>
  <eco-labeled-control class="eco-numeric-input-control"
    :context="context"
    :attributes="attributes"
    :direction="showSlider ? 'column' : 'row'"
  >
    <!-- numeric input / hue color slider -->
    <template v-slot:hint>
      <div v-if="showSliderCurrentValue" class="hint-value">
        <span>{{ currentValue }}</span>
        <span class="hint-value-unit">{{ unit }}</span>
      </div>
    </template>
    <base-range-slider v-if="showSlider"
      :current-value="currentValue"
      :minimal-value="minValue"
      :maximal-value="maxValue"
      :interval="interval"
      :gradient-colors="gradient"
      :readonly="readonly"
      @selectedNewValue="onChange"
      @startedSliding="onBeginSliding"
      @stoppedSliding="onEndSliding"
    />
    <div v-if="showSliderMinMaxValueLabels" class="ui-slider-labels">
      <div class="truncate"> 
        {{ minValueLabel }}
      </div>
      <div class="truncate"> 
        {{ maxValueLabel }}
      </div>
    </div>
    <!-- input -->
    <eco-number-input v-if="isInput"
      :current-value="currentValue"
      :pending-value="pendingValue"
      :min="minValue"
      :max="maxValue"
      :step="interval"
      :unit="unit"
      :readonly="readonly"
      @changed="onChange"
    />
  </eco-labeled-control>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import BaseRangeSlider from '../../base/BaseRangeSlider.vue'
import { ControlContextV2, NumericInputControlAppearance, NumericInputControlAttributes } from './models'
import EcoLabeledControl from './shared/EcoLabeledControl.vue'
import EcoNumberInput from './shared/EcoNumberInput.vue'

import Gradients from './../v1/shared/gradients'

@Component({
  components: {
    BaseRangeSlider,
    EcoLabeledControl,
    EcoNumberInput,
  },
})
export default class EcoNumericInputControl extends Vue {
  @Prop() public id: string
  @Prop() public context: ControlContextV2
  @Prop() public attributes: NumericInputControlAttributes
  @Prop() public state: object
  @Prop() public pendingState: object
  @Prop() public readonly: boolean

  public internalValue: number = this.currentValue

  public onChange(value: number) {
    this.context.executeCommand(this.id, {
      command: this.attributes.command,
      params: value,
      pendingState: {
        [this.attributes.state]: value,
      },
    })
  }

  public onBeginSliding() {
    this.context.executeCommand(this.id, {
      command: this.attributes.beginCommand,
      params: true,
    })
  }

  public onEndSliding() {
    this.context.executeCommand(this.id, {
      command: this.attributes.endCommand,
      params: false,
    })
  }

  public get isInput() {
    return this.attributes?.appearance === NumericInputControlAppearance.Input
  }

  public get isNormalSlider() {
    return this.attributes?.appearance === NumericInputControlAppearance.Slider
  }

  public get isHueColorSlider() {
    return this.attributes?.appearance === NumericInputControlAppearance.HueColorSlider
  }

  public get showSlider() {
    return this.isNormalSlider || this.isHueColorSlider
  }

  public get showSliderCurrentValue() {
    return this.showSlider && !!this.unit
  }

  public get showSliderMinMaxValueLabels() {
    return this.showSlider && (!!this.minValueLabel || !!this.maxValueLabel)
  }

  public get minValue() {
    return this.attributes.minValue
  }

  public get maxValue() {
    return this.attributes.maxValue
  }

  public get interval() {
    return this.attributes.interval
  }

  public get minValueLabel() {
    return this.context.resolveStringResource(this.attributes.minValueLabel)
  }

  public get maxValueLabel() {
    return this.context.resolveStringResource(this.attributes.maxValueLabel)
  }

  public get gradient() {
    if (this.isNormalSlider) {
      return Gradients.stops(this.attributes.gradient)
    } else if (this.isHueColorSlider) {
      return ['#FF0000','#FFA500','#FFFF00','#008000','#0000FF','#4B0082','#EE82EE','#FF0000']
    }
    return []
  }

  public get unit() {
    return this.attributes.unit
  }

  public get currentValue() {
    return this.state[this.attributes.state]
  }

  public get pendingValue() {
    return this.pendingState[this.attributes.state]
  }
}
</script>

<style lang="scss" scoped>
.eco-numeric-input-control {
  font-family: $font-family-primary;
  flex: 1;
  .ui-slider-labels {
    display: flex;
    justify-content: space-between;
    margin-top: -20px;
    color: #7b8b9a;
    font-size: 12px;
    .truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .hint-value {
    padding: 5px 5px;
    line-height: 1.2;
    border-radius: 5px;
    background: rgba(123, 139, 154, 0.1);
    .hint-value-unit {
      margin-left: 5px;
      color: #7b8b9a;
    }
  }
}
</style>

<style lang="css">
</style>
