import { asyncScheduler, Observable, Subscriber } from 'rxjs'
import { concatMap, endWith, startWith, throttleTime } from 'rxjs/operators'
import { ActionContext, ActionTree } from 'vuex'
import { ControlTypeV2 } from '../common'
import ApiService from '../services/api.service'
import CacheService, { CacheOptions } from '../services/cache.service'
import HubService from '../services/hub.service'
import { delay, upsert } from './../utils'
import command from './api/command'
import query from './api/query'
import {
  CommandResult,
  ComponentFirmwareType,
  ControlInput,
  ControlModelV2,
  controlStateHubId,
  ControlValueChangeFailed,
  DeviceConfirmationStateChanged,
  ExecuteControlCommandInput,
  InitializePlcInput,
  MultiplePlcStateChanged,
  MultipleStateChanged,
  PendingControlCommand,
  PlcOperationReleaseOutput,
  PlcOperationStateChanged,
  plcStateHubId,
  ProjectModel,
  SceneModel,
  SceneTimeScheduleModel,
  SetConsumptionProcessOwnerLockEnabledInput,
  SetControlAppDisplayLevelInput,
  SetControlColorGradientInput,
  SetDeviceConsumptionValidationEnabledInput,
  SetDeviceIgnoreInChartsInput,
  SetDisplayNameInput,
  SetEnergyStatusItemColorInput,
  SetMeasuringPointColorInput,
} from './models'
import { PlcOperationAction, PlcOperationGetter, PlcOperationMutation, PlcOperationState } from './types'

const sliderControlAppearances = new Set(['Slider', 'HueColorSlider'])
const sliderSubscribersV2 = new Map<string, Subscriber<ExecuteControlCommandInput>>()

const THROTTLE_TIMEOUT = 200
const INTERACTION_TIMEOUT = 10000

function isSliderControl(control: ControlModelV2) {
  return control.type === ControlTypeV2.NumericInput && sliderControlAppearances.has(control.attributes.appearance)
}

export const actions: ActionTree<PlcOperationState, {}> = {
  async [PlcOperationAction.loadProjects]({ commit }, payload?: { includeDemoProjects: boolean }) {
    const includeDemoProjects = payload?.includeDemoProjects || false
    await CacheService.load({
      action: PlcOperationAction.loadProjects,
      parameters: [includeDemoProjects],
      load: () => query.loadProjects(includeDemoProjects),
      commit: data => commit(PlcOperationMutation.setProjects, data),
    } as CacheOptions<ProjectModel[]>)
  },
  async [PlcOperationAction.loadConfigurationByPlcId]({ commit }: ActionContext<PlcOperationState, {}>, payload: { plcId: string, language: string }): Promise<void> {
    const plcConfiguration = await query.activeReleaseByPlc(payload.plcId, payload.language)
    commit(PlcOperationMutation.setPlcs, plcConfiguration.plcs ?? [])
    commit(PlcOperationMutation.setRooms, plcConfiguration.rooms)
    commit(PlcOperationMutation.setDevices, plcConfiguration.devices)
    commit(PlcOperationMutation.setControls, plcConfiguration.controls)
    commit(PlcOperationMutation.setPlcId, payload.plcId)
    commit(PlcOperationMutation.setProjectId, '')
    commit(PlcOperationMutation.setActivePlcConfigurationReleaseId, plcConfiguration.activePlcConfigurationReleaseId)
  },
  async [PlcOperationAction.loadConfigurationByProjectId]({ commit }: ActionContext<PlcOperationState, {}>, payload: { projectId: string, language: string }): Promise<void> {
    await CacheService.load({
      action: PlcOperationAction.loadConfigurationByProjectId,
      parameters: [payload.projectId, payload.language],
      load: () => query.activeReleasesByProject(payload.projectId, payload.language),
      commit: data => {
        commit(PlcOperationMutation.setPlcs, data.plcs ?? [])
        commit(PlcOperationMutation.setRooms, data.rooms)
        commit(PlcOperationMutation.setDevices, data.devices)
        commit(PlcOperationMutation.setControls, data.controls)
        commit(PlcOperationMutation.setPlcId, '')
        commit(PlcOperationMutation.setProjectId, payload.projectId)
        commit(PlcOperationMutation.setActivePlcConfigurationReleaseId, '')
      },
    } as CacheOptions<PlcOperationReleaseOutput>)
  },
  async [PlcOperationAction.initializePlc](_, payload: InitializePlcInput) {
    command.initializePlc(payload.plcId, payload.fileName)
  },
  async [PlcOperationAction.connectToControlStateHub]({ dispatch }): Promise<void> {
    await HubService.connect({
      hubId: controlStateHubId,
      hubUrl: `${ApiService.backendEnvironmentConfiguration().controlStateHub}`,
      methodCallbacks: [
        {
          method: 'MultipleStateChanged',
          callback: (payload: MultipleStateChanged) => {
            dispatch(PlcOperationAction.stateUpdatesReceived, payload)
          },
        },
        {
          method: 'CommandResult',
          callback: async (payload: CommandResult) => {
            dispatch(PlcOperationAction.unregisterControlCommand, payload.correlationId)
            if (payload.error) {
              dispatch(PlcOperationAction.triggerControlUpdateFailed)
            }
          },
        },
        {
          method: 'ControlValueChangeFailed',
          callback: (payload: ControlValueChangeFailed) => {
            dispatch(PlcOperationAction.unregisterControlCommand, payload.controlId)
            dispatch(PlcOperationAction.triggerControlUpdateFailed)
          },
        },
      ],
    })
  },
  async [PlcOperationAction.disconnectFromControlStateHub] () {
    await HubService.disconnect(controlStateHubId)
  },
  async [PlcOperationAction.setControlsFilter]({ commit, dispatch }, filter: (control: ControlModelV2) => boolean): Promise<void> {
    commit(PlcOperationMutation.setControlsFilter, filter)
    await dispatch(PlcOperationAction.startNotifications)
  },
  async [PlcOperationAction.startNotifications]({ state }): Promise<void> {
    const controlIds = state.controls.filter(state.controlsFilter).map(c => c.id)
    await HubService.invoke({
      hubId: controlStateHubId,
      method: 'StartNotifications',
      args: [controlIds],
    })
  },
  async [PlcOperationAction.stopNotifications](): Promise<void> {
    await HubService.invoke({
      hubId: controlStateHubId,
      method: 'StartNotifications',
      args: [[]],
    })
  },
  async [PlcOperationAction.stateUpdatesReceived]({ commit, dispatch, state }, payload: MultipleStateChanged): Promise<void> {
    payload.states.forEach(message => {
      commit(PlcOperationMutation.setControlState, message)
      const control = state.controlsLookup.get(message.controlId) as ControlModelV2
      if (control?.isMappedClassicControl) {
        dispatch(PlcOperationAction.unregisterControlCommand, message.controlId)
      }
    })
  },
  async [PlcOperationAction.registerControlCommand] ({ getters, commit, dispatch }, payload: { input: ControlInput<any>, correlationId: string }): Promise<void> {
    const timeout = setTimeout(() => {
      const pendingControlCommand = getters[`${PlcOperationGetter.pendingControlCommand}`](payload.input.controlId)
      if(pendingControlCommand) {
        dispatch(PlcOperationAction.triggerControlUpdateTimeout, payload.correlationId)
      }
    }, INTERACTION_TIMEOUT)

    commit(PlcOperationMutation.upsertPendingControlCommand, {
      controlId: payload.input.controlId,
      correlationId: payload.correlationId || payload.input.controlId,
      pendingValue: payload.input.payload.pendingState,
      timeout,
    } as PendingControlCommand)
  },
  async [PlcOperationAction.unregisterControlCommand] ({ commit }, correlationId: string): Promise<void> {
    commit(PlcOperationMutation.removePendingControlCommand, correlationId)
  },
  async [PlcOperationAction.triggerControlUpdateTimeout] ({ commit, dispatch }, correlationId: string): Promise<void> {
    await dispatch(PlcOperationAction.unregisterControlCommand, correlationId)
    commit(PlcOperationMutation.setControlUpdateTimeout, true)
    await delay(0)
    commit(PlcOperationMutation.setControlUpdateTimeout, false)
  },
  async [PlcOperationAction.triggerControlUpdateFailed] ({ commit}): Promise<void> {
    commit(PlcOperationMutation.setControlUpdateFailed, true)
    await delay(0)
    commit(PlcOperationMutation.setControlUpdateFailed, false)
  },
  async [PlcOperationAction.createArrayEntry] ({ commit, dispatch, state}, payload: { input: ControlInput<{}>, language: string }): Promise<void> {
    const createdControls = await command.addArrayEntry(payload.input.controlId, payload.language)
    const controls = state.controls.concat(createdControls)
    commit(PlcOperationMutation.setControls, controls)
    dispatch(PlcOperationAction.startNotifications)
  },
  async [PlcOperationAction.deleteArrayEntry] ({ commit, state}, input: ControlInput<{}>): Promise<void> {
    const control = state.controlsLookup.get(input.controlId)!
    const deletedControlIds = await command.removeArrayEntry(control.parentControlId!, control.id)
    const controls = state.controls.filter(c => !deletedControlIds.includes(c.id))
    commit(PlcOperationMutation.setControls, controls)
  },
  async [PlcOperationAction.setDisplayName] ({ commit }, input: SetDisplayNameInput): Promise<void> {
    await command.setDisplayName(input.controlId, input.payload)
    commit(PlcOperationMutation.setControlDisplayName, input)
  },
  async [PlcOperationAction.setControlAppDisplayLevel] ({ commit }, input: SetControlAppDisplayLevelInput): Promise<void> {
    await command.setAppDisplayLevel(input.controlId, input.payload)
    commit(PlcOperationMutation.setControlAppDisplayLevel, input)
  },
  async [PlcOperationAction.setControlColorGradient] ({ commit }, input: SetControlColorGradientInput): Promise<void> {
    await command.setControlColorGradient(input.controlId, input.payload)
    commit(PlcOperationMutation.setControlColorGradient, input)
  },
  async [PlcOperationAction.executeControlCommand] ({ dispatch, state }, input: ExecuteControlCommandInput): Promise<void> {
    const control = state.controlsLookup.get(input.controlId) as ControlModelV2
    if (isSliderControl(control)) { // is a slider control, streaming/throttling of api call needed
      if (input.payload.command === control.attributes.beginCommand && input.payload.params === true) { // beginCommand
        if (sliderSubscribersV2.has(input.controlId)) {
          return
        }
        // empty beginCommand name indicates nothing to start with
        const beginCommandIfRequired = control.attributes.beginCommand ? [{
          controlId: control.id,
          payload: {
            command: control.attributes.beginCommand,
            params: true,
          },
        }] : []
        // empty endCommand name indicates nothing to end with
        const endCommandIfRequired = control.attributes.endCommand ? [{
          controlId: control.id,
          payload: {
            command: control.attributes.endCommand,
            params: false,
          },
        }] : []
        const observable = new Observable<ExecuteControlCommandInput>(subscribe => {
          sliderSubscribersV2.set(input.controlId, subscribe)
        }).pipe(
          throttleTime(THROTTLE_TIMEOUT, asyncScheduler, { trailing: true }),
          startWith(...beginCommandIfRequired),
          endWith(...endCommandIfRequired),
          concatMap(async commandInput => {
            try {
              let { correlationId } = await command.executeControlCommand(commandInput.controlId, commandInput.payload)
              if (commandInput.payload.command === control.attributes.command) {
                if (control.isMappedClassicControl) {
                  correlationId = control.id
                }
                await dispatch(PlcOperationAction.registerControlCommand, { input: commandInput, correlationId })
              }
            } catch (error) {
              if (commandInput.payload.command === control.attributes.command) {
                await dispatch(PlcOperationAction.unregisterControlCommand, commandInput.controlId)
              }
            }
          }),
        )
        const subscription = observable.subscribe({
          complete: () => {
            subscription.unsubscribe()
            sliderSubscribersV2.delete(input.controlId)
          },
        })
      } else if (input.payload.command === control.attributes.command) { // command
        if (sliderSubscribersV2.get(input.controlId)) {
          sliderSubscribersV2.get(input.controlId)!.next(input)
        }
      } else if (input.payload.command === control.attributes.endCommand && input.payload.params === false) { // endCommand
        if (sliderSubscribersV2.get(input.controlId)) {
          sliderSubscribersV2.get(input.controlId)!.complete()
        }
      }
    } else { // regular non-slider control command
      let { correlationId } = await command.executeControlCommand(input.controlId, input.payload)
      if (control.isMappedClassicControl) {
        correlationId = control.id
      }
      await dispatch(PlcOperationAction.registerControlCommand, { input, correlationId })
    }
  },
  async [PlcOperationAction.setDeviceIgnoreInCharts] ({ commit }, input: SetDeviceIgnoreInChartsInput): Promise<void> {
    await command.setDeviceIgnoreInCharts(input.deviceId, input.ignoreInCharts)
    commit(PlcOperationMutation.setDeviceIgnoreInCharts, input)
  },
  async [PlcOperationAction.setDeviceConsumptionValidationEnabled] ({ commit }, input: SetDeviceConsumptionValidationEnabledInput): Promise<void> {
    await command.setDeviceConsumptionValidationEnabled(input.deviceId, input.consumptionValidationEnabled)
    commit(PlcOperationMutation.setDeviceConsumptionValidationEnabled, input)
  },
  async [PlcOperationAction.setConsumptionProcessOwnerLockEnabled] ({ commit }, input: SetConsumptionProcessOwnerLockEnabledInput): Promise<void> {
    await command.setConsumptionProcessOwnerLockEnabled(input.deviceId, input.consumptionProcessOwnerLockEnabled)
    commit(PlcOperationMutation.setConsumptionProcessOwnerLockEnabled, input)
  },
  async [PlcOperationAction.setMeasuringPointColor] ({ commit }, input: SetMeasuringPointColorInput): Promise<void> {
    await command.setMeasuringPointColor(input.deviceId, input.measuringPointId, input.colorGradient)
    commit(PlcOperationMutation.setMeasuringPointColor, input)
  },
  async [PlcOperationAction.setEnergyStatusItemColor] ({ commit }, input: SetEnergyStatusItemColorInput): Promise<void> {
    await command.setEnergyStatusItemColor(input.deviceId, input.energyStatusItemId, input.colorGradient)
    commit(PlcOperationMutation.setEnergyStatusItemColor, input)
  },
  async [PlcOperationAction.loadScenes]({ commit }, projectId: string): Promise<void> {
    await CacheService.load({
      action: PlcOperationAction.loadScenes,
      parameters: [projectId],
      load: () => query.scenesByProjectId(projectId),
      commit: data => commit(PlcOperationMutation.setScenes, data),
    } as CacheOptions<SceneModel[]>)
  },
  async [PlcOperationAction.createScene]({ commit }, scene: SceneModel): Promise<void> {
    const { id, unauthorizedControlIds } = await command.createScene(scene)
    const createdScene: SceneModel = {
      ...scene,
      id,
      controlCommands: scene.controlCommands.filter(c => !unauthorizedControlIds.includes(c.controlId)),
    }
    commit(PlcOperationMutation.upsertScene, createdScene)
  },
  async [PlcOperationAction.updateScene]({ commit }, scene: SceneModel): Promise<void> {
    const { unauthorizedControlIds } = await command.modifyScene(scene)
    scene.controlCommands = scene.controlCommands.filter(c => !unauthorizedControlIds.includes(c.controlId))
    commit(PlcOperationMutation.upsertScene, scene)
  },
  async [PlcOperationAction.deleteScene]({ commit, state }, sceneId: string): Promise<void> {
    await command.deleteScene(state.projectId, sceneId)
    commit(PlcOperationMutation.removeScene, sceneId)
  },
  async [PlcOperationAction.activateScene]({ state }, sceneId: string): Promise<void> {
    await command.activateScene(state.projectId, sceneId)
  },
  async [PlcOperationAction.createSceneSchedule]({ commit, state }, payload: { sceneId: string, schedule: SceneTimeScheduleModel }): Promise<void> {
    const { id } = await command.createSceneTimeSchedule(state.projectId, payload.sceneId, payload.schedule)
    const scene = state.scenes.find(s => s.id === payload.sceneId)!
    scene.timeSchedules = [{
      ...payload.schedule,
      id,
    }]
    commit(PlcOperationMutation.upsertScene, scene)
  },
  async [PlcOperationAction.updateSceneSchedule]({ commit, state }, payload: { sceneId: string, schedule: SceneTimeScheduleModel }): Promise<void> {
    await command.modifySceneTimeSchedule(state.projectId, payload.sceneId, payload.schedule)
    const scene = state.scenes.find(s => s.id === payload.sceneId)!
    scene.timeSchedules = [payload.schedule]
    commit(PlcOperationMutation.upsertScene, scene)
  },
  async [PlcOperationAction.deleteSceneSchedule]({ commit, state }, payload: { sceneId: string, scheduleId: string}): Promise<void> {
    await command.deleteSceneTimeSchedule(state.projectId, payload.sceneId, payload.scheduleId)
    const scene = state.scenes.find(s => s.id === payload.sceneId)!
    scene.timeSchedules = []
    commit(PlcOperationMutation.upsertScene, scene)
  },
  async [PlcOperationAction.loadFavorites]({ commit }, projectId: string): Promise<void> {
    await CacheService.load({
      action: PlcOperationAction.loadFavorites,
      parameters: [projectId],
      load: () => query.favoritesByProjectId(projectId),
      commit: data => commit(PlcOperationMutation.setFavorites, data),
    } as CacheOptions<string[]>)
  },
  async [PlcOperationAction.createFavorite]({ commit, state }, payload: string): Promise<void> {
    await command.createFavorite(state.projectId, payload)
    commit(PlcOperationMutation.addFavorite, payload)
  },
  async [PlcOperationAction.deleteFavorite]({ commit, state }, payload: string): Promise<void> {
    await command.deleteFavorite(state.projectId, payload)
    commit(PlcOperationMutation.removeFavorite, payload)
  },
  async [PlcOperationAction.connectToDeviceConfirmationStateHub]({ commit }): Promise<void> {
    const hubId = 'deviceConfirmationStateHub'
    await HubService.connect({
      hubId,
      hubUrl: `${ApiService.backendEnvironmentConfiguration().comissioningStateHub}`,
      methodCallbacks: [{
        method: 'commissioningConfirmationStateChanged',
        callback: (payload: DeviceConfirmationStateChanged) => {
          const mutation = payload.confirmation ? PlcOperationMutation.addConfirmedDevice : PlcOperationMutation.removeConfirmedDevice
          commit(mutation, payload.confirmation)
        },
      }],
    })
    HubService.invoke({
      hubId,
      method: 'Start',
    })
  },
  async [PlcOperationAction.loadConfirmedDevices]({ commit }, payload: string) {
    const deviceIds = await query.confirmedDevices(payload)
    commit(PlcOperationMutation.setConfirmedDevices, deviceIds)
  },
  async [PlcOperationAction.confirmDevice]({ commit }, payload: { plcId: string, deviceId: string }) {
    await command.confirmDevice(payload.plcId, payload.deviceId)
    commit(PlcOperationMutation.addConfirmedDevice, payload.deviceId)
  },
  async [PlcOperationAction.unconfirmDevice]({ commit }, payload: { plcId: string, deviceId: string }) {
    await command.unconfirmDevice(payload.plcId, payload.deviceId)
    commit(PlcOperationMutation.removeConfirmedDevice, payload.deviceId)
  },
  async [PlcOperationAction.loadComponentFirmwareReleases]({ commit }) {
    const responses = await Promise.all([
      query.componentFirmwareReleases(ComponentFirmwareType.EcoCloudConnectorSmall),
      query.componentFirmwareReleases(ComponentFirmwareType.EcoCloudConnectorMedium),
      query.componentFirmwareReleases(ComponentFirmwareType.EcoCloudConnectorLarge),
      query.componentFirmwareReleases(ComponentFirmwareType.EcoCloudConnectorLight),
      query.componentFirmwareReleases(ComponentFirmwareType.InverterTrumpf),
    ])
    const releases = responses.reduce((a, b) => a.concat(b), [])
    commit(PlcOperationMutation.setComponentFirmwareReleases, releases)
  },
  async [PlcOperationAction.migratePlcToEcoCloudConnector](_, plcId: string) {
    await command.migratePlcToEcoCloudConnector(plcId)
  },
  async [PlcOperationAction.restartPlc](_, plcId: string) {
    await command.restartPlc(plcId)
  },
  async [PlcOperationAction.restartEcoCloudConnector](_, plcId: string) {
    await command.restartEcoCloudConnector(plcId)
  },
  async [PlcOperationAction.updateToLatestEcoCloudConnector](_, payload: { plcId: string, forceUpdate: boolean}): Promise<void> {
    await command.deployLatestPlcEcoCloudConnectorRelease(payload.plcId, payload.forceUpdate)
  },
  async [PlcOperationAction.updateToEcoCloudConnectorVersion](_, payload: { plcId: string, versionId: string, forceUpdate: boolean }): Promise<void> {
    await command.deployPlcEcoCloudConnectorRelease(payload.plcId, payload.versionId, payload.forceUpdate)
  },
  async [PlcOperationAction.deployComponentFirmware](_, payload: { plcId: string, versionId: string, ipAddress: string }): Promise<void> {
    await command.deployComponentFirmware(payload.plcId, payload.versionId, payload.ipAddress)
  },
  async [PlcOperationAction.resetPlcConfigurationRelease](_, plcId: string): Promise<void> {
    await command.resetPlcConfigurationRelease(plcId)
  },
  async [PlcOperationAction.connectToPlcStateHub]({ state }) {
    await HubService.connect({
      hubId: plcStateHubId,
      hubUrl: `${ApiService.backendEnvironmentConfiguration().plcStateHub}`,
      methodCallbacks: [{
        method: 'plcOperationStateChanged',
        callback: (payload: PlcOperationStateChanged) => {
          const plc = state.plcs.find(p => p.id === payload.plcId)
          if (plc) {
            upsert(state.plcs, {
              ...plc,
              plcOperationState: payload.plcOperationState,
              plcOperationStateTimeStamp: payload.timeStamp,
            })
          }
        },
      }, {
        method: 'multiplePlcStateChanged',
        callback: (payloads: MultiplePlcStateChanged) => {
          payloads.plcOperationStateList.forEach(payload => {
            const plc = state.plcs.find(p => p.id === payload.plcId)
            if (plc) {
              upsert(state.plcs, {
                ...plc,
                plcOperationState: payload.plcOperationState,
                plcOperationStateTimeStamp: payload.timeStamp,
              })
            }
          })
        },
      }],
    })
  },
  async [PlcOperationAction.disconnectFromPlcStateHub] () {
    await HubService.disconnect(plcStateHubId)
  },
  async [PlcOperationAction.startPlcStateStateHubForProject](_, payload: string) {
    HubService.invoke({
      hubId: plcStateHubId,
      method: 'StartForProject',
      args: [payload],
    })
  },
  async [PlcOperationAction.stopPlcStateHubForProject](_, payload: string) {
    HubService.invoke({
      hubId: plcStateHubId,
      method: 'StopForProject',
      args: [payload],
    })
  },
}
