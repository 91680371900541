<template>
  <table class="table">
    <tr @click.stop="toggleDeviceList" class="headerRow">
      <th valign="middle" class="iconCol">
        <svg-icon :icon="roomPermissions.icon" width="25" height="25"/>
      </th>
      <th valign="middle" class="descriptionCol"> {{ roomPermissions.name }} </th>
      <th v-if="devices.length > 0"
        valign="middle"
        class="authCol">
        <div class="authDiv">
          {{ 'authorizationLevel.read' | translate }}
          <div @click.stop="toggleDeviceReadPermissionForRoom">
            <svg-icon :icon="checkboxIcon(roomPermissions.hasRead)" width="25" height="25"/>
          </div>
        </div>
      </th>
      <th v-if="devices.length > 0" valign="middle" class="authCol">
        <div class="authDiv">
          {{ 'authorizationLevel.control' | translate }}
          <div @click.stop="toggleDeviceExecutePermissionForRoom">
            <svg-icon :icon="checkboxIcon(roomPermissions.hasExecute)" width="25" height="25"/>
          </div>
        </div>
      </th>
      <th v-if="devices.length > 0" valign="middle" class="collapse">
        <v-icon @click.stop="toggleDeviceList">
          {{ collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}
        </v-icon>
      </th>
      <th v-else valign="middle">
        <div class="infoCol">
          {{ 'authorization.room.no.devices' | translate }}
        </div>
      </th>
    </tr>
    <tr v-for="devicePermission in devicePermissionsOfRoom" :key="devicePermission.id" class="deviceRow">
      <td valign="middle" class="iconCol">
        <svg-icon :icon="devicePermission.icon" width="25" height="25"/>
      </td>
      <td valign="middle" class="descriptionCol"> {{ devicePermission.name }} </td>
      <td valign="middle" class="authCol">
        <div class="authDiv">
        {{ 'authorizationLevel.read' | translate }}
        <div @click="toggleDeviceReadPermission(devicePermission)">
          <svg-icon :icon="checkboxIcon(devicePermission.hasRead)" width="25" height="25"/>
        </div>
      </div>
      </td>
      <td valign="middle" class="authCol">
        <div class="authDiv">
        {{ 'authorizationLevel.control' | translate }}
        <div @click="toggleDeviceExecutePermission(devicePermission)">
          <svg-icon :icon="checkboxIcon(devicePermission.hasExecute)" width="25" height="25"/>
        </div>
      </div>
      </td>
      <td valign="middle" class="collapse"></td>
    </tr>
  </table>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { PermissionsViewModel } from '../../../store/modules/authorizationUi/models'

import { DeviceAuthorizationsModel, Permission, RoomModel } from '../../../../../eco-domain-store-modules/src/authorization/models'

import SvgIcon from '../../ui/SvgIcon.vue'

const AuthorizationUi = namespace('authorizationUi')

@Component({
  components: {
    SvgIcon,
  },
})
export default class RoomPermissions extends Vue {
  @Prop({type: Object, required: true }) public room: RoomModel
  @AuthorizationUi.Getter public isInteracted: boolean
  @AuthorizationUi.Getter public devicesOfRoom: (roomId: string) => DeviceAuthorizationsModel[]
  @AuthorizationUi.Getter public devicePermissions: (deviceId: string) => PermissionsViewModel
  @AuthorizationUi.Getter public devicePermissionsForRoom: (roomId: string) => PermissionsViewModel
  @AuthorizationUi.Action public grantDevicePermission: (payload: { deviceId: string, permission: Permission }) => Promise<void>
  @AuthorizationUi.Action public revokeDevicePermission: (payload: { deviceId: string, permission: Permission }) => Promise<void>
  @AuthorizationUi.Action public grantDevicePermissionForRoom: (payload: { roomId: string, permission: Permission }) => Promise<void>
  @AuthorizationUi.Action public revokeDevicePermissionForRoom: (payload: { roomId: string, permission: Permission }) => Promise<void>

  public collapsed = true

  public get devices(): DeviceAuthorizationsModel[] {
    return this.devicesOfRoom(this.room.roomId)
  }

  public get roomPermissions(): PermissionsViewModel {
    return this.devicePermissionsForRoom(this.room.roomId)
  }
  
  public get devicePermissionsOfRoom(): PermissionsViewModel[] {
    return this.collapsed ? [] : this.devices.map(d => this.devicePermissions(d.id))
  }

  public checkboxIcon(value?: boolean | null) {
    if (value === null) {
      return 'eco.checkbox-partial.white'
    }
    return value ? 'eco.checkbox-all.white' : 'eco.checkbox-none.white'
  }

  public toggleDeviceReadPermissionForRoom() {
    if (this.roomPermissions.hasRead) {
      this.revokeDevicePermissionForRoom({ roomId: this.roomPermissions.id, permission: Permission.READ })
    } else {
      this.grantDevicePermissionForRoom({ roomId: this.roomPermissions.id, permission: Permission.READ })
    }
  }

  public toggleDeviceExecutePermissionForRoom() {
    if (this.roomPermissions.hasExecute) {
      this.revokeDevicePermissionForRoom({ roomId: this.roomPermissions.id, permission: Permission.EXECUTE })
    } else {
      this.grantDevicePermissionForRoom({ roomId: this.roomPermissions.id, permission: Permission.EXECUTE })
    }
  }

  public toggleDeviceReadPermission(devicePermission: PermissionsViewModel) {
    if (devicePermission.hasRead) {
      this.revokeDevicePermission({ deviceId: devicePermission.id, permission: Permission.READ })
    } else {
      this.grantDevicePermission({ deviceId: devicePermission.id, permission: Permission.READ })
    }
  }

  public toggleDeviceExecutePermission(devicePermission: PermissionsViewModel) {
    if (devicePermission.hasExecute) {
      this.revokeDevicePermission({ deviceId: devicePermission.id, permission: Permission.EXECUTE })
    } else {
      this.grantDevicePermission({ deviceId: devicePermission.id, permission: Permission.EXECUTE })
    }
  }

  public toggleDeviceList() {
    if(this.devices.length > 0) {
      this.collapsed = !this.collapsed
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  table-layout: fixed;
  width: 100%;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-spacing: 0px;
  text-align: right;
  th {
    &.iconCol {
      width: 36px;
      justify-content: center;
      text-align: left;
    }
    &.descriptionCol {
      justify-items: center;
      text-align: left;
      width: 33%;
    }
    &.authCol {
      width: 33%;
    }
    &.collapse {
      width: 7%;
      cursor: pointer;
      .material-icons {
        color: white;
      }
    }
  }
  td, th {
    padding: 10px 10px;
    font-weight: normal;
    &.iconCol {
      justify-content: center;
      text-align: left;
      overflow: hidden;
    }
    &.descriptionCol {
      justify-items: center;
      text-align: left;
    }
    &.collapse {
      .material-icons {
        overflow: hidden;
        max-width: 24px;
        color: white;
      }
    }
  }
  .headerRow {
    background: linear-gradient(to bottom, #20476e, #20476e); 
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #20476e), color-stop(0.6, #20476e));
  }
  .deviceRow {
    background: linear-gradient(to bottom, #1a3856, #1a3856); 
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #1a3856), color-stop(0.6, #1a3856));
  }
  .authDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: -5px;
    > div {
      cursor: pointer;
      margin-left: 5px;
    }
  }
}
</style>