<template>
  <v-layout column class="egonline">
    <div class="egonline-head">
      <div class="egonline-title">
        {{ 'oneclick.egonline.header' | translate }}
      </div>
    </div>
    <v-layout row align-end>
      <v-flex shrink>
        <form-field :text="'oneclick.egonline.projectId' | translate" class="form-field">
          <text-input
            :value="egonlineProjectId"
            :placeholder="'oneclick.egonline.projectId' | translate"
            :disabled="adminEgonlineConfiguration.isLinked"
            @input="egonlineProjectId = $event"
          />
        </form-field>
      </v-flex>
      <v-flex shrink class="egonline-status">
        <popover class="infopopover" :color="infoPopoverColor" :icon="infoPopoverIcon" :header="'oneclick.egonline.header' | translate" :tooltip="infoPopoverText">
          {{ infoPopoverText }}
        </popover>
      </v-flex>
      <base-text-button class="egonline-button" v-if="!adminEgonlineConfiguration.isLinked"
        @buttonClicked="linkProjectEgonline(egonlineProjectId.trim())"
        :enabled="!isInteracted" 
      > 
        {{ 'oneclick.egonline.link' | translate }}
      </base-text-button>
      <base-text-button class="egonline-button" v-if="adminEgonlineConfiguration.isLinked"
        @buttonClicked="showConfirmUnlinkDialog = true"
        :enabled="!isInteracted" 
      > 
        {{ 'oneclick.egonline.unlink' | translate }}
      </base-text-button>
    </v-layout>
    <confirm-dialog 
      :show="showConfirmUnlinkDialog"
      :header="'oneclick.egonline.unlink' | translate"
      :text="'oneclick.egonline.unlink.confirmation' | translate"
      :cancel-button-text="'common.button.cancel' | translate"
      confirm-button-text="Ok"
      @confirmed="onUnlink"
      @cancelled="showConfirmUnlinkDialog = false"
    />
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { EgonlineConfigurationModel } from '../../../../../../eco-domain-store-modules/src/consumption/models'

const ConsumptionUi = namespace ('consumptionUi')
const Resource = namespace ('resource')

@Component({
  components: {
  },
})
export default class EgonlineConfiguration extends Vue {
  @ConsumptionUi.Getter public isInteracted: boolean
  @ConsumptionUi.Getter public adminEgonlineConfiguration: EgonlineConfigurationModel
  @ConsumptionUi.Action public linkProjectEgonline: (egonlineProjectId: string) => Promise<void>
  @ConsumptionUi.Action public unlinkProjectEgonline: () => Promise<void>
  @Resource.Getter public dictionary

  public showConfirmUnlinkDialog = false
  public egonlineProjectId = ''

  public mounted() {
    this.adminEgonlineConfigurationChanged()
  }

  @Watch('adminEgonlineConfiguration')
  public adminEgonlineConfigurationChanged() {
    this.egonlineProjectId = this.adminEgonlineConfiguration.egonlineProjectId
  }

  public get infoPopoverColor() {
    if (this.adminEgonlineConfiguration.isLinked) {
      return 'lime'
    } else {
      return 'yellow'
    }
  }

  public get infoPopoverIcon() {
    if (this.adminEgonlineConfiguration.isLinked) {
      return 'check_circle'
    } else {
      return 'help'
    }
  }

  public get infoPopoverText() {
    if (this.adminEgonlineConfiguration.isLinked) {
      return this.dictionary('oneclick.egonline.linked')
    } else {
      return this.dictionary('oneclick.egonline.unlinked')
    }
  }

  public onUnlink() {
    this.unlinkProjectEgonline()
    this.showConfirmUnlinkDialog = false
  }
}
</script>
<style lang="scss" scoped>
.egonline {
  margin-left: 30px;
  margin-bottom: 20px;
  .egonline-head {
    .egonline-title{
      font-size: 24px;
      line-height: 48px;
      height: 48px;
    }
  }
  .form-field {
    min-width: 300px;
    padding-right: 8px;
    margin-bottom: 8px;
    margin-left: -10px;
    div {
      margin-left: 10px;
    }
  }
  .egonline-status {
    margin-left: 10px;
    padding-bottom: 12px;
  }
  .v-btn {
    border: 1px solid white;
  }
}
</style>