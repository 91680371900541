import { Module } from 'vuex'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'
import { AuditLogAction, AuditLogMutation, AuditLogState } from './types'

const namespaced: boolean = true
export const auditLog: Module<AuditLogState,any> = {
  namespaced,
  state,
  actions,
  mutations,
}

export const types = {
  action:  AuditLogAction,
  mutation: AuditLogMutation,
}

export const domain = 'auditLog'

export const vuexModule = {
  [domain]: auditLog,
}