import { ArrayWithIdLookup } from '../helpers/arrayWithIdLookup'
import { ResourceModel } from './models'
import { ResourceState } from './types'

export const state: ResourceState = {
  appStrings: new ArrayWithIdLookup<ResourceModel>(),
  fbStrings: new ArrayWithIdLookup<ResourceModel>(),
  appIcons: new ArrayWithIdLookup<ResourceModel>(),
  deviceIcons: new ArrayWithIdLookup<ResourceModel>(),
  solutionHardwareIcons: new ArrayWithIdLookup<ResourceModel>(),
  roomIcons: new ArrayWithIdLookup<ResourceModel>(),
  sceneIcons: new ArrayWithIdLookup<ResourceModel>(),
  chartIcons: new ArrayWithIdLookup<ResourceModel>(),
  plcImages: new ArrayWithIdLookup<ResourceModel>(),
  terminalImages: new ArrayWithIdLookup<ResourceModel>(),
  moduleImages: new ArrayWithIdLookup<ResourceModel>(),
  systemConfigurationStrings: new ArrayWithIdLookup<ResourceModel>(),
}
