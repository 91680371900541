import { CustomPlcAlarmModel, MessageAlarmModel, ServiceAlarmModel } from './models'

export interface AlarmState {
  // collection of alarms
  serviceAlarms: ServiceAlarmModel[], // backend-service (only one active alarm)
  messageAlarms: MessageAlarmModel[], // custom(ised) messages from backend to ui
  customPlcAlarms: CustomPlcAlarmModel[], // custom (user specific) messages from plc

  // trigger that help ui to identify important pushs
  serviceAlarmTrigger: boolean
  messageAlarmTrigger: boolean
  customPlcAlarmTrigger: boolean
}

export enum AlarmAction {
  loadCustomPlcAlarmsForPlc = 'loadCustomPlcAlarmsForPlc',
  loadCustomPlcAlarmsForProject = 'loadCustomPlcAlarmsForProject',
  serviceAlarmReceived = 'serviceAlarmReceived',
  messageAlarmReceived = 'messageAlarmReceived',
  customPlcAlarmReceived = 'customPlcAlarmReceived',
  connectToAlarmStateHub = 'connectToAlarmStateHub',
  disconnectFromAlarmStateHub = 'disconnectFromAlarmStateHub',
  startForProject = 'startForProject',
  startForPlcs = 'startForPlcs',
}

export enum AlarmMutation {
  loadedServiceAlarms = 'loadedServiceAlarms',
  upsertServiceAlarm = 'upsertServiceAlarm',
  setServiceAlarmTrigger = 'setServiceAlarmTrigger',

  loadedMessageAlarms = 'loadedMessageAlarms',
  upsertMessageAlarm = 'upsertMessageAlarm',
  setMessageAlarmTrigger = 'setMessageAlarmTrigger',

  loadedCustomPlcAlarms = 'loadedCustomPlcAlarms',
  upsertCustomPlcAlarm = 'upsertCustomPlcAlarm',
  setCustomPlcAlarmTrigger = 'setCustomPlcAlarmTrigger'
}

export const AppIdWebApp: string = 'WebApp'  // includes setup-, partner-, authTool, oneclick and functionDefinitionEditor
export const AppIdMobileApp: string = 'MobileApp'
