<template>
    <v-btn flat
        class="base-text-button"
        @click="emitButtonClicked"
        :disabled="!enabled"
        :class="{ disabled: !enabled }">
        <slot></slot>
    </v-btn>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
    name: 'BaseTextButton',
    props: {
        /** 
         * determines if the button is enabled (if disabled, the click-event is not sent and the element an opacity of 0.4)
         */
        enabled: {
          type: Boolean,
          default: true,
        },
    },
    methods: {
        emitButtonClicked(e): void {
          if(this.enabled) {
            /**
             * Clicked event.
             *
             * @event buttonClicked
             * @type {object}
             */
            this.$emit('buttonClicked')
          }
        },
    },
})
</script>
<style lang="scss" scoped>
.base-text-button {
  color: $fore-color-primary;
  font-family: $font-family-primary;
  outline: none;
  &.disabled {
    opacity: 0.4;
    color: $fore-color-disabled !important;
  }
}

</style>

<docs>
An text button

The default text button
```jsx
<base-text-button>Some Text</base-text-button>
```

The emitted events
```js
new Vue({
  methods: {
    log(text) {
      console.log(text)
    }
  },
  template: `<base-text-button 
      @buttonClicked="() => log('buttonClicked')"
    >
      Some Text
    </base-text-button>`
})
```

Disabled
```jsx
<base-text-button :enabled="false" >Some Text</base-text-button>
```
</docs>
